import { useState, useEffect } from 'react';

export type Theme = 'light' | 'dark' | 'system';

export function useTheme() {
  // Initialize theme state
  const [theme, setTheme] = useState<Theme>(() => {
    if (typeof window === 'undefined') return 'dark';
    
    const stored = localStorage.getItem('theme');
    if (!stored) {
      // Set light mode as default
      localStorage.setItem('theme', 'light');
      document.documentElement.classList.remove('dark');
      document.documentElement.classList.add('light');
      return 'light';
    }
    
    return stored as Theme;
  });

  useEffect(() => {
    if (typeof window === 'undefined') return;

    function updateTheme() {
      const root = window.document.documentElement;
      root.classList.remove('light', 'dark');

      let newTheme = theme;
      if (theme === 'system') {
        newTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
          ? 'dark'
          : 'light';
      }

      root.classList.add(newTheme);
      localStorage.setItem('theme', theme);
    }
    
    updateTheme();

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handler = () => theme === 'system' && updateTheme();
    mediaQuery.addEventListener('change', handler);

    return () => mediaQuery.removeEventListener('change', handler);
  }, [theme]);

  return { theme, setTheme };
}