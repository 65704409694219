import React from 'react';
import { useState } from 'react';
import { Home, Users, DollarSign, LineChart, Settings, Shield, Database, Bell } from 'lucide-react';
import { useRouter } from '../../hooks/useRouter';
import { ROUTES } from '../../utils/routes';

export const adminNavigation = [
  { name: 'Overview', icon: Home, current: true, href: ROUTES.ADMIN.DASHBOARD },
  { name: 'User Management', icon: Users, current: false, href: ROUTES.ADMIN.USERS },
  { name: 'Revenue', icon: DollarSign, current: false, href: ROUTES.ADMIN.ANALYTICS },
  { name: 'Analytics', icon: LineChart, current: false, href: ROUTES.ADMIN.ANALYTICS },
  { name: 'System Health', icon: Database, current: false },
  { name: 'Security', icon: Shield, current: false, href: ROUTES.ADMIN.SECURITY },
  { name: 'Notifications', icon: Bell, current: false },
  { name: 'Settings', icon: Settings, current: false, href: ROUTES.ADMIN.SETTINGS }
];

export function getActiveRoute() {
  const path = window.location.pathname;
  return adminNavigation.find(item => item.href === path)?.name || 'Overview';
}
export default function AdminSidebar() {
  const [activeItem, setActiveItem] = useState(getActiveRoute());
  const { navigate } = useRouter();

  return (
    <aside className="w-64 bg-white dark:bg-slate-900 border-r border-gray-200 dark:border-gray-800 min-h-[calc(100vh-4rem)] sticky top-0">
      <nav className="p-4 space-y-1">
        {adminNavigation.map((item) => (
          <a
            key={item.name}
            onClick={() => {
              setActiveItem(item.name);
              if (item.href) {
                navigate(item.href);
              }
            }}
            className={`flex items-center px-4 py-3 rounded-lg text-sm font-medium transition-colors ${
              activeItem === item.name
                ? 'bg-gradient-to-r from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 text-blue-600 dark:text-blue-400'
                : 'text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-slate-800'
            }`}
            style={{ cursor: 'pointer' }}
          >
            <item.icon className="w-5 h-5 mr-3" />
            {item.name}
          </a>
        ))}
      </nav>
    </aside>
  );
}