import React, { useState } from 'react';
import { Mail, Building2, Users, ArrowRight, Shield, Clock, DollarSign, Calendar } from 'lucide-react';
import Button from '../ui/Button';
import { trackConversion } from '../../utils/analytics';
import { ROUTES } from '../../utils/routes';

interface ConsultationFormProps {
  isOpen: boolean;
  onClose: () => void;
}

// Generate available time slots (MST)
const generateTimeSlots = () => {
  const slots = [];
  for (let hour = 7; hour <= 14; hour++) { // 7am-2pm MST (9am-4pm EST)
    if (hour !== 10 && hour !== 11) { // Exclude lunch hours
      slots.push(`${hour}:00 MST`);
      slots.push(`${hour}:30 MST`);
    }
  }
  return slots;
};

const timeSlots = generateTimeSlots();

// Get next 14 available business days
const getAvailableDates = () => {
  const dates = [];
  let current = new Date();
  current.setDate(current.getDate() + 1); // Start from tomorrow
  
  while (dates.length < 14) {
    if (current.getDay() !== 0 && current.getDay() !== 6) { // Skip weekends
      dates.push(new Date(current));
    }
    current.setDate(current.getDate() + 1);
  }
  return dates;
};

const availableDates = getAvailableDates();

const ventureTypes = [
  { value: 'ai-saas', label: 'AI-First SaaS' },
  { value: 'ai-platform', label: 'AI Platform' },
  { value: 'ml-startup', label: 'ML Startup' },
  { value: 'ai-marketplace', label: 'AI Marketplace' }
];

const fundingStages = [
  { value: 'pre-seed', label: 'Pre-seed' },
  { value: 'seed', label: 'Seed' },
  { value: 'series-a', label: 'Series A' },
  { value: 'series-b', label: 'Series B+' }
];

const teamSizes = [
  { value: 'solo', label: 'Solo Founder' },
  { value: '2-5', label: '2-5 Team Members' },
  { value: '6-10', label: '6-10 Team Members' },
  { value: '10+', label: '10+ Team Members' }
];

export default function ConsultationForm({ isOpen, onClose }: ConsultationFormProps) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    role: '',
    ventureType: '',
    fundingStage: '',
    teamSize: '',
    date: '',
    timeSlot: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Format email content
      const emailContent = `
New Venture Builder Consultation Request

Date: ${new Date(formData.date).toLocaleDateString()}
Time: ${formData.timeSlot}
Name: ${formData.name}
Email: ${formData.email}
Company: ${formData.company}
Role: ${formData.role}
Venture Type: ${formData.ventureType}
Funding Stage: ${formData.fundingStage}
Team Size: ${formData.teamSize}

Additional Details:
${formData.message}
      `.trim();

      // Send to landon@galax.ai
      await fetch('/api/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          to: 'landon@galax.ai',
          subject: `Venture Builder Consultation Request - ${formData.company}`,
          text: emailContent
        })
      });

      // Track form submission
      trackConversion({
        type: 'form_submit',
        page: ROUTES.VENTURE_BUILDER,
        data: { 
          form: 'consultation',
          ventureType: formData.ventureType,
          fundingStage: formData.fundingStage
        }
      });

      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      setSuccess(true);
      setFormData({
        name: '',
        email: '',
        company: '',
        role: '',
        ventureType: '',
        fundingStage: '',
        teamSize: '',
        date: '',
        timeSlot: '',
        message: ''
      });

      // Close modal after delay
      setTimeout(() => {
        onClose();
        setSuccess(false);
      }, 2000);

    } catch (err) {
      setError('Failed to schedule consultation. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[100] flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm overflow-y-auto pt-24">
      <div className="relative w-full max-w-2xl bg-white dark:bg-slate-900 rounded-xl shadow-xl">
        <div className="p-8 max-h-[85vh] overflow-y-auto">
          {success ? (
            <div className="text-center py-8">
              <div className="inline-flex items-center justify-center p-2 rounded-lg bg-green-100 dark:bg-green-900/30 mb-4">
                <Shield className="w-8 h-8 text-green-600 dark:text-green-400" />
              </div>
              <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">
                Consultation Scheduled!
              </h3>
              <p className="text-gray-600 dark:text-gray-400">
                We'll send you a confirmation email with meeting details.
              </p>
            </div>
          ) : (
            <>
              <div className="text-center mb-8">
                <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
                  <Building2 className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                </div>
                <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
                  Schedule Venture Consultation
                </h2>
                <p className="text-gray-600 dark:text-gray-400">
                  Let's discuss your AI venture and how we can help you succeed
                </p>
              </div>

              {error && (
                <div className="mb-6 p-4 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 rounded-lg text-sm">
                  {error}
                </div>
              )}

              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Full Name
                    </label>
                    <input
                      type="text"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Email
                    </label>
                    <input
                      type="email"
                      value={formData.email}
                      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                      required
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Company/Project Name
                    </label>
                    <input
                      type="text"
                      value={formData.company}
                      onChange={(e) => setFormData({ ...formData, company: e.target.value })}
                      className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Role/Title
                    </label>
                    <input
                      type="text"
                      value={formData.role}
                      onChange={(e) => setFormData({ ...formData, role: e.target.value })}
                      className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                      required
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Venture Type
                    </label>
                    <select
                      value={formData.ventureType}
                      onChange={(e) => setFormData({ ...formData, ventureType: e.target.value })}
                      className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                      required
                    >
                      <option value="">Select venture type</option>
                      {ventureTypes.map(type => (
                        <option key={type.value} value={type.value}>
                          {type.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Funding Stage
                    </label>
                    <select
                      value={formData.fundingStage}
                      onChange={(e) => setFormData({ ...formData, fundingStage: e.target.value })}
                      className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                      required
                    >
                      <option value="">Select funding stage</option>
                      {fundingStages.map(stage => (
                        <option key={stage.value} value={stage.value}>
                          {stage.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Team Size
                    </label>
                    <select
                      value={formData.teamSize}
                      onChange={(e) => setFormData({ ...formData, teamSize: e.target.value })}
                      className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                      required
                    >
                      <option value="">Select team size</option>
                      {teamSizes.map(size => (
                        <option key={size.value} value={size.value}>
                          {size.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Preferred Date
                    </label>
                    <input
                      type="date"
                      value={formData.date}
                      onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                      min={availableDates[0].toISOString().split('T')[0]}
                      max={availableDates[availableDates.length - 1].toISOString().split('T')[0]}
                      className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                      required
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Preferred Time (MST)
                  </label>
                  <select
                    value={formData.timeSlot}
                    onChange={(e) => setFormData({ ...formData, timeSlot: e.target.value })}
                    className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                    required
                  >
                    <option value="">Select time slot (MST)</option>
                    {timeSlots.map(slot => (
                      <option key={slot} value={slot}>{slot}</option>
                    ))}
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Tell us about your venture
                  </label>
                  <textarea
                    value={formData.message}
                    onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                    rows={4}
                    className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                    placeholder="Brief description of your venture idea, current stage, and what you're looking to achieve..."
                  />
                </div>

                <div className="flex items-center justify-between mt-6">
                  <button
                    type="button"
                    onClick={onClose}
                    className="text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
                  >
                    Cancel
                  </button>
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    icon={ArrowRight}
                    loading={loading}
                  >
                    Schedule Consultation
                  </Button>
                </div>
              </form>

              {/* Trust Indicators */}
              <div className="mt-6 flex items-center justify-center space-x-6 text-sm text-gray-500">
                <div className="flex items-center">
                  <Shield className="w-4 h-4 text-green-500 mr-1" />
                  Secure & Confidential
                </div>
                <div className="flex items-center">
                  <Users className="w-4 h-4 text-blue-500 mr-1" />
                  45+ Ventures Launched
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}