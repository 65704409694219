import React from 'react';
import { Brain, Target, Rocket, ArrowRight, CheckCircle2, Star, Users, Shield, Building2, Zap } from 'lucide-react';
import Button from './ui/Button';

const differentiators = [
  {
    title: 'End-to-End Venture Building',
    icon: Rocket,
    description: 'We own the entire journey, from ideation to scaling. Unlike traditional consultants, we build and operate ventures ourselves.',
    metrics: {
      ventures: '20+',
      timeline: '6 months',
      success: '90% ROI'
    },
    comparison: {
      us: 'Full technical co-founding',
      them: 'Advisory services only'
    }
  },
  {
    title: 'Predictive Success',
    icon: Brain,
    description: 'Our AI predicts and maps the path to success before the first line of code. Success is intentional—not accidental.',
    metrics: {
      accuracy: '92%',
      insights: '10K+',
      savings: '$2.1M'
    },
    comparison: {
      us: 'AI-driven success prediction',
      them: 'Trial and error approach'
    }
  },
  {
    title: 'Multi-Market Expertise',
    icon: Building2,
    description: 'From startups to government agencies, we deliver scalable AI solutions across every sector.',
    metrics: {
      markets: '12+',
      clients: '500+',
      scale: 'Global'
    },
    comparison: {
      us: 'Cross-market expertise',
      them: 'Single market focus'
    }
  }
];

const testimonials = [
  {
    quote: "GALAX Studios doesn't just advise—they build. Their predictive AI approach saved us months of iteration.",
    author: "Sarah Chen",
    role: "CTO, TechFlow AI",
    metric: "$2.1M ARR in 6 months"
  },
  {
    quote: "Their technical co-founding support was game-changing. We launched 3x faster than planned.",
    author: "Michael Rodriguez",
    role: "Founder, DataSphere",
    metric: "10x efficiency gain"
  }
];

export default function WhyGalax() {
  return (
    <div className="py-24 bg-gradient-to-br from-gray-50 via-white to-gray-50 dark:from-[rgb(3,3,5)] dark:via-[rgb(8,8,15)] dark:to-[rgb(3,3,5)] relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(59,130,246,0.03),rgba(0,0,0,0))]" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_80%,rgba(139,92,246,0.03),rgba(0,0,0,0))]" />
        <div className="absolute inset-0 bg-[conic-gradient(from_0deg_at_50%_50%,rgba(139,92,246,0.03)_0deg,rgba(147,51,234,0.03)_120deg,rgba(168,85,247,0.03)_240deg)]" />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <Star className="w-6 h-6 text-blue-600 dark:text-blue-400" />
          </div>
          <h2 className="text-4xl sm:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-violet-600 to-purple-600 dark:from-blue-400 dark:via-violet-400 dark:to-purple-400">
            Why GALAX Studios
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            We are not just partners; we are creators. At GALAX Studios, we don't support ventures—we build them.
          </p>
        </div>

        {/* Differentiators Grid */}
        <div className="grid lg:grid-cols-3 gap-8 mb-16">
          {differentiators.map((diff) => {
            const Icon = diff.icon;
            return (
              <div
                key={diff.title}
                className="group relative bg-white dark:bg-slate-900 rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-[1.02] border border-gray-200/50 dark:border-gray-800/50"
              >
                <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-violet-500/5 dark:from-blue-400/5 dark:to-violet-400/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
                
                <div className="relative">
                  <div className="w-12 h-12 rounded-xl bg-gradient-to-br from-blue-100 to-violet-100 dark:from-blue-900/20 dark:to-violet-900/20 p-3 mb-6">
                    <Icon className="w-full h-full text-blue-600 dark:text-blue-400" />
                  </div>

                  <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-4">
                    {diff.title}
                  </h3>
                  
                  <p className="text-gray-600 dark:text-gray-400 mb-6">
                    {diff.description}
                  </p>

                  <div className="grid grid-cols-3 gap-2 mb-6">
                    {Object.entries(diff.metrics).map(([key, value]) => (
                      <div key={key} className="text-center p-2 bg-blue-50 dark:bg-blue-900/20 rounded-lg">
                        <div className="text-sm font-semibold text-blue-600 dark:text-blue-400">
                          {value}
                        </div>
                        <div className="text-xs text-gray-600 dark:text-gray-400 capitalize">
                          {key}
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Comparison */}
                  <div className="space-y-3 bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-lg p-4">
                    <div className="flex items-center text-sm">
                      <Zap className="w-4 h-4 text-blue-600 dark:text-blue-400 mr-2" />
                      <span className="text-gray-900 dark:text-white font-medium">
                        {diff.comparison.us}
                      </span>
                    </div>
                    <div className="flex items-center text-sm opacity-60">
                      <span className="w-4 h-4 mr-2" />
                      <span className="text-gray-600 dark:text-gray-400">
                        vs. {diff.comparison.them}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {/* Testimonials */}
        <div className="grid md:grid-cols-2 gap-8">
          {testimonials.map((testimonial) => (
            <div
              key={testimonial.author}
              className="bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-8"
            >
              <div className="flex items-center mb-6">
                <div className="p-2 rounded-lg bg-green-100 dark:bg-green-900/20">
                  <CheckCircle2 className="w-5 h-5 text-green-600 dark:text-green-400" />
                </div>
                <div className="ml-4">
                  <div className="font-semibold text-gray-900 dark:text-white">
                    {testimonial.author}
                  </div>
                  <div className="text-sm text-gray-600 dark:text-gray-400">
                    {testimonial.role}
                  </div>
                </div>
              </div>
              
              <blockquote className="text-lg text-gray-900 dark:text-white mb-4">
                "{testimonial.quote}"
              </blockquote>
              
              <div className="text-sm font-medium text-blue-600 dark:text-blue-400">
                {testimonial.metric}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}