import React, { useState } from 'react';
import { CheckCircle, XCircle, ArrowRight, Building2, Target, Coins, Sparkles } from 'lucide-react';
import Button from '../ui/Button';

const questions = [
  {
    id: 'organization',
    label: 'Organization Type',
    icon: Building2,
    options: ['Government Agency', 'Research Institution', 'Private Company', 'Non-Profit']
  },
  {
    id: 'project',
    label: 'Project Focus',
    icon: Target,
    options: ['AI Research', 'Implementation', 'Infrastructure', 'Training']
  },
  {
    id: 'budget',
    label: 'Project Budget',
    icon: Coins,
    options: ['< $100k', '$100k - $500k', '$500k - $1M', '> $1M']
  }
];

export default function EligibilityChecker() {
  const [answers, setAnswers] = useState<Record<string, string>>({});
  const [result, setResult] = useState<'eligible' | 'ineligible' | null>(null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Simple eligibility check - customize based on actual criteria
    const isEligible = answers.organization === 'Government Agency' || 
                      answers.organization === 'Research Institution';
    setResult(isEligible ? 'eligible' : 'ineligible');
  };

  return (
    <div className="py-16 bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 relative overflow-hidden">
      {/* Decorative background elements */}
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />
      <div className="absolute inset-y-0 right-0 w-1/2 bg-[radial-gradient(circle_at_70%_50%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />
      
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12 relative">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <Sparkles className="w-6 h-6 text-blue-600 dark:text-blue-400 animate-pulse" />
          </div>
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            Check Your Eligibility
          </h2>
          <p className="text-gray-600 dark:text-gray-300">
            Answer a few questions to see if you qualify for government AI grants
          </p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-8">
          {questions.map((question) => (
            <div key={question.id} className="group bg-white/80 dark:bg-slate-900/80 backdrop-blur-sm rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-200/50 dark:border-gray-700/50">
              <div className="flex items-center mb-6">
                <div className="p-2 rounded-lg bg-blue-50 dark:bg-blue-900/20 mr-3">
                  <question.icon className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                </div>
                <label className="text-lg font-medium text-gray-900 dark:text-white">
                  {question.label}
                </label>
              </div>
              <div className="grid sm:grid-cols-2 gap-4">
                {question.options.map((option) => (
                  <div
                    key={option}
                    className="relative group/option">
                  <button
                    key={option}
                    type="button"
                    onClick={() => setAnswers({ ...answers, [question.id]: option })}
                    className={`p-4 rounded-lg border-2 transition-all duration-300 relative overflow-hidden ${
                      answers[question.id] === option
                        ? 'border-blue-500 bg-gradient-to-br from-blue-50 to-blue-100/50 dark:from-blue-900/30 dark:to-blue-800/20 text-blue-700 dark:text-blue-300 shadow-md'
                        : 'border-gray-200 dark:border-gray-700 hover:border-blue-300 dark:hover:border-blue-700 hover:bg-gray-50 dark:hover:bg-slate-800/50 w-full'
                    }`}
                  >
                    {answers[question.id] === option && (
                      <>
                        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/[0.05] to-transparent translate-x-[-200%] group-hover:translate-x-[200%] transition-transform duration-[400ms] ease-out" />
                        <div className="absolute right-3 top-1/2 -translate-y-1/2">
                          <CheckCircle className="w-5 h-5 text-blue-600 dark:text-blue-400" />
                        </div>
                      </>
                    )}
                    <span className="relative z-10">{option}</span>
                    <div className="absolute inset-0 bg-gradient-to-r from-blue-500/0 via-blue-500/5 to-blue-500/0 opacity-0 group-hover/option:opacity-100 transition-opacity duration-300" />
                    {!answers[question.id] && (
                      <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/[0.03] to-transparent translate-x-[-200%] group-hover/option:translate-x-[200%] transition-transform duration-[400ms] ease-out" />
                    )}
                  </button>
                  </div>
                ))}
              </div>
            </div>
          ))}

          <div className="relative">
          <Button
            type="submit"
            variant="primary"
            size="lg"
            icon={ArrowRight}
            className="w-full group"
            disabled={Object.keys(answers).length !== questions.length}
          >
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/[0.05] to-transparent translate-x-[-200%] group-hover:translate-x-[200%] transition-transform duration-[400ms] ease-out" />
            Check Eligibility
          </Button>
          </div>
        </form>

        {result && (
          <div className={`mt-8 p-6 rounded-xl backdrop-blur-sm transition-all duration-500 ${
            result === 'eligible'
              ? 'bg-green-50/80 dark:bg-green-900/30 border-2 border-green-500/50 dark:border-green-500/30'
              : 'bg-red-50/80 dark:bg-red-900/30 border-2 border-red-500/50 dark:border-red-500/30'
          } animate-fade-in`}>
            <div className="flex items-center space-x-4">
              {result === 'eligible' ? (
                <div className="flex-shrink-0 w-12 h-12 rounded-full bg-green-100 dark:bg-green-900/30 flex items-center justify-center">
                  <CheckCircle className="w-6 h-6 text-green-600 dark:text-green-400" />
                </div>
              ) : (
                <div className="flex-shrink-0 w-12 h-12 rounded-full bg-red-100 dark:bg-red-900/30 flex items-center justify-center">
                  <XCircle className="w-6 h-6 text-red-600 dark:text-red-400" />
                </div>
              )}
              <div>
                <h3 className={`text-lg font-semibold ${
                  result === 'eligible'
                    ? 'text-green-900 dark:text-green-100'
                    : 'text-red-900 dark:text-red-100'
                }`}>
                  {result === 'eligible' ? 'You are eligible!' : 'Not eligible'}
                </h3>
                <p className={`text-sm ${
                  result === 'eligible'
                    ? 'text-green-700 dark:text-green-300'
                    : 'text-red-700 dark:text-red-300'
                }`}>
                  {result === 'eligible'
                    ? 'You may qualify for government AI grants. Contact us to learn more.'
                    : 'Unfortunately, you do not meet the current eligibility criteria.'}
                </p>
                {result === 'eligible' && (
                  <div className="mt-4">
                    <Button variant="primary" size="sm" icon={ArrowRight}>
                      View Available Grants
                    </Button>
                  </div>
                )}
                
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}