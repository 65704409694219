import { trackConversion } from './analytics';
import { ROUTES } from './routes';

export interface FunnelStep {
  id: string;
  name: string;
  order: number;
  requirements?: string[];
  conversion?: {
    type: string;
    value: number;
  };
}

export const FUNNEL_STEPS: FunnelStep[] = [
  {
    id: 'assessment',
    name: 'Business Assessment',
    order: 1,
    requirements: ['businessType', 'goals']
  },
  {
    id: 'solution',
    name: 'Solution Discovery',
    order: 2,
    requirements: ['selectedPlan']
  },
  {
    id: 'signup',
    name: 'Account Creation',
    order: 3,
    requirements: ['email']
  },
  {
    id: 'trial',
    name: 'Free Trial',
    order: 4,
    conversion: {
      type: 'trial_start',
      value: 0
    }
  },
  {
    id: 'subscription',
    name: 'Paid Subscription',
    order: 5,
    conversion: {
      type: 'purchase',
      value: 7.99
    }
  }
];

export interface FunnelState {
  currentStep: string;
  startTime: number;
  lastActive: number;
  completedSteps: string[];
  data: Record<string, any>;
}

export function initializeFunnel(): FunnelState {
  try {
    const funnelState = localStorage.getItem('funnelState');
    if (funnelState) {
      return JSON.parse(funnelState);
    }
    
    const initialState: FunnelState = {
      currentStep: 'assessment',
      startTime: Date.now(),
      lastActive: Date.now(),
      completedSteps: [],
      data: {}
    };
    
    localStorage.setItem('funnelState', JSON.stringify(initialState));
    return initialState;
  } catch (error) {
    console.error('Error initializing funnel:', error);
    // Return default state if error occurs
    return {
      currentStep: 'assessment',
      startTime: Date.now(),
      lastActive: Date.now(),
      completedSteps: [],
      data: {}
    };
  }
}

export function updateFunnelStep(step: string, data: Record<string, any>) {
  try {
    const state = getFunnelState();
    if (!state) return;

    // Calculate confidence score
    const confidence = calculateFunnelScore(data);
    
    // Track step completion
    trackConversion({
      type: 'funnel_step',
      page: window.location.pathname as any,
      data: {
        step,
        confidence,
        answers: data,
        timeInFunnel: Date.now() - state.startTime
      }
    });

    // Store updated state
    const updatedState = {
      ...state,
      currentStep: step,
      lastActive: Date.now(),
      data: { ...state.data, ...data },
      confidence
    };

    localStorage.setItem('funnelState', JSON.stringify(updatedState));

    // Return next best action
    return getNextBestAction(updatedState.data);
  } catch (error) {
    console.error('Error updating funnel:', error);
    return ROUTES.CHOOSE_PLAN;
  }
}

export function optimizeFunnelConversion() {
  const state = getFunnelState();
  if (!state) return;

  // Analyze drop-off points
  const dropOffPoints = analyzeDropOffPoints(state);

  // Generate personalized recommendations
  const recommendations = generateRecommendations(state.data);

  // Track optimization metrics
  trackConversion({
    type: 'funnel_optimization',
    page: window.location.pathname as any,
    data: {
      dropOffPoints,
      recommendations,
      confidence: state.confidence
    }
  });

  return recommendations;
}

function analyzeDropOffPoints(state: FunnelState) {
  const dropOffs = [];
  
  // Check for common drop-off scenarios
  if (!state.data.businessType) {
    dropOffs.push('initial_selection');
  }
  
  if (state.data.businessType && !state.data.goals) {
    dropOffs.push('goals_selection');
  }
  
  // Check time between steps
  const stepTimes = state.stepTimes || {};
  for (const [step, time] of Object.entries(stepTimes)) {
    if (time > 300000) { // 5 minutes
      dropOffs.push(`long_time_${step}`);
    }
  }

  return dropOffs;
}

export function getFunnelState(): FunnelState {
  try {
    const state = localStorage.getItem('funnelState');
    if (!state) {
      return initializeFunnel();
    }
    const parsedState = JSON.parse(state);
    // Validate state has required properties
    if (!parsedState.currentStep || !parsedState.startTime) {
      return initializeFunnel();
    }
    return parsedState;
  } catch (error) {
    console.error('Error getting funnel state:', error);
    return initializeFunnel();
  }
}

export function getFunnelProgress(): number {
  const state = getFunnelState();
  const totalSteps = FUNNEL_STEPS.length;
  const completedSteps = state.completedSteps.length;
  return Math.round((completedSteps / totalSteps) * 100);
}

export function getNextFunnelStep(): string | null {
  const state = getFunnelState();
  const currentStepIndex = FUNNEL_STEPS.findIndex(s => s.id === state.currentStep);
  const nextStep = FUNNEL_STEPS[currentStepIndex + 1];
  return nextStep?.id || null;
}

export function abandonedFunnelRecovery() {
  const state = getFunnelState();
  const timeSinceActive = Date.now() - state.lastActive;
  
  // If inactive for more than 30 minutes
  if (timeSinceActive > 30 * 60 * 1000) {
    trackConversion({
      type: 'funnel_abandoned',
      page: ROUTES.HOME,
      data: {
        lastStep: state.currentStep,
        timeInFunnel: Date.now() - state.startTime
      }
    });
  }
}