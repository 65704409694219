import React, { useState } from 'react';
import { Download, Copy, Share2, MoreVertical } from 'lucide-react';

interface ImagePreviewProps {
  src: string;
  onDownload: () => void;
  onCopy: () => void;
  onShare: () => void;
}

export default function ImagePreview({ src, onDownload, onCopy, onShare }: ImagePreviewProps) {
  const [showActions, setShowActions] = useState(false);

  return (
    <div 
      className="relative group rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-800"
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
    >
      <img
        src={src}
        alt="Generated image"
        className="w-full aspect-square object-cover"
      />
      
      {showActions && (
        <div className="absolute inset-0 bg-black/50 flex items-center justify-center space-x-2">
          <button
            onClick={onDownload}
            className="p-2 rounded-lg bg-white/10 hover:bg-white/20 text-white transition-colors"
            title="Download"
          >
            <Download className="w-5 h-5" />
          </button>
          <button
            onClick={onCopy}
            className="p-2 rounded-lg bg-white/10 hover:bg-white/20 text-white transition-colors"
            title="Copy"
          >
            <Copy className="w-5 h-5" />
          </button>
          <button
            onClick={onShare}
            className="p-2 rounded-lg bg-white/10 hover:bg-white/20 text-white transition-colors"
            title="Share"
          >
            <Share2 className="w-5 h-5" />
          </button>
          <button
            onClick={() => {}}
            className="p-2 rounded-lg bg-white/10 hover:bg-white/20 text-white transition-colors"
            title="More options"
          >
            <MoreVertical className="w-5 h-5" />
          </button>
        </div>
      )}
    </div>
  );
}