import React from 'react';
import { Brain, ArrowRight, Shield, Star, Users, Clock } from 'lucide-react';
import Button from '../components/ui/Button';
import { useRouter } from '../hooks/useRouter';
import { ROUTES } from '../utils/routes';
import { trackConversion } from '../utils/analytics';

const ACTIVE_USERS = Math.floor(Math.random() * 50) + 350;

export default function ToolsDirectory() {
  const { navigate } = useRouter();

  const handleGetStarted = () => {
    trackConversion({
      type: 'signup_start',
      page: ROUTES.TOOLS_DIRECTORY,
      data: { source: 'directory' }
    });
    window.location.href = 'https://buy.stripe.com/7sI9C76iF8LWfNS28p';
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <Brain className="w-6 h-6 text-blue-600 dark:text-blue-400" />
          </div>
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            AI Tools Directory
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300 mb-8">
            Access 500+ premium AI tools with exclusive discounts
          </p>

          {/* Trust Badges */}
          <div className="flex flex-wrap justify-center gap-8">
            <div className="flex items-center space-x-2">
              <Shield className="w-5 h-5 text-green-600 dark:text-green-400" />
              <span className="text-gray-700 dark:text-gray-300">
                <span className="font-semibold">7-day</span> free trial
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <Users className="w-5 h-5 text-blue-600 dark:text-blue-400" />
              <span className="text-gray-700 dark:text-gray-300">
                <span className="font-semibold">{ACTIVE_USERS}</span> active users
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <Star className="w-5 h-5 text-yellow-400 fill-yellow-400" />
              <span className="text-gray-700 dark:text-gray-300">
                <span className="font-semibold">4.9/5</span> rating
              </span>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="max-w-3xl mx-auto bg-white dark:bg-slate-900 rounded-xl p-8 shadow-lg border border-gray-200/50 dark:border-gray-800/50">
          <div className="text-center mb-8">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              Start Your Free Trial Today
            </h2>
            <p className="text-gray-600 dark:text-gray-400">
              Get instant access to all premium AI tools for 7 days, completely free.
              Then just $49/month for unlimited access.
            </p>
          </div>

          <Button
            variant="primary"
            size="lg"
            icon={ArrowRight}
            className="w-full group mb-4"
            onClick={handleGetStarted}
          >
            Start Free Trial
          </Button>

          <div className="flex items-center justify-center space-x-6 text-sm text-gray-500">
            <div className="flex items-center">
              <Shield className="w-4 h-4 text-green-500 mr-1" />
              No credit card required
            </div>
            <div className="flex items-center">
              <Clock className="w-4 h-4 text-blue-500 mr-1" />
              2-minute setup
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}