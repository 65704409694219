import React from 'react';
import { ArrowRight, TrendingUp, Clock, DollarSign, Brain, Target, Users, Star } from 'lucide-react';
import Button from '../components/ui/Button';

const caseStudies = [
  {
    company: 'TechFlow AI',
    industry: 'SaaS',
    logo: 'https://images.unsplash.com/photo-1560179707-f14e90ef3623?w=300&h=300&fit=crop',
    challenge: 'Needed to scale AI operations while reducing infrastructure costs',
    solution: 'Implemented our enterprise AI suite with custom integrations',
    results: {
      timeSaved: '45+ hours/month',
      costReduction: '65% lower costs',
      productivity: '3x team output',
      roi: '425% ROI'
    },
    quote: {
      text: "GALAX AI's solutions helped us scale our AI operations while significantly reducing costs. The ROI has been incredible.",
      author: "Sarah Chen",
      role: "CTO"
    },
    metrics: [
      { label: 'Cost Reduction', value: '65%', trend: 'up' },
      { label: 'Team Productivity', value: '300%', trend: 'up' },
      { label: 'Time Saved', value: '45h/mo', trend: 'up' }
    ]
  },
  {
    company: 'DataSphere',
    industry: 'Data Analytics',
    logo: 'https://images.unsplash.com/photo-1551434678-e076c223a692?w=300&h=300&fit=crop',
    challenge: 'Manual data processing created bottlenecks in analysis workflow',
    solution: 'Deployed AI-powered data processing automation',
    results: {
      timeSaved: '60+ hours/month',
      accuracy: '99.9% accuracy',
      throughput: '10x data processing',
      roi: '312% ROI'
    },
    quote: {
      text: "We've eliminated data bottlenecks and can now process 10x more data with better accuracy.",
      author: "Michael Rodriguez",
      role: "Head of Analytics"
    },
    metrics: [
      { label: 'Processing Speed', value: '10x', trend: 'up' },
      { label: 'Accuracy', value: '99.9%', trend: 'up' },
      { label: 'Cost Savings', value: '$50K/mo', trend: 'up' }
    ]
  },
  {
    company: 'HealthTech Solutions',
    industry: 'Healthcare',
    logo: 'https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?w=300&h=300&fit=crop',
    challenge: 'Needed to improve patient care while reducing administrative burden',
    solution: 'Implemented AI-powered healthcare automation suite',
    results: {
      timeSaved: '50+ hours/month',
      satisfaction: '95% satisfaction',
      efficiency: '4x faster processing',
      roi: '289% ROI'
    },
    quote: {
      text: "The AI automation has transformed our workflow, allowing our staff to focus more on patient care.",
      author: "Dr. Emily Thompson",
      role: "Medical Director"
    },
    metrics: [
      { label: 'Patient Satisfaction', value: '95%', trend: 'up' },
      { label: 'Processing Speed', value: '4x', trend: 'up' },
      { label: 'Time Saved', value: '50h/mo', trend: 'up' }
    ]
  }
];

export default function CaseStudies() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <Target className="w-6 h-6 text-blue-600 dark:text-blue-400" />
          </div>
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Customer Success Stories
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            See how leading companies are transforming their businesses with our AI solutions
          </p>
        </div>

        {/* Quick Stats */}
        <div className="grid grid-cols-4 gap-6 mb-16">
          {[
            { icon: Users, value: '50,000+', label: 'Active Users' },
            { icon: Star, value: '4.9/5', label: 'Customer Rating' },
            { icon: Brain, value: '500+', label: 'AI Tools' },
            { icon: TrendingUp, value: '312%', label: 'Avg. ROI' }
          ].map(({ icon: Icon, value, label }) => (
            <div key={label} className="bg-white/80 dark:bg-slate-900/80 backdrop-blur-sm rounded-xl p-6 border border-gray-200/50 dark:border-gray-800/50">
              <Icon className="w-6 h-6 text-blue-600 dark:text-blue-400 mb-2" />
              <div className="text-2xl font-bold text-gray-900 dark:text-white mb-1">{value}</div>
              <div className="text-sm text-gray-600 dark:text-gray-400">{label}</div>
            </div>
          ))}
        </div>

        {/* Case Studies */}
        <div className="space-y-16">
          {caseStudies.map((study, index) => (
            <div
              key={study.company}
              className="group relative bg-white dark:bg-slate-900 rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-200/50 dark:border-gray-800/50"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-violet-500/5 dark:from-blue-400/5 dark:to-violet-400/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
              
              <div className="relative">
                <div className="flex flex-col lg:flex-row gap-8">
                  {/* Logo & Company Info */}
                  <div className="lg:w-64">
                    <img
                      src={study.logo}
                      alt={`${study.company} logo`}
                      className="w-16 h-16 rounded-xl mb-4 object-cover"
                    />
                    <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
                      {study.company}
                    </h2>
                    <div className="text-sm text-blue-600 dark:text-blue-400 font-medium mb-4">
                      {study.industry}
                    </div>
                  </div>

                  {/* Main Content */}
                  <div className="flex-1 space-y-6">
                    {/* Challenge & Solution */}
                    <div className="grid md:grid-cols-2 gap-6">
                      <div>
                        <h3 className="font-semibold text-gray-900 dark:text-white mb-2">
                          Challenge
                        </h3>
                        <p className="text-gray-600 dark:text-gray-400">
                          {study.challenge}
                        </p>
                      </div>
                      <div>
                        <h3 className="font-semibold text-gray-900 dark:text-white mb-2">
                          Solution
                        </h3>
                        <p className="text-gray-600 dark:text-gray-400">
                          {study.solution}
                        </p>
                      </div>
                    </div>

                    {/* Results Grid */}
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                      {Object.entries(study.results).map(([key, value]) => (
                        <div key={key} className="bg-gray-50 dark:bg-slate-800 rounded-lg p-4">
                          <div className="text-lg font-bold text-blue-600 dark:text-blue-400">
                            {value}
                          </div>
                          <div className="text-sm text-gray-600 dark:text-gray-400 capitalize">
                            {key.replace(/([A-Z])/g, ' $1').trim()}
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Quote */}
                    <div className="bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-lg p-6">
                      <blockquote className="text-gray-900 dark:text-white mb-4">
                        "{study.quote.text}"
                      </blockquote>
                      <div className="font-medium text-gray-900 dark:text-white">
                        {study.quote.author}
                      </div>
                      <div className="text-sm text-gray-600 dark:text-gray-400">
                        {study.quote.role}, {study.company}
                      </div>
                    </div>
                  </div>
                </div>

                {/* View Full Case Study Button */}
                <div className="mt-6 text-right">
                  <Button variant="outline" size="sm" icon={ArrowRight}>
                    Read Full Case Study
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* CTA Section */}
        <div className="mt-16 bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-8 text-center">
          <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Ready to Transform Your Business?
          </h3>
          <p className="text-gray-600 dark:text-gray-400 mb-6 max-w-2xl mx-auto">
            Join thousands of companies already using our AI solutions to drive growth and efficiency.
          </p>
          <Button variant="primary" size="lg" icon={ArrowRight}>
            Start Free Trial
          </Button>
        </div>
      </div>
    </div>
  );
}