import React, { useState, useEffect } from 'react';
import { Calculator, GraduationCap, Rocket, DollarSign, Clock, Brain, Target, CheckCircle2, ArrowRight, BarChart, Star, Users, Zap } from 'lucide-react';
import Button from '../components/ui/Button';
import { useRouter } from '../hooks/useRouter';
import { ROUTES } from '../utils/routes';

interface CareerPath {
  type: 'college' | 'venture';
  initialCost: number;
  monthlyExpenses: number;
  timeToRevenue: number;
  projectedEarnings: {
    year1: number;
    year5: number;
    year10: number;
  };
  risks: string[];
  benefits: string[];
}

const defaultPaths: Record<'college' | 'venture', CareerPath> = {
  college: {
    type: 'college',
    initialCost: 40000, // Annual tuition
    monthlyExpenses: 2000, // Living expenses, books, etc.
    timeToRevenue: 48, // 4 years + job search
    projectedEarnings: {
      year1: 65000, // Entry level salary
      year5: 95000, // Mid-level salary
      year10: 130000 // Senior level salary
    },
    risks: [
      'Student loan debt burden',
      'Job market uncertainty',
      'Skills becoming outdated',
      'Limited earning potential',
      'Fixed career path'
    ],
    benefits: [
      'Traditional career stability',
      'Structured learning path',
      'Professional network',
      'Established credentials',
      'Lower initial risk'
    ]
  },
  venture: {
    type: 'venture',
    initialCost: 15000, // Initial tech setup & MVP
    monthlyExpenses: 1000, // Operating costs
    timeToRevenue: 6, // 6 months to first revenue
    projectedEarnings: {
      year1: 120000, // First year revenue
      year5: 500000, // Scale phase revenue
      year10: 2000000 // Mature business revenue
    },
    risks: [
      'Initial uncertainty',
      'Market competition',
      'Cash flow management',
      'Technical challenges',
      'Scaling pressure'
    ],
    benefits: [
      'Unlimited earning potential',
      'Full creative control',
      'Flexible lifestyle',
      'Cutting-edge skills',
      'Equity appreciation'
    ]
  }
};

export default function CareerCalculator() {
  const [selectedPath, setSelectedPath] = useState<'college' | 'venture'>('venture');
  const [timeframe, setTimeframe] = useState<1 | 5 | 10>(5);
  const [showTooltip, setShowTooltip] = useState<string | null>(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [chartView, setChartView] = useState<'earnings' | 'roi' | 'timeline'>('earnings');
  const { navigate } = useRouter();
  const [progress, setProgress] = useState(0);
  const [showDetails, setShowDetails] = useState<'risks' | 'benefits' | null>(null);

  // Update progress as user interacts
  useEffect(() => {
    let score = 0;
    if (selectedPath) score += 50;
    if (timeframe) score += 50;
    setProgress(score);
  }, [selectedPath, timeframe]);

  const calculateTotalCost = (path: CareerPath) => {
    const years = timeframe;
    const totalInitialCost = path.type === 'college' ? path.initialCost * 4 : path.initialCost;
    const totalMonthlyExpenses = path.monthlyExpenses * 12 * years;
    const opportunityCost = path.type === 'college' ? 
      defaultPaths.venture.projectedEarnings.year1 * (years > 4 ? 4 : years) / 2 : 0;
    return totalInitialCost + totalMonthlyExpenses + opportunityCost;
  };

  const calculateTotalEarnings = (path: CareerPath) => {
    switch (timeframe) {
      case 1:
        return path.projectedEarnings.year1;
      case 5:
        return path.projectedEarnings.year5;
      case 10:
        return path.projectedEarnings.year10;
      default:
        return 0;
    }
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    }).format(amount);
  };

  const selectedPathData = defaultPaths[selectedPath];
  const totalCost = calculateTotalCost(selectedPathData);
  const totalEarnings = calculateTotalEarnings(selectedPathData);
  const roi = ((totalEarnings - totalCost) / totalCost) * 100;

  return (
    <div id="career-calculator" className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-24 pb-16 overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute top-1/4 left-1/4 w-64 h-64 bg-blue-400/10 dark:bg-blue-400/5 rounded-full blur-3xl animate-pulse-slow" />
        <div className="absolute bottom-1/4 right-1/4 w-64 h-64 bg-violet-400/10 dark:bg-violet-400/5 rounded-full blur-3xl animate-pulse-slow" style={{ animationDelay: '-2s' }} />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Animated Stats Banner */}
        <div className="text-center mb-8">
          <div className="inline-block px-6 py-3 bg-gradient-to-r from-blue-600 to-violet-600 text-white rounded-full text-sm font-medium animate-pulse">
            🚀 95% of our ventures reach profitability within 12 months
          </div>
        </div>
        {/* Header */}
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <Calculator className="w-6 h-6 text-blue-600 dark:text-blue-400" />
          </div>
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Compare Your Career Path ROI
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            See how launching an AI venture compares to traditional education over time
          </p>

          {/* Quick Stats */}
          <div className="flex flex-wrap justify-center gap-8 mt-8">
            <div className="flex items-center space-x-2">
              <Zap className="w-5 h-5 text-blue-600 dark:text-blue-400" />
              <span className="text-gray-700 dark:text-gray-300">
                <span className="font-semibold">10x</span> Faster Launch
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <Star className="w-5 h-5 text-yellow-400 fill-yellow-400" />
              <span className="text-gray-700 dark:text-gray-300">
                <span className="font-semibold">98%</span> Success Rate
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <Users className="w-5 h-5 text-violet-600 dark:text-violet-400" />
              <span className="text-gray-700 dark:text-gray-300">
                <span className="font-semibold">15+</span> Ventures Launched
              </span>
            </div>
          </div>
        </div>

        {/* Path Selection */}
        <div className="grid md:grid-cols-2 gap-8 mb-12">
          {(['college', 'venture'] as const).map((path) => (
            <button
              key={path}
              onClick={() => setSelectedPath(path)}
              className={`group relative p-8 rounded-xl text-left transition-all ${
                selectedPath === path
                  ? 'bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 border-2 border-blue-500 shadow-xl scale-[1.02]'
                  : 'bg-white dark:bg-slate-900 border-2 border-gray-200 dark:border-gray-700 hover:border-blue-300'
              }`}
            >
              <div className="flex items-start">
                <div className="p-3 rounded-lg bg-gradient-to-br from-blue-100 to-violet-100 dark:from-blue-900/20 dark:to-violet-900/20 mr-3 group-hover:scale-110 transition-transform duration-300">
                  {path === 'college' ? (
                    <GraduationCap className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                  ) : (
                    <Rocket className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                  )}
                </div>
                <div>
                  <h3 className="font-medium text-gray-900 dark:text-white">
                    {path === 'college' ? 'Traditional College' : 'AI Venture'}
                    {path === 'venture' && (
                      <span className="ml-2 inline-block px-2 py-1 text-xs bg-green-100 dark:bg-green-900/30 text-green-600 dark:text-green-400 rounded-full">
                        Recommended
                      </span>
                    )}
                  </h3>
                  <p className="text-sm text-blue-600 dark:text-blue-400">
                    {path === 'college' ? '4-Year Degree Path' : 'Tech Startup Path'}
                  </p>
                </div>
              </div>

              {/* Key Metrics */}
              <div className="grid grid-cols-2 gap-4 mt-6">
                <div className="bg-blue-50 dark:bg-blue-900/20 rounded-lg p-3">
                  <div className="text-sm text-gray-600 dark:text-gray-400">Initial Cost</div>
                  <div className="font-medium text-gray-900 dark:text-white">
                    {formatCurrency(defaultPaths[path].initialCost)}
                  </div>
                </div>
                <div className="bg-blue-50 dark:bg-blue-900/20 rounded-lg p-3">
                  <div className="text-sm text-gray-600 dark:text-gray-400">Time to Revenue</div>
                  <div className="font-medium text-gray-900 dark:text-white">
                    {defaultPaths[path].timeToRevenue} months
                  </div>
                </div>
              </div>

              {/* ROI Preview */}
              <div className="mt-6 bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-lg p-4">
                <div className="text-sm font-medium text-blue-700 dark:text-blue-200 mb-2">
                  5-Year Potential
                </div>
                <div className="text-2xl font-bold text-gray-900 dark:text-white">
                  {formatCurrency(defaultPaths[path].projectedEarnings.year5)}
                </div>
              </div>
            </button>
          ))}
        </div>

        {/* Timeframe Selection */}
        <div className="mb-12">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4 text-center">
            Select Timeframe
          </h3>
          <div className="flex justify-center gap-4">
            {([1, 5, 10] as const).map((years) => (
              <button
                key={years}
                onClick={() => setTimeframe(years)}
                className={`px-6 py-3 rounded-lg transition-all ${
                  timeframe === years
                    ? 'bg-gradient-to-r from-blue-600 to-violet-600 text-white shadow-lg'
                    : 'bg-white dark:bg-slate-900 text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-slate-800 border border-gray-200 dark:border-gray-700'
                }`}
              >
                {years} {years === 1 ? 'Year' : 'Years'}
              </button>
            ))}
          </div>
        </div>

        {/* Results Chart */}
        <div className="mb-12 bg-white dark:bg-slate-900 rounded-xl p-6 border border-gray-200/50 dark:border-gray-800/50">
          <div className="flex items-center mb-6">
            <BarChart className="w-6 h-6 text-blue-600 dark:text-blue-400 mr-2" />
            <h4 className="text-lg font-semibold text-gray-900 dark:text-white">
              Earnings Comparison Over Time
            </h4>
            <div className="flex space-x-2">
              <button
                onClick={() => setChartView('earnings')}
                className={`px-3 py-1 rounded-lg text-sm ${
                  chartView === 'earnings'
                    ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400'
                    : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800'
                }`}
              >
                Earnings
              </button>
              <button
                onClick={() => setChartView('roi')}
                className={`px-3 py-1 rounded-lg text-sm ${
                  chartView === 'roi'
                    ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400'
                    : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800'
                }`}
              >
                ROI
              </button>
              <button
                onClick={() => setChartView('timeline')}
                className={`px-3 py-1 rounded-lg text-sm ${
                  chartView === 'timeline'
                    ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400'
                    : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800'
                }`}
              >
                Timeline
              </button>
            </div>
          </div>

          <div className="relative h-64">
            {/* Chart Lines */}
            <div className="absolute inset-0 flex items-end">
              {/* College Path Line */}
              <div 
                className="w-1/2 bg-gradient-to-t from-blue-500/20 to-transparent"
                style={{ 
                  height: `${(defaultPaths.college.projectedEarnings.year10 / 2000000) * 100}%`,
                  transition: 'height 1s ease-out'
                }}
              >
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-6 text-sm font-medium text-blue-600 dark:text-blue-400">
                  {formatCurrency(defaultPaths.college.projectedEarnings.year10)}
                </div>
              </div>
              {/* Venture Path Line */}
              <div 
                className="w-1/2 bg-gradient-to-t from-green-500/20 to-transparent"
                style={{ 
                  height: `${(defaultPaths.venture.projectedEarnings.year10 / 2000000) * 100}%`,
                  transition: 'height 1s ease-out'
                }}
              >
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-6 text-sm font-medium text-green-600 dark:text-green-400">
                  {formatCurrency(defaultPaths.venture.projectedEarnings.year10)}
                </div>
              </div>
            </div>
            {/* X-Axis Labels */}
            <div className="absolute bottom-0 left-0 right-0 flex justify-around text-sm text-gray-600 dark:text-gray-400">
              <div>College Path</div>
              <div>AI Venture Path</div>
            </div>
          </div>
          
          {/* Key Metrics */}
          <div className="grid grid-cols-3 gap-6 mt-8 pt-8 border-t border-gray-200 dark:border-gray-700">
            <div className="text-center">
              <div className="text-sm text-gray-500 dark:text-gray-400 mb-1">Total Investment</div>
              <div className="text-xl font-bold text-gray-900 dark:text-white">
                {formatCurrency(totalCost)}
              </div>
            </div>
            <div className="text-center">
              <div className="text-sm text-gray-500 dark:text-gray-400 mb-1">Total Earnings</div>
              <div className="text-xl font-bold text-green-600 dark:text-green-400">
                {formatCurrency(totalEarnings)}
              </div>
            </div>
            <div className="text-center">
              <div className="text-sm text-gray-500 dark:text-gray-400 mb-1">ROI</div>
              <div className="text-xl font-bold text-blue-600 dark:text-blue-400">
                {roi.toFixed(0)}%
              </div>
            </div>
          </div>
        </div>

        {/* Take Action Section */}
        <div className="text-center">
          <div className="bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-8 max-w-3xl mx-auto">
            <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              Ready to Start Your AI Journey?
            </h3>
            <p className="text-gray-600 dark:text-gray-400 mb-6">
              Join our venture builder program and get elite technical co-founding support to launch your AI startup.
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <Button
                variant="primary"
                size="lg"
                icon={ArrowRight}
                onClick={() => navigate(ROUTES.VENTURE_BUILDER)}
                className="group"
              >
                Start Building Today
              </Button>
              <Button
                variant="outline"
                size="lg"
                icon={ArrowRight}
                onClick={() => navigate(ROUTES.CASE_STUDIES)}
              >
                View Success Stories
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}