import React, { useState, useEffect } from 'react';
import AdminHeader from './AdminHeader';
import AdminSidebar from './AdminSidebar';
import { getActiveRoute } from './AdminSidebar';
import { checkAdminAuth, redirectToLogin } from '../../utils/adminAuth';

interface AdminLayoutProps {
  children: React.ReactNode;
}

export default function AdminLayout({ children }: AdminLayoutProps) {
  const [activeRoute, setActiveRoute] = useState(getActiveRoute());
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    try {
      // Check admin authentication
      const checkAuth = async () => {
        try {
          const hasAccess = await checkAdminAuth();
          if (!hasAccess) {
            redirectToLogin();
            return;
          }
          setIsAuthorized(true);
        } catch (error) {
          console.error('Auth check error:', error);
          redirectToLogin();
        } finally {
          setIsLoading(false);
        }
      };
      
      checkAuth();

      // Update active route when URL changes
      const handleRouteChange = () => {
        setActiveRoute(getActiveRoute());
      };

      window.addEventListener('popstate', handleRouteChange);
      return () => window.removeEventListener('popstate', handleRouteChange);
    } catch (error) {
      handleError(error);
      redirectToLogin();
    }
  }, []);

  // Show loading state
  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="w-8 h-8 border-4 border-blue-600 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  // Prevent unauthorized access
  if (!isAuthorized) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/10 to-gray-50 dark:from-slate-950 dark:via-blue-950/10 dark:to-slate-950">
      <AdminHeader />
      
      <div className="flex">
        <AdminSidebar />
        
        <main className="flex-1 p-8 ml-64">
          {children}
        </main>
      </div>
    </div>
  );
}