export default function HowWeWork() {
  const caseStudies = [
  {
    company: 'Mod.Homes',
    logo: 'https://images.unsplash.com/photo-1560518883-ce09059eeffa?w=300',
    result: 'Revolutionizing modular home production with AI-driven permitting and manufacturing optimization',
    metrics: {
      'Homes Needed': '30,000+',
      'In Production': '1,000+',
      'Permit Speed': '90% faster'
    }
  },
  {
    company: 'AI.NYC',
    logo: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?w=300',
    result: 'Pioneering Ethical AI and NYC 3.0 initiatives, transforming the city\'s technological landscape',
    metrics: {
      'Ethical AI': '100% Compliant',
      'NYC 3.0': 'City-wide Impact',
      'Innovation': 'Web3 Leader'
    }
  }
];

  return (
    <div className="py-24 bg-gradient-to-br from-gray-50 via-white to-gray-50 dark:from-[rgb(8,12,23)] dark:via-[rgb(10,14,25)] dark:to-[rgb(8,12,23)] relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_80%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />
        <div className="absolute inset-0 bg-[conic-gradient(from_0deg_at_50%_50%,rgba(139,92,246,0.05)_0deg,rgba(147,51,234,0.05)_120deg,rgba(168,85,247,0.05)_240deg)]" />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Case Studies */}
        <div className="grid md:grid-cols-2 gap-8">
          {caseStudies.map((study) => (
            <div
              key={study.company}
              className="bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-8"
            >
              <div className="flex items-center mb-6">
                <img
                  src={study.logo}
                  alt={study.company}
                  className="w-16 h-16 rounded-xl object-cover mr-4"
                />
                <h3 className="font-bold text-gray-900 dark:text-white">
                  {study.company}
                </h3>
              </div>
              
              <p className="text-lg text-gray-900 dark:text-white mb-6">
                {study.result}
              </p>

              <div className="grid grid-cols-3 gap-4">
                {Object.entries(study.metrics).map(([key, value]) => (
                  <div key={key} className="text-center">
                    <div className="text-xl font-bold bg-gradient-to-br from-blue-600 to-violet-600 dark:from-blue-400 dark:to-violet-400 bg-clip-text text-transparent">
                      {value}
                    </div>
                    <div className="text-sm text-gray-600 dark:text-gray-400 font-medium capitalize">
                      {key}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}