import React, { useState } from 'react';
import { Search, ArrowUpDown, Star, Zap, Shield, Users, Clock, ArrowRight, CheckCircle2 } from 'lucide-react';
import { categories, tools } from '../data/toolsData';
import { checkToolAccess, redirectToPricing } from '../utils/auth';
import Button from '../components/ui/Button';
import ToolCard from '../components/tools/ToolCard';
import ToolsFilters from '../components/tools/ToolsFilters';
import { filterTools } from '../utils/toolFilters';
import UrgencyTimer from '../components/conversion/UrgencyTimer';

// Video mapping for tools
export const toolVideos: Record<string, string> = {
  'Claude 3': 'gSBpoSL2Org',
  'Midjourney V6': 'h5nxat56wKI',
  'Sora': 'HK6y4TgqFxg',
  'Gemini Pro': 'UIZAiXYXkPo',
  'Runway Gen-2': 'oXqGI5kYgSs',
  'Anthropic API': 'x8TtcOYrjhM'
};

type SortKey = 'rating' | 'reviewCount' | 'name';

export default function Tools() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const hasAccess = checkToolAccess();

  const [filters, setFilters] = useState({
    industry: '',
    size: '',
    useCase: '',
    priceRange: '',
    sortBy: 'popular',
    searchQuery: ''
  });

  const handleFilterChange = (key: string, value: string) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  const filteredTools = filterTools(tools, filters);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-900 pt-32 font-[system-ui]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Quick stats */}
        <div className="grid grid-cols-4 gap-6 mb-8">
          {[
            { icon: Users, value: '50,000+', label: 'Active Users' },
            { icon: Star, value: '4.9/5', label: 'Average Rating' },
            { icon: Shield, value: '99.9%', label: 'Uptime SLA' },
            { icon: Clock, value: '24/7', label: 'Support' }
          ].map(({ icon: Icon, value, label }) => (
            <div key={label} className="bg-white/80 dark:bg-slate-900/90 backdrop-blur-sm rounded-xl p-4 text-center border border-gray-200/50 dark:border-gray-800/60">
              <Icon className="w-6 h-6 text-blue-600 dark:text-blue-400 mx-auto mb-2" />
              <div className="text-xl font-bold text-gray-900 dark:text-slate-50">{value}</div>
              <div className="text-sm text-gray-600 dark:text-slate-400 font-medium">{label}</div>
            </div>
          ))}
        </div>

        {/* Category tabs */}
        <div className="mb-8">
          <div className="flex space-x-2 overflow-x-auto pb-2">
            <button
              onClick={() => handleFilterChange('industry', '')}
              className={`px-4 py-2 rounded-lg whitespace-nowrap transition-colors ${
                filters.industry === ''
                  ? 'bg-gradient-to-r from-blue-600 to-violet-600 dark:from-blue-500 dark:to-violet-500 text-white shadow-lg'
                  : 'bg-white dark:bg-slate-900/90 text-gray-600 dark:text-gray-400 hover:bg-gray-50 dark:hover:bg-slate-800/90 border border-gray-200/50 dark:border-gray-800/60'
              }`}
            >
              All Tools
            </button>
            {categories.map(category => (
              <button
                key={category.id}
                onClick={() => handleFilterChange('industry', category.id)}
                className={`px-4 py-2 rounded-lg whitespace-nowrap transition-all duration-200 ${
                  filters.industry === category.id
                    ? 'bg-gradient-to-r from-blue-600 to-violet-600 dark:from-blue-500 dark:to-violet-500 text-white shadow-lg font-medium'
                    : 'bg-white dark:bg-slate-900/90 text-gray-600 dark:text-slate-300 hover:bg-gray-50 dark:hover:bg-slate-800/90 border border-gray-200/50 dark:border-gray-800/60 font-medium'
                }`}
              >
                {category.name}
              </button>
            ))}
          </div>
        </div>

        {/* Advanced Filters */}
        <div className="mb-8">
          <ToolsFilters
            filters={filters}
            onFilterChange={handleFilterChange}
          />
        </div>

        {/* Special offer banner */}
        <div className="mb-8">
          <UrgencyTimer />
        </div>

        {/* Tools list */}
        <div className="space-y-4">
          {filteredTools.map((tool) => (
            <ToolCard
              key={tool.name}
              tool={tool}
              videoId={toolVideos[tool.name]}
            />
          ))}
        </div>
        
        {/* Bottom CTA */}
        <div className="mt-12 text-center">
          <div className="inline-block">
          <div className="bg-gradient-to-r from-blue-600 to-violet-600 text-white px-4 py-1 rounded-full text-sm font-medium mb-4 animate-pulse">
            🔥 Special Launch Offer - Save 60% Today
          </div>
          </div>
          <Button 
            variant="primary" 
            size="lg" 
            icon={ArrowRight}
            onClick={() => !hasAccess && redirectToPricing()}
            className="w-full max-w-md mx-auto group"
          >
            <span className="relative">
              {hasAccess ? 'Access All Tools' : 'Get All Tools for $7.99/mo'}
              <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/[0.1] to-transparent translate-x-[-200%] group-hover:translate-x-[200%] transition-transform duration-[400ms]" />
            </span>
          </Button>
          <div className="mt-4 flex items-center justify-center space-x-6 text-sm text-gray-500 dark:text-slate-400 font-medium">
            <div className="flex items-center">
              <CheckCircle2 className="w-4 h-4 text-green-500 mr-1" />
              No credit card required
            </div>
            <div className="flex items-center">
              <CheckCircle2 className="w-4 h-4 text-green-500 mr-1" />
              7-day free trial
            </div>
            <div className="flex items-center">
              <CheckCircle2 className="w-4 h-4 text-green-500 mr-1" />
              Cancel anytime
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}