import React, { useState } from 'react';
import { Key, RefreshCw, Eye, EyeOff, CheckCircle2, XCircle, Settings } from 'lucide-react';
import Button from '../ui/Button';

interface APIKey {
  provider: string;
  key: string;
  status: 'active' | 'inactive';
  lastUsed?: string;
  expiresAt?: string;
}

const apiKeys: APIKey[] = [
  {
    provider: 'Stripe',
    key: 'sk_test_...', 
    status: 'active',
    lastUsed: '2 hours ago',
    expiresAt: 'Never'
  },
  {
    provider: 'OpenAI',
    key: 'sk-...', 
    status: 'active',
    lastUsed: '5 mins ago',
    expiresAt: 'Never'
  },
  {
    provider: 'Google Cloud',
    key: 'AIza...', 
    status: 'active',
    lastUsed: '1 hour ago',
    expiresAt: '2025-12-31'
  },
  {
    provider: 'Google Analytics',
    key: 'UA-...', 
    status: 'active',
    lastUsed: '30 mins ago',
    expiresAt: 'Never'
  }
];

export default function APIManagement() {
  const [showKeys, setShowKeys] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState<Record<string, boolean>>({});

  const toggleKeyVisibility = (provider: string) => {
    setShowKeys(prev => ({
      ...prev,
      [provider]: !prev[provider]
    }));
  };

  const handleRefreshKey = async (provider: string) => {
    setLoading({ ...loading, [provider]: true });
    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 1000));
    setLoading({ ...loading, [provider]: false });
  };

  return (
    <div className="bg-white dark:bg-slate-900 rounded-xl border border-gray-200 dark:border-gray-800">
      <div className="p-6 border-b border-gray-200 dark:border-gray-800">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Key className="w-5 h-5 text-blue-600 dark:text-blue-400 mr-2" />
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
              API Management
            </h2>
          </div>
          <Button variant="outline" size="sm" icon={Settings}>
            Configure Webhooks
          </Button>
        </div>
      </div>

      <div className="divide-y divide-gray-200 dark:divide-gray-800">
        {apiKeys.map((api) => (
          <div key={api.provider} className="p-6 hover:bg-gray-50 dark:hover:bg-slate-800/50">
            <div className="flex items-center justify-between mb-4">
              <div>
                <h3 className="font-medium text-gray-900 dark:text-white">
                  {api.provider}
                </h3>
                <div className="flex items-center mt-1">
                  <div className={`w-2 h-2 rounded-full ${
                    api.status === 'active' 
                      ? 'bg-green-500' 
                      : 'bg-red-500'
                  } mr-2`} />
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {api.status === 'active' ? 'Active' : 'Inactive'}
                  </span>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <button
                  onClick={() => toggleKeyVisibility(api.provider)}
                  className="p-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                >
                  {showKeys[api.provider] ? (
                    <EyeOff className="w-5 h-5" />
                  ) : (
                    <Eye className="w-5 h-5" />
                  )}
                </button>
                <button
                  onClick={() => handleRefreshKey(api.provider)}
                  className={`p-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 ${
                    loading[api.provider] ? 'animate-spin' : ''
                  }`}
                >
                  <RefreshCw className="w-5 h-5" />
                </button>
              </div>
            </div>

            <div className="bg-gray-50 dark:bg-slate-800 rounded-lg p-4">
              <div className="font-mono text-sm">
                {showKeys[api.provider] ? api.key : '•'.repeat(20)}
              </div>
            </div>

            <div className="mt-4 flex items-center justify-between text-sm">
              <div className="text-gray-500 dark:text-gray-400">
                Last used: {api.lastUsed}
              </div>
              <div className="text-gray-500 dark:text-gray-400">
                Expires: {api.expiresAt}
              </div>
            </div>

            <div className="mt-4 grid grid-cols-2 gap-4">
              <div className="bg-green-50 dark:bg-green-900/20 rounded-lg p-3">
                <div className="flex items-center text-sm text-green-700 dark:text-green-300">
                  <CheckCircle2 className="w-4 h-4 mr-2" />
                  Webhook configured
                </div>
              </div>
              <div className="bg-blue-50 dark:bg-blue-900/20 rounded-lg p-3">
                <div className="flex items-center text-sm text-blue-700 dark:text-blue-300">
                  <Settings className="w-4 h-4 mr-2" />
                  Rate limits: 10K/min
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}