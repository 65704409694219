import React, { useState } from 'react';
import { Calendar, Clock, Users, Building2, FileText, ArrowRight, Shield } from 'lucide-react';
import Button from '../ui/Button';

const consultationTypes = [
  {
    id: 'technical',
    name: 'Technical Deep Dive',
    duration: '90 minutes',
    attendees: 'Technical Team & Security Officers',
    topics: [
      'AI Architecture Review',
      'Security Controls Assessment',
      'Integration Requirements',
      'ATO Documentation'
    ]
  },
  {
    id: 'executive',
    name: 'Executive Briefing',
    duration: '60 minutes',
    attendees: 'Agency Leadership',
    topics: [
      'Strategic AI Roadmap',
      'ROI Analysis',
      'Risk Management',
      'Resource Planning'
    ]
  },
  {
    id: 'procurement',
    name: 'Procurement Planning',
    duration: '60 minutes',
    attendees: 'Contracting Officers',
    topics: [
      'Contract Vehicles',
      'Pricing Models',
      'Terms & Conditions',
      'Compliance Requirements'
    ]
  }
];

const availabilityByType = {
  technical: ['9:00 AM EST', '2:00 PM EST', '3:30 PM EST'],
  executive: ['10:00 AM EST', '1:00 PM EST', '4:00 PM EST'],
  procurement: ['11:00 AM EST', '2:30 PM EST', '4:30 PM EST']
};

export default function ConsultationScheduler() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    agency: '',
    consultationType: '',
    timeSlot: '',
    clearanceLevel: '',
    projectScope: '',
    timeline: '',
    attendees: ''
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 1000));
    setLoading(false);
    alert('Thank you! We will confirm your consultation shortly.');
  };

  return (
    <div className="py-12 sm:py-24 bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid lg:grid-cols-2 gap-8 sm:gap-12 items-start">
          {/* Left Column - Form */}
          <div className="bg-white dark:bg-slate-900 rounded-xl p-8 shadow-lg border border-gray-200/50 dark:border-gray-800/50">
            <div className="flex items-center mb-6">
              <Calendar className="w-6 h-6 text-blue-600 dark:text-blue-400 mr-2" />
              <h2 className="text-2xl font-bold text-black dark:text-white">
                Schedule Your Consultation
              </h2>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Consultation Type */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Consultation Type
                </label>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  {consultationTypes.map(type => (
                    <button
                      key={type.id}
                      type="button"
                      onClick={() => setFormData(prev => ({ ...prev, consultationType: type.id }))}
                      className={`p-4 rounded-xl text-left transition-all ${
                        formData.consultationType === type.id
                          ? 'bg-blue-50 dark:bg-blue-900/20 border-2 border-blue-500'
                          : 'bg-gray-50 dark:bg-slate-800 border-2 border-transparent'
                      }`}
                    >
                      <div className="font-medium text-black dark:text-white mb-1">
                        {type.name}
                      </div>
                      <div className="text-sm text-gray-600 dark:text-gray-400">
                        {type.duration}
                      </div>
                    </button>
                  ))}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Full Name
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                  required
                />
              </div>

              {/* Security Clearance Level */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Security Clearance Level
                </label>
                <select
                  value={formData.clearanceLevel}
                  onChange={(e) => setFormData(prev => ({ ...prev, clearanceLevel: e.target.value }))}
                  className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                >
                  <option value="">Select clearance level</option>
                  <option value="public">Public Trust</option>
                  <option value="secret">Secret</option>
                  <option value="tssci">TS/SCI</option>
                </select>
              </div>

              {/* Project Scope */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Project Scope
                </label>
                <select
                  value={formData.projectScope}
                  onChange={(e) => setFormData(prev => ({ ...prev, projectScope: e.target.value }))}
                  className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                >
                  <option value="">Select project scope</option>
                  <option value="pilot">Pilot Program</option>
                  <option value="department">Department-Wide</option>
                  <option value="agency">Agency-Wide</option>
                  <option value="multi-agency">Multi-Agency</option>
                </select>
              </div>

              {/* Implementation Timeline */}
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Implementation Timeline
                </label>
                <select
                  value={formData.timeline}
                  onChange={(e) => setFormData(prev => ({ ...prev, timeline: e.target.value }))}
                  className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                >
                  <option value="">Select timeline</option>
                  <option value="q2-2024">Q2 2024</option>
                  <option value="q3-2024">Q3 2024</option>
                  <option value="q4-2024">Q4 2024</option>
                  <option value="2025">FY 2025</option>
                </select>
              </div>

              <Button
                type="submit"
                variant="primary"
                size="lg"
                icon={ArrowRight}
                className="w-full"
                loading={loading}
              >
                Schedule Consultation
              </Button>
            </form>
          </div>

          {/* Right Column - Benefits */}
          <div className="space-y-8">
            {consultationTypes.map(type => (
              <div key={type.id} className="bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-6">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                  {type.name}
                </h3>
                <div className="space-y-4">
                  <div className="flex items-center text-sm text-gray-700 dark:text-gray-300">
                    <Clock className="w-5 h-5 text-blue-600 dark:text-blue-400 mr-3" />
                    Duration: {type.duration}
                  </div>
                  <div className="flex items-center text-sm text-gray-700 dark:text-gray-300">
                    <Users className="w-5 h-5 text-blue-600 dark:text-blue-400 mr-3" />
                    Recommended Attendees: {type.attendees}
                  </div>
                  <div className="mt-4">
                    <h4 className="font-medium text-gray-900 dark:text-white mb-2">
                      Discussion Topics:
                    </h4>
                    <ul className="space-y-2">
                      {type.topics.map(topic => (
                        <li key={topic} className="flex items-center text-sm text-gray-700 dark:text-gray-300">
                          <div className="w-1.5 h-1.5 rounded-full bg-blue-500 dark:bg-blue-400 mr-2" />
                          {topic}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}