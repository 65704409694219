import React, { useState } from 'react';
import { Mail, Lock, X, ArrowRight, Shield, Chrome, CheckCircle2 } from 'lucide-react';
import Button from '../ui/Button';
import { useRouter } from '../../hooks/useRouter';
import { ROUTES } from '../../utils/routes';
import { trackConversion } from '../../utils/analytics';

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function LoginModal({ isOpen, onClose }: LoginModalProps) {
  const { navigate } = useRouter();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      if (!email) {
        throw new Error('Please enter your email');
      }

      // Track login attempt
      trackConversion({
        type: 'login_attempt',
        page: window.location.pathname as any,
        data: { email }
      });

      // Store user email
      localStorage.setItem('userEmail', email);
      localStorage.setItem('accessToken', 'true');
      localStorage.setItem('trialStarted', 'true');
      
      // Show success message
      setSuccessMessage('Login successful! Redirecting...');

      // Redirect after brief delay to show success message
      setTimeout(() => {
        window.location.href = ROUTES.WORKSPACE;
      }, 1000);

    } catch (err) {
      console.error('Login error:', err);
      setError('Invalid email or password');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[100] flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm overflow-y-auto pt-24">
      <div className="mt-24 w-full max-w-md">
      <div className="relative w-full max-w-md bg-white/90 dark:bg-slate-900/90 backdrop-blur-sm rounded-xl shadow-xl border border-gray-200/50 dark:border-gray-800/50 transform hover:scale-[1.01] transition-all duration-300 cyber-button web3-glow">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
        >
          <X className="w-5 h-5" />
        </button>

        <div className="p-8">
          <div className="text-center mb-8">
            <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
              <Shield className="w-6 h-6 text-blue-600 dark:text-blue-400 animate-pulse" />
            </div>
            <h2 className="text-2xl font-bold web3-luxury mb-2">
              Get Started with AI
            </h2>
            <p className="text-gray-600 dark:text-gray-400">
              500+ AI tools • 7-day free trial • Cancel anytime
            </p>
          </div>
          
          {/* Social Proof */}
          <div className="flex items-center justify-center space-x-4 mb-6 text-sm text-gray-500">
            <div className="flex items-center">
              <Shield className="w-4 h-4 text-green-500 mr-1" />
              7 day free trial
            </div>
            <div className="flex items-center">
              <CheckCircle2 className="w-4 h-4 text-green-500 mr-1" />
              Cancel anytime
            </div>
            <div className="flex -space-x-2">
              {[...Array(3)].map((_, i) => (
                <img
                  key={i}
                  src={`https://i.pravatar.cc/32?img=${i + 10}`}
                  alt="User avatar"
                  className="w-8 h-8 rounded-full border-2 border-white dark:border-slate-900"
                />
              ))}
            </div>
            <span>50+ people signed up today</span>
          </div>

          {error && (
            <div className="mb-6 p-4 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 rounded-lg text-sm">
              {error}
            </div>
          )}

          {successMessage && (
            <div className="mb-6 p-4 bg-green-50 dark:bg-green-900/20 text-green-600 dark:text-green-400 rounded-lg text-sm">
              {successMessage}
            </div>
          )}

          <div className="mb-6">
            <button
              onClick={() => {
                trackConversion({
                  type: 'signup_start',
                  page: window.location.pathname as any,
                  data: { source: 'login_modal' }
                });
                // Store redirect URL
                localStorage.setItem('redirectAfterAuth', ROUTES.WORKSPACE);
                // Redirect to Google auth
                window.location.href = '/api/auth/google';
              }}
              className="w-full flex items-center justify-center px-4 py-3 bg-white dark:bg-slate-800 border-2 border-gray-200 dark:border-gray-700 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 transition-all duration-300 group hover:scale-[1.02] active:scale-[0.98] dark:cyber-button"
            >
              <Chrome className="w-5 h-5 text-[#4285F4] mr-2" />
              <span className="font-medium">Sign up with Google</span>
            </button>
          </div>

          <div className="relative text-center mb-6">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-200 dark:border-gray-700"></div>
            </div>
            <div className="relative">
              <span className="px-4 text-sm text-gray-500 bg-white dark:bg-slate-900">
                Or continue with work email
              </span>
            </div>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Email Address
              </label>
              <div className="relative">
                <Mail className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800 focus:ring-2 focus:ring-blue-500 transition-all duration-300 dark:focus:ring-violet-500/50 dark:focus:border-violet-500/50"
                  required
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Password
              </label>
              <div className="relative group">
                <Lock className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800 focus:ring-2 focus:ring-blue-500 transition-all duration-300 dark:focus:ring-violet-500/50 dark:focus:border-violet-500/50"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 opacity-0 group-hover:opacity-100 transition-opacity"
                >
                  {showPassword ? 'Hide' : 'Show'}
                </button>
              </div>
            </div>

            <div className="flex items-center justify-between mb-6">
              <label className="flex items-center">
                <input type="checkbox" className="rounded border-gray-300 dark:border-gray-700" />
                <span className="ml-2 text-sm text-gray-600 dark:text-gray-400">
                  Remember me
                </span>
              </label>
              <button
                type="button"
                className="text-sm text-blue-600 dark:text-blue-400 hover:underline"
              >
                Forgot password?
              </button>
            </div>

            <Button
              type="submit"
              variant="primary"
              size="lg"
              icon={ArrowRight}
              className="w-full group dark:cyber-button"
              loading={loading || !!successMessage}
            >
              {loading ? 'Logging in...' : successMessage ? 'Success!' : 'Log In'}
            </Button>
          </form>

          <div className="mt-6 text-center">
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Don't have an account?{' '}
              <button
                onClick={() => {
                  onClose();
                  navigate(ROUTES.SIGNUP);
                }}
                className="text-blue-600 dark:text-blue-400 hover:underline font-medium"
              >
                Sign up for free
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}