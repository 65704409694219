import React, { useState } from 'react';
import { Bell } from 'lucide-react';

export default function NotificationBell() {
  const [isHovered, setIsHovered] = useState(false);
  
  return (
    <button
      className="relative p-2 rounded-lg transition-colors hover:bg-gray-100 dark:hover:bg-slate-800"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      aria-label="Notifications"
    >
      <Bell className="w-5 h-5" />
      <span className="absolute top-2 right-2 w-2 h-2 bg-blue-600 rounded-full">
        <span className={`absolute inset-0 rounded-full bg-blue-600 animate-ping ${
          isHovered ? 'opacity-0' : 'opacity-75'
        }`} />
      </span>
    </button>
  );
}