import React from 'react';
import { Brain, Award, Building2, Target, Heart, Globe, Shield, Star, Users, Clock } from 'lucide-react';
import SpaceBackground from '../hero/SpaceBackground';

export default function AboutHero() {
  return (
    <div className="relative min-h-[90vh] lg:min-h-screen flex items-center justify-center overflow-hidden bg-gradient-to-b from-white via-white to-gray-50 dark:from-[rgb(3,3,5)] dark:via-[rgb(8,8,15)] dark:to-[rgb(3,3,5)]">
      {/* Dark mode background effects only */}
      <div className="hidden dark:block">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(120,119,198,0.03),rgba(0,0,0,0))]" />
        <div className="absolute inset-y-0 right-0 w-1/2 bg-[radial-gradient(circle_at_70%_50%,rgba(120,119,198,0.03),rgba(0,0,0,0))]" />
        <div className="absolute inset-0 bg-[conic-gradient(from_0deg_at_50%_50%,rgba(139,92,246,0.03)_0deg,rgba(147,51,234,0.03)_120deg,rgba(168,85,247,0.03)_240deg)]" />
        <SpaceBackground />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <div className="text-center max-w-4xl mx-auto">
          {/* Animated Badge */}
          <div className="inline-flex items-center justify-center p-3 mb-8 rounded-2xl bg-gradient-to-r from-blue-600/10 to-violet-600/10 dark:from-blue-400/10 dark:to-violet-400/10 backdrop-blur-sm border border-blue-200/50 dark:border-blue-800/50 animate-pulse-slow">
            <div className="px-6 py-2 rounded-xl bg-gradient-to-r from-blue-600 to-violet-600 text-white font-medium">
              Building Tomorrow's AI Ventures
            </div>
          </div>

          <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-extrabold tracking-tight mb-8 leading-[1.1] [text-shadow:0_4px_8px_rgba(0,0,0,0.1)] dark:[text-shadow:0_4px_8px_rgba(0,0,0,0.2)] animate-fade-in">
            <span className="relative">
              <span className="absolute inset-0 hidden dark:block bg-gradient-to-r from-blue-400/10 via-violet-400/10 to-purple-400/10 blur-xl animate-pulse-slow"></span>
              <span className="web3-luxury">
                Building
              </span>
              <br />
              <span className="web3-luxury">
                Tomorrow's Ventures Today
              </span>
            </span>
          </h1>
          <p className="max-w-2xl mx-auto text-xl text-gray-600 dark:text-gray-300 mb-12 leading-relaxed animate-fade-in [animation-delay:200ms]">
            At Galax Studios, we don't just build ventures — we empower yours with the same vetted, cutting-edge AI tools and strategies that drive success.
          </p>

          {/* Trust Badges */}
          <div className="flex flex-wrap items-center justify-center gap-8 animate-fade-in [animation-delay:400ms]">
            <div className="flex items-center space-x-2">
              <Star className="w-5 h-5 text-yellow-400 fill-yellow-400" />
              <span className="text-gray-700 dark:text-gray-300">
                <span className="font-semibold">4.9/5</span> from 500+ reviews
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <Users className="w-5 h-5 text-blue-600 dark:text-blue-400" />
              <span className="text-gray-700 dark:text-gray-300">
                <span className="font-semibold">50,000+</span> users
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <Clock className="w-5 h-5 text-violet-600 dark:text-violet-400" />
              <span className="text-gray-700 dark:text-gray-300">
                <span className="font-semibold">Since</span> 2009
              </span>
            </div>
          </div>
        </div>
      </div>
      
      {/* Floating Elements */}
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        {[...Array(5)].map((_, i) => (
          <div
            key={i}
            className="absolute w-24 h-24 bg-gradient-to-br from-blue-500/5 to-violet-500/5 dark:from-blue-400/5 dark:to-violet-400/5 rounded-full blur-2xl animate-float"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDelay: `${i * -3}s`,
              animationDuration: `${15 + Math.random() * 10}s`
            }}
          />
        ))}
      </div>
    </div>
  );
}