import React from 'react';
import { Activity, CheckCircle, AlertTriangle, Clock } from 'lucide-react';

const metrics = [
  {
    name: 'System Uptime',
    value: '99.99%',
    status: 'healthy',
    details: 'Last 30 days'
  },
  {
    name: 'API Response Time',
    value: '145ms',
    status: 'warning',
    details: 'Avg. last hour'
  },
  {
    name: 'Error Rate',
    value: '0.02%',
    status: 'healthy',
    details: 'Last 24 hours'
  },
  {
    name: 'Active Sessions',
    value: '2,345',
    status: 'healthy',
    details: 'Current'
  }
];

export default function SystemHealth() {
  return (
    <div className="bg-white dark:bg-slate-900 rounded-xl border border-gray-200 dark:border-gray-800">
      <div className="p-6 border-b border-gray-200 dark:border-gray-800">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
            System Health
          </h2>
          <div className="flex items-center text-sm text-gray-500 dark:text-gray-400">
            <Clock className="w-4 h-4 mr-1" />
            Updated 2 mins ago
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-6 p-6">
        {metrics.map((metric) => (
          <div
            key={metric.name}
            className="bg-gray-50 dark:bg-slate-800 rounded-lg p-4"
          >
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm text-gray-500 dark:text-gray-400">
                {metric.name}
              </span>
              {metric.status === 'healthy' ? (
                <CheckCircle className="w-5 h-5 text-green-500" />
              ) : (
                <AlertTriangle className="w-5 h-5 text-yellow-500" />
              )}
            </div>
            <div className="text-2xl font-bold text-gray-900 dark:text-white mb-1">
              {metric.value}
            </div>
            <div className="text-sm text-gray-500 dark:text-gray-400">
              {metric.details}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}