import { Brain, Image, FileText, Bot, Code2, Video, Shield, Wand2, BrainCircuit } from 'lucide-react';
import type { LucideIcon } from 'lucide-react';

interface Tool {
  name: string;
  description: string;
  icon: LucideIcon;
  category: string;
  features: string[];
  price: string;
  rating: number;
  reviewCount: number;
  recommended?: boolean;
  new?: boolean;
  popular?: boolean;
  videoId?: string;
}

export const categories = [
  {
    id: 'content',
    name: 'Content Creation',
    description: 'AI-powered writing and content generation tools'
  },
  {
    id: 'image',
    name: 'Image Generation',
    description: 'Create and edit images with AI'
  },
  {
    id: 'video',
    name: 'Video Creation',
    description: 'AI video generation and editing tools'
  },
  {
    id: 'coding',
    name: 'Development',
    description: 'AI-powered development tools and code generation'
  },
  {
    id: 'research',
    name: 'Research & Analysis',
    description: 'AI tools for research and data analysis'
  }
];

export const tools: Tool[] = [
  {
    name: 'Claude 3',
    videoId: 'gSBpoSL2Org',
    description: 'Latest multimodal AI assistant with enhanced reasoning',
    icon: Brain,
    category: 'ai-tools',
    features: [
      'Blog post generation',
      'Marketing copy',
      'Social media content',
      'SEO optimization'
    ],
    price: 'From $49/mo',
    rating: 4.8,
    reviewCount: 2453,
    popular: true,
    industries: ['tech', 'marketing', 'ecommerce', 'media'],
    companySize: ['startup', 'small', 'medium'],
    useCases: ['content', 'marketing', 'automation'],
    integrations: ['zapier', 'slack', 'wordpress'],
    deployment: 'cloud',
    compliance: ['gdpr', 'ccpa'],
    supportLevel: 'premium'
  },
  {
    name: 'Jasper AI',
    description: 'Advanced AI content creation with GPT-4',
    icon: Brain,
    category: 'content',
    features: [
      'Blog post generation',
      'Marketing copy',
      'Social media content',
      'SEO optimization'
    ],
    price: 'From $49/mo',
    rating: 4.8,
    reviewCount: 2453,
    popular: true
  },
  {
    name: 'Copy.ai',
    description: 'AI copywriting and content assistant',
    icon: FileText,
    category: 'content',
    features: [
      'Sales copy',
      'Email templates',
      'Product descriptions',
      'Multi-language'
    ],
    price: 'Free trial',
    rating: 4.7,
    reviewCount: 1876
  },
  {
    name: 'Midjourney V6',
    videoId: 'h5nxat56wKI',
    description: 'Next-gen AI image generation with perfect hands',
    icon: Image,
    category: 'image',
    features: [
      'Image generation',
      'Art styles',
      'Variations',
      'High resolution'
    ],
    price: 'From $10/mo',
    rating: 4.9,
    reviewCount: 3421,
    popular: true
  },
  {
    name: 'Midjourney',
    description: 'Create stunning AI-generated artwork',
    icon: Image,
    category: 'image',
    features: [
      'Image generation',
      'Art styles',
      'Variations',
      'High resolution'
    ],
    price: 'From $10/mo',
    rating: 4.9,
    reviewCount: 3421,
    popular: true
  },
  {
    name: 'DALL-E 3',
    description: 'Advanced AI image generation by OpenAI',
    icon: Image,
    category: 'image',
    features: [
      'Photorealistic images',
      'Art creation',
      'Image editing',
      'Style transfer'
    ],
    price: 'Credits based',
    rating: 4.8,
    reviewCount: 1567,
    new: true
  },
  {
    name: 'Sora',
    videoId: 'HK6y4TgqFxg',
    description: 'OpenAI\'s revolutionary video generation model',
    icon: Video,
    category: 'video',
    features: [
      'Video generation',
      'Motion graphics',
      'Visual effects',
      'Green screen'
    ],
    price: 'Pro plans',
    rating: 4.7,
    reviewCount: 892,
    recommended: true
  },
  {
    name: 'Runway',
    description: 'Professional AI video creation',
    icon: Video,
    category: 'video',
    features: [
      'Video generation',
      'Motion graphics',
      'Visual effects',
      'Green screen'
    ],
    price: 'Pro plans',
    rating: 4.7,
    reviewCount: 892,
    recommended: true
  },
  {
    name: 'Synthesia',
    description: 'AI video creation from text',
    icon: Video,
    category: 'video',
    features: [
      'Avatar videos',
      'Voice synthesis',
      'Multiple languages',
      'Templates'
    ],
    price: 'From $30/mo',
    rating: 4.6,
    reviewCount: 743
  },
  {
    name: 'Gemini Pro',
    videoId: 'UIZAiXYXkPo',
    description: 'Google\'s most capable multimodal AI model',
    icon: Brain,
    category: 'ai-tools',
    features: [
      'Code completion',
      'Documentation',
      'Bug detection',
      'Multiple languages'
    ],
    price: '$10/mo',
    rating: 4.8,
    reviewCount: 5234,
    popular: true
  },
  {
    name: 'GitHub Copilot',
    description: 'AI pair programmer',
    icon: Code2,
    category: 'coding',
    features: [
      'Code completion',
      'Documentation',
      'Bug detection',
      'Multiple languages'
    ],
    price: '$10/mo',
    rating: 4.8,
    reviewCount: 5234,
    popular: true
  },
  {
    name: 'Tabnine',
    description: 'AI code completion',
    icon: Code2,
    category: 'coding',
    features: [
      'Code suggestions',
      'Team learning',
      'Private codebase',
      'Security'
    ],
    price: 'Free tier',
    rating: 4.6,
    reviewCount: 1243
  },
  {
    name: 'Runway Gen-2',
    videoId: 'oXqGI5kYgSs',
    description: 'Professional AI video generation and editing',
    icon: Video,
    category: 'video',
    features: [
      'Avatar videos',
      'Voice synthesis',
      'Multiple languages',
      'Templates'
    ],
    price: 'From $30/mo',
    rating: 4.6,
    reviewCount: 743
  },
  {
    name: 'Elicit',
    description: 'AI research assistant',
    icon: BrainCircuit,
    category: 'research',
    features: [
      'Paper analysis',
      'Literature review',
      'Research synthesis',
      'Citations'
    ],
    price: 'Free beta',
    rating: 4.7,
    reviewCount: 567,
    new: true
  },
  {
    name: 'Consensus',
    description: 'Scientific research AI',
    icon: BrainCircuit,
    category: 'research',
    features: [
      'Paper search',
      'Key findings',
      'Evidence analysis',
      'Bias detection'
    ],
    price: 'From $20/mo',
    rating: 4.8,
    reviewCount: 892
  }
];

export function getToolsByCategory(categoryId: string) {
  return tools.filter(tool => tool.category === categoryId);
}

export function searchTools(query: string) {
  const searchTerms = query.toLowerCase().split(' ');
  return tools.filter(tool => {
    const searchText = `${tool.name} ${tool.description} ${tool.features.join(' ')}`.toLowerCase();
    return searchTerms.every(term => searchText.includes(term));
  });
}