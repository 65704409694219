import React from 'react';
import { Brain, Building2, Shield, ArrowRight, CheckCircle2 } from 'lucide-react';
import Button from '../ui/Button';

const customProjects = [
  {
    name: 'Government AI Solutions',
    icon: Shield,
    description: 'FedRAMP High authorized AI solutions for federal, state, and local agencies',
    features: [
      'IL5 certified infrastructure',
      'NIST 800-53 compliance',
      'Custom AI model development',
      'Air-gapped deployment options',
      'Agency-wide licensing'
    ],
    minBudget: '$250K+',
    timeline: '3-6 months'
  },
  {
    name: 'Enterprise AI Development',
    icon: Building2,
    description: 'Custom AI solutions for Fortune 500 companies and large organizations',
    features: [
      'Custom LLM development',
      'Enterprise data integration',
      'MLOps infrastructure',
      'Custom security controls',
      'White-label solutions'
    ],
    minBudget: '$150K+',
    timeline: '2-4 months'
  },
  {
    name: 'Research & Innovation',
    icon: Brain,
    description: 'Cutting-edge AI research and development for specialized applications',
    features: [
      'Advanced AI research',
      'Novel algorithm development',
      'Academic partnerships',
      'Patent-pending technology',
      'Research team access'
    ],
    minBudget: '$500K+',
    timeline: '6-12 months'
  }
];

export default function CustomProjects({ onConsultation }: { onConsultation: () => void }) {
  return (
    <div className="py-16 bg-gradient-to-br from-gray-50 to-gray-100 dark:from-slate-900 dark:to-slate-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            Custom AI Projects
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Enterprise-grade AI solutions for specialized needs and large-scale deployments
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {customProjects.map((project) => {
            const Icon = project.icon;
            return (
              <div
                key={project.name}
                className="bg-white dark:bg-slate-900 rounded-xl p-8 shadow-lg border border-gray-200/50 dark:border-gray-800/50"
              >
                <div className="flex items-center mb-6">
                  <div className="p-3 rounded-xl bg-gradient-to-br from-blue-100 to-violet-100 dark:from-blue-900/20 dark:to-violet-900/20">
                    <Icon className="w-8 h-8 text-blue-600 dark:text-blue-400" />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-xl font-bold text-gray-900 dark:text-white">
                      {project.name}
                    </h3>
                    <div className="text-sm text-blue-600 dark:text-blue-400 mt-1">
                      From {project.minBudget}
                    </div>
                  </div>
                </div>

                <p className="text-gray-600 dark:text-gray-400 mb-6">
                  {project.description}
                </p>

                <ul className="space-y-3 mb-6">
                  {project.features.map((feature) => (
                    <li key={feature} className="flex items-center text-gray-600 dark:text-gray-300">
                      <CheckCircle2 className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                      {feature}
                    </li>
                  ))}
                </ul>

                <div className="flex items-center justify-between text-sm text-gray-500 dark:text-gray-400 mb-6">
                  <span>Timeline: {project.timeline}</span>
                </div>

                <Button
                  variant="outline"
                  size="lg"
                  icon={ArrowRight}
                  onClick={onConsultation}
                  className="w-full"
                >
                  Request Consultation
                </Button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}