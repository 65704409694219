import { Shield, Award, CheckCircle2, Lock, FileCheck, Clock, Users } from 'lucide-react';
import type { LucideIcon } from 'lucide-react';

interface Agency {
  name: string;
  acronym: string;
  type: 'defense' | 'civilian' | 'intelligence';
  requirements?: string[];
}

interface Achievement {
  icon: LucideIcon;
  label: string;
  value: string;
}

interface Certification {
  name: string;
  icon: LucideIcon;
}

interface UrgencyIndicator {
  icon: LucideIcon;
  text: string;
}

export const AGENCIES: Agency[] = [
  {
    name: 'Department of Defense',
    acronym: 'DoD',
    type: 'defense',
    requirements: ['IL5', 'CMMC Level 3', 'Zero Trust']
  },
  {
    name: 'Department of Energy',
    acronym: 'DOE',
    type: 'civilian',
    requirements: ['FedRAMP High', 'NIST 800-53']
  },
  {
    name: 'NASA',
    acronym: 'NASA',
    type: 'civilian',
    requirements: ['FedRAMP Moderate', 'NIST CSF']
  },
  {
    name: 'Department of Homeland Security',
    acronym: 'DHS',
    type: 'defense',
    requirements: ['FedRAMP High', 'TIC 3.0']
  },
  {
    name: 'Department of Justice',
    acronym: 'DOJ',
    type: 'civilian',
    requirements: ['FedRAMP High', 'CJIS']
  }
];

export const ACHIEVEMENTS: Achievement[] = [
  {
    icon: Shield,
    label: 'FedRAMP Authorized',
    value: 'High Impact Level'
  },
  {
    icon: Award,
    label: 'Contract Value',
    value: '$250M+'
  },
  {
    icon: CheckCircle2,
    label: 'Success Rate',
    value: '98%'
  }
];

export const CERTIFICATIONS: Certification[] = [
  { name: 'NIST 800-53', icon: Lock },
  { name: 'CMMC Level 3', icon: Shield },
  { name: 'IL5 Certified', icon: FileCheck }
];

export const URGENCY_INDICATORS: UrgencyIndicator[] = [
  { icon: Clock, text: 'Limited Time: FY2024 Funding Available' },
  { icon: Users, text: '95% of Agencies See ROI in 6 Months' },
  { icon: Shield, text: 'Pre-Approved for Federal Contracts' }
];

export const COMPLIANCE_REQUIREMENTS = {
  security: [
    'FedRAMP High Authorization',
    'NIST 800-53 Rev 5',
    'FIPS 140-3',
    'Zero Trust Architecture',
    'DISA STIG Compliance',
    'Continuous Monitoring',
    'Air-Gapped Deployment',
    'End-to-End Encryption'
  ],
  privacy: [
    'Privacy Impact Assessment',
    'System of Records Notice',
    'Privacy Threshold Analysis',
    'Data Minimization',
    'Consent Management',
    'Data Sovereignty',
    'Access Controls',
    'Audit Logging'
  ],
  audit: [
    'Continuous Monitoring',
    'Annual Security Assessment',
    'Vulnerability Scanning',
    'Penetration Testing',
    'Security Controls Testing',
    'Compliance Auditing',
    'Risk Assessment',
    'Incident Response'
  ]
};

export const GOVERNMENT_COLORS = {
  primary: {
    light: '#003875',  // Deeper navy blue for authority
    dark: '#4A90E2'    // Modern government blue
  },
  secondary: {
    light: '#0056B3',  // Supporting blue
    dark: '#63B3ED'    // Accessible secondary
  },
  background: {
    light: '#F8FAFC',  // Clean, professional light
    dark: '#1a365d'    // Rich navy dark mode
  },
  border: {
    light: '#003875/20',
    dark: '#4A90E2/20'
  }
};

export const GOVERNMENT_BADGES = {
  fedramp: {
    title: 'FedRAMP High',
    icon: Shield,
    color: 'blue'
  },
  nist: {
    title: 'NIST 800-53',
    icon: Lock,
    color: 'navy'
  },
  disa: {
    title: 'DISA STIG',
    icon: Shield,
    color: 'steel'
  },
  cmmc: {
    title: 'CMMC Level 3',
    icon: CheckCircle2,
    color: 'federal'
  }
};