import React, { useState } from 'react';
import { Copy, Folder, Settings } from 'lucide-react';

export default function BatchSettings() {
  const [numImages, setNumImages] = useState(4);
  const [variations, setVariations] = useState(2);

  return (
    <div className="space-y-6">
      {/* Number of Images */}
      <div>
        <div className="flex items-center text-sm font-medium text-gray-900 dark:text-white mb-3">
          <Copy className="w-4 h-4 mr-2" />
          Number of Images
        </div>
        <input
          type="range"
          min="1"
          max="10"
          value={numImages}
          onChange={(e) => setNumImages(Number(e.target.value))}
          className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-lg appearance-none cursor-pointer"
        />
        <div className="flex justify-between text-sm text-gray-600 dark:text-gray-400 mt-2">
          <span>1</span>
          <span className="font-medium">{numImages} images</span>
          <span>10</span>
        </div>
      </div>

      {/* Variations */}
      <div>
        <div className="flex items-center text-sm font-medium text-gray-900 dark:text-white mb-3">
          <Settings className="w-4 h-4 mr-2" />
          Variations per Image
        </div>
        <input
          type="range"
          min="1"
          max="5"
          value={variations}
          onChange={(e) => setVariations(Number(e.target.value))}
          className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-lg appearance-none cursor-pointer"
        />
        <div className="flex justify-between text-sm text-gray-600 dark:text-gray-400 mt-2">
          <span>1</span>
          <span className="font-medium">{variations} variations</span>
          <span>5</span>
        </div>
      </div>

      {/* Output Settings */}
      <div>
        <div className="flex items-center text-sm font-medium text-gray-900 dark:text-white mb-3">
          <Folder className="w-4 h-4 mr-2" />
          Output Settings
        </div>
        <div className="space-y-3">
          <label className="flex items-center">
            <input type="checkbox" className="rounded border-gray-300 dark:border-gray-700" />
            <span className="ml-2 text-sm text-gray-600 dark:text-gray-400">
              Auto-organize by prompt
            </span>
          </label>
          <label className="flex items-center">
            <input type="checkbox" className="rounded border-gray-300 dark:border-gray-700" />
            <span className="ml-2 text-sm text-gray-600 dark:text-gray-400">
              Include metadata
            </span>
          </label>
          <label className="flex items-center">
            <input type="checkbox" className="rounded border-gray-300 dark:border-gray-700" />
            <span className="ml-2 text-sm text-gray-600 dark:text-gray-400">
              Auto-download when complete
            </span>
          </label>
        </div>
      </div>
    </div>
  );
}