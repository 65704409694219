import React from 'react';
import { GovernmentErrorBoundary } from '../../components/government/ErrorBoundary';
import CapabilityStatement from '../../components/government/CapabilityStatement';

export default function CapabilityStatementPage() {
  return (
    <GovernmentErrorBoundary section="capability-statement">
      <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-24 pb-16">
        <div className="container max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <CapabilityStatement isOpen={true} onClose={() => window.history.back()} />
        </div>
      </div>
    </GovernmentErrorBoundary>
  );
}