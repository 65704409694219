import React from 'react';
import { X, Shield, ArrowRight } from 'lucide-react';
import Button from '../ui/Button';

interface UpgradeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function UpgradeModal({ isOpen, onClose }: UpgradeModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm">
      <div className="relative w-full max-w-md bg-white dark:bg-slate-900 rounded-xl shadow-xl">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
        >
          <X className="w-5 h-5" />
        </button>

        <div className="p-6">
          <div className="text-center mb-6">
            <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
              <Shield className="w-6 h-6 text-blue-600 dark:text-blue-400" />
            </div>
            <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">
              Active Trial Detected
            </h3>
            <p className="text-gray-600 dark:text-gray-400">
              Your upgrade will take effect after your trial period ends. Continue enjoying all premium features until then!
            </p>
          </div>

          <Button
            variant="primary"
            size="lg"
            icon={ArrowRight}
            className="w-full"
            onClick={onClose}
          >
            Continue Using Trial
          </Button>
        </div>
      </div>
    </div>
  );
}