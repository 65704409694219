import React from 'react';
import { ArrowRight, Shield, Star, Users } from 'lucide-react';
import Button from '../ui/Button'; 
import { useRouter } from '../../hooks/useRouter';
import { ROUTES } from '../../utils/routes';
import { trackConversion } from '../../utils/analytics';
import { checkTrialExpired } from '../../utils/auth';
import PricingHero from './PricingHero';
import PricingTiers from './PricingTiers';
import ComparisonTable from './ComparisonTable';
import PricingTestimonials from './PricingTestimonials';
import CustomProjects from './CustomProjects';
import PricingFAQ from './PricingFAQ';
import PricingGuarantee from './PricingGuarantee';

interface PricingContentProps {
  onConsultation: () => void;
  onUpgrade: () => void;
}

export default function PricingContent({ onConsultation, onUpgrade }: PricingContentProps) {
  const { navigate } = useRouter();

  const handleSubscribe = async (packageName: string) => {
    if (!packageName) {
      console.error('Invalid package name');
      return;
    }

    try {
      // Check if user is logged in
      const isLoggedIn = localStorage.getItem('accessToken');
      
      if (!isLoggedIn) {
        // Store selected plan and redirect to signup
        localStorage.setItem('selectedPlan', packageName);
        window.location.href = ROUTES.SIGNUP;
        return;
      }

      // Check trial status
      const trialActive = localStorage.getItem('trialStarted') === 'true';
      const trialExpired = checkTrialExpired();
      
      if (trialActive && !trialExpired) {
        onUpgrade();
        return;
      }

      // If enterprise/custom plan, show consultation form
      if (packageName === 'Enterprise' || packageName === 'Custom') {
        onConsultation();
        return;
      }

      // Store selected plan
      localStorage.setItem('selectedPlan', packageName);
      
      // Track conversion
      trackConversion({
        type: 'cta_click',
        page: ROUTES.PRICING,
        data: { 
          package: packageName,
          action: packageName === 'Enterprise' ? 'contact_sales' : 'subscribe'
        }
      });

      // Handle checkout based on package
      if (packageName === 'Enterprise' || packageName === 'Venture Builder') {
        onConsultation();
      } else if (packageName === 'Pro') {
        window.location.href = 'https://buy.stripe.com/7sI9C76iF8LWfNS28p';
      } else {
        // Start trial for other plans
        localStorage.setItem('trialStarted', 'true');
        localStorage.setItem('trialStartDate', Date.now().toString());
        localStorage.setItem('trialEndDate', (Date.now() + 7 * 24 * 60 * 60 * 1000).toString());
        window.location.href = ROUTES.WORKSPACE;
      }
    } catch (error) {
      console.error('Error handling subscription:', error);
    }
  };

  return (
    <>
      <PricingHero />

      {/* New Pricing Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-center mb-16">
          <h1 className="text-4xl sm:text-5xl font-bold mb-6 text-black dark:text-white">
            Simple, Transparent Pricing—Designed to Scale With You
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto leading-relaxed">
            Choose the plan that fits your venture's stage. Start small, grow big, or go custom.
          </p>
          
          {/* Quick Stats */}
          <div className="flex flex-wrap justify-center gap-8 mt-8">
            <div className="flex items-center space-x-2">
              <Shield className="w-5 h-5 text-green-600 dark:text-green-400" />
              <span className="text-gray-700 dark:text-gray-300">
                <span className="font-semibold">7-day</span> free trial
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <Star className="w-5 h-5 text-yellow-400 fill-yellow-400" />
              <span className="text-gray-700 dark:text-gray-300">
                <span className="font-semibold">4.9/5</span> from 500+ reviews
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <Users className="w-5 h-5 text-blue-600 dark:text-blue-400" />
              <span className="text-gray-700 dark:text-gray-300">
                <span className="font-semibold">15+</span> ventures launched
              </span>
            </div>
          </div>
        </div>

        {/* Pricing Table */}
        <div className="overflow-x-auto bg-white/90 dark:bg-slate-900/90 backdrop-blur-sm rounded-xl border border-gray-200/50 dark:border-gray-800/50 shadow-lg hover:shadow-xl transition-all duration-300 mb-16">
          <table className="w-full">
            <thead>
              <tr className="bg-gradient-to-r from-gray-50 to-gray-100 dark:from-slate-800/50 dark:to-slate-800/50 border-b border-gray-200 dark:border-gray-800">
                <th className="py-5 px-6 text-left text-sm font-medium text-gray-500 dark:text-gray-400">Plan</th>
                <th className="py-5 px-6 text-left text-sm font-medium text-gray-500 dark:text-gray-400">Price</th>
                <th className="py-5 px-6 text-left text-sm font-medium text-gray-500 dark:text-gray-400">Equity</th>
                <th className="py-5 px-6 text-left text-sm font-medium text-gray-500 dark:text-gray-400">Best For</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-800">
              <tr className="hover:bg-gray-50/80 dark:hover:bg-slate-800/50 transition-colors duration-200">
                <td className="py-4 px-6 text-sm text-gray-900 dark:text-white">Basic Build</td>
                <td className="py-4 px-6 text-sm text-gray-600 dark:text-gray-300">$20,000 + $499/month</td>
                <td className="py-4 px-6 text-sm text-gray-600 dark:text-gray-300">15–20%</td>
                <td className="py-4 px-6 text-sm text-gray-600 dark:text-gray-300">First-time founders with unvalidated ideas</td>
              </tr>
              <tr className="hover:bg-blue-50/50 dark:hover:bg-blue-900/10 transition-colors duration-200">
                <td className="py-4 px-6 text-sm text-gray-900 dark:text-white">
                  Validated Build
                  <span className="ml-2 px-2 py-1 text-xs bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400 rounded-full">
                    Most Popular
                  </span>
                </td>
                <td className="py-4 px-6 text-sm text-gray-600 dark:text-gray-300">$30,000 + $999/month</td>
                <td className="py-4 px-6 text-sm text-gray-600 dark:text-gray-300">10–15%</td>
                <td className="py-4 px-6 text-sm text-gray-600 dark:text-gray-300">Market-tested ventures seeking funding</td>
              </tr>
              <tr className="hover:bg-gray-50/80 dark:hover:bg-slate-800/50 transition-colors duration-200">
                <td className="py-4 px-6 text-sm text-gray-900 dark:text-white">Custom Enterprise</td>
                <td className="py-4 px-6 text-sm text-gray-600 dark:text-gray-300">$100,000+ + $1,500/month</td>
                <td className="py-4 px-6 text-sm text-gray-600 dark:text-gray-300">5–10% (optional)</td>
                <td className="py-4 px-6 text-sm text-gray-600 dark:text-gray-300">Scaling businesses needing tailored AI</td>
              </tr>
              <tr className="hover:bg-gray-50/80 dark:hover:bg-slate-800/50 transition-colors duration-200">
                <td className="py-4 px-6 text-sm text-gray-900 dark:text-white">Non-Equity Option</td>
                <td className="py-4 px-6 text-sm text-gray-600 dark:text-gray-300">+$15K–$25K per tier</td>
                <td className="py-4 px-6 text-sm text-gray-600 dark:text-gray-300">0%</td>
                <td className="py-4 px-6 text-sm text-gray-600 dark:text-gray-300">Founders who prefer to retain full ownership</td>
              </tr>
              <tr className="hover:bg-gray-50 dark:hover:bg-slate-800/50">
                <td className="py-4 px-6 text-sm text-gray-900 dark:text-white">Non-Equity Option</td>
                <td className="py-4 px-6 text-sm text-gray-600 dark:text-gray-300">+$15K–$25K per tier</td>
                <td className="py-4 px-6 text-sm text-gray-600 dark:text-gray-300">0%</td>
                <td className="py-4 px-6 text-sm text-gray-600 dark:text-gray-300">Founders who prefer to retain full ownership</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* CTA */}
        <div className="text-center">
          <Button
            variant="primary"
            size="lg"
            icon={ArrowRight}
            onClick={() => onConsultation()}
            className="group"
          >
            Schedule Consultation
          </Button>
        </div>
      </div>

      <div className="mb-16 bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 py-16 rounded-2xl">
        <PricingTestimonials />
      </div>

      <div className="mb-16 bg-white/80 dark:bg-slate-900/80 backdrop-blur-sm rounded-2xl p-8 border border-gray-200/50 dark:border-gray-800/50">
        <CustomProjects onConsultation={onConsultation} />
      </div>

      <div className="mb-16 grid md:grid-cols-2 gap-8">
        <PricingFAQ />
        <PricingGuarantee />
      </div>
    </>
  );
}