import React, { useState, useEffect } from 'react';
import { Mail, Lock, ArrowRight, Shield, Chrome, User, Star, Clock } from 'lucide-react';
import Button from '../components/ui/Button';
import { useRouter } from '../hooks/useRouter';
import { ROUTES } from '../utils/routes';
import { login, checkAuth } from '../utils/auth';
import { trackConversion } from '../utils/analytics';

export default function Login() {
  const { navigate } = useRouter();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Check if already logged in
    if (checkAuth()) {
      const redirectTo = localStorage.getItem('redirectAfterLogin') || ROUTES.WORKSPACE;
      localStorage.removeItem('redirectAfterLogin');
      window.location.href = redirectTo;
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Validate input
      if (!email || !password) {
        throw new Error('Please enter both email and password');
      }

      // Track login attempt
      trackConversion({
        type: 'login_attempt',
        page: ROUTES.LOGIN,
        data: { email }
      });

      // Perform login
      login(email);

      // Get redirect destination
      const redirectTo = localStorage.getItem('redirectAfterLogin') || ROUTES.WORKSPACE;
      localStorage.removeItem('redirectAfterLogin');
      
      // Redirect after successful login
      window.location.href = redirectTo;

    } catch (err) {
      console.error('Login error:', err);
      setError(err instanceof Error ? err.message : 'Failed to login');
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 flex flex-col items-center justify-center p-4 relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_80%,rgba(147,51,234,0.05),rgba(0,0,0,0))]" />
        <div className="absolute inset-0 bg-[conic-gradient(from_0deg_at_50%_50%,rgba(139,92,246,0.03)_0deg,rgba(147,51,234,0.03)_120deg,rgba(168,85,247,0.03)_240deg)]" />
      </div>
      
      <div className="w-full max-w-md relative z-10">
        <div className="relative bg-white/90 dark:bg-slate-900/90 backdrop-blur-sm rounded-xl p-8 shadow-xl border border-gray-200/50 dark:border-gray-800/50">
          <div className="text-center mb-8">
            <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
              <Shield className="w-6 h-6 text-blue-600 dark:text-blue-400" />
            </div>
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-2">
              Welcome Back
            </h2>
            <p className="text-gray-600 dark:text-gray-400">
              Log in to access your AI tools directory
            </p>
            <div className="mt-4 flex items-center justify-center space-x-4">
              <div className="flex items-center">
                <Shield className="w-4 h-4 text-green-500 mr-1" />
                <span className="text-sm text-gray-600 dark:text-gray-400">Secure login</span>
              </div>
              <div className="flex items-center">
                <User className="w-4 h-4 text-blue-500 mr-1" />
                <span className="text-sm text-gray-600 dark:text-gray-400">50,000+ users</span>
              </div>
            </div>
          </div>

          {error && (
            <div className="mb-6 p-4 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 rounded-lg text-sm">
              {error}
            </div>
          )}

          <div className="mb-8">
            <button
              onClick={() => {
                setLoading(true);
                trackConversion({
                  type: 'login_google',
                  page: ROUTES.LOGIN,
                  data: {}
                });
                // Store redirect URL
                localStorage.setItem('redirectAfterAuth', ROUTES.WORKSPACE);
                // Redirect to Google auth
                window.location.href = '/api/auth/google';
              }}
              className="w-full flex items-center justify-center px-4 py-3 bg-white dark:bg-slate-800 border-2 border-gray-200 dark:border-gray-700 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 transition-all duration-300 group hover:scale-[1.02] active:scale-[0.98]"
              disabled={loading}
            >
              <Chrome className="w-5 h-5 text-[#4285F4] mr-2" />
              <span className="font-medium">Continue with Google</span>
            </button>
          </div>

          <div className="relative text-center mb-6">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-200 dark:border-gray-700"></div>
            </div>
            <div className="relative">
              <span className="px-4 text-sm text-gray-500 bg-white dark:bg-slate-900">
                Or continue with email
              </span>
            </div>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Email Address
              </label>
              <div className="relative">
                <Mail className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800 focus:ring-2 focus:ring-blue-500 transition-all duration-300 focus:outline-none"
                  required
                  autoComplete="email"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Password
              </label>
              <div className="relative">
                <Lock className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800 focus:ring-2 focus:ring-blue-500 transition-all duration-300 focus:outline-none"
                  required
                  autoComplete="current-password"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="rounded border-gray-300 dark:border-gray-700 focus:ring-2 focus:ring-blue-500"
                />
                <span className="ml-2 text-sm text-gray-600 dark:text-gray-400">
                  Remember me
                </span>
              </label>
              <button
                type="button"
                className="text-sm text-blue-600 dark:text-blue-400 hover:underline"
              >
                Forgot password?
              </button>
            </div>
            <Button
              type="submit"
              variant="primary"
              size="lg"
              icon={ArrowRight}
              className="w-full group hover:scale-[1.02] active:scale-[0.98]"
              loading={loading}
            >
              Log In
            </Button>
          </form>

          <div className="mt-6 text-center">
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Don't have an account?{' '}
              <button
                onClick={() => navigate(ROUTES.SIGNUP)}
                className="text-blue-600 dark:text-blue-400 hover:underline font-medium"
              >
                Sign up for free
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}