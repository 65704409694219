import { Tool } from '../types/tools';

interface Filters {
  industry: string;
  size: string;
  useCase: string;
  priceRange: string;
  sortBy: string;
  searchQuery: string;
}

export function filterTools(tools: Tool[], filters: Filters): Tool[] {
  return tools
    .filter(tool => {
      if (filters.searchQuery) {
        const search = filters.searchQuery.toLowerCase();
        const searchableText = `
          ${tool.name} 
          ${tool.description} 
          ${tool.features.join(' ')}
          ${tool.industries?.join(' ') || ''}
          ${tool.companySize?.join(' ') || ''}
          ${tool.useCases?.join(' ') || ''}
          ${tool.integrations?.join(' ') || ''}
          ${tool.compliance?.join(' ') || ''}
        `.toLowerCase();
        if (!searchableText.includes(search)) return false;
      }

      if (filters.industry && tool.industries && !tool.industries.includes(filters.industry)) return false;
      if (filters.size && tool.companySize && !tool.companySize.includes(filters.size)) return false;
      if (filters.useCase && tool.useCases && !tool.useCases.includes(filters.useCase)) return false;
      if (filters.expertise && tool.expertise !== filters.expertise) return false;
      if (filters.language && tool.language && !tool.language.includes(filters.language)) return false;
      if (filters.dataPrivacy && tool.dataPrivacy && !tool.dataPrivacy.includes(filters.dataPrivacy)) return false;
      if (filters.aiModel && tool.aiModel && !tool.aiModel.includes(filters.aiModel)) return false;
      if (filters.outputFormat && tool.outputFormats && !tool.outputFormats.includes(filters.outputFormat)) return false;
      
      if (filters.priceRange) {
        const price = extractPrice(tool.price);
        if (!isPriceInRange(price, filters.priceRange)) return false;
      }

      return true;
    })
    .sort((a, b) => {
      switch (filters.sortBy) {
        case 'popular':
          return (b.reviewCount || 0) - (a.reviewCount || 0);
        case 'rating':
          return (b.rating || 0) - (a.rating || 0);
        case 'newest':
          return b.new ? 1 : -1;
        case 'price-low':
          return extractPrice(a.price) - extractPrice(b.price);
        case 'price-high':
          return extractPrice(b.price) - extractPrice(a.price);
        default:
          return 0;
      }
    });
}

function extractPrice(priceString: string): number {
  if (!priceString) return 0;
  const match = priceString.toLowerCase().match(/\$?(\d+)/);
  return match ? parseInt(match[1]) : 0;
}

function isPriceInRange(price: number, range: string): boolean {
  switch (range) {
    case 'free':
      return price === 0;
    case 'freemium':
      return price <= 10;
    case 'low':
      return price > 0 && price <= 50;
    case 'medium':
      return price > 50 && price <= 200;
    case 'high':
      return price > 200;
    default:
      return true;
  }
}