import React from 'react';
import { Heart, Star, Users, Sparkles, ArrowRight, DollarSign } from 'lucide-react';
import Button from '../components/ui/Button';

const impactChannels = [
  {
    name: "Tyler Robinson Foundation",
    description: "In partnership with Imagine Dragons, we help families navigate the financial and emotional challenges of pediatric cancer treatment.",
    image: "https://images.unsplash.com/photo-1542884748-2b87b36c6b90?w=800&auto=format&fit=crop",
    stats: { 
      raised: "$500K+", 
      families: "1,000+",
      impact: "Medical Bills Covered"
    },
    link: "https://trf.org",
    color: "from-blue-500 to-violet-500"
  },
  {
    name: "Homeless Support Initiative",
    description: "Comprehensive support including emergency shelter, nutritious meals, job training, and permanent housing placement assistance.",
    image: "https://images.unsplash.com/photo-1532629345422-7515f3d16bb6?w=800&auto=format&fit=crop",
    stats: { 
      meals: "50K+", 
      sheltered: "500+",
      employed: "200+"
    },
    link: "#",
    color: "from-emerald-500 to-teal-500"
  },
  {
    name: "Tech Education Fund",
    description: "Empowering the next generation through STEM and AI education, providing laptops, curriculum, and mentorship to underserved communities.",
    image: "https://images.unsplash.com/photo-1509062522246-3755977927d7?w=800&auto=format&fit=crop",
    stats: { 
      students: "2,000+", 
      schools: "50+",
      graduates: "500+"
    },
    link: "#",
    color: "from-violet-500 to-purple-500"
  },
  {
    name: "Environmental Conservation",
    description: "Partnering with global organizations to combat climate change through reforestation, ocean cleanup, and sustainable technology initiatives.",
    image: "https://images.unsplash.com/photo-1497250681960-ef046c08a56e?w=800&auto=format&fit=crop",
    stats: { 
      trees: "10K+", 
      beaches: "25+",
      carbon: "500T+"
    },
    link: "#",
    color: "from-green-500 to-emerald-500"
  }
];

export default function Impact() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-32 overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />
      <div className="absolute inset-y-0 right-0 w-1/2 bg-[radial-gradient(circle_at_70%_50%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />
      
      {/* Revenue Commitment Banner */}
      <div className="relative bg-gradient-to-r from-blue-600 via-violet-600 to-purple-600 text-white py-20 mb-16 overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_120%,rgba(255,255,255,0.1),rgba(255,255,255,0))]" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_0%,rgba(255,255,255,0.1),rgba(255,255,255,0))]" />
        {/* Animated Hearts */}
        {[...Array(5)].map((_, i) => (
          <div
            key={i}
            className="absolute animate-float"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDelay: `${i * 0.5}s`
            }}
          >
            <Heart className="w-4 h-4 text-white/20" />
          </div>
        ))}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-white/10 backdrop-blur-sm mb-4">
            <Heart className="w-8 h-8 text-white animate-pulse hover:scale-110 transition-transform cursor-pointer" />
          </div>
          <h2 className="text-4xl sm:text-5xl font-bold mb-6 [text-shadow:0_0_40px_rgba(255,255,255,0.2)]">
            Making a Meaningful Impact
          </h2>
          <p className="text-lg sm:text-xl text-white/90 max-w-3xl mx-auto leading-relaxed">
            Every subscription helps us make a difference. We're committed to supporting impactful causes that create lasting positive change.
          </p>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Impact Stats */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mb-24">
          {[
            { value: '$1M+', label: 'Donated', icon: DollarSign },
            { value: '5,000+', label: 'Lives Impacted', icon: Users },
            { value: '100+', label: 'Projects Funded', icon: Star },
            { value: '4', label: 'Impact Channels', icon: Sparkles }
          ].map(({ value, label, icon: Icon }) => (
            <div
              key={label}
              className="group relative bg-white/80 dark:bg-slate-900/80 backdrop-blur-sm rounded-xl p-8 border border-gray-200/50 dark:border-gray-800/50 hover:border-blue-500/30 dark:hover:border-blue-400/30 transition-all duration-300 hover:scale-105"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-violet-500/5 dark:from-blue-400/5 dark:to-violet-400/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
              <div className="w-16 h-16 mx-auto mb-6 rounded-xl bg-gradient-to-br from-blue-100 to-violet-100 dark:from-blue-900/20 dark:to-violet-900/20 flex items-center justify-center group-hover:scale-110 transition-transform">
                <Icon className="w-6 h-6 text-blue-600 dark:text-blue-400" />
              </div>
              <div className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-br from-blue-600 to-violet-600 dark:from-blue-400 dark:to-violet-400 mb-2">
                {value}
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400">
                {label}
              </div>
            </div>
          ))}
        </div>

        {/* Impact Channels */}
        <div className="grid md:grid-cols-2 gap-12 mb-24">
          {impactChannels.map((channel) => (
            <div
              key={channel.name}
              className="group relative bg-white/90 dark:bg-slate-900/90 backdrop-blur-sm rounded-2xl overflow-hidden border border-gray-200/50 dark:border-gray-800/50 hover:shadow-xl transition-all duration-300 hover:scale-[1.02]"
            >
              <div className="absolute inset-0 bg-gradient-to-br opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              
              <div className="relative aspect-video">
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent" />
                <img
                  src={channel.image}
                  alt={channel.name}
                  className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-1000 filter brightness-90"
                />
                <div className="absolute inset-0 bg-gradient-to-br from-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <div className="absolute bottom-4 left-4 right-4">
                  <h3 className="text-2xl font-bold text-white mb-2 drop-shadow-lg">
                    {channel.name}
                  </h3>
                </div>
              </div>
              
              <div className="p-8">
                <p className="text-gray-600 dark:text-gray-400 mb-4">
                  {channel.description}
                </p>
                
                <div className="grid grid-cols-3 gap-6 mb-8">
                  {Object.entries(channel.stats).map(([key, value]) => (
                    <div key={key} className="text-center p-4 bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl">
                      <div className="text-lg font-bold bg-gradient-to-br from-blue-600 to-violet-600 dark:from-blue-400 dark:to-violet-400 bg-clip-text text-transparent">
                        {value}
                      </div>
                      <div className="text-sm text-gray-600 dark:text-gray-400 capitalize">
                        {key}
                      </div>
                    </div>
                  ))}
                </div>

                <Button
                  variant="outline"
                  size="lg"
                  icon={ArrowRight}
                  className="w-full group hover:bg-gradient-to-r hover:from-blue-600 hover:to-violet-600 hover:text-white transition-all duration-300"
                  onClick={() => window.open(channel.link, '_blank')}
                >
                  <span className="relative">
                    Learn More
                    <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/[0.1] to-transparent translate-x-[-200%] group-hover:translate-x-[200%] transition-transform duration-[400ms]" />
                  </span>
                </Button>
              </div>
            </div>
          ))}
        </div>

        {/* CTA Section */}
        <div className="relative bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-2xl p-16 text-center mb-16 overflow-hidden">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(120,119,198,0.1),rgba(0,0,0,0))]" />
          <div className="relative">
          <h3 className="text-3xl font-bold text-gray-900 dark:text-white mb-6">
            Join Us in Making a Difference
          </h3>
          <p className="text-lg text-gray-600 dark:text-gray-400 mb-8 max-w-2xl mx-auto">
            Your subscription directly supports these amazing initiatives. Start your free trial today and be part of something bigger.
          </p>
          <Button
            variant="primary"
            size="lg"
            icon={ArrowRight}
            className="group relative px-12 py-6 text-lg"
          >
            <span className="relative">
              Start Free Trial
              <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/[0.1] to-transparent translate-x-[-200%] group-hover:translate-x-[200%] transition-transform duration-[400ms]" />
            </span>
          </Button>
          </div>
        </div>
      </div>
    </div>
  );
}