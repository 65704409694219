import React from 'react';
import { Shield, Lock, FileCheck, Server, Users, Bell, Database, Key } from 'lucide-react';

const sections = [
  {
    title: 'Infrastructure Security',
    icon: Server,
    content: [
      'SOC 2 Type II certified infrastructure',
      'Multi-region data redundancy',
      'Real-time threat monitoring',
      '99.99% uptime SLA',
      'Regular penetration testing'
    ]
  },
  {
    title: 'Data Protection',
    icon: Shield,
    content: [
      'AES-256 encryption at rest',
      'TLS 1.3 encryption in transit',
      'Regular security audits',
      'Automated backup systems',
      'Data isolation architecture'
    ]
  },
  {
    title: 'Access Control',
    icon: Lock,
    content: [
      'Role-based access control (RBAC)',
      'Multi-factor authentication (MFA)',
      'Single sign-on (SSO) support',
      'IP whitelisting',
      'Session management'
    ]
  },
  {
    title: 'Compliance',
    icon: FileCheck,
    content: [
      'GDPR compliance',
      'HIPAA compliance',
      'CCPA compliance',
      'ISO 27001 certified',
      'Regular compliance audits'
    ]
  },
  {
    title: 'Privacy Features',
    icon: Users,
    content: [
      'Data anonymization',
      'Privacy by design',
      'Consent management',
      'Data retention controls',
      'Right to be forgotten'
    ]
  },
  {
    title: 'Monitoring & Response',
    icon: Bell,
    content: [
      '24/7 security monitoring',
      'Incident response team',
      'Automated threat detection',
      'Security event logging',
      'Vulnerability scanning'
    ]
  }
];

const certifications = [
  { name: 'SOC 2 Type II', icon: Shield },
  { name: 'ISO 27001', icon: FileCheck },
  { name: 'HIPAA', icon: Database },
  { name: 'GDPR', icon: Key }
];

export default function Security() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-24">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <Shield className="w-6 h-6 text-blue-600 dark:text-blue-400" />
          </div>
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Enterprise-Grade Security
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Your data security and privacy is our top priority
          </p>
        </div>

        {/* Certifications */}
        <div className="flex justify-center gap-8 mb-16">
          {certifications.map((cert) => {
            const Icon = cert.icon;
            return (
              <div
                key={cert.name}
                className="flex items-center bg-white/80 dark:bg-slate-900/80 backdrop-blur-sm px-4 py-2 rounded-lg border border-gray-200/50 dark:border-gray-700/50"
              >
                <Icon className="w-5 h-5 text-blue-600 dark:text-blue-400 mr-2" />
                <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                  {cert.name}
                </span>
              </div>
            );
          })}
        </div>

        {/* Main Content */}
        <div className="space-y-12 mb-16">
          {sections.map((section) => {
            const Icon = section.icon;
            return (
              <div
                key={section.title}
                className="bg-white dark:bg-slate-900 rounded-xl p-6 shadow-lg border border-gray-200/50 dark:border-gray-800/50"
              >
                <div className="flex items-center mb-6">
                  <div className="p-2 rounded-lg bg-blue-50 dark:bg-blue-900/20">
                    <Icon className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                  </div>
                  <h2 className="ml-4 text-xl font-semibold text-gray-900 dark:text-white">
                    {section.title}
                  </h2>
                </div>
                <ul className="space-y-3">
                  {section.content.map((item, index) => (
                    <li
                      key={index}
                      className="flex items-start"
                    >
                      <div className="w-1.5 h-1.5 rounded-full bg-blue-500 dark:bg-blue-400 mt-2 mr-3 flex-shrink-0" />
                      <span className="text-gray-600 dark:text-gray-300">
                        {item}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>

        {/* Contact Section */}
        <div className="bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-8 text-center mb-16">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
            Have Security Questions?
          </h3>
          <p className="text-gray-600 dark:text-gray-300 mb-6">
            Our security team is available 24/7 to address any concerns.
          </p>
          <div className="text-gray-600 dark:text-gray-300 mb-6 space-y-4">
            <div>
              <strong>Utah Headquarters:</strong><br />
              GALAX Studios<br />
              10718 South Beckstead, Suite 200<br />
              South Jordan, Utah 84095
            </div>
            <div>
              <strong>Puerto Rico Office:</strong><br />
              GALAX Studios<br />
              1225 Avenida Juan Ponce de León<br />
              San Juan, 00907, Puerto Rico
            </div>
          </div>
          <a
            href="mailto:security@galaxstudios.com"
            className="inline-flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Contact Security Team
          </a>
        </div>
      </div>
    </div>
  );
}