import React from 'react';
import { Video } from 'lucide-react';

interface VideoThumbnailProps {
  videoId: string;
  title: string;
  duration?: string;
  onClick?: () => void;
}

export default function VideoThumbnail({ videoId, title, duration = '2:15', onClick }: VideoThumbnailProps) {
  return (
    <div className="relative w-64 aspect-video rounded-lg overflow-hidden cursor-pointer group/video shadow-lg hover:shadow-xl transition-all"
      onClick={onClick}
    >
      <div className="absolute inset-0 bg-gradient-to-br from-gray-900/80 to-gray-900/40 z-10" />
      <img
        src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
        alt={`${title} demo`}
        className="w-full h-full object-cover transform group-hover/video:scale-105 transition-transform duration-300"
      />
      <div className="absolute inset-0 flex items-center justify-center z-20">
        <div className="w-12 h-12 rounded-full bg-white/10 backdrop-blur-sm flex items-center justify-center group-hover/video:scale-110 transition-transform border border-white/20">
          <Video className="w-5 h-5 text-white group-hover/video:text-blue-400 transition-colors" />
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/60 to-transparent z-20">
        <p className="text-white text-sm font-medium text-center group-hover/video:text-blue-400 transition-colors">
          Watch Demo ({duration})
        </p>
      </div>
    </div>
  );
}