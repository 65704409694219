import React from 'react';

import { useRouter } from '../../hooks/useRouter';
import { ROUTES } from '../../utils/routes';

export default function GalaxLogo() {
  const { currentPath } = useRouter();
  
  // Check if we're on a government page
  const isGovernment = currentPath === ROUTES.GOVERNMENT || 
                      currentPath === ROUTES.GOVERNMENT_SOLUTIONS ||
                      currentPath === ROUTES.GOVERNMENT_ASSESSMENT ||
                      currentPath === ROUTES.GOVERNMENT_CONSULTATION ||
                      currentPath === ROUTES.GOVERNMENT_CAPABILITY;

  return (
    <div className="flex flex-col items-center relative group">
      {/* Main logo */}
      <div className="relative flex flex-col items-center transition-all duration-1000 group-hover:scale-105">
        <div className="text-3xl font-black tracking-tight flex items-center transition-all duration-1000 relative">
          <div className="flex items-center">
            {['G', 'A', 'L', 'A', 'X'].map((letter, index) => (
              <span
                key={letter + index}
                className={`relative transition-all duration-500 hover:scale-110 hover:-translate-y-0.5 cursor-default px-[0.5px] 
                  ${index === 0 ? 'text-black dark:text-transparent dark:bg-gradient-to-br dark:from-indigo-400 dark:via-violet-300 dark:to-blue-400 dark:bg-clip-text dark:group-hover:animate-pulse-slow' : ''}
                  ${index === 1 ? 'text-black dark:text-transparent dark:bg-gradient-to-br dark:from-blue-400 dark:to-violet-400 dark:bg-clip-text' : ''}
                  ${index === 2 ? 'text-black dark:text-transparent dark:bg-gradient-to-br dark:from-violet-400 dark:to-indigo-400 dark:bg-clip-text' : ''}
                  ${index === 3 ? 'text-black dark:text-transparent dark:bg-gradient-to-br dark:from-indigo-400 dark:to-blue-400 dark:bg-clip-text' : ''}
                  ${index === 4 ? 'text-black dark:text-transparent dark:bg-gradient-to-br dark:from-blue-400 dark:to-violet-400 dark:bg-clip-text' : ''}
                  hover:text-black dark:hover:text-transparent [text-shadow:0_0_40px_rgba(147,51,234,0)] dark:[text-shadow:0_0_40px_rgba(147,51,234,0.5)] group-hover:dark:[text-shadow:0_0_80px_rgba(147,51,234,0.8)]`}
              >
                {letter}
              </span>
            ))}
            <span className="absolute -top-1.5 -right-3 text-[0.45em] text-black dark:text-transparent dark:bg-gradient-to-br dark:from-indigo-400 dark:to-blue-400 dark:bg-clip-text opacity-80 group-hover:opacity-100 transition-all duration-1000 group-hover:animate-pulse-slow group-hover:scale-125">™</span>
          </div>
        </div>
        
        {/* Centered Studios text */}
        <div className="relative -mt-2.5 text-center transition-all duration-1000 translate-x-[0.5em]">
          <span className="relative text-[0.55rem] tracking-[0.4em] font-medium text-black dark:text-transparent dark:bg-gradient-to-r dark:from-indigo-400 dark:via-violet-400 dark:to-blue-400 dark:bg-clip-text inline-flex items-center justify-center group-hover:tracking-[0.5em] transition-all duration-1000">
            {isGovernment ? 'GOVERNMENT' : 'STUDIOS'}<span className="text-[0.7em] ml-1 -translate-y-1 text-black dark:text-transparent opacity-80 group-hover:opacity-100 transition-opacity duration-1000">™</span>
          </span>
        </div>
      </div>
    </div>
  );
}