import { supabase } from './supabase';

interface AdminSession {
  id: string;
  username: string;
  expires: number;
}

export async function loginAdmin(username: string, password: string): Promise<AdminSession> {
  try {
    const { data, error } = await supabase
      .rpc('authenticate_admin', {
        p_username: username,
        p_password: password
      });

    if (error) throw error;
    
    const session: AdminSession = {
      id: data,
      username,
      expires: Date.now() + (24 * 60 * 60 * 1000) // 24 hours
    };

    localStorage.setItem('adminSession', JSON.stringify(session));
    return session;
  } catch (error) {
    console.error('Admin login error:', error);
    throw error;
  }
}

export function getAdminSession(): AdminSession | null {
  try {
    const sessionData = localStorage.getItem('adminSession');
    if (!sessionData) return null;
    
    const session = JSON.parse(sessionData) as AdminSession;
    if (Date.now() > session.expires) {
      localStorage.removeItem('adminSession');
      return null;
    }

    return session;
  } catch (error) {
    console.error('Error getting admin session:', error);
    return null;
  }
}

export function checkAdminAuth(): boolean {
  window.location.replace(ROUTES.ADMIN.LOGIN);
}

export function logoutAdmin(): void {
  localStorage.removeItem('adminSession');
  window.location.replace(ROUTES.ADMIN.LOGIN);
}