import React, { useEffect } from 'react';
import { Shield, CheckCircle2 } from 'lucide-react';
import { handleCheckoutSuccess } from '../utils/auth';
import { ROUTES } from '../utils/routes';

export default function CheckoutSuccess() {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('session_id');

    if (sessionId) {
      handleCheckoutSuccess(sessionId);
    } else {
      // No session ID - redirect to workspace
      window.location.href = ROUTES.WORKSPACE.DASHBOARD;
    }
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950">
      <div className="text-center">
        <div className="inline-flex items-center justify-center p-2 rounded-lg bg-green-100 dark:bg-green-900/30 mb-4">
          <CheckCircle2 className="w-8 h-8 text-green-600 dark:text-green-400" />
        </div>
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
          Payment Successful!
        </h1>
        <p className="text-gray-600 dark:text-gray-400">
          Redirecting you to your workspace...
        </p>
      </div>
    </div>
  );
}