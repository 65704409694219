import React, { useState, useEffect } from 'react';
import { Brain, Building2, Shield, ArrowRight, CheckCircle2, Star, Users, Clock } from 'lucide-react';
import Button from '../components/ui/Button';
import { useRouter } from '../hooks/useRouter';
import { ROUTES } from '../utils/routes';
import { trackConversion } from '../utils/analytics';

interface AssessmentQuestion {
  id: string;
  text: string;
  options: {
    id: string;
    label: string;
    description?: string;
    icon?: LucideIcon;
    metrics?: Record<string, string>;
  }[];
}

const startupQuestions: AssessmentQuestion[] = [
  {
    id: 'stage',
    text: 'What stage is your startup currently in?',
    options: [
      {
        id: 'idea',
        label: 'Idea Stage',
        description: 'I have an idea but haven\'t started building',
        metrics: {
          timeline: '3-4 months',
          investment: '$15K-25K'
        }
      },
      {
        id: 'mvp',
        label: 'MVP Development',
        description: 'Building or validating initial product',
        metrics: {
          timeline: '2-3 months',
          investment: '$25K-35K'
        }
      },
      {
        id: 'scaling',
        label: 'Scaling Phase',
        description: 'Growing user base and revenue',
        metrics: {
          timeline: '3-6 months',
          investment: '$35K-50K'
        }
      },
      {
        id: 'fundraising',
        label: 'Fundraising',
        description: 'Preparing for or actively raising capital',
        metrics: {
          timeline: '1-2 months',
          investment: '$25K-40K'
        }
      }
    ]
  },
  {
    id: 'challenge',
    text: 'What\'s your primary challenge?',
    options: [
      {
        id: 'validation',
        label: 'Market Validation',
        description: 'Validating product-market fit',
        metrics: {
          success: '92%',
          timeline: '4-6 weeks'
        }
      },
      {
        id: 'technical',
        label: 'Technical Development',
        description: 'Building the AI product',
        metrics: {
          success: '95%',
          timeline: '8-12 weeks'
        }
      },
      {
        id: 'go_to_market',
        label: 'Go-to-Market',
        description: 'Launch and customer acquisition',
        metrics: {
          success: '88%',
          timeline: '6-8 weeks'
        }
      },
      {
        id: 'fundraising',
        label: 'Fundraising',
        description: 'Investor pitch and fundraising',
        metrics: {
          success: '85%',
          timeline: '8-12 weeks'
        }
      }
    ]
  }
];

const enterpriseQuestions: AssessmentQuestion[] = [
  {
    id: 'objective',
    text: 'What\'s the main objective of your project?',
    options: [
      {
        id: 'automation',
        label: 'Automate Processes',
        description: 'Streamline operations with AI automation',
        metrics: {
          roi: '425%',
          savings: '$2.1M/yr',
          timeline: '3-4 months',
          success: '98%'
        }
      },
      {
        id: 'product',
        label: 'Launch New Product',
        description: 'Build and launch AI-powered products',
        metrics: {
          timeline: '4-6 months',
          success: '92%',
          roi: '312%',
          scale: 'Global'
        }
      },
      {
        id: 'efficiency',
        label: 'Improve Efficiency',
        description: 'Optimize operations with AI',
        metrics: {
          savings: '65%',
          timeline: '2-3 months',
          roi: '285%',
          success: '95%'
        }
      },
      {
        id: 'custom',
        label: 'Custom AI Development',
        description: 'Build custom AI models and infrastructure',
        metrics: {
          timeline: '6+ months',
          scale: 'Enterprise',
          security: 'SOC2',
          support: '24/7'
        }
      }
    ]
  },
  {
    id: 'timeline',
    text: 'What\'s your timeline for deployment?',
    options: [
      {
        id: 'urgent',
        label: '1-3 months',
        description: 'Urgent deployment needed',
        metrics: {
          success: '90%',
          support: 'Priority',
          team: 'Dedicated'
        }
      },
      {
        id: 'standard',
        label: '3-6 months',
        description: 'Standard implementation timeline',
        metrics: {
          success: '95%',
          support: '24/7',
          team: 'Dedicated'
        }
      },
      {
        id: 'extended',
        label: '6+ months',
        description: 'Extended planning and implementation',
        metrics: {
          success: '98%',
          support: 'White Glove',
          team: 'Custom'
        }
      }
    ]
  }
];

const governmentQuestions: AssessmentQuestion[] = [
  {
    id: 'domain',
    text: 'What\'s your primary domain?',
    options: [
      {
        id: 'defense',
        label: 'Defense & Intelligence',
        description: 'AI solutions for defense operations',
        metrics: {
          security: 'IL6',
          timeline: '6-8 months'
        }
      },
      {
        id: 'civilian',
        label: 'Civilian Agency',
        description: 'AI modernization for civilian agencies',
        metrics: {
          compliance: 'FedRAMP',
          timeline: '4-6 months'
        }
      },
      {
        id: 'state',
        label: 'State & Local',
        description: 'AI solutions for state/local government',
        metrics: {
          roi: '285%',
          timeline: '3-4 months'
        }
      }
    ]
  }
];

const ACTIVE_USERS = Math.floor(Math.random() * 50) + 350;

const userTypes = [
  {
    id: 'startup',
    label: 'Startup Entrepreneur',
    icon: Brain,
    description: 'Building an AI-first startup or integrating AI into your venture',
    benefits: [
      'Technical co-founding support',
      'Custom AI development',
      'Startup-friendly pricing',
      'Launch in 90 days'
    ],
    stats: {
      success: '98%',
      timeline: '90 days',
      savings: '$250K+'
    },
    testimonial: 'Launched MVP in 6 weeks'
  },
  {
    id: 'enterprise',
    label: 'Specialized Enterprise',
    icon: Building2,
    benefits: [
      'Enterprise AI solutions',
      'Custom model development',
      'Security & compliance',
      'Dedicated support'
    ],
    stats: {
      uptime: '99.99%',
      security: 'SOC2',
      scale: 'Global'
    },
    testimonial: 'Reduced costs by 65%'
  },
  {
    id: 'government',
    label: 'Government/Institution',
    icon: Shield,
    benefits: [
      'FedRAMP authorized',
      'IL5/IL6 certified',
      'Custom AI solutions',
      'Agency-wide deployment'
    ],
    stats: {
      compliance: 'IL5/IL6',
      security: 'NIST',
      support: '24/7'
    },
    testimonial: 'FedRAMP in 6 months'
  }
];

export default function AssessmentFunnel() {
  const { navigate } = useRouter();
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const [answers, setAnswers] = useState<Record<string, string>>({});
  const [timeLeft, setTimeLeft] = useState(900); // 15 minutes
  const [loading, setLoading] = useState(false);
  const [confidence, setConfidence] = useState(0);

  // Get questions based on user type
  const getCurrentQuestions = () => {
    switch (selectedType) {
      case 'startup':
        return startupQuestions;
      case 'enterprise':
        return enterpriseQuestions;
      case 'government':
        return governmentQuestions;
      default:
        return [];
    }
  };

  const questions = getCurrentQuestions();
  const currentQuestionData = questions[currentQuestion];

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(t => t > 0 ? t - 1 : 0);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    // Calculate confidence score
    const totalQuestions = questions.length + 1; // +1 for user type selection
    const answeredQuestions = Object.keys(answers).length + (selectedType ? 1 : 0);
    setConfidence((answeredQuestions / totalQuestions) * 100);
  }, [selectedType, answers, questions.length]);

  const handleAnswer = (questionId: string, answerId: string) => {
    setAnswers(prev => ({
      ...prev,
      [questionId]: answerId
    }));

    // Track answer
    trackConversion({
      type: 'assessment_answer',
      page: ROUTES.ASSESSMENT,
      data: {
        userType: selectedType,
        questionId,
        answerId
      }
    });

    // Move to next question or continue to specialized assessment
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(prev => prev + 1);
    }
  };

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const handleContinue = async () => {
    if (!selectedType) return;

    setLoading(true);
    try {
      // Store selection
      localStorage.setItem('userType', selectedType);
      localStorage.setItem('assessmentAnswers', JSON.stringify(answers));
      
      // Track selection
      trackConversion({
        type: 'assessment_start',
        page: ROUTES.ASSESSMENT,
        data: { 
          userType: selectedType,
          answers,
          confidence
        }
      });

      // Route to appropriate page based on user type and answers
      switch (selectedType) {
        case 'startup':
          navigate(ROUTES.CHOOSE_PLAN);
          break;
        case 'enterprise':
          // Store enterprise-specific data
          localStorage.setItem('enterpriseAssessment', JSON.stringify({
            objective: answers['objective'],
            timeline: answers['timeline'],
            confidence: confidence
          }));
          
          // Track enterprise assessment completion
          trackConversion({
            type: 'enterprise_assessment_complete',
            page: ROUTES.ASSESSMENT,
            data: {
              objective: answers['objective'],
              timeline: answers['timeline'],
              confidence
            }
          });
          
          navigate(ROUTES.ENTERPRISE);
          break;
        case 'government':
          navigate(ROUTES.GOVERNMENT_ASSESSMENT);
          break;
        default:
          // Default to pricing/plan selection
          navigate(ROUTES.CHOOSE_PLAN);
      }
    } catch (error) {
      console.error('Error handling selection:', error);
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-24">
      {/* Urgency Banner */}
      <div className="fixed top-0 left-0 right-0 bg-gradient-to-r from-blue-600 to-violet-600 text-white py-2 text-center z-50">
        <div className="flex items-center justify-center space-x-4">
          <div className="flex items-center">
            <Clock className="w-4 h-4 mr-1" />
            <span>Special Launch Pricing Ends In: {formatTime(timeLeft)}</span>
          </div>
          <div className="hidden sm:flex items-center">
            <Users className="w-4 h-4 mr-1" />
            <span>{ACTIVE_USERS} people viewing</span>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <Brain className="w-6 h-6 text-blue-600 dark:text-blue-400" />
          </div>
          {!selectedType ? (
            <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
              Let's Find Your Perfect AI Solution
            </h1>
          ) : (
            <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
              {currentQuestionData?.text || 'Analyzing Your Needs'}
            </h1>
          )}
          <p className="text-lg text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            Tell us about your organization and we'll recommend the best AI tools and solutions for your needs
          </p>

          {/* Trust Badges */}
          <div className="flex flex-wrap justify-center gap-8 mt-8">
            <div className="flex items-center space-x-2">
              <Shield className="w-5 h-5 text-green-600 dark:text-green-400" />
              <span className="text-gray-700 dark:text-gray-300">
                <span className="font-semibold">7-day</span> free trial
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <Star className="w-5 h-5 text-yellow-400 fill-yellow-400" />
              <span className="text-gray-700 dark:text-gray-300">
                <span className="font-semibold">4.9/5</span> from 500+ reviews
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <CheckCircle2 className="w-5 h-5 text-blue-600 dark:text-blue-400" />
              <span className="text-gray-700 dark:text-gray-300">
                <span className="font-semibold">2-minute</span> setup
              </span>
            </div>
          </div>
        </div>

        {/* User Type Selection */}
        {!selectedType ? (
          <div className="grid md:grid-cols-3 gap-8 mb-12">
            {userTypes.map((type) => {
              const Icon = type.icon;
              return (
                <button
                  key={type.id}
                  onClick={() => setSelectedType(type.id)}
                  className={`group relative p-8 rounded-xl text-left transition-all duration-300 ${
                    selectedType === type.id
                      ? 'bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 border-2 border-blue-500 shadow-xl scale-[1.02]'
                      : 'bg-white dark:bg-slate-900 border-2 border-gray-200/50 dark:border-gray-700/50 hover:border-blue-300 hover:scale-[1.01]'
                  }`}
                >
                  {type.testimonial && (
                    <div className="absolute top-3 right-3 text-xs bg-green-100 dark:bg-green-900/30 text-green-700 dark:text-green-300 px-2 py-1 rounded-full animate-pulse">
                      "{type.testimonial}"
                    </div>
                  )}

                  <div className="flex items-start">
                    <div className="p-3 rounded-lg bg-gradient-to-br from-blue-100 to-violet-100 dark:from-blue-900/20 dark:to-violet-900/20">
                      <Icon className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                    </div>
                    <div className="ml-4 flex-1">
                      <h4 className="text-lg font-semibold text-gray-900 dark:text-white mb-3">
                        {type.label}
                      </h4>

                      <ul className="space-y-3">
                        {type.benefits.map((benefit, index) => (
                          <li
                            key={index}
                            className="flex items-center text-gray-700 dark:text-gray-300"
                          >
                            <CheckCircle2 className="w-4 h-4 text-green-500 mr-2 flex-shrink-0" />
                            {benefit}
                          </li>
                        ))}
                      </ul>

                      {type.stats && (
                        <div className="grid grid-cols-3 gap-2 mt-6">
                          {Object.entries(type.stats).map(([key, value]) => (
                            <div key={key} className="text-center p-2 bg-blue-50 dark:bg-blue-900/20 rounded-lg">
                              <div className="text-sm font-semibold text-blue-600 dark:text-blue-400">
                                {value}
                              </div>
                              <div className="text-xs text-gray-600 dark:text-gray-400 capitalize">
                                {key}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </button>
              );
            })}
          </div>
        ) : currentQuestionData ? (
          <div className="grid md:grid-cols-2 gap-8 mb-12">
            {currentQuestionData.options.map((option) => (
              <button
                key={option.id}
                onClick={() => handleAnswer(currentQuestionData.id, option.id)}
                className={`group relative p-6 rounded-xl text-left transition-all ${
                  answers[currentQuestionData.id] === option.id
                    ? 'bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 border-2 border-blue-500 shadow-xl scale-[1.02]'
                    : 'bg-white dark:bg-slate-900 border-2 border-gray-200/50 dark:border-gray-700/50 hover:border-blue-300'
                }`}
              >
                <div className="flex items-start">
                  {option.icon && (
                    <div className="p-3 rounded-lg bg-gradient-to-br from-blue-100 to-violet-100 dark:from-blue-900/20 dark:to-violet-900/20">
                      <option.icon className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                    </div>
                  )}
                  <div className="ml-4 flex-1">
                    <h4 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                      {option.label}
                    </h4>
                    {option.description && (
                      <p className="text-gray-600 dark:text-gray-400 mb-4">
                        {option.description}
                      </p>
                    )}
                    {option.metrics && (
                      <div className="grid grid-cols-2 gap-2">
                        {Object.entries(option.metrics).map(([key, value]) => (
                          <div key={key} className="text-center p-2 bg-blue-50 dark:bg-blue-900/20 rounded-lg">
                            <div className="text-sm font-semibold text-blue-600 dark:text-blue-400">
                              {value}
                            </div>
                            <div className="text-xs text-gray-600 dark:text-gray-400 capitalize">
                              {key}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </button>
            ))}
          </div>
        ) : null}

        {/* Progress Indicator */}
        {selectedType && (
          <div className="max-w-md mx-auto mb-8">
            <div className="flex justify-between text-sm mb-2">
              <span className="text-gray-600 dark:text-gray-400">Assessment Progress</span>
              <span className="font-medium text-blue-600 dark:text-blue-400">{confidence}%</span>
            </div>
            <div className="h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
              <div
                className="h-full bg-gradient-to-r from-blue-500 to-violet-500 rounded-full transition-all duration-500"
                style={{ width: `${confidence}%` }}
              />
            </div>
          </div>
        )}

        {/* Continue Button */}
        <div className="text-center">
          <Button
            variant="primary"
            size="lg"
            icon={ArrowRight}
            onClick={handleContinue}
            disabled={!selectedType || (currentQuestion < questions.length - 1) || loading}
            className="group"
          >
            <span className="relative">
              {!selectedType ? 'Continue to Assessment' : currentQuestion < questions.length - 1 ? 'Continue' : 'See Your Solution'}
              <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/[0.1] to-transparent translate-x-[-200%] group-hover:translate-x-[200%] transition-transform duration-[400ms]" />
            </span>
          </Button>

          {/* Trust Indicators */}
          <div className="mt-6 flex items-center justify-center space-x-6 text-sm text-gray-500 dark:text-gray-400">
            <div className="flex items-center">
              <Shield className="w-4 h-4 text-green-500 mr-1" />
              No credit card required
            </div>
            <div className="flex items-center">
              <Clock className="w-4 h-4 text-blue-500 mr-1" />
              2-minute setup
            </div>
            <div className="flex items-center">
              <Users className="w-4 h-4 text-violet-500 mr-1" />
              50,000+ users
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}