import React, { useState } from 'react';
import { ArrowLeft, Home, Search } from 'lucide-react';
import Button from '../components/ui/Button';
import { useRouter } from '../hooks/useRouter';
import { ROUTES } from '../utils/routes';

export default function NotFound() {
  const { navigate } = useRouter();
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 flex items-center justify-center p-4 relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_80%,rgba(147,51,234,0.05),rgba(0,0,0,0))]" />
      </div>
      
      <div className="relative max-w-lg w-full text-center px-4">
        <div className="mb-8">
          <h1 className="text-[150px] leading-none font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-violet-600 to-purple-600 dark:from-blue-400 dark:via-violet-400 dark:to-purple-400 animate-fade-in">
            404
          </h1>
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4 animate-fade-in [animation-delay:200ms]">
            Page Not Found
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-400 mb-8 animate-fade-in [animation-delay:400ms]">
            The page you're looking for doesn't exist or has been moved.
          </p>
        </div>

        <div className="flex flex-col sm:flex-row justify-center gap-4 mb-8 animate-fade-in [animation-delay:600ms]">
          <Button
            variant="primary"
            size="lg"
            icon={Home}
            onClick={() => navigate(ROUTES.HOME)}
            className="group"
          >
            <span className="relative">
              Back to Home
              <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/[0.1] to-transparent translate-x-[-200%] group-hover:translate-x-[200%] transition-transform duration-[400ms]" />
            </span>
          </Button>
          <Button
            variant="outline"
            size="lg"
            icon={Search}
            onClick={() => navigate(ROUTES.TOOLS)}
          >
            Browse AI Tools
          </Button>
        </div>

        <div className="text-sm text-gray-500 dark:text-gray-400 animate-fade-in [animation-delay:800ms]">
          Need help? <button onClick={() => navigate(ROUTES.CONTACT)} className="text-blue-600 dark:text-blue-400 hover:underline">Contact our support team</button>
        </div>
      </div>
    </div>
  );
}