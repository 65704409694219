import React from 'react';
import { Brain, Image, Video, Bot } from 'lucide-react';
import Button from '../components/ui/Button';
import ImageGenerator from '../components/tools/image-generator/ImageGenerator';

const toolConfigs = {
  'content-writer': {
    name: 'AI Content Writer',
    icon: Brain,
    description: 'Generate high-quality content with AI',
    features: [
      'Blog post generation',
      'Social media content',
      'Marketing copy',
      'Product descriptions'
    ]
  },
  'image-generator': {
    name: 'AI Image Generator',
    icon: Image,
    description: 'Create custom images with AI',
    features: [
      'Custom artwork',
      'Marketing visuals',
      'Product photos',
      'Brand assets'
    ]
  },
  'video-creator': {
    name: 'AI Video Creator',
    icon: Video,
    description: 'Create and edit videos with AI',
    features: [
      'Video generation',
      'Video editing',
      'Motion graphics',
      'Animations'
    ]
  },
  'ai-assistant': {
    name: 'AI Assistant',
    icon: Bot,
    description: 'Your personal AI assistant',
    features: [
      'Task automation',
      'Research assistance',
      'Data analysis',
      'Report generation'
    ]
  }
};

interface ToolPageProps {
  tool: string;
}

export default function ToolPage({ tool }: ToolPageProps) {
  const config = toolConfigs[tool as keyof typeof toolConfigs];
  
  if (!config) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Tool not found
          </h1>
          <Button variant="primary" onClick={() => window.history.back()}>
            Go Back
          </Button>
        </div>
      </div>
    );
  }

  const Icon = config.icon;

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-24">
      {tool === 'image-generator' ? (
        <ImageGenerator />
      ) : (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* ... rest of the existing tool page code ... */}
        </div>
      )}
    </div>
  );
}