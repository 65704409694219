import React from 'react';
import { Brain, Users, DollarSign, Rocket, Shield, Building2, Globe } from 'lucide-react';

const leadershipTeam = [
  {
    name: 'Landon B. Reid',
    role: 'Founder & CEO',
    image: 'https://media.licdn.com/dms/image/v2/D5603AQEp_2hLmnOGCQ/profile-displayphoto-shrink_400_400/B56ZQOnP.rGQAg-/0/1735411959343?e=1741219200&v=beta&t=xp-vkY3PrCEp6IZvAW3y-fxfUxXnmetDIrifb43xg98',
    bio: 'Visionary AI pioneer and ethical technology advocate leading GALAX AI\'s mission to democratize artificial intelligence. A recognized thought leader in responsible AI development, Landon combines cutting-edge innovation with a steadfast commitment to ethical AI principles. His unique approach to technical co-founding has helped launch numerous successful AI ventures while maintaining the highest standards of technological excellence and social responsibility.',
    expertise: [
      'Ethical AI Leadership',
      'Technical Co-Founding',
      'AI Innovation Strategy',
      'Venture Architecture',
      'Responsible AI Development'
    ]
  },
  {
    name: 'Vishal S.',
    role: 'Chief Architect of Full-Stack AI Solutions',
    image: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?w=400&h=400&fit=crop',
    bio: 'Distinguished full-stack architect and AI systems expert with a proven track record of designing and implementing transformative AI solutions at scale. Vishal leads the technical architecture of GALAX AI\'s enterprise platforms, combining deep expertise in distributed systems with cutting-edge AI technologies to create robust, scalable solutions that drive business value.',
    expertise: [
      'Enterprise AI Architecture',
      'Distributed Systems',
      'MLOps Infrastructure',
      'Cloud-Native Solutions',
      'Technical Innovation'
    ]
  },
  {
    name: 'Jordan Steele',
    role: 'Head of Business Development & Strategic Partnerships',
    image: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=400&h=400&fit=crop',
    bio: 'Dynamic business development leader with an exceptional track record of forging transformative partnerships in the AI industry. With over a decade of experience, Jordan has orchestrated strategic alliances with Fortune 500 companies and pioneering AI startups, driving over $50M in partnership revenue. Known for an innovative approach to ecosystem building and a deep understanding of AI market dynamics.',
    expertise: [
      'Strategic AI Partnerships',
      'Enterprise Sales',
      'Market Expansion',
      'Revenue Growth',
      'Alliance Management'
    ]
  },
  {
    name: 'Bryan Farris',
    role: 'General Counsel',
    image: 'https://media.licdn.com/dms/image/v2/D5622AQFT9PXbm_eO9g/feedshare-shrink_2048_1536/feedshare-shrink_2048_1536/0/1727320795605?e=1738800000&v=beta&t=jyB32SdkrzstKZOu7uDeRb_zFRmJjSbz0cu44JfZz98',
    bio: 'Distinguished legal strategist with extensive experience in AI technology law, intellectual property protection, and regulatory compliance. Specializes in navigating complex AI governance frameworks and ensuring ethical AI development while protecting innovation.',
    expertise: [
      'AI Law & Ethics',
      'IP Portfolio Strategy',
      'Regulatory Compliance',
      'Contract Negotiation',
      'Tech Licensing'
    ]
  },
  {
    name: 'Amelia Vaughn',
    role: 'Fractional AI Ethics and Compliance Officer',
    image: 'https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?w=400&h=400&fit=crop',
    bio: 'Distinguished expert in AI governance and ethical technology development with over a decade of experience implementing responsible AI practices. Leads Galax AI\'s commitment to trustworthy AI innovation while ensuring regulatory compliance and ethical standards across all ventures.',
    expertise: [
      'AI Ethics & Governance',
      'Regulatory Compliance',
      'Risk Assessment',
      'Policy Development',
      'Stakeholder Engagement'
    ]
  },
  {
    name: 'Sophia Hayes',
    role: 'Fractional Head of Deployment and Support',
    image: 'https://images.unsplash.com/photo-1580489944761-15a19d654956?w=400&h=400&fit=crop',
    bio: 'Seasoned deployment strategist overseeing the seamless integration of AI solutions into client environments. With extensive experience in AI deployment and client support, Sophia ensures every system operates at peak efficiency while maintaining the highest standards of performance and client satisfaction.',
    expertise: [
      'AI Deployment Strategy',
      'Client Success',
      'System Integration',
      'Performance Optimization',
      'Technical Support'
    ]
  },
  {
    name: 'Dr. Sarah Chen',
    role: 'Fractional CTO',
    image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=800&auto=format&fit=crop&q=80',
    bio: 'AI/ML expert with 15+ years experience building enterprise-scale AI systems and leading technical teams.',
    expertise: ['AI Architecture', 'MLOps', 'Enterprise Systems']
  },
  {
    name: 'Michael Rodriguez',
    role: 'Fractional CMO',
    image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=800&auto=format&fit=crop&q=80',
    bio: 'Growth marketing veteran specializing in AI/ML product launches and go-to-market strategy.',
    expertise: ['AI Marketing', 'Product Strategy', 'Growth']
  },
  {
    name: 'Emily Thompson',
    role: 'Fractional COO',
    image: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=800&auto=format&fit=crop&q=80',
    bio: 'Operations expert focused on scaling AI ventures and optimizing business processes.',
    expertise: ['Operations', 'Process Optimization', 'Team Building']
  },
  {
    name: 'Col. David Park (Ret.)',
    role: 'Government AI Lead',
    image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=800&auto=format&fit=crop&q=80',
    bio: 'Former DoD AI strategy leader, specializing in government AI transformation and FedRAMP compliance.',
    expertise: ['Government AI', 'FedRAMP', 'Security']
  },
  {
    name: 'Dr. Maria Garcia',
    role: 'AI Research Director',
    image: 'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?w=800&auto=format&fit=crop&q=80',
    bio: 'Leading our AI research initiatives and venture development programs.',
    expertise: ['AI Research', 'LLMs', 'Neural Networks']
  },
  {
    name: 'Robert Chen',
    role: 'Venture Building Lead',
    image: 'https://images.unsplash.com/photo-1519345182560-3f2917c472ef?w=800&auto=format&fit=crop&q=80',
    bio: 'Serial entrepreneur focused on helping founders build successful AI ventures.',
    expertise: ['Venture Building', 'AI Strategy', 'Startup Growth']
  },
  {
    name: 'Dr. Alex Thompson',
    role: 'AI Government Team Lead',
    image: 'https://images.unsplash.com/photo-1560250097-0b93528c311a?w=800&auto=format&fit=crop&q=80',
    bio: 'Leading our government AI initiatives with expertise in FedRAMP and IL5 compliance.',
    expertise: ['Government AI', 'FedRAMP', 'Security Compliance']
  },
  {
    name: 'Jennifer Lee',
    role: 'Venture Strategy Lead',
    image: 'https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?w=800&auto=format&fit=crop&q=80',
    bio: 'Expert in AI venture strategy and scaling, helping founders navigate from seed to Series A.',
    expertise: ['Venture Strategy', 'AI Scaling', 'Growth']
  },
  {
    name: 'Dr. Marcus Wright',
    role: 'AI Research Lead',
    image: 'https://images.unsplash.com/photo-1578496781197-b85385c7f0b3?w=800&auto=format&fit=crop&q=80',
    bio: 'Leading advanced AI research initiatives and custom model development for enterprise clients.',
    expertise: ['AI Research', 'Model Development', 'MLOps']
  },
  {
    name: 'Sarah Kim',
    role: 'Technical Program Director',
    image: 'https://images.unsplash.com/photo-1580489944761-15a19d654956?w=800&auto=format&fit=crop&q=80',
    bio: 'Orchestrating complex AI implementations and technical program management across ventures.',
    expertise: ['Program Management', 'AI Implementation', 'Technical Leadership']
  }
];

export default function LeadershipTeam() {
  return (
    <div className="py-24 bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            Our Leadership Team
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Elite technical and business leaders building the future of AI
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {leadershipTeam.map((member) => (
            <div
              key={member.name}
              className="group relative bg-white dark:bg-slate-900 rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-[1.02] border border-gray-200/50 dark:border-gray-800/50"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-violet-500/5 dark:from-blue-400/5 dark:to-violet-400/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
              
              <div className="relative">
                <div className="flex items-center space-x-4 mb-4">
                  <img
                    src={member.image}
                    alt={member.name}
                    className="w-16 h-16 rounded-full object-cover border-2 border-gray-200 dark:border-gray-700"
                  />
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                      {member.name}
                    </h3>
                    <p className="text-blue-600 dark:text-blue-400">
                      {member.role}
                    </p>
                  </div>
                </div>

                <p className="text-gray-600 dark:text-gray-400 mb-4">
                  {member.bio}
                </p>

                <div className="flex flex-wrap gap-2">
                  {member.expertise.map((skill) => (
                    <span
                      key={skill}
                      className="px-2 py-1 text-xs font-medium bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400 rounded-full"
                    >
                      {skill}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}