import React from 'react';
import { Brain, Zap, Shield, ArrowRight, CheckCircle2, Star, Users, Clock } from 'lucide-react';

const portfolioStats = [
  { value: '500+', label: 'Enterprise Clients' },
  { value: '15+', label: 'Ventures Launched' },
  { value: '98%', label: 'Success Rate' },
  { value: '24/7', label: 'Support' }
];

export default function AboutPortfolio() {
  return (
    <div className="py-24 bg-gradient-to-br from-gray-50 via-white to-gray-50 dark:from-slate-950 dark:via-slate-900 dark:to-slate-950 relative overflow-hidden">
      {/* Background decorations */}
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />
      <div className="absolute inset-y-0 right-0 w-1/2 bg-[radial-gradient(circle_at_70%_50%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <Brain className="w-6 h-6 text-blue-600 dark:text-blue-400" />
          </div>
          <h2 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-violet-600 to-purple-600 dark:from-blue-400 dark:via-violet-400 dark:to-purple-400 mb-4">
            Our Portfolio Companies
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            Building innovative AI solutions that transform industries
          </p>
        </div>

        {/* Portfolio Stats */}
        <div className="grid grid-cols-4 gap-6 mb-12">
          {portfolioStats.map(stat => (
            <div key={stat.label} className="text-center">
              <div className="text-2xl font-bold bg-gradient-to-r from-blue-600 to-violet-600 dark:from-blue-400 dark:to-violet-400 bg-clip-text text-transparent">
                {stat.value}
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400">
                {stat.label}
              </div>
            </div>
          ))}
        </div>

        {/* Portfolio Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Add portfolio company cards here */}
        </div>
      </div>
    </div>
  );
}