import React from 'react';
import { DollarSign, TrendingUp, Users } from 'lucide-react';

const affiliates = [
  {
    name: 'Jasper AI',
    revenue: '$12,458',
    clicks: '2,345',
    conversions: '125',
    growth: '+23%'
  },
  {
    name: 'Midjourney',
    revenue: '$8,932',
    clicks: '1,892',
    conversions: '98',
    growth: '+15%'
  },
  {
    name: 'ChatGPT Plus',
    revenue: '$6,745',
    clicks: '1,456',
    conversions: '76',
    growth: '+18%'
  }
];

export default function AffiliateTracking() {
  return (
    <div className="bg-white dark:bg-slate-900 rounded-xl border border-gray-200 dark:border-gray-800">
      <div className="p-6 border-b border-gray-200 dark:border-gray-800">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
            Top Affiliates
          </h2>
          <button className="text-sm text-blue-600 dark:text-blue-400 hover:underline">
            View All
          </button>
        </div>
      </div>
      <div className="divide-y divide-gray-200 dark:divide-gray-800">
        {affiliates.map((affiliate) => (
          <div key={affiliate.name} className="p-6 hover:bg-gray-50 dark:hover:bg-slate-800/50 transition-colors">
            <div className="flex items-center justify-between mb-4">
              <h3 className="font-medium text-gray-900 dark:text-white">
                {affiliate.name}
              </h3>
              <div className="flex items-center text-green-600 dark:text-green-400">
                <TrendingUp className="w-4 h-4 mr-1" />
                {affiliate.growth}
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4">
              <div>
                <div className="flex items-center text-gray-500 dark:text-gray-400 mb-1">
                  <DollarSign className="w-4 h-4 mr-1" />
                  Revenue
                </div>
                <div className="text-lg font-semibold text-gray-900 dark:text-white">
                  {affiliate.revenue}
                </div>
              </div>
              <div>
                <div className="flex items-center text-gray-500 dark:text-gray-400 mb-1">
                  <Users className="w-4 h-4 mr-1" />
                  Clicks
                </div>
                <div className="text-lg font-semibold text-gray-900 dark:text-white">
                  {affiliate.clicks}
                </div>
              </div>
              <div>
                <div className="text-gray-500 dark:text-gray-400 mb-1">
                  Conversions
                </div>
                <div className="text-lg font-semibold text-gray-900 dark:text-white">
                  {affiliate.conversions}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}