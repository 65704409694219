import React from 'react';
import { Users, DollarSign, ArrowUpRight, ArrowDownRight, Activity, Clock } from 'lucide-react';

const stats = [
  {
    name: 'Total Users',
    value: '24,589',
    change: '+12%',
    trend: 'up',
    icon: Users
  },
  {
    name: 'Monthly Revenue',
    value: '$89,458',
    change: '+23%',
    trend: 'up',
    icon: DollarSign
  },
  {
    name: 'Active Trials',
    value: '1,234',
    change: '-5%',
    trend: 'down',
    icon: Activity
  },
  {
    name: 'Avg. Session Time',
    value: '24m 32s',
    change: '+8%',
    trend: 'up',
    icon: Clock
  }
];

export default function AdminStats() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      {stats.map((stat) => {
        const Icon = stat.icon;
        return (
          <div
            key={stat.name}
            className="bg-white dark:bg-slate-900 rounded-xl p-6 border border-gray-200/50 dark:border-gray-800/50 hover:border-blue-500/30 dark:hover:border-blue-400/30 transition-all duration-300"
          >
            <div className="flex items-center justify-between mb-4">
              <div className="p-2 rounded-lg bg-blue-50 dark:bg-blue-900/20">
                <Icon className="w-6 h-6 text-blue-600 dark:text-blue-400" />
              </div>
              <div className={`flex items-center ${
                stat.trend === 'up' 
                  ? 'text-green-600 dark:text-green-400'
                  : 'text-red-600 dark:text-red-400'
              }`}>
                {stat.trend === 'up' ? (
                  <ArrowUpRight className="w-4 h-4 mr-1" />
                ) : (
                  <ArrowDownRight className="w-4 h-4 mr-1" />
                )}
                {stat.change}
              </div>
            </div>
            <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-1">
              {stat.value}
            </h3>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              {stat.name}
            </p>
          </div>
        );
      })}
    </div>
  );
}