import { useState, useEffect } from 'react';
import { ROUTES, type Route, isValidRoute } from '../utils/routes';

export function useRouter() {
  const [currentPath, setCurrentPath] = useState<Route>(() => {
    const path = window.location.pathname;
    return path && isValidRoute(path) ? path : ROUTES.HOME;
  });

  useEffect(() => {
    const handlePathChange = () => {
      const path = window.location.pathname;
      setCurrentPath(path && isValidRoute(path) ? path : ROUTES.HOME);
    };

    // Handle initial route
    handlePathChange();

    // Listen for both popstate and custom routechange events
    window.addEventListener('popstate', handlePathChange);
    window.addEventListener('routechange', handlePathChange);
    
    return () => {
      window.removeEventListener('popstate', handlePathChange);
      window.removeEventListener('routechange', handlePathChange);
    };
  }, []);

  const navigate = (path: Route) => {
    try {
      // Handle tools routes
      if (path === ROUTES.TOOLS || path.startsWith(`${ROUTES.TOOLS}#`)) {
        window.location.href = ROUTES.SIGNUP;
        return;
      }

      // Normalize path by removing trailing slash
      const normalizedPath = path.endsWith('/') ? path.slice(0, -1) : path;
      
      // Update browser history
      window.history.pushState({}, '', normalizedPath);
      
      // Update current path state
      setCurrentPath(normalizedPath);
      
      // Dispatch custom event to ensure app re-renders
      window.dispatchEvent(new CustomEvent('routechange', { detail: normalizedPath }));
      
      // Scroll to top
      window.scrollTo(0, 0);
    } catch (error) {
      console.error('Navigation error:', error);
    }
  };

  return {
    currentPath,
    navigate
  };
}