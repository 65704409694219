import React from 'react';
import { GovernmentErrorBoundary } from '../components/government/ErrorBoundary';
import ConsultationScheduler from '../components/government/ConsultationScheduler';

export default function GovernmentConsultation() {
  return (
    <GovernmentErrorBoundary section="consultation">
      <div className="min-h-screen bg-gray-50 dark:bg-slate-950 pt-24">
        <ConsultationScheduler />
      </div>
    </GovernmentErrorBoundary>
  );
}