export const recentSignups = [
  {
    name: 'Aisha K.',
    company: 'AI Innovations',
    location: 'Dubai, UAE',
    time: '2 days ago',
    avatar: 'https://images.unsplash.com/photo-1548142813-c348350df52b?w=150'
  },
  {
    name: 'Lukas M.',
    company: 'Quantum Computing',
    location: 'Berlin, Germany',
    time: '3 days ago',
    avatar: 'https://images.unsplash.com/photo-1563833717765-00462801314e?w=150'
  },
  {
    name: 'Zara H.',
    company: 'Neural Networks Ltd',
    location: 'Toronto, Canada',
    time: '4 days ago',
    avatar: 'https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?w=150'
  },
  {
    name: 'Diego R.',
    company: 'AI Solutions Lab',
    location: 'Madrid, Spain',
    time: '5 days ago',
    avatar: 'https://images.unsplash.com/photo-1545167622-3a6ac756afa4?w=150'
  },
  {
    name: 'Mei L.',
    company: 'Deep Learning Tech',
    location: 'Shanghai, China',
    time: '6 days ago',
    avatar: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?w=150'
  },
  {
    name: 'Viktor P.',
    company: 'Neural Tech',
    location: 'Stockholm',
    time: '7 mins ago',
    avatar: 'https://images.unsplash.com/photo-1568602471122-7832951cc4c5?w=150'
  },
  {
    name: 'Nina S.',
    company: 'Data Dynamics',
    location: 'Amsterdam',
    time: '11 mins ago',
    avatar: 'https://images.unsplash.com/photo-1544005313-94ddf0286df2?w=150'
  },
  {
    name: 'Raj M.',
    company: 'AI Solutions',
    location: 'Mumbai',
    time: '14 mins ago',
    avatar: 'https://images.unsplash.com/photo-1566492031773-4f4e44671857?w=150'
  },
  {
    name: 'Yuki T.',
    company: 'Tech Innovate',
    location: 'Tokyo',
    time: '17 mins ago',
    avatar: 'https://images.unsplash.com/photo-1554151228-14d9def656e4?w=150'
  },
  {
    name: 'Sarah L.',
    company: 'Growth AI',
    location: 'New York',
    time: '2 mins ago',
    avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=150'
  },
  {
    name: 'Michael R.',
    company: 'TechFlow',
    location: 'San Francisco',
    time: '5 mins ago',
    avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=150'
  },
  {
    name: 'Emma K.',
    company: 'AI Solutions',
    location: 'London',
    time: '8 mins ago',
    avatar: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=150'
  },
  {
    name: 'James W.',
    company: 'Neural Systems',
    location: 'Toronto',
    time: '12 mins ago',
    avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=150'
  },
  {
    name: 'Sophia P.',
    company: 'Data Dynamics',
    location: 'Singapore',
    time: '15 mins ago',
    avatar: 'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?w=150'
  },
  { name: 'Michael Chen', company: 'TechFlow AI', location: 'San Francisco', time: '2 mins ago', avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=150' },
  { name: 'Sarah Rodriguez', company: 'DataSphere', location: 'New York', time: '5 mins ago', avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=150' },
  { name: 'David Kim', company: 'AI Ventures', location: 'Boston', time: '8 mins ago', avatar: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?w=150' },
  { name: 'Emily Patel', company: 'InnovateAI', location: 'Seattle', time: '12 mins ago', avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=150' },
  { name: 'James Wilson', company: 'Neural Labs', location: 'Austin', time: '15 mins ago', avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=150' },
  { name: 'Alex Thompson', company: 'FinTech AI', location: 'Chicago', time: '18 mins ago', avatar: 'https://images.unsplash.com/photo-1519345182560-3f2917c472ef?w=150' },
  { name: 'Maria Garcia', company: 'AI Health', location: 'Miami', time: '20 mins ago', avatar: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=150' },
  { name: 'Robert Chen', company: 'TechGrowth', location: 'Los Angeles', time: '25 mins ago', avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=150' },
  { name: 'Jennifer Lee', company: 'AI Education', location: 'Toronto', time: '30 mins ago', avatar: 'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?w=150' },
  { name: 'Thomas Wright', company: 'Quantum AI', location: 'London', time: '35 mins ago', avatar: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?w=150' },
  { name: 'Sophia Patel', company: 'AI Research Lab', location: 'Singapore', time: '38 mins ago', avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=150' },
  { name: 'Marcus Johnson', company: 'Neural Systems', location: 'Berlin', time: '42 mins ago', avatar: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?w=150' },
  { name: 'Isabella Santos', company: 'AI Analytics', location: 'São Paulo', time: '45 mins ago', avatar: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=150' },
  { name: 'Liam O\'Connor', company: 'AI Security', location: 'Dublin', time: '48 mins ago', avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=150' }
];

export const testimonials = [
  {
    name: 'Dr. Lisa Chen',
    role: 'Chief AI Officer',
    company: 'HealthTech Solutions',
    avatar: 'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?w=150',
    quote: 'GALAX AI has transformed our healthcare workflows. The AI tools have helped us analyze patient data 10x faster.',
    metrics: { timeSaved: '45+ hours/month', roi: '312% ROI' }
  },
  {
    name: 'Marcus Thompson', 
    role: 'VP of Innovation',
    company: 'Global Finance Corp', 
    avatar: 'https://images.unsplash.com/photo-1519345182560-3f2917c472ef?w=150',
    quote: 'The enterprise AI suite has revolutionized our financial analysis. We\'ve automated 75% of our reporting tasks.',
    metrics: { timeSaved: '60+ hours/month', roi: '425% ROI' }
  },
  {
    name: 'Sophia Martinez', 
    role: 'Head of Product', 
    company: 'TechStart Inc', 
    avatar: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=150',
    quote: 'As a startup, GALAX AI\'s tools helped us move fast and scale. We launched our MVP 3x faster than planned.',
    metrics: { timeSaved: '50+ hours/month', roi: '280% ROI' }
  },
  {
    name: 'Daniel Park',
    role: 'CTO',
    company: 'EduTech Solutions',
    avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=150',
    quote: 'GALAX AI transformed our online learning platform. Student engagement increased by 200% and course completion rates doubled.',
    metrics: { timeSaved: '55+ hours/month', roi: '375% ROI' }
  },
  {
    name: 'Rachel Anderson',
    role: 'Director of AI',
    company: 'RetailTech Corp',
    avatar: 'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?w=150',
    quote: 'The AI-powered inventory management and demand forecasting tools have revolutionized our retail operations.',
    metrics: { timeSaved: '65+ hours/month', roi: '450% ROI' }
  },
  {
    name: 'Michael Zhang',
    role: 'Head of Engineering',
    company: 'CloudScale AI',
    avatar: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?w=150',
    quote: 'GALAX AI\'s enterprise solutions helped us scale our infrastructure seamlessly. Deployment time reduced by 80%.',
    metrics: { timeSaved: '70+ hours/month', roi: '385% ROI' }
  },
  {
    name: 'Emma Wilson',
    role: 'AI Research Lead',
    company: 'BioTech Innovations',
    avatar: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=150',
    quote: 'The AI research tools accelerated our drug discovery process significantly. We\'ve reduced research cycles by 60%.',
    metrics: { timeSaved: '80+ hours/month', roi: '520% ROI' }
  }
];

export const companyLogos = [
  { name: 'Microsoft', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/200px-Microsoft_logo.svg.png' },
  { name: 'IBM', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/IBM_logo.svg/200px-IBM_logo.svg.png' },
  { name: 'Deloitte', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Deloitte.svg/200px-Deloitte.svg.png' },
  { name: 'Accenture', logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cd/Accenture.svg/200px-Accenture.svg.png' }
];

export const metrics = {
  activeUsers: Math.floor(Math.random() * 1000) + 49000, // 49,000 - 50,000
  recentSignups: Math.floor(Math.random() * 20) + 40, // 40-60
  averageRating: 4.9,
  reviewCount: Math.floor(Math.random() * 500) + 2500, // 2,500 - 3,000
  successRate: 99,
  uptime: 99.99
};