import React from 'react';
import { Cpu, Brain, Shield, ArrowRight } from 'lucide-react';
import Button from '../ui/Button';

const projects = [
  {
    title: 'Mission Planning AI',
    icon: Cpu,
    description: 'Advanced AI-powered mission planning and optimization system for defense operations.',
    status: 'Operational',
    progress: 85,
    metrics: {
      efficiency: '+65%',
      accuracy: '98.5%',
      timeframe: '24 months'
    },
    clearance: 'TS/SCI',
    classification: 'IL6'
  },
  {
    title: 'Intelligence Analytics',
    icon: Brain,
    description: 'AI-powered intelligence analysis and threat detection platform for IC agencies.',
    status: 'Active',
    progress: 75,
    metrics: {
      processing: '10x faster',
      detection: '+60%',
      automation: '85%'
    },
    clearance: 'TS/SCI',
    classification: 'IL6'
  },
  {
    title: 'Zero Trust Security',
    icon: Shield,
    description: 'AI-enhanced Zero Trust security implementation for critical infrastructure protection.',
    status: 'Active',
    progress: 90,
    metrics: {
      coverage: '100%',
      incidents: '-85%',
      compliance: 'NIST 800-207'
    },
    clearance: 'Secret',
    classification: 'IL5'
  }
];

export default function ProjectsShowcase() {
  return (
    <div className="py-16 bg-gradient-to-br from-gray-50 via-white to-gray-50 dark:from-slate-950 dark:via-slate-900 dark:to-slate-950 relative overflow-hidden">
      {/* Decorative background elements */}
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_0%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[800px] h-[800px] bg-gradient-conic from-blue-500/5 via-violet-500/5 to-blue-500/5 rounded-full animate-spin-slow" />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            Featured Projects
          </h2>
          <p className="text-gray-600 dark:text-gray-300">
            Discover how AI is transforming government services
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {projects.map((project) => {
            const Icon = project.icon;
            return (
              <div key={project.title} className="group bg-white/80 dark:bg-slate-900/80 backdrop-blur-sm rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-[1.02] border border-gray-200/50 dark:border-gray-700/50">
                <div className="flex items-center mb-4">
                  <div className="p-3 rounded-lg bg-blue-50 dark:bg-blue-900/20">
                    <Icon className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                  </div>
                  <div className="ml-4">
                    <h3 className="font-semibold text-gray-900 dark:text-white">
                      {project.title}
                    </h3>
                    <span className={`text-sm ${
                      project.status === 'Active' 
                        ? 'text-green-600 dark:text-green-400'
                        : 'text-yellow-600 dark:text-yellow-400'
                    }`}>
                      {project.status}
                    </span>
                  </div>
                </div>

                <p className="text-gray-600 dark:text-gray-400 mb-4">
                  {project.description}
                </p>

                <div className="mb-4">
                  <div className="flex justify-between text-sm mb-1">
                    <span className="text-gray-600 dark:text-gray-400">Progress</span>
                    <span className="text-gray-900 dark:text-white font-medium">
                      {project.progress}%
                    </span>
                  </div>
                  <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2">
                    <div
                      className="bg-blue-600 dark:bg-blue-400 rounded-full h-2 transition-all"
                      style={{ width: `${project.progress}%` }}
                    />
                  </div>
                </div>

                <Button variant="outline" size="sm" icon={ArrowRight}>
                  Learn More
                </Button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}