import React, { useState, useEffect, useRef } from 'react';
import { Menu, X, ChevronRight, ArrowLeft } from 'lucide-react';
import { useNavigate } from '../../hooks/useNavigate';
import LoginButton from './LoginButton';
import type { LucideIcon } from 'lucide-react';

interface MobileMenuProps {
  isOpen: boolean;
  onToggle: () => void;
  isScrolled: boolean;
  links: Array<{
    href: string;
    label: string;
    icon: LucideIcon;
    submenu?: Array<{
      label: string;
      description?: string;
      icon: LucideIcon;
      href: string;
      badge?: string;
    }>;
  }>;
}

export default function MobileMenu({ isOpen, onToggle, isScrolled, links }: MobileMenuProps) {
  const navigate = useNavigate();
  const [activeSubmenu, setActiveSubmenu] = useState<string | null>(null);
  const [menuDepth, setMenuDepth] = useState(0);
  const [currentSubmenu, setCurrentSubmenu] = useState<typeof links[0]['submenu']>([]);
  const menuRef = useRef<HTMLDivElement>(null);
  const [isAnimating, setIsAnimating] = useState(false);

  // Handle body scroll lock
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  // Handle escape key
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onToggle();
        setActiveSubmenu(null);
      }
    };
    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [onToggle]);

  // Handle route changes
  useEffect(() => {
    const handleRouteChange = () => {
      onToggle();
      setActiveSubmenu(null);
      setCurrentSubmenu([]);
      setMenuDepth(0);
    };
    window.addEventListener('routechange', handleRouteChange);
    return () => window.removeEventListener('routechange', handleRouteChange);
  }, [onToggle]);

  const handleNavigation = (href: string) => {
    try {
      // Skip extension-related navigation
      if (!href || href.includes('extension') || href.includes('chrome-extension')) {
        console.debug('Skipping extension navigation');
        return;
      }

      setIsAnimating(true);
      setTimeout(() => {
        navigate(href);
        onToggle();
        setActiveSubmenu(null);
        setIsAnimating(false);
      }, 300);
    } catch (error) {
      console.debug('Navigation error:', error);
      window.location.href = href;
    }
  };

  const handleSubmenuClick = (label: string) => {
    setIsAnimating(true);
    const link = links.find(l => l.label === label);
    
    // Skip if link not found
    if (!link) {
      setIsAnimating(false);
      return;
    }

    if (activeSubmenu === label) {
      setActiveSubmenu(null);
      setCurrentSubmenu([]);
      setMenuDepth(0);
    } else {
      setActiveSubmenu(label);
      setCurrentSubmenu(link?.submenu || []);
      setMenuDepth(1);
    }
    setTimeout(() => setIsAnimating(false), 300);
  };

  const handleBackClick = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setActiveSubmenu(null);
      setCurrentSubmenu([]);
      setMenuDepth(0);
      setIsAnimating(false);
    }, 300);
  };

  return (
    <div className="md:hidden">
      {/* Menu Toggle Button */}
      <button
        onClick={onToggle}
        className={`fixed top-4 right-4 p-3 z-[1000] rounded-xl bg-white dark:bg-slate-900 shadow-lg border border-gray-200/50 dark:border-gray-800/50 transition-all duration-300 ${
          isScrolled ? 'shadow-lg' : ''
        } ${isOpen ? 'rotate-90' : 'rotate-0'}`}
        aria-label="Toggle menu"
        aria-expanded={isOpen}
      >
        {isOpen ? (
          <X className="w-6 h-6 text-gray-900 dark:text-white transition-transform duration-300" />
        ) : (
          <Menu className="w-6 h-6 text-gray-900 dark:text-white transition-transform duration-300" />
        )}
      </button>

      {/* Menu Overlay */}
      <div
        className={`fixed inset-0 bg-black/60 transition-opacity duration-300 z-[990] ${
          isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onClick={onToggle}
        aria-hidden="true"
      />

      {/* Menu Panel */}
      <div
        ref={menuRef}
        className={`fixed inset-y-0 right-0 w-[90%] max-w-md bg-white/95 dark:bg-slate-900/95 backdrop-blur-md shadow-2xl z-[995] transform transition-all duration-300 ease-out safe-top safe-bottom ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        {/* Menu Header */}
        <div className="sticky top-0 z-[996] px-6 py-4 bg-white/95 dark:bg-slate-900/95 backdrop-blur-md border-b border-gray-200/50 dark:border-gray-800/50">
          <LoginButton />
        </div>

        {/* Menu Content */}
        <div className="h-[calc(100vh-4rem)] overflow-y-auto overscroll-contain py-6 px-6 bg-white/95 dark:bg-slate-900/95 backdrop-blur-md">
          <div className="space-y-2">
            {links.map((link, index) => (
              <div key={link.label} className="relative">
                <button
                  onClick={() => link.submenu ? handleSubmenuClick(link.label) : handleNavigation(link.href)}
                  className={`w-full px-6 py-4 rounded-xl text-left transition-all duration-300 group ${
                    activeSubmenu === link.label
                      ? 'bg-gradient-to-r from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 shadow-lg scale-[1.02]'
                      : 'hover:bg-gray-50/80 dark:hover:bg-slate-800/80'
                  }`}
                  style={{
                    transform: `translateX(${isAnimating ? '20px' : '0'})`,
                    opacity: isAnimating ? 0 : 1,
                    transition: `all 0.3s cubic-bezier(0.4, 0, 0.2, 1) ${index * 50}ms`
                  }}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="p-3 rounded-xl bg-gradient-to-br from-blue-100 to-violet-100 dark:from-blue-900/20 dark:to-violet-900/20 group-hover:scale-110 transition-transform duration-300">
                        <link.icon className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                      </div>
                      <div className="ml-3">
                        <div className="text-lg font-semibold text-gray-900 dark:text-white">
                          {link.label}
                        </div>
                      </div>
                    </div>
                    {link.submenu && (
                      <ChevronRight 
                        className={`w-5 h-5 text-gray-400 transition-all duration-300 group-hover:text-blue-600 dark:group-hover:text-blue-400 ${
                          activeSubmenu === link.label ? 'rotate-90' : ''
                        }`} 
                      />
                    )}
                  </div>
                </button>

                {/* Submenu */}
                {link.submenu && (
                  <div
                    className={`fixed inset-0 bg-white dark:bg-slate-900 z-[997] transition-all duration-300 ${
                      activeSubmenu === link.label ? 'translate-x-0' : 'translate-x-full'
                    }`}
                  >
                    <div className="sticky top-0 z-[998] px-6 py-4 bg-white dark:bg-slate-900 border-b border-gray-200 dark:border-gray-800">
                      <button
                        onClick={handleBackClick}
                        className="flex items-center text-gray-600 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400 transition-colors font-medium"
                      >
                        <ArrowLeft className="w-5 h-5 mr-2" />
                        Back
                      </button>
                    </div>
                    <div className="p-6 space-y-3 bg-white dark:bg-slate-900">
                      {link.submenu.map((item) => (
                        <button
                          key={item.href}
                          onClick={() => handleNavigation(item.href)}
                          className="w-full p-4 rounded-xl text-left bg-white dark:bg-slate-900 hover:bg-gray-50 dark:hover:bg-slate-800 transition-all duration-300 group border border-gray-100 dark:border-gray-800"
                        >
                          <div className="flex items-center">
                            <div className="p-3 rounded-xl bg-gradient-to-br from-blue-100 to-violet-100 dark:from-blue-900/20 dark:to-violet-900/20 group-hover:scale-110 transition-transform">
                              <item.icon className="w-5 h-5 text-blue-600 dark:text-blue-400" />
                            </div>
                            <div className="ml-3">
                              <div className="font-semibold text-gray-900 dark:text-white group-hover:text-blue-600 dark:group-hover:text-blue-400 transition-colors">
                                {item.label}
                              </div>
                              {item.description && (
                                <div className="text-sm text-gray-600 dark:text-gray-400 mt-1">
                                  {item.description}
                                </div>
                              )}
                            </div>
                            {item.badge && (
                              <span className="ml-auto px-3 py-1 text-xs font-medium bg-gradient-to-br from-blue-100 to-violet-100 dark:from-blue-900/20 dark:to-violet-900/20 text-blue-600 dark:text-blue-400 rounded-full">
                                {item.badge}
                              </span>
                            )}
                          </div>
                        </button>
                      ))}
                      <div className="h-safe-bottom" />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}