import React from 'react';
import { Award, TrendingUp, CheckCircle2, Building2, ArrowRight } from 'lucide-react';
import Button from '../ui/Button';

const achievements = [
  {
    metric: '$250M+',
    label: 'In Defense Contracts',
    description: 'Successfully delivered AI solutions across 100+ agencies'
  },
  {
    metric: '98%',
    label: 'Project Success Rate',
    description: 'Track record of mission-critical AI deployments'
  },
  {
    metric: '45+',
    label: 'Agency Partners',
    description: 'Including DoD, Intelligence Community, and Civilian Agencies'
  }
];

const testimonials = [
  {
    quote: "GALAX AI's solutions have transformed our intelligence analysis capabilities, reducing processing time by 75%.",
    author: "Col. James Mitchell",
    role: "Director of AI Innovation",
    organization: "Defense Innovation Unit"
  }
];

const certifications = [
  { name: 'FedRAMP Authorized', icon: Award },
  { name: 'NIST 800-53 Compliant', icon: CheckCircle2 },
  { name: 'GSA Contract Holder', icon: Building2 }
];

export default function ExpertiseSection() {
  return (
    <div className="py-24 bg-gradient-to-br from-gray-50 via-white to-gray-50 dark:from-slate-950 dark:via-slate-900 dark:to-slate-950 relative overflow-hidden">
      {/* Background decorations */}
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />
      <div className="absolute inset-y-0 right-0 w-1/2 bg-[radial-gradient(circle_at_70%_50%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section header */}
        <div className="text-center mb-16">
          <h2 className="text-3xl sm:text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-violet-600 to-purple-600 dark:from-blue-400 dark:via-violet-400 dark:to-purple-400">
            A Proven Partner for Government Innovation
          </h2>
          <p className="text-lg text-black dark:text-gray-300 max-w-3xl mx-auto">
            With a track record of successful collaborations, GALAX AI is the trusted choice for government agencies seeking innovative, secure, and impactful AI solutions.
          </p>
        </div>

        {/* Achievements grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {achievements.map((achievement) => (
            <div
              key={achievement.metric}
              className="relative group bg-white dark:bg-slate-900 rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-[1.02] border border-gray-200/50 dark:border-gray-700/50"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-violet-500/5 dark:from-blue-400/5 dark:to-violet-400/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
              <div className="relative">
                <div className="text-3xl font-bold text-blue-600 dark:text-blue-400 mb-2">
                  {achievement.metric}
                </div>
                <div className="font-semibold text-gray-900 dark:text-white mb-2">
                  {achievement.label}
                </div>
                <p className="text-black dark:text-gray-400 text-sm">
                  {achievement.description}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* Testimonial */}
        <div className="mb-16">
          {testimonials.map((testimonial) => (
            <div
              key={testimonial.author}
              className="relative bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-950/30 dark:to-violet-950/30 rounded-2xl p-8 md:p-12"
            >
              <div className="flex flex-col md:flex-row items-center md:space-x-8">
                <img
                  src={testimonial.image}
                  alt={testimonial.author}
                  className="w-24 h-24 rounded-full object-cover mb-6 md:mb-0"
                />
                <div>
                  <blockquote className="text-xl text-gray-900 dark:text-white mb-6">
                    "{testimonial.quote}"
                  </blockquote>
                  <div>
                    <div className="font-semibold text-gray-900 dark:text-white">
                      {testimonial.author}
                    </div>
                    <div className="text-gray-600 dark:text-gray-400">
                      {testimonial.role}, {testimonial.organization}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Certifications */}
        <div className="bg-white/80 dark:bg-slate-900/80 backdrop-blur-sm rounded-xl p-8 border border-gray-200/50 dark:border-gray-700/50">
          <div className="text-center mb-8">
            <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
              Certifications & Standards
            </h3>
            <p className="text-gray-600 dark:text-gray-400">
              Meeting and exceeding government security requirements
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-6 mb-8">
            {certifications.map(({ name, icon: Icon }) => (
              <div
                key={name}
                className="flex items-center p-4 bg-gray-50 dark:bg-slate-800 rounded-lg"
              >
                <div className="p-2 rounded-lg bg-blue-50 dark:bg-blue-900/20 mr-4">
                  <Icon className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                </div>
                <span className="font-medium text-gray-900 dark:text-white">
                  {name}
                </span>
              </div>
            ))}
          </div>

          <div className="text-center">
            <Button variant="primary" size="lg" icon={ArrowRight}>
              View All Certifications
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}