import React from 'react';
import AboutHero from '../components/about/AboutHero';
import AboutMission from '../components/about/AboutMission';
import AboutLocation from '../components/about/AboutLocation';
import AboutPortfolio from '../components/about/AboutPortfolio';
import LeadershipTeam from '../components/about/LeadershipTeam';

export default function About() {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-slate-950 pt-32">
      <AboutHero />
      <AboutMission />
      <AboutPortfolio />
      <LeadershipTeam />
      <AboutLocation />
    </div>
  );
}