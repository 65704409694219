import React, { useState, useEffect } from 'react';
import { Users, CheckCircle2, ArrowRight, Clock } from 'lucide-react';
import { recentSignups } from '../../data/socialProof';

export default function SocialProof() {
  const [currentSignup, setCurrentSignup] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [signupCount] = useState(() => Math.floor(Math.random() * 20) + 30); // Random number between 30-50

  // Show each notification for 10 seconds, then wait 30 seconds before showing next
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSignup(i => (i + 1) % recentSignups.length);
    }, 3600000); // Show new signup every hour
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(false);
    }, 10000); // Show for 10 seconds

    const showTimeout = setTimeout(() => {
      setIsVisible(true);
    }, 30000); // Wait 30 seconds between notifications

    return () => {
      clearTimeout(timeout);
      clearTimeout(showTimeout);
    };
  }, [currentSignup]);

  return (
    isVisible && (
    <div className="fixed bottom-4 left-4 max-w-sm bg-white/95 dark:bg-slate-900/95 backdrop-blur-sm rounded-xl shadow-xl border border-gray-200/50 dark:border-gray-800/50 p-4 animate-fade-in transform hover:scale-105 transition-all duration-300 hover:shadow-2xl">
      <button 
        onClick={() => setIsVisible(false)}
        className="absolute -top-2 -right-2 w-6 h-6 bg-gray-200 dark:bg-gray-700 rounded-full text-gray-600 dark:text-gray-400 flex items-center justify-center text-xs hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors shadow-md"
      >
        ×
      </button>
      <div className="flex items-center space-x-3">
        <div className="relative group">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-500/20 to-violet-500/20 rounded-full blur-md group-hover:blur-lg transition-all duration-300" />
          <img
            src={recentSignups[currentSignup].avatar}
            alt="User avatar"
            className="relative w-12 h-12 rounded-full border-2 border-white dark:border-slate-900 shadow-md transform group-hover:scale-105 transition-transform duration-300 object-cover"
          />
          <div className="absolute -bottom-1 -right-1 w-5 h-5 bg-green-500 rounded-full border-2 border-white dark:border-slate-900 flex items-center justify-center">
            <CheckCircle2 className="w-3 h-3 text-white" />
          </div>
        </div>
        <div className="flex-1 min-w-0">
          <p className="text-sm font-medium text-gray-800 dark:text-gray-200 truncate">
            <span className="font-medium text-gray-900 dark:text-white">
              {recentSignups[currentSignup].name} 
            </span>
          </p>
          <p className="text-xs text-gray-600 dark:text-gray-400 mt-0.5">
            <span className="font-medium text-gray-900 dark:text-white">
              {recentSignups[currentSignup].company}
            </span>
            {' '}in{' '}
            <span className="text-gray-500 dark:text-gray-400">
              {recentSignups[currentSignup].location}
            </span>
          </p>
          <p className="text-xs text-blue-600 dark:text-blue-400 mt-0.5 flex items-center">
            <Clock className="w-3 h-3 mr-1" />
            <span className="text-blue-600 dark:text-blue-400">
              {Math.floor(Math.random() * 6) + 1} days ago
            </span>
          </p>
        </div>
        <div className="flex-shrink-0">
          <div className={`text-xs font-medium px-2 py-1 rounded-full ${
            currentSignup % 2 === 0
              ? 'bg-green-100 dark:bg-green-900/30 text-green-600 dark:text-green-400'
              : 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400'
          }`}>
            {currentSignup % 2 === 0 ? 'Just Joined' : 'Started Trial'}
          </div>
        </div>
      </div>
    </div>
    )
  );
}