import React from 'react';
import { Brain, Target, Rocket, ArrowRight, CheckCircle2, Star, Users, Shield, Building2, Play } from 'lucide-react';
import Button from './ui/Button';

const ventureTypes = [
  {
    title: 'Internal Ventures',
    icon: Rocket,
    description: 'Building and scaling our own AI-first companies',
    metrics: {
      ventures: '15+',
      success: '98%',
      timeline: '6 months'
    }
  },
  {
    title: 'Enterprise AI',
    icon: Building2,
    description: 'Transforming large organizations with custom AI solutions',
    metrics: {
      clients: '500+',
      savings: '$2.1M',
      satisfaction: '99%'
    }
  },
  {
    title: 'Government Projects',
    icon: Shield,
    description: 'FedRAMP-authorized AI solutions for agencies',
    metrics: {
      agencies: '45+',
      compliance: 'IL5',
      clearance: 'TS/SCI'
    }
  }
];

export default function WhatWeDo() {
  return (
    <div className="py-24 bg-gradient-to-br from-gray-50 via-white to-gray-50 dark:from-[rgb(3,3,5)] dark:via-[rgb(8,8,15)] dark:to-[rgb(3,3,5)] relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(59,130,246,0.03),rgba(0,0,0,0))]" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_80%,rgba(139,92,246,0.03),rgba(0,0,0,0))]" />
        <div className="absolute inset-0 bg-[conic-gradient(from_0deg_at_50%_50%,rgba(139,92,246,0.03)_0deg,rgba(147,51,234,0.03)_120deg,rgba(168,85,247,0.03)_240deg)]" />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <Brain className="w-6 h-6 text-blue-600 dark:text-blue-400" />
          </div>
          <h2 className="text-4xl sm:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-violet-600 to-purple-600 dark:from-blue-400 dark:via-violet-400 dark:to-purple-400">
            Building Predictive AI Ventures That Redefine Industries and Change Lives
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            GALAX Studios builds ventures with predictive success, combining cutting-edge AI, visionary business models, and scalable engineering.
          </p>
        </div>

        {/* Venture Types Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {ventureTypes.map((type) => {
            const Icon = type.icon;
            return (
              <div
                key={type.title}
                className="group relative bg-white dark:bg-slate-900 rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-[1.02] border border-gray-200/50 dark:border-gray-800/50"
              >
                <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-violet-500/5 dark:from-blue-400/5 dark:to-violet-400/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
                
                <div className="relative">
                  <div className="w-12 h-12 rounded-xl bg-gradient-to-br from-blue-100 to-violet-100 dark:from-blue-900/20 dark:to-violet-900/20 p-3 mb-6">
                    <Icon className="w-full h-full text-blue-600 dark:text-blue-400" />
                  </div>

                  <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-4">
                    {type.title}
                  </h3>
                  
                  <p className="text-gray-600 dark:text-gray-400 mb-6">
                    {type.description}
                  </p>

                  <div className="grid grid-cols-3 gap-2">
                    {Object.entries(type.metrics).map(([key, value]) => (
                      <div key={key} className="text-center p-2 bg-blue-50 dark:bg-blue-900/20 rounded-lg">
                        <div className="text-sm font-semibold text-blue-600 dark:text-blue-400">
                          {value}
                        </div>
                        <div className="text-xs text-gray-600 dark:text-gray-400 capitalize">
                          {key}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {/* Video Preview */}
        <div className="max-w-4xl mx-auto">
          <div className="aspect-video bg-gradient-to-br from-gray-900 to-gray-800 rounded-xl overflow-hidden relative group cursor-pointer shadow-xl hover:shadow-2xl transition-all duration-300">
            <div className="absolute inset-0 flex items-center justify-center z-10">
              <div className="w-20 h-20 rounded-full bg-white/10 flex items-center justify-center group-hover:scale-110 transition-transform duration-300">
                <Play className="w-8 h-8 text-white fill-white ml-1" />
                <div className="absolute inset-0 bg-white/5 rounded-full animate-ping" />
              </div>
            </div>
            <img
              src="https://images.unsplash.com/photo-1551434678-e076c223a692?w=1600&h=900&fit=crop&q=80"
              alt="AI implementation guide"
              className="w-full h-full object-cover opacity-50 group-hover:opacity-40 transition-all duration-300 scale-105 group-hover:scale-110"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
            <div className="absolute bottom-6 left-6 right-6 text-white z-10">
              <h3 className="text-2xl font-bold mb-2">
                Watch: The GALAX AI Advantage
              </h3>
              <p className="text-white/80">
                See how we build successful AI ventures in record time
              </p>
            </div>
          </div>
          <div className="mt-4 text-center text-sm text-gray-600 dark:text-gray-400">
            Watch this 2-minute video to see our predictive success methodology in action
          </div>
        </div>
      </div>
    </div>
  );
}