import React, { useState } from 'react';
import { MoreVertical, Shield, Ban, Play, Trash, Edit } from 'lucide-react';
import Button from '../ui/Button';

interface UserActionsProps {
  userId: string;
  userStatus: string;
  userRole: string;
  onStatusChange: (status: string) => void;
  onRoleChange: (role: string) => void;
}

export default function UserActions({ 
  userId, 
  userStatus, 
  userRole,
  onStatusChange,
  onRoleChange 
}: UserActionsProps) {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="relative">
      <button
        onClick={() => setShowMenu(!showMenu)}
        className="p-1 hover:bg-gray-100 dark:hover:bg-slate-800 rounded"
      >
        <MoreVertical className="w-5 h-5 text-gray-400" />
      </button>

      {showMenu && (
        <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-slate-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 py-2 z-10">
          {/* Status Actions */}
          <div className="px-3 py-2 text-xs font-semibold text-gray-500 dark:text-gray-400">
            Status
          </div>
          <button
            onClick={() => {
              onStatusChange('active');
              setShowMenu(false);
            }}
            className="w-full px-4 py-2 text-sm text-left hover:bg-gray-100 dark:hover:bg-slate-700 flex items-center"
          >
            <Play className="w-4 h-4 mr-2 text-green-500" />
            Activate
          </button>
          <button
            onClick={() => {
              onStatusChange('suspended');
              setShowMenu(false);
            }}
            className="w-full px-4 py-2 text-sm text-left hover:bg-gray-100 dark:hover:bg-slate-700 flex items-center"
          >
            <Ban className="w-4 h-4 mr-2 text-red-500" />
            Suspend
          </button>

          {/* Role Actions */}
          <div className="border-t border-gray-200 dark:border-gray-700 mt-2 pt-2">
            <div className="px-3 py-2 text-xs font-semibold text-gray-500 dark:text-gray-400">
              Role
            </div>
            <button
              onClick={() => {
                onRoleChange('admin');
                setShowMenu(false);
              }}
              className="w-full px-4 py-2 text-sm text-left hover:bg-gray-100 dark:hover:bg-slate-700 flex items-center"
            >
              <Shield className="w-4 h-4 mr-2 text-blue-500" />
              Make Admin
            </button>
            <button
              onClick={() => {
                onRoleChange('user');
                setShowMenu(false);
              }}
              className="w-full px-4 py-2 text-sm text-left hover:bg-gray-100 dark:hover:bg-slate-700 flex items-center"
            >
              <Edit className="w-4 h-4 mr-2 text-gray-500" />
              Make User
            </button>
          </div>

          {/* Delete Action */}
          <div className="border-t border-gray-200 dark:border-gray-700 mt-2 pt-2">
            <button
              onClick={() => {
                // Handle delete
                setShowMenu(false);
              }}
              className="w-full px-4 py-2 text-sm text-left hover:bg-gray-100 dark:hover:bg-slate-700 flex items-center text-red-600 dark:text-red-400"
            >
              <Trash className="w-4 h-4 mr-2" />
              Delete User
            </button>
          </div>
        </div>
      )}
    </div>
  );
}