import React, { useState } from 'react';
import { Brain, Shield, Target, ArrowRight, CheckCircle2, DollarSign, Users, Star, Zap, Building2, Lock, Globe, Clock } from 'lucide-react';
import Button from '../components/ui/Button';
import ConsultationForm from '../components/enterprise/ConsultationForm';

const ACTIVE_USERS = Math.floor(Math.random() * 50) + 350;

const enterpriseFeatures = [
  {
    name: 'Custom AI Development',
    icon: Brain,
    description: 'Build enterprise-grade AI solutions tailored to your needs',
    features: [
      'Custom LLM development & fine-tuning',
      'Proprietary model training',
      'MLOps infrastructure setup',
      'Model monitoring & optimization'
    ],
    roi: {
      metric: '75%',
      label: 'Cost reduction'
    }
  },
  {
    name: 'Enterprise Security',
    icon: Shield,
    description: 'Bank-grade security and compliance features',
    features: [
      'SOC 2 Type II certified',
      'HIPAA & GDPR compliant',
      'End-to-end encryption',
      'Custom security controls'
    ],
    roi: {
      metric: '99.99%',
      label: 'Uptime SLA'
    }
  },
  {
    name: 'Global Scale',
    icon: Globe,
    description: 'Scale AI operations across your organization',
    features: [
      'Multi-region deployment',
      'Load balancing & failover',
      'Automatic scaling',
      'Edge computing support'
    ],
    roi: {
      metric: '10x',
      label: 'Faster deployment'
    }
  }
];

const caseStudies = [
  {
    company: 'Global Financial Corp',
    logo: 'https://images.unsplash.com/photo-1560179707-f14e90ef3623?w=300&h=300&fit=crop',
    result: 'Reduced operational costs by $2.5M annually through AI automation',
    metrics: {
      savings: '$2.5M/year',
      efficiency: '+300%',
      deployment: '3 months'
    }
  },
  {
    company: 'HealthTech Solutions',
    logo: 'https://images.unsplash.com/photo-1551434678-e076c223a692?w=300&h=300&fit=crop',
    result: 'Improved patient outcomes by 45% using AI-powered diagnostics',
    metrics: {
      outcomes: '+45%',
      accuracy: '99.9%',
      deployment: '2 months'
    }
  }
];

const urgencyTriggers = [
  { text: 'Limited Q2 2024 capacity remaining', icon: Clock },
  { text: 'Current pricing guaranteed until April 30th', icon: DollarSign },
  { text: '3 enterprise slots available', icon: Users }
];

export default function Enterprise() {
  const [showConsultationForm, setShowConsultationForm] = useState(false);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-32">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <Building2 className="w-6 h-6 text-blue-600 dark:text-blue-400" />
          </div>
          
          <h1 className="text-4xl sm:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-violet-600 to-purple-600 dark:from-blue-400 dark:via-violet-400 dark:to-purple-400">
            Enterprise AI Solutions
          </h1>
          
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto mb-8">
            Transform your business with enterprise-grade AI solutions. From custom development to full-scale deployment, we help large organizations harness the power of AI.
          </p>

          {/* Stats Grid */}
          <div className="grid grid-cols-4 gap-6 max-w-4xl mx-auto mb-8">
            {[
              { icon: Users, value: '500+', label: 'Enterprise Clients' },
              { icon: Star, value: '99.9%', label: 'Uptime SLA' },
              { icon: Lock, value: 'SOC 2', label: 'Certified' },
              { icon: Globe, value: '24/7', label: 'Support' }
            ].map(({ icon: Icon, value, label }) => (
              <div key={label} className="bg-white/80 dark:bg-slate-900/80 backdrop-blur-sm rounded-xl p-4 border border-gray-200/50 dark:border-gray-800/50">
                <Icon className="w-6 h-6 text-blue-600 dark:text-blue-400 mx-auto mb-2" />
                <div className="text-2xl font-bold text-gray-900 dark:text-white mb-1">{value}</div>
                <div className="text-sm text-gray-600 dark:text-gray-400">{label}</div>
              </div>
            ))}
          </div>

          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <Button
              variant="primary"
              size="lg"
              icon={ArrowRight}
              onClick={() => setShowConsultationForm(true)}
              className="group"
            >
              <span className="relative">
                Schedule Enterprise Demo
                <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/[0.1] to-transparent translate-x-[-200%] group-hover:translate-x-[200%] transition-transform duration-[400ms]" />
              </span>
            </Button>
            <Button
              variant="outline"
              size="lg"
              icon={ArrowRight}
              className="group"
            >
              View Case Studies
            </Button>
          </div>
        </div>

        {/* Enterprise Features */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {enterpriseFeatures.map((feature) => {
            const Icon = feature.icon;
            return (
              <div
                key={feature.name}
                className="group relative bg-white dark:bg-slate-900 rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-[1.02] border border-gray-200/50 dark:border-gray-800/50"
              >
                <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-violet-500/5 dark:from-blue-400/5 dark:to-violet-400/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
                
                <div className="relative">
                  <div className="w-12 h-12 rounded-xl bg-gradient-to-br from-blue-100 to-violet-100 dark:from-blue-900/20 dark:to-violet-900/20 p-3 mb-6">
                    <Icon className="w-full h-full text-blue-600 dark:text-blue-400" />
                  </div>

                  <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-4">
                    {feature.name}
                  </h3>
                  
                  <p className="text-gray-600 dark:text-gray-400 mb-6">
                    {feature.description}
                  </p>

                  <ul className="space-y-3 mb-6">
                    {feature.features.map((item) => (
                      <li key={item} className="flex items-center text-gray-600 dark:text-gray-300">
                        <CheckCircle2 className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                        {item}
                      </li>
                    ))}
                  </ul>

                  <div className="bg-blue-50 dark:bg-blue-900/20 rounded-lg p-4">
                    <div className="text-2xl font-bold text-blue-600 dark:text-blue-400">
                      {feature.roi.metric}
                    </div>
                    <div className="text-sm text-blue-600 dark:text-blue-400">
                      {feature.roi.label}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {/* Case Studies */}
        <div className="mb-16">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white text-center mb-8">
            Enterprise Success Stories
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            {caseStudies.map((study) => (
              <div
                key={study.company}
                className="bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-8"
              >
                <div className="flex items-center mb-6">
                  <img
                    src={study.logo}
                    alt={study.company}
                    className="w-16 h-16 rounded-xl object-cover mr-4"
                  />
                  <div>
                    <h3 className="font-bold text-gray-900 dark:text-white">
                      {study.company}
                    </h3>
                  </div>
                </div>
                
                <p className="text-lg text-gray-900 dark:text-white mb-6">
                  {study.result}
                </p>

                <div className="grid grid-cols-3 gap-4">
                  {Object.entries(study.metrics).map(([key, value]) => (
                    <div key={key} className="text-center">
                      <div className="text-xl font-bold text-blue-600 dark:text-blue-400">
                        {value}
                      </div>
                      <div className="text-sm text-gray-600 dark:text-gray-400 capitalize">
                        {key}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* CTA Section */}
        <div className="bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-12 text-center mb-16">
          <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Ready to Transform Your Business?
          </h3>
          <p className="text-gray-600 dark:text-gray-400 mb-8 max-w-2xl mx-auto">
            Join industry leaders already using our enterprise AI solutions. Schedule a consultation with our enterprise team today.
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <Button
              variant="primary"
              size="lg"
              icon={ArrowRight}
              onClick={() => setShowConsultationForm(true)}
              className="group"
            >
              Schedule Consultation
            </Button>
            <Button
              variant="outline"
              size="lg"
              icon={ArrowRight}
              onClick={() => setShowConsultationForm(true)}
            >
              Request Consultation
            </Button>
          </div>
          <div className="mt-6 flex justify-center space-x-8 text-sm text-gray-500">
            <div className="flex items-center">
              <Shield className="w-4 h-4 mr-1" />
              Enterprise SLA
            </div>
            <div className="flex items-center">
              <Users className="w-4 h-4 mr-1" />
              Dedicated Support
            </div>
            <div className="flex items-center">
              <Lock className="w-4 h-4 mr-1" />
              Custom Security
            </div>
          </div>
        </div>
      </div>

      {/* Consultation Form Modal */}
      <ConsultationForm
        isOpen={showConsultationForm}
        onClose={() => setShowConsultationForm(false)}
      />
    </div>
  );
}