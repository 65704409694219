import React from 'react';
import { Shield, Clock, ArrowRight, CheckCircle2, Star } from 'lucide-react';
import Button from '../ui/Button';

export default function PricingGuarantee() {
  return (
    <div className="py-16 bg-white dark:bg-slate-900">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-2xl p-8 md:p-12">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="text-left">
              <Shield className="w-12 h-12 text-blue-600 dark:text-blue-400 mb-6" />
              <h2 className="text-2xl md:text-3xl font-bold text-gray-900 dark:text-white mb-4">
                30-Day Money-Back Guarantee
              </h2>
              <p className="text-lg text-gray-600 dark:text-gray-300 mb-8">
                Try GALAX AI risk-free. If you're not completely satisfied within 30 days, we'll refund your payment. No questions asked.
              </p>
              
              <div className="space-y-4 mb-8">
                <div className="flex items-center">
                  <CheckCircle2 className="w-5 h-5 text-green-500 mr-3" />
                  <span className="text-gray-700 dark:text-gray-300">Full access to all features</span>
                </div>
                <div className="flex items-center">
                  <CheckCircle2 className="w-5 h-5 text-green-500 mr-3" />
                  <span className="text-gray-700 dark:text-gray-300">Priority support included</span>
                </div>
                <div className="flex items-center">
                  <CheckCircle2 className="w-5 h-5 text-green-500 mr-3" />
                  <span className="text-gray-700 dark:text-gray-300">No long-term commitment</span>
                </div>
              </div>

              <Button variant="primary" size="lg" icon={ArrowRight}>
                Start Free Trial
              </Button>
            </div>

            <div className="bg-white dark:bg-slate-800 rounded-xl p-6 shadow-lg">
              <h3 className="font-semibold text-gray-900 dark:text-white mb-4">
                What Our Customers Say
              </h3>
              <div className="space-y-6">
                <div className="flex items-start space-x-4">
                  <img
                    src="https://images.unsplash.com/photo-1494790108377-be9c29b29330"
                    alt="Customer"
                    className="w-10 h-10 rounded-full"
                  />
                  <div>
                    <div className="flex items-center mb-1">
                      {[...Array(5)].map((_, i) => (
                        <Star key={i} className="w-4 h-4 text-yellow-400 fill-yellow-400" />
                      ))}
                    </div>
                    <p className="text-gray-600 dark:text-gray-400 text-sm">
                      "The money-back guarantee gave me confidence to try GALAX AI. Now I can't imagine running my business without it!"
                    </p>
                    <p className="text-sm font-medium text-gray-900 dark:text-white mt-1">
                      Sarah M. - Marketing Director
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p className="mt-4 text-sm text-gray-500 dark:text-gray-400">
            No credit card required • Cancel anytime
          </p>
        </div>
      </div>
    </div>
  );
}