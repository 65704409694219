import React, { useState } from 'react';
import { Palette, Layers, Maximize2, Sparkles } from 'lucide-react';

const styles = [
  'Photorealistic',
  'Digital Art',
  'Oil Painting',
  'Watercolor',
  'Sketch',
  '3D Render'
];

const aspectRatios = [
  { label: '1:1', value: 'square' },
  { label: '16:9', value: 'landscape' },
  { label: '9:16', value: 'portrait' },
  { label: '4:3', value: 'standard' }
];

export default function StyleControls() {
  const [selectedStyle, setSelectedStyle] = useState('Photorealistic');
  const [selectedRatio, setSelectedRatio] = useState('square');
  const [quality, setQuality] = useState(75);

  return (
    <div className="space-y-6">
      {/* Art Style */}
      <div>
        <div className="flex items-center text-sm font-medium text-gray-900 dark:text-white mb-3">
          <Palette className="w-4 h-4 mr-2" />
          Art Style
        </div>
        <div className="grid grid-cols-2 gap-2">
          {styles.map((style) => (
            <button
              key={style}
              onClick={() => setSelectedStyle(style)}
              className={`px-3 py-2 text-sm rounded-lg transition-colors ${
                selectedStyle === style
                  ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400'
                  : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800'
              }`}
            >
              {style}
            </button>
          ))}
        </div>
      </div>

      {/* Aspect Ratio */}
      <div>
        <div className="flex items-center text-sm font-medium text-gray-900 dark:text-white mb-3">
          <Maximize2 className="w-4 h-4 mr-2" />
          Aspect Ratio
        </div>
        <div className="grid grid-cols-4 gap-2">
          {aspectRatios.map((ratio) => (
            <button
              key={ratio.value}
              onClick={() => setSelectedRatio(ratio.value)}
              className={`px-3 py-2 text-sm rounded-lg transition-colors ${
                selectedRatio === ratio.value
                  ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400'
                  : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800'
              }`}
            >
              {ratio.label}
            </button>
          ))}
        </div>
      </div>

      {/* Quality */}
      <div>
        <div className="flex items-center text-sm font-medium text-gray-900 dark:text-white mb-3">
          <Sparkles className="w-4 h-4 mr-2" />
          Quality
        </div>
        <input
          type="range"
          min="1"
          max="100"
          value={quality}
          onChange={(e) => setQuality(Number(e.target.value))}
          className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-lg appearance-none cursor-pointer"
        />
        <div className="flex justify-between text-sm text-gray-600 dark:text-gray-400 mt-2">
          <span>Draft</span>
          <span className="font-medium">{quality}%</span>
          <span>HD</span>
        </div>
      </div>

      {/* Layers */}
      <div>
        <div className="flex items-center text-sm font-medium text-gray-900 dark:text-white mb-3">
          <Layers className="w-4 h-4 mr-2" />
          Advanced Settings
        </div>
        <div className="space-y-3">
          <label className="flex items-center">
            <input type="checkbox" className="rounded border-gray-300 dark:border-gray-700" />
            <span className="ml-2 text-sm text-gray-600 dark:text-gray-400">
              Enable upscaling
            </span>
          </label>
          <label className="flex items-center">
            <input type="checkbox" className="rounded border-gray-300 dark:border-gray-700" />
            <span className="ml-2 text-sm text-gray-600 dark:text-gray-400">
              Remove background
            </span>
          </label>
          <label className="flex items-center">
            <input type="checkbox" className="rounded border-gray-300 dark:border-gray-700" />
            <span className="ml-2 text-sm text-gray-600 dark:text-gray-400">
              Generate variations
            </span>
          </label>
        </div>
      </div>
    </div>
  );
}