import React, { useState } from 'react';
import { Mail, MapPin, Phone, Send, CheckCircle2, Shield, Star, Users, Clock } from 'lucide-react';
import Button from '../components/ui/Button';
import { validation } from '../utils/validation';
import { trackConversion } from '../utils/analytics';

const offices = [
  {
    name: 'Utah Headquarters',
    address: '10718 South Beckstead, Suite 200',
    city: 'South Jordan',
    state: 'Utah',
    zip: '84095',
    phone: '+1 (801) 555-0123',
    email: 'hello@galax.ai'
  },
  {
    name: 'Dallas Office',
    address: 'Trammell Crow Building, 7th Floor',
    city: 'Dallas',
    state: 'Texas',
    zip: '75201',
    phone: '+1 (214) 555-0123',
    email: 'hello@galax.ai'
  },
  {
    name: 'Puerto Rico Office',
    address: '1225 Avenida Juan Ponce de León',
    city: 'San Juan',
    state: 'Puerto Rico',
    zip: '00907',
    phone: '+1 (787) 555-0123',
    email: 'hello@galax.ai'
  }
];

const stats = [
  { icon: Users, value: '500+', label: 'Enterprise Clients' },
  { icon: Star, value: '4.9/5', label: 'Client Rating' },
  { icon: Clock, value: '24/7', label: 'Support' }
];

export default function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    message: '',
    priority: 'normal'
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Validate inputs
      if (!validation.required(formData.name)) {
        throw new Error('Please enter your name');
      }
      if (!validation.email(formData.email)) {
        throw new Error('Please enter a valid email address');
      }
      if (!validation.required(formData.message)) {
        throw new Error('Please enter your message');
      }

      // Track form submission
      trackConversion({
        type: 'form_submit',
        page: '/contact',
        data: { form: 'contact' }
      });

      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      setSuccess(true);
      setFormData({ name: '', email: '', company: '', message: '', priority: 'normal' });
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to send message');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-32">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="relative text-center mb-16">
          {/* Animated background elements */}
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />
          <div className="absolute inset-y-0 right-0 w-1/2 bg-[radial-gradient(circle_at_70%_50%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />
          
          <div className="relative">
            <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
              <Mail className="w-6 h-6 text-blue-600 dark:text-blue-400" />
            </div>
            
            <h1 className="text-4xl sm:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-violet-600 to-purple-600 dark:from-blue-400 dark:via-violet-400 dark:to-purple-400">
              Let's Build Something Amazing
            </h1>
            
            <p className="text-xl text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
              Whether you need technical support, custom solutions, or want to explore partnership opportunities, we're here to help.
            </p>

            {/* Stats Grid */}
            <div className="grid grid-cols-3 gap-6 max-w-2xl mx-auto mt-12">
              {stats.map(({ icon: Icon, value, label }) => (
                <div key={label} className="bg-white/80 dark:bg-slate-900/80 backdrop-blur-sm rounded-xl p-4 border border-gray-200/50 dark:border-gray-800/50">
                  <Icon className="w-6 h-6 text-blue-600 dark:text-blue-400 mx-auto mb-2" />
                  <div className="text-2xl font-bold text-gray-900 dark:text-white mb-1">{value}</div>
                  <div className="text-sm text-gray-600 dark:text-gray-400">{label}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="grid lg:grid-cols-2 gap-12">
          {/* Contact Form */}
          <div className="bg-white/90 dark:bg-slate-900/90 backdrop-blur-sm rounded-xl p-8 shadow-lg border border-gray-200/50 dark:border-gray-800/50">
            {success ? (
              <div className="text-center py-8">
                <div className="inline-flex items-center justify-center p-2 rounded-lg bg-green-100 dark:bg-green-900/30 mb-4">
                  <CheckCircle2 className="w-8 h-8 text-green-600 dark:text-green-400" />
                </div>
                <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">
                  Message Sent Successfully!
                </h3>
                <p className="text-gray-600 dark:text-gray-400">
                  We'll get back to you within 24 hours.
                </p>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-6">
                {error && (
                  <div className="p-4 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 rounded-lg text-sm">
                    {error}
                  </div>
                )}

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Full Name
                  </label>
                  <input
                    type="text"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Work Email
                  </label>
                  <input
                    type="email"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                    required
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Company (Optional)
                  </label>
                  <input
                    type="text"
                    value={formData.company}
                    onChange={(e) => setFormData({ ...formData, company: e.target.value })}
                    className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Priority Level
                  </label>
                  <div className="grid grid-cols-3 gap-4">
                    {['normal', 'high', 'urgent'].map((level) => (
                      <button
                        key={level}
                        type="button"
                        onClick={() => setFormData({ ...formData, priority: level })}
                        className={`p-4 rounded-xl text-center transition-all ${
                          formData.priority === level
                            ? 'bg-blue-50 dark:bg-blue-900/20 border-2 border-blue-500'
                            : 'bg-gray-50 dark:bg-slate-800 border-2 border-transparent'
                        }`}
                      >
                        <div className="capitalize font-medium text-gray-900 dark:text-white">
                          {level}
                        </div>
                      </button>
                    ))}
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Message
                  </label>
                  <textarea
                    value={formData.message}
                    onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                    rows={4}
                    className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                    required
                  />
                </div>

                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  icon={Send}
                  className="w-full"
                  loading={loading}
                >
                  Send Message
                </Button>
              </form>
            )}
          </div>

          {/* Contact Info */}
          <div className="space-y-8">
            {offices.map((office) => (
              <div
                key={office.name}
                className="group bg-white/90 dark:bg-slate-900/90 backdrop-blur-sm rounded-xl p-6 border border-gray-200/50 dark:border-gray-800/50 hover:shadow-lg transition-all duration-300"
              >
                <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-violet-500/5 dark:from-blue-400/5 dark:to-violet-400/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
                
                <div className="relative">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                    {office.name}
                  </h3>
                  <div className="space-y-4">
                    <div className="flex items-start">
                      <MapPin className="w-5 h-5 text-blue-600 dark:text-blue-400 mt-1 mr-3" />
                      <div>
                        <p className="text-gray-600 dark:text-gray-400">
                          {office.address}<br />
                          {office.city}, {office.state} {office.zip}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <Phone className="w-5 h-5 text-blue-600 dark:text-blue-400 mr-3" />
                      <a
                        href={`tel:${office.phone}`}
                        className="text-gray-600 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400"
                      >
                        {office.phone}
                      </a>
                    </div>
                    <div className="flex items-center">
                      <Mail className="w-5 h-5 text-blue-600 dark:text-blue-400 mr-3" />
                      <a
                        href={`mailto:${office.email}`}
                        className="text-gray-600 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400"
                      >
                        {office.email}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* Enterprise Support Card */}
            <div className="bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-6">
              <div className="flex items-center mb-4">
                <Shield className="w-6 h-6 text-blue-600 dark:text-blue-400 mr-2" />
                <h3 className="font-semibold text-gray-900 dark:text-white">
                  Enterprise Support
                </h3>
              </div>
              <p className="text-gray-600 dark:text-gray-400 mb-4">
                Get priority support with our enterprise plans. 24/7 availability and dedicated account management.
              </p>
              <Button variant="primary" size="lg" icon={Send}>
                Contact Enterprise Sales
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}