import React from 'react';
import { Sun, Moon } from 'lucide-react';
import NotificationBell from './NotificationBell';
import { type Theme } from '../../hooks/useTheme';

interface ActionsProps {
  theme: Theme;
  setTheme: (theme: Theme) => void;
  isScrolled: boolean;
}

export default function Actions({ theme, setTheme, isScrolled }: ActionsProps) {
  const moonClasses = "w-6 h-6 relative group-hover:rotate-[360deg] transition-all duration-[1500ms] ease-in-out";
  const moonGradient = "absolute inset-0 bg-gradient-conic from-blue-600 via-violet-600 to-blue-600 dark:from-blue-400 dark:via-violet-400 dark:to-blue-400 opacity-0 group-hover:opacity-100 transition-opacity duration-700 rounded-full blur-sm animate-spin-slow";

  return (
    <div className="flex items-center space-x-6">
      <button
        onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
        className="relative group p-3 rounded-lg hover:bg-gray-100/50 dark:hover:bg-slate-800/30 transition-all backdrop-blur-sm"
        aria-label="Toggle theme"
      >
        <div className="relative [transform-style:preserve-3d]">
          <div className={moonGradient} />
          {theme === 'dark' ? (
            <Moon className={`${moonClasses} text-blue-600/90 dark:text-blue-400/90`} />
          ) : (
            <Sun className={`${moonClasses} text-violet-600/90 dark:text-violet-400/90`} />
          )}
        </div>
      </button>
      <NotificationBell />
    </div>
  );
}