import React from 'react';
import { Code2, Brain, Zap, ArrowRight, CheckCircle2, Shield, Clock, Users, Star, Target, FileText, Bot } from 'lucide-react';
import Button from '../components/ui/Button';

const features = [
  {
    name: 'AI-Powered Code Generation',
    icon: Code2,
    description: 'Generate production-ready code with advanced AI models',
    benefits: [
      'Automated code generation',
      'Smart code completion',
      'Bug detection & fixes',
      'Code optimization suggestions'
    ],
    roi: {
      metric: '10x',
      label: 'Faster Development'
    }
  },
  {
    name: 'Intelligent Code Review',
    icon: Brain,
    description: 'AI-assisted code review and quality assurance',
    benefits: [
      'Automated code analysis',
      'Security vulnerability detection',
      'Performance optimization',
      'Best practice enforcement'
    ],
    roi: {
      metric: '95%',
      label: 'Issue Detection'
    }
  },
  {
    name: 'AI Documentation',
    icon: FileText,
    description: 'Automated documentation generation and maintenance',
    benefits: [
      'Auto-generated documentation',
      'API documentation',
      'Code comments generation',
      'Technical specification writing'
    ],
    roi: {
      metric: '75%',
      label: 'Time Saved'
    }
  }
];

const caseStudies = [
  {
    company: 'TechCorp Solutions',
    logo: 'https://images.unsplash.com/photo-1560179707-f14e90ef3623?w=300',
    result: 'Reduced development time by 60% using AI-powered code generation',
    metrics: {
      development: '-60%',
      quality: '+45%',
      deployment: '3x faster'
    }
  },
  {
    company: 'DataFlow Systems',
    logo: 'https://images.unsplash.com/photo-1551434678-e076c223a692?w=300',
    result: 'Improved code quality by 75% with AI-assisted reviews',
    metrics: {
      bugs: '-80%',
      efficiency: '+65%',
      coverage: '98%'
    }
  }
];

const aiTools = [
  {
    name: 'Code Assistant Pro',
    description: 'AI-powered code completion and generation',
    features: ['Smart completion', 'Refactoring suggestions', 'Code analysis'],
    icon: Bot
  },
  {
    name: 'DevOps AI',
    description: 'Automated CI/CD and deployment optimization',
    features: ['Pipeline automation', 'Performance monitoring', 'Issue detection'],
    icon: Target
  },
  {
    name: 'DocGen AI',
    description: 'Automated documentation generation',
    features: ['API docs', 'Code comments', 'Technical specs'],
    icon: FileText
  }
];

export default function AISoftwareDev() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <Code2 className="w-6 h-6 text-blue-600 dark:text-blue-400" />
          </div>
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            AI-Enabled Software Development
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto mb-8">
            Transform your development process with AI-powered tools and automation
          </p>

          {/* Stats Grid */}
          <div className="grid grid-cols-4 gap-6 max-w-4xl mx-auto">
            {[
              { icon: Zap, value: '10x', label: 'Faster Development' },
              { icon: Shield, value: '95%', label: 'Bug Detection' },
              { icon: Star, value: '75%', label: 'Cost Reduction' },
              { icon: Users, value: '500+', label: 'Dev Teams' }
            ].map(({ icon: Icon, value, label }) => (
              <div key={label} className="bg-white/80 dark:bg-slate-900/80 backdrop-blur-sm rounded-xl p-4 border border-gray-200/50 dark:border-gray-800/50">
                <Icon className="w-6 h-6 text-blue-600 dark:text-blue-400 mx-auto mb-2" />
                <div className="text-2xl font-bold text-gray-900 dark:text-white mb-1">{value}</div>
                <div className="text-sm text-gray-600 dark:text-gray-400">{label}</div>
              </div>
            ))}
          </div>
        </div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {features.map((feature) => {
            const Icon = feature.icon;
            return (
              <div
                key={feature.name}
                className="group relative bg-white dark:bg-slate-900 rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-[1.02] border border-gray-200/50 dark:border-gray-800/50"
              >
                <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-violet-500/5 dark:from-blue-400/5 dark:to-violet-400/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
                
                <div className="relative">
                  <div className="w-12 h-12 rounded-xl bg-gradient-to-br from-blue-100 to-violet-100 dark:from-blue-900/20 dark:to-violet-900/20 p-3 mb-6">
                    <Icon className="w-full h-full text-blue-600 dark:text-blue-400" />
                  </div>

                  <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-4">
                    {feature.name}
                  </h3>
                  
                  <p className="text-gray-600 dark:text-gray-400 mb-6">
                    {feature.description}
                  </p>

                  <ul className="space-y-3 mb-6">
                    {feature.benefits.map((benefit) => (
                      <li key={benefit} className="flex items-center text-gray-600 dark:text-gray-300">
                        <CheckCircle2 className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                        {benefit}
                      </li>
                    ))}
                  </ul>

                  <div className="bg-blue-50 dark:bg-blue-900/20 rounded-lg p-4">
                    <div className="text-2xl font-bold text-blue-600 dark:text-blue-400">
                      {feature.roi.metric}
                    </div>
                    <div className="text-sm text-blue-600 dark:text-blue-400">
                      {feature.roi.label}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {/* Case Studies */}
        <div className="mb-16">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white text-center mb-8">
            Success Stories
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            {caseStudies.map((study) => (
              <div
                key={study.company}
                className="bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-8"
              >
                <div className="flex items-center mb-6">
                  <img
                    src={study.logo}
                    alt={study.company}
                    className="w-16 h-16 rounded-xl object-cover mr-4"
                  />
                  <h3 className="font-bold text-gray-900 dark:text-white">
                    {study.company}
                  </h3>
                </div>
                
                <p className="text-lg text-gray-900 dark:text-white mb-6">
                  {study.result}
                </p>

                <div className="grid grid-cols-3 gap-4">
                  {Object.entries(study.metrics).map(([key, value]) => (
                    <div key={key} className="text-center">
                      <div className="text-xl font-bold text-blue-600 dark:text-blue-400">
                        {value}
                      </div>
                      <div className="text-sm text-gray-600 dark:text-gray-400 capitalize">
                        {key}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* AI Tools */}
        <div className="mb-16">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white text-center mb-8">
            AI Development Tools
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {aiTools.map((tool) => {
              const Icon = tool.icon;
              return (
                <div
                  key={tool.name}
                  className="bg-white dark:bg-slate-900 rounded-xl p-6 border border-gray-200/50 dark:border-gray-800/50"
                >
                  <div className="p-2 rounded-lg bg-blue-50 dark:bg-blue-900/20 w-fit mb-4">
                    <Icon className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                  </div>
                  <h3 className="font-semibold text-gray-900 dark:text-white mb-2">
                    {tool.name}
                  </h3>
                  <p className="text-gray-600 dark:text-gray-400 mb-4">
                    {tool.description}
                  </p>
                  <ul className="space-y-2">
                    {tool.features.map((feature) => (
                      <li key={feature} className="flex items-center text-sm text-gray-600 dark:text-gray-300">
                        <div className="w-1.5 h-1.5 rounded-full bg-blue-500 dark:bg-blue-400 mr-2" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center">
          <div className="bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-12">
            <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              Ready to Transform Your Development Process?
            </h3>
            <p className="text-gray-600 dark:text-gray-400 mb-8 max-w-2xl mx-auto">
              Join leading development teams already using our AI-powered tools to ship better code faster.
            </p>
            <Button
              variant="primary"
              size="lg"
              icon={ArrowRight}
              className="group"
            >
              Start Free Trial
            </Button>
            <div className="mt-4 flex items-center justify-center space-x-6 text-sm text-gray-500">
              <div className="flex items-center">
                <Shield className="w-4 h-4 text-green-500 mr-1" />
                7-day free trial
              </div>
              <div className="flex items-center">
                <Clock className="w-4 h-4 text-blue-500 mr-1" />
                2-minute setup
              </div>
              <div className="flex items-center">
                <Users className="w-4 h-4 text-violet-500 mr-1" />
                500+ dev teams
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}