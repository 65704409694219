import React, { useState, useEffect } from 'react';
import { CreditCard, Users, Shield, Building2, Brain, Target, Mail, Rocket, Heart, Clock, Settings, Calculator } from 'lucide-react';
import Logo from './header/Logo';
import Navigation from './header/Navigation';
import MobileMenu from './header/MobileMenu';
import Actions from './header/Actions';
import LoginButton from './header/LoginButton';
import { ROUTES } from '../utils/routes';
import { useTheme } from '../hooks/useTheme';

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { theme, setTheme } = useTheme();

  const links = [
    {
      href: '#',
      label: 'Solutions',
      icon: Target,
      description: 'Solutions for every audience',
      submenu: [
        {
          label: 'Start Your AI Venture',
          description: 'Launch your AI startup',
          icon: Rocket,
          href: ROUTES.START_AI_VENTURE,
          badge: 'New',
        },
        {
          label: 'Career Calculator',
          description: 'Compare career paths with AI',
          icon: Calculator,
          href: ROUTES.CAREER_CALCULATOR,
          badge: 'Popular'
        },
        {
          label: 'Investor Network',
          description: 'Connect with top investors',
          icon: Building2,
          href: ROUTES.INVESTOR_NETWORK,
          badge: 'Exclusive'
        },
        {
          label: 'Enterprise',
          description: 'Transform your business',
          icon: Building2,
          href: ROUTES.ENTERPRISE,
          badge: 'Enterprise'
        },
        {
          label: 'Government',
          description: 'FedRAMP solutions',
          icon: Shield,
          href: ROUTES.GOVERNMENT,
          badge: 'IL5'
        },
      ]
    },
    { 
      href: ROUTES.PRICING, 
      label: 'Pricing', 
      icon: CreditCard,
      description: 'Simple, transparent plans',
      badge: '60% Off'
    },
    { 
      href: ROUTES.ABOUT, 
      label: 'About', 
      icon: Users,
      description: 'Our story & mission',
      submenu: [
        { 
          label: 'Our Story',
          description: 'Our journey & vision',
          icon: Users,
          href: ROUTES.ABOUT
        },
        {
          label: 'Our Impact',
          description: 'How we give back',
          icon: Heart,
          href: ROUTES.IMPACT,
          badge: 'New'
        },
        { 
          label: 'Contact Us',
          description: 'Get enterprise support',
          icon: Mail,
          href: ROUTES.CONTACT
        }
      ]
    }
  ];
  
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  
  return (
    <header className={`fixed top-safe-top left-0 right-0 z-40 transition-all duration-300 w-full bg-white/90 dark:bg-slate-900/90 backdrop-blur-md ${
      isScrolled || isMobileMenuOpen ? 'bg-white/90 dark:bg-slate-900/90 backdrop-blur-md border-b border-gray-200/50 dark:border-gray-800/50 shadow-sm' : 'bg-white dark:bg-transparent'
    }`}>
      <div className="max-w-7xl mx-auto px-safe-left sm:px-6 lg:px-8 h-16 sm:h-20">
        <div className="flex items-center justify-between h-full">
          <Logo />
          <div className="hidden lg:flex items-center space-x-6 xl:space-x-8">
            <Navigation links={links} isScrolled={isScrolled} />
            <LoginButton />
          </div>
          <MobileMenu
            isOpen={isMobileMenuOpen}
            onToggle={toggleMobileMenu}
            isScrolled={isScrolled}
            links={links}
          />
          <div className="flex items-center space-x-4">
            <Actions
              theme={theme}
              setTheme={setTheme}
              isScrolled={isScrolled}
            />
            <div className="lg:hidden">
              <LoginButton />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}