import React from 'react';
import { Filter, Search, ArrowUpDown } from 'lucide-react';

interface FiltersProps {
  filters: {
    industry: string;
    size: string;
    useCase: string;
    priceRange: string;
    sortBy: string;
    searchQuery: string;
  };
  onFilterChange: (key: string, value: string) => void;
}

const filterOptions = {
  industry: [
    { value: '', label: 'All Industries' },
    { value: 'tech', label: 'Technology' },
    { value: 'finance', label: 'Finance' },
    { value: 'healthcare', label: 'Healthcare' },
    { value: 'ecommerce', label: 'E-commerce' },
    { value: 'education', label: 'Education' },
    { value: 'marketing', label: 'Marketing' },
    { value: 'manufacturing', label: 'Manufacturing & Industry' },
    { value: 'legal', label: 'Legal & Compliance' },
    { value: 'research', label: 'Research & Development' },
    { value: 'media', label: 'Media & Entertainment' },
    { value: 'nonprofit', label: 'Nonprofit & NGO' }
  ],
  expertise: [
    { value: '', label: 'All Skill Levels' },
    { value: 'beginner', label: 'Beginner-Friendly' },
    { value: 'intermediate', label: 'Intermediate' },
    { value: 'advanced', label: 'Advanced' }
  ],
  language: [
    { value: '', label: 'All Languages' },
    { value: 'english', label: 'English' },
    { value: 'spanish', label: 'Spanish' },
    { value: 'chinese', label: 'Chinese' },
    { value: 'french', label: 'French' },
    { value: 'german', label: 'German' }
  ],
  dataPrivacy: [
    { value: '', label: 'All Privacy Levels' },
    { value: 'gdpr', label: 'GDPR Compliant' },
    { value: 'hipaa', label: 'HIPAA Compliant' },
    { value: 'soc2', label: 'SOC 2 Certified' },
    { value: 'ccpa', label: 'CCPA Compliant' }
  ],
  aiModel: [
    { value: '', label: 'All AI Models' },
    { value: 'gpt4', label: 'GPT-4' },
    { value: 'claude3', label: 'Claude 3' },
    { value: 'gemini', label: 'Gemini Pro' },
    { value: 'mistral', label: 'Mistral' },
    { value: 'llama', label: 'Llama 2' }
  ],
  outputFormat: [
    { value: '', label: 'All Formats' },
    { value: 'text', label: 'Text' },
    { value: 'image', label: 'Images' },
    { value: 'video', label: 'Video' },
    { value: 'audio', label: 'Audio' },
    { value: 'code', label: 'Code' }
  ],
  size: [
    { value: '', label: 'All Sizes' },
    { value: 'startup', label: 'Startup (1-10)' },
    { value: 'small', label: 'Small (11-50)' },
    { value: 'medium', label: 'Medium (51-200)' },
    { value: 'large', label: 'Large (201-1000)' },
    { value: 'enterprise', label: 'Enterprise (1000+)' }
  ],
  useCase: [
    { value: '', label: 'All Use Cases' },
    { value: 'content', label: 'Content Creation' },
    { value: 'automation', label: 'Automation' },
    { value: 'analytics', label: 'Analytics' },
    { value: 'customer-service', label: 'Customer Service' },
    { value: 'development', label: 'Development' }
  ],
  priceRange: [
    { value: '', label: 'All Prices' },
    { value: 'free', label: 'Free' },
    { value: 'freemium', label: 'Freemium' },
    { value: 'low', label: '$1-50/mo' },
    { value: 'medium', label: '$51-200/mo' },
    { value: 'high', label: '$201+/mo' }
  ],
  sortBy: [
    { value: 'popular', label: 'Most Popular' },
    { value: 'rating', label: 'Highest Rated' },
    { value: 'newest', label: 'Newest First' },
    { value: 'price-low', label: 'Price: Low to High' },
    { value: 'price-high', label: 'Price: High to Low' }
  ]
};

export default function ToolsFilters({ filters, onFilterChange }: FiltersProps) {
  return (
    <div className="space-y-6">
      {/* Search Bar */}
      <div className="relative">
        <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
        <input
          type="search"
          placeholder="Search AI tools by name, feature, or use case..."
          value={filters.searchQuery}
          onChange={(e) => onFilterChange('searchQuery', e.target.value)}
          className="w-full pl-10 pr-4 py-3 rounded-xl border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800 focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Filter Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4">
        {/* Industry Filter */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Industry
          </label>
          <select
            value={filters.industry}
            onChange={(e) => onFilterChange('industry', e.target.value)}
            className="w-full px-3 py-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
          >
            {filterOptions.industry.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        {/* Company Size Filter */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Company Size
          </label>
          <select
            value={filters.size}
            onChange={(e) => onFilterChange('size', e.target.value)}
            className="w-full px-3 py-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
          >
            {filterOptions.size.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        {/* Use Case Filter */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Use Case
          </label>
          <select
            value={filters.useCase}
            onChange={(e) => onFilterChange('useCase', e.target.value)}
            className="w-full px-3 py-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
          >
            {filterOptions.useCase.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        {/* Price Range Filter */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Price Range
          </label>
          <select
            value={filters.priceRange}
            onChange={(e) => onFilterChange('priceRange', e.target.value)}
            className="w-full px-3 py-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
          >
            {filterOptions.priceRange.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        {/* Sort By */}
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Sort By
          </label>
          <select
            value={filters.sortBy}
            onChange={(e) => onFilterChange('sortBy', e.target.value)}
            className="w-full px-3 py-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
          >
            {filterOptions.sortBy.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}