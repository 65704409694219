import React, { useState, useEffect } from 'react';
import { Brain, Building2, Users, Shield, CreditCard, Rocket, Briefcase } from 'lucide-react';
import { useNavigate } from '../../hooks/useNavigate';
import type { LucideIcon } from 'lucide-react';
import { ROUTES } from '../../utils/routes';

interface NavLink {
  href: string;
  label: string;
  icon: LucideIcon;
  description?: string;
  badge?: string;
  submenu?: NavLink[];
}

import { trackConversion } from '../../utils/analytics';

interface NavigationProps {
  isScrolled: boolean;
  links: NavLink[];
}

export default function Navigation({ isScrolled, links }: NavigationProps) {
  const navigate = useNavigate();
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const [activeSection, setActiveSection] = useState<string | null>(null);

  // Add scroll listener to track active section
  useEffect(() => {
    const handleScroll = () => {
      const calculator = document.getElementById('career-calculator');
      if (calculator) {
        const rect = calculator.getBoundingClientRect();
        const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;
        setActiveSection(isVisible ? 'career-calculator' : null);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleMouseEnter = (label: string) => {
    setActiveDropdown(label);
  };

  const handleMouseLeave = () => {
    setActiveDropdown(null);
  };

  const handleClick = (href: string, hasSubmenu: boolean) => {
    try {
      if (!href) return;
      
      // Handle career calculator navigation
      if (href === '#career-calculator') {
        const element = document.getElementById('career-calculator');
        if (element) {
          // Add offset for header height
          const headerOffset = 100;
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
          
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
          return;
        }
      }
      
      // Handle career calculator navigation
      if (href === '#career-calculator') {
        const element = document.getElementById('career-calculator');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
          return;
        }
      }

      // Special handling for AI Tools directory
      if (href === ROUTES.AI_TOOLS.ROOT || href.startsWith(ROUTES.AI_TOOLS.ROOT)) {
        href = ROUTES.AI_TOOLS.DIRECTORY;
      }

      // Don't navigate if has submenu and not AI Tools
      if (hasSubmenu && href !== ROUTES.AI_TOOLS.ROOT) {
        return;
      }

      // Track navigation
      trackConversion({
        type: 'navigation',
        page: window.location.pathname as any,
        data: { destination: href }
      });

      navigate(href);
    } catch (error) {
      console.error('Navigation error:', error);
      navigate(ROUTES.HOME);
    }
  };

  return (
    <nav className="hidden md:flex items-center space-x-10">
      {links.map((link) => (
        <div
          key={link.label}
          className="relative"
          onMouseEnter={() => handleMouseEnter(link.label)}
          onMouseLeave={handleMouseLeave}
        >
          <button
            onClick={() => handleClick(link.href, !!link.submenu)}
            className={`group relative px-4 py-2.5 transition-all duration-300 text-base font-medium rounded-lg hover:scale-105 ${
              (isScrolled || (link.href === '#career-calculator' && activeSection === 'career-calculator'))
                ? 'text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400' 
                : 'text-gray-800 dark:text-white/90 hover:text-blue-600 dark:hover:text-blue-400'
            }`}
          >
            <div className="flex items-center space-x-2">
              <link.icon className="w-4 h-4 opacity-70 group-hover:opacity-100 transition-all duration-300 group-hover:scale-110 group-hover:rotate-6 group-hover:text-blue-600 dark:group-hover:text-blue-400" />
              <span className={`group-hover:text-blue-600 dark:group-hover:text-blue-400 ${
                link.href === '#career-calculator' && activeSection === 'career-calculator'
                  ? 'text-blue-600 dark:text-blue-400'
                  : ''
              }`}>{link.label}</span>
            </div>
            {/* Hover effect */}
            <div className={`absolute bottom-0 left-0 w-full h-0.5 bg-blue-600 dark:bg-blue-400 transform transition-transform duration-300 ${
              link.href === '#career-calculator' && activeSection === 'career-calculator'
                ? 'scale-x-100'
                : 'scale-x-0 group-hover:scale-x-100'
            }`} />
          </button>

          {link.submenu && activeDropdown === link.label && (
            <div className="absolute top-full left-0 pt-2">
              <div className="bg-white dark:bg-slate-900 rounded-xl shadow-lg border border-gray-200/50 dark:border-gray-800/50 p-4 min-w-[240px]">
                {link.submenu.map((item) => (
                  <button
                    key={item.label}
                    onClick={() => handleClick(item.href, false)}
                    className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-slate-800 rounded-lg group"
                  >
                    <item.icon className="w-5 h-5 mr-3 text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-400" />
                    <div className="flex-1 text-left">
                      <div className="font-medium">{item.label}</div>
                      {item.description && (
                        <div className="text-xs text-gray-500 dark:text-gray-400">
                          {item.description}
                        </div>
                      )}
                    </div>
                    {item.badge && (
                      <span className={`ml-2 px-2 py-1 text-xs rounded-full ${
                        item.badge === 'Popular'
                          ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400'
                          : item.badge === 'New'
                          ? 'bg-green-100 dark:bg-green-900/30 text-green-600 dark:text-green-400'
                          : 'bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-400'
                      }`}>
                        {item.badge}
                      </span>
                    )}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </nav>
  );
}