import React, { Component, ErrorInfo, ReactNode } from 'react';
import { AlertTriangle } from 'lucide-react';
import Button from './ui/Button';
import { handleError } from '../utils/errorHandler';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Skip extension errors and continue rendering
    if (this.isExtensionError(error, errorInfo)) {
      console.debug('Skipping extension error:', error);
      return;
    }

    // Use centralized error handler
    handleError(error);
  }

  private isExtensionError(error: Error, errorInfo: ErrorInfo): boolean {
    const errorText = [
      errorInfo?.componentStack || '',
      error?.message || '', 
      error?.stack || '', 
      error?.name || '', 
      'componentStack' in error ? String((error as any).componentStack) : '',
      'startsWith' in error ? String((error as any).startsWith) : '',
      'type' in error ? String((error as any).type) : '',
      'inpage' in error ? String((error as any).inpage) : '',
      errorInfo?.componentStack || '',
      error?.message || '', 
      error?.stack || '', 
      error?.name || '', 
      'componentStack' in error ? String((error as any).componentStack) : '',
      String(error),
      'type' in error ? String((error as any).type) : ''
    ].join(' ').toLowerCase();

    return errorText.includes('extension') || 
           errorText.includes('chrome-extension') ||
           errorText.includes('browser-extension') ||
           errorText.includes('cannot read properties of null') ||
           errorText.includes('cannot read property type of null') ||
           errorText.includes('cannot read property \'type\' of null') ||
           errorText.includes('startswith') ||
           errorText.includes('inpage.js') ||
           errorText.includes('web3') ||
           errorText.includes('metamask') ||
           errorText.includes('wallet') ||
           errorText.includes('undefined is not an object') ||
           errorText.includes('cannot read property') ||
           errorText.includes('null is not an object') ||
           errorText.includes('type of null') ||
           errorText.includes('type of undefined') ||
           errorText.includes('cannot read properties of null');
           errorText.includes('cannot read properties of null');
  }

  public render() {
    // Skip rendering error UI for extension errors
    if (this.state.hasError && this.state.error && 
        this.isExtensionError(this.state.error, {} as ErrorInfo)) {
      return this.props.children;
    }

    if (this.state.hasError) {
      return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-slate-950">
          <div className="text-center p-8">
            <AlertTriangle className="w-12 h-12 text-red-500 mx-auto mb-4" />
            <h1 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              Something went wrong
            </h1>
            <p className="text-gray-600 dark:text-gray-400 mb-6">
              {this.state.error?.message || 'An unexpected error occurred'}
            </p>
            <Button
              variant="primary"
              onClick={() => window.location.reload()}
            >
              Reload Page
            </Button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}