import React from 'react';
import { DollarSign, TrendingUp, Users, ArrowUpRight } from 'lucide-react';

const metrics = [
  {
    label: 'Monthly Recurring Revenue',
    value: '$89,458',
    change: '+23%',
    subMetrics: [
      { label: 'New Subscriptions', value: '$12,345' },
      { label: 'Renewals', value: '$77,113' }
    ]
  },
  {
    label: 'Average Revenue Per User',
    value: '$149',
    change: '+8%',
    subMetrics: [
      { label: 'Free Users', value: '$0' },
      { label: 'Paid Users', value: '$249' }
    ]
  }
];

export default function RevenueMetrics() {
  return (
    <div className="bg-white dark:bg-slate-900 rounded-xl border border-gray-200 dark:border-gray-800">
      <div className="p-6 border-b border-gray-200 dark:border-gray-800">
        <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
          Revenue Overview
        </h2>
      </div>
      <div className="p-6 space-y-6">
        {metrics.map((metric) => (
          <div key={metric.label}>
            <div className="flex items-center justify-between mb-4">
              <div>
                <h3 className="text-sm text-gray-500 dark:text-gray-400">
                  {metric.label}
                </h3>
                <div className="flex items-center mt-1">
                  <span className="text-2xl font-bold text-gray-900 dark:text-white mr-2">
                    {metric.value}
                  </span>
                  <span className="flex items-center text-green-600 dark:text-green-400 text-sm">
                    <ArrowUpRight className="w-4 h-4 mr-1" />
                    {metric.change}
                  </span>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              {metric.subMetrics.map((subMetric) => (
                <div
                  key={subMetric.label}
                  className="bg-gray-50 dark:bg-slate-800 rounded-lg p-4"
                >
                  <div className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                    {subMetric.label}
                  </div>
                  <div className="text-lg font-semibold text-gray-900 dark:text-white">
                    {subMetric.value}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}