import React from 'react';
import { Star, Quote } from 'lucide-react';

const testimonials = [
  {
    content: "GALAX AI paid for itself in the first week. We're saving 40+ hours per month on content creation and seeing better results.",
    author: "Sarah Chen",
    role: "Marketing Director",
    company: "TechCorp",
    image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330",
    stats: {
      timeSaved: "40+ hours/month",
      roi: "312% ROI"
    }
  },
  {
    content: "The AI tools suite has transformed our workflow. What used to take days now takes hours. The ROI is incredible.",
    author: "Michael Rodriguez",
    role: "Product Lead",
    company: "InnovateAI",
    image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e",
    stats: {
      timeSaved: "60+ hours/month",
      roi: "425% ROI"
    }
  },
  {
    content: "Best investment we've made this year. The AI tools have helped us scale our content production by 5x.",
    author: "Emily Thompson",
    role: "Content Director",
    company: "GrowthCo",
    image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80",
    stats: {
      timeSaved: "50+ hours/month",
      roi: "280% ROI"
    }
  }
];

export default function PricingTestimonials() {
  return (
    <div className="py-24 bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            Trusted by Industry Leaders
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            See how companies are transforming their business with GALAX AI
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {testimonials.map((testimonial) => (
            <div
              key={testimonial.author}
              className="relative bg-white dark:bg-slate-900 rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-200/50 dark:border-gray-800/50"
            >
              <Quote className="absolute top-6 right-6 w-12 h-12 text-blue-100 dark:text-blue-900" />
              
              <div className="relative">
                <div className="flex items-center mb-6">
                  <img
                    src={testimonial.image}
                    alt={testimonial.author}
                    className="w-12 h-12 rounded-full object-cover mr-4"
                  />
                  <div>
                    <div className="font-semibold text-gray-900 dark:text-white">
                      {testimonial.author}
                    </div>
                    <div className="text-sm text-gray-600 dark:text-gray-400">
                      {testimonial.role}, {testimonial.company}
                    </div>
                  </div>
                </div>

                <blockquote className="text-gray-600 dark:text-gray-300 mb-6">
                  "{testimonial.content}"
                </blockquote>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    {[...Array(5)].map((_, i) => (
                      <Star
                        key={i}
                        className="w-4 h-4 text-yellow-400 fill-yellow-400"
                      />
                    ))}
                  </div>
                  <div className="text-sm">
                    <div className="text-green-600 dark:text-green-400 font-medium">
                      {testimonial.stats.timeSaved}
                    </div>
                    <div className="text-blue-600 dark:text-blue-400 font-medium">
                      {testimonial.stats.roi}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}