import React from 'react';
import { Shield, Activity, Clock, AlertTriangle, CheckCircle2, Lock, Server, Globe, Database } from 'lucide-react';

const securityMetrics = [
  { id: 'uptime', name: 'System Uptime', value: '99.99%', status: 'optimal' },
  { id: 'threats', name: 'Threat Detection', value: 'Active', status: 'optimal' },
  { id: 'scans', name: 'Security Scans', value: '24/7', status: 'optimal' },
  { id: 'active-threats', name: 'Active Threats', value: '0', status: 'optimal' },
  { id: 'data-centers', name: 'Data Centers', value: 'CONUS', status: 'optimal' },
  { id: 'encryption', name: 'Encryption', value: 'FIPS 140-3', status: 'optimal' }
];

const securityZones = [
  {
    name: 'Perimeter Security',
    icon: Globe,
    status: 'optimal',
    metrics: {
      'Zero Trust': 'Enforced',
      'TIC 3.0': 'Compliant',
      'EINSTEIN': 'Protected',
      'CDM': 'Integrated'
    }
  },
  {
    name: 'Data Center Security',
    icon: Server,
    status: 'optimal',
    metrics: {
      'Air-Gapped': 'Yes',
      'FIPS 140-3': 'Validated',
      'Geo-Redundant': '3x CONUS',
      'Data Sovereignty': 'US Only'
    }
  },
  {
    name: 'Access Control',
    icon: Lock,
    status: 'optimal',
    metrics: {
      'PIV/CAC': 'Enforced',
      'RBAC': 'Granular',
      'Audit Logs': 'Immutable',
      'FedRAMP JAB': 'P-ATO'
    }
  }
];
export default function SecurityMonitor() {
  return (
    <div className="relative bg-[#F8FAFC]/95 dark:bg-[#1a365d]/95 backdrop-blur-md rounded-xl p-8 shadow-[0_8px_32px_rgba(0,56,117,0.1)] dark:shadow-[0_8px_32px_rgba(74,144,226,0.1)] border-2 border-[#003875]/20 dark:border-[#4A90E2]/20 overflow-hidden group">
      {/* Animated security pulse effect */}
      <div className="absolute inset-0 bg-gradient-to-br from-[#003875]/5 via-transparent to-transparent dark:from-[#4A90E2]/5 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
      <div className="absolute -top-32 -right-32 w-64 h-64 bg-gradient-to-br from-[#003875]/5 to-transparent dark:from-[#4A90E2]/5 rounded-full blur-3xl animate-pulse-slow" />

      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center">
          <div className="relative">
            <Activity className="w-6 h-6 text-[#003875] dark:text-[#4A90E2] mr-3 animate-pulse" />
            <div className="absolute inset-0 bg-[#003875]/10 dark:bg-[#4A90E2]/10 rounded-full blur animate-ping" />
          </div>
          <h2 className="text-xl font-bold text-[#003875] dark:text-[#4A90E2] flex items-center tracking-tight">
            Real-Time Security Monitor
            <span className="ml-3 px-2 py-1 text-xs bg-green-100 dark:bg-green-900/30 text-green-600 dark:text-green-400 rounded-full">
              Authority to Operate
            </span>
          </h2>
        </div>
        <div className="flex items-center">
          <div className="relative">
            <div className="w-2 h-2 bg-green-500 rounded-full animate-pulse mr-2" />
            <div className="absolute inset-0 bg-green-500/50 rounded-full blur animate-ping" />
          </div>
          <span className="text-sm font-medium text-green-600 dark:text-green-400">
            FedRAMP High • IL5 • NIST 800-53 • DISA Approved
          </span>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4 mb-6">
        {[
          { 
            id: 'auth-status',
            label: 'Authorization Status',
            value: 'High Impact',
            subtext: 'IL5/IL6 Certified',
            icon: Shield,
            color: 'green'
          },
          { 
            id: 'security-controls',
            label: 'Security Controls',
            value: '325/325',
            subtext: 'NIST 800-53 Rev 5',
            icon: CheckCircle2,
            color: 'blue'
          },
          { 
            id: 'data-sovereignty',
            label: 'Data Sovereignty',
            value: 'US-Based',
            subtext: 'CONUS Air-Gapped',
            icon: Database,
            color: 'violet'
          },
          { 
            id: 'encryption',
            label: 'Encryption',
            value: 'AES-256',
            subtext: 'End-to-End',
            icon: Lock,
            color: 'indigo'
          }
        ].map(badge => (
          <div
            key={badge.id}
            className={`bg-gradient-to-br from-${badge.color}-50 to-${badge.color}-100/50 dark:from-${badge.color}-900/20 dark:to-${badge.color}-900/10 rounded-lg p-4 border border-${badge.color}-200/50 dark:border-${badge.color}-800/50 hover:scale-105 transition-transform duration-300`}
          >
            <div className="flex items-center mb-3">
              <div className={`p-2 rounded-lg bg-${badge.color}-100/50 dark:bg-${badge.color}-900/30`}>
                <badge.icon className={`w-5 h-5 text-${badge.color}-600 dark:text-${badge.color}-400`} />
              </div>
            </div>
            <div className="text-lg font-bold text-black dark:text-white mb-1">{badge.value}</div>
            <div className="text-sm text-black dark:text-gray-300">{badge.label}</div>
            <div className={`text-xs text-${badge.color}-600 dark:text-${badge.color}-400 mt-1`}>{badge.subtext}</div>
          </div>
        ))}
      </div>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">
        {securityMetrics.map((metric) => (
          <div 
            key={metric.id}
            className="group relative bg-gradient-to-br from-[#F1F5F9] to-white dark:from-[#0F172A] dark:to-[#1a365d] rounded-lg p-4 border border-[#112240]/10 dark:border-[#3B82F6]/10 hover:border-[#003875]/30 dark:hover:border-[#4A90E2]/30 transform hover:scale-105 transition-all duration-300 hover:shadow-lg"
          >
            <div className="absolute inset-0 bg-gradient-to-br from-[#003875]/5 to-transparent dark:from-[#4A90E2]/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg" />
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm text-[#1E293B] dark:text-[#94A3B8]">{metric.name}</span>
              {metric.status === 'optimal' ? (
                <div className="relative">
                  <CheckCircle2 className="w-4 h-4 text-green-500 group-hover:scale-110 transition-transform" />
                  <div className="absolute inset-0 bg-green-500/20 rounded-full blur group-hover:animate-ping" />
                </div>
              ) : (
                <AlertTriangle className="w-4 h-4 text-yellow-500" />
              )}
            </div>
            <div className="text-lg font-bold bg-gradient-to-r from-[#003875] to-[#0056B3] dark:from-[#4A90E2] dark:to-[#63B3ED] bg-clip-text text-transparent">
              {metric.value}
            </div>
          </div>
        ))}
      </div>
      {/* Security Zones */}
      <div className="grid md:grid-cols-3 gap-6">
        {securityZones.map((zone) => {
          const Icon = zone.icon;
          return (
            <div 
              key={zone.name} 
              className="group relative bg-gradient-to-br from-[#F8FAFC] to-white dark:from-[#1a365d] dark:to-[#0F172A] rounded-xl p-6 border border-[#003875]/20 dark:border-[#4A90E2]/20 hover:border-[#003875]/40 dark:hover:border-[#4A90E2]/40 hover:shadow-[0_8px_32px_rgba(0,56,117,0.1)] dark:hover:shadow-[0_8px_32px_rgba(74,144,226,0.1)] transform hover:scale-[1.02] transition-all duration-500"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-[#003875]/5 to-transparent dark:from-[#4A90E2]/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
              <div className="flex items-center mb-4">
                <div className="relative p-3 rounded-xl bg-gradient-to-br from-[#003875]/10 to-[#0056B3]/10 dark:from-[#4A90E2]/10 dark:to-[#63B3ED]/10 group-hover:scale-110 transition-transform duration-500">
                  <Icon className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                  <div className="absolute inset-0 bg-[#003875]/5 dark:bg-[#4A90E2]/5 rounded-xl blur group-hover:animate-pulse" />
                </div>
                <div className="ml-3">
                  <h3 className="font-medium text-black dark:text-white">
                    {zone.name}
                  </h3>
                  <div className="flex items-center mt-1">
                    <div className="relative">
                      <div className="w-2 h-2 rounded-full bg-green-500 mr-2 animate-pulse" />
                      <div className="absolute inset-0 bg-green-500/50 rounded-full blur animate-ping" />
                    </div>
                    <span className="text-sm text-green-600 dark:text-green-400">
                      Protected
                    </span>
                  </div>
                </div>
              </div>
              <div className="space-y-3 mt-4">
                {Object.entries(zone.metrics).map(([key, value]) => (
                  <div 
                    key={key} 
                    className="flex items-center justify-between p-3 rounded-lg bg-gradient-to-br from-[#F1F5F9]/80 to-white dark:from-[#0F172A]/80 dark:to-[#1a365d] border border-[#003875]/10 dark:border-[#4A90E2]/10 group-hover:border-[#003875]/20 dark:group-hover:border-[#4A90E2]/20 transform group-hover:translate-x-1 transition-all duration-300"
                  >
                    <span className="text-black dark:text-gray-400 capitalize">
                      {key.replace(/([A-Z])/g, ' $1').trim()}:
                    </span>
                    <span className="font-medium bg-gradient-to-r from-[#003875] to-[#0056B3] dark:from-[#4A90E2] dark:to-[#63B3ED] bg-clip-text text-transparent">
                      {value}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}