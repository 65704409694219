import React, { useState, useEffect } from 'react';
import { Shield, ArrowRight, CheckCircle2, Star, Users, Clock, Brain, Target, Chrome } from 'lucide-react';
import Button from '../components/ui/Button';
import { useRouter } from '../hooks/useRouter';
import { ROUTES } from '../utils/routes';
import { trackConversion } from '../utils/analytics';

// Constants
const ACTIVE_USERS = Math.floor(Math.random() * 50) + 350;

export default function Signup() {
  const { navigate } = useRouter();
  const [timeLeft, setTimeLeft] = useState(900); // 15 minutes
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState('');
  const [selectedPlan] = useState(() => localStorage.getItem('selectedPlan'));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(time => time > 0 ? time - 1 : 0);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      if (!email) {
        throw new Error('Email is required');
      }

      // Get selected plan
      const selectedPlan = localStorage.getItem('selectedPlan');
      
      // Track signup attempt
      trackConversion({
        type: 'signup_start',
        page: ROUTES.SIGNUP,
        data: { email, plan: selectedPlan }
      });

      // Store user email
      localStorage.setItem('userEmail', email);
      localStorage.setItem('accessToken', 'true');
      localStorage.setItem('trialStarted', 'true');
      localStorage.setItem('signupEmail', email);

      // If Pro plan, redirect to Stripe
      if (selectedPlan === 'Pro') {
        window.location.href = 'https://buy.stripe.com/7sI9C76iF8LWfNS28p';
      } else {
        // For other plans, start trial and redirect to workspace
        window.location.href = ROUTES.WORKSPACE;
      }
    } catch (err) {
      console.error('Signup error:', err);
      setError(err instanceof Error ? err.message : 'Failed to sign up');
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignup = () => {
    trackConversion({
      type: 'signup_google',
      page: ROUTES.SIGNUP,
      data: { plan: selectedPlan }
    });
    // Store redirect URL
    localStorage.setItem('redirectAfterAuth', ROUTES.WORKSPACE);
    // Redirect to Google auth
    window.location.href = '/api/auth/google';
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 flex flex-col relative overflow-y-auto">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_80%,rgba(147,51,234,0.05),rgba(0,0,0,0))]" />
        <div className="absolute inset-0 bg-[conic-gradient(from_0deg_at_50%_50%,rgba(139,92,246,0.03)_0deg,rgba(147,51,234,0.03)_120deg,rgba(168,85,247,0.03)_240deg)]" />
      </div>
      
      {/* Animated Particles */}
      <div className="absolute inset-0 overflow-hidden">
        {[...Array(20)].map((_, i) => (
          <div
            key={i}
            className="absolute animate-float"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 5}s`,
              animationDuration: `${5 + Math.random() * 5}s`
            }}
          >
            <div className="w-1 h-1 bg-blue-400/30 rounded-full" />
          </div>
        ))}
      </div>
      
      {/* Glowing Orbs */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="orb w-64 h-64 text-blue-400/20 dark:text-blue-500/10" 
          style={{ top: '15%', left: '10%', animationDelay: '0s' }} />
        <div className="orb w-96 h-96 text-violet-400/20 dark:text-violet-500/10" 
          style={{ top: '40%', right: '15%', animationDelay: '-4s' }} />
        <div className="orb w-48 h-48 text-indigo-400/20 dark:text-indigo-500/10" 
          style={{ bottom: '20%', left: '25%', animationDelay: '-8s' }} />
      </div>
      
      {/* Urgency Banner */}
      <div className="fixed top-0 left-0 right-0 bg-gradient-to-r from-blue-600 to-violet-600 text-white py-2 text-center z-50">
        <div className="flex items-center justify-center space-x-4">
          <div className="flex items-center">
            <Clock className="w-4 h-4 mr-1" />
            <span>Special Launch Pricing Ends In: {formatTime(timeLeft)}</span>
          </div>
          <div className="hidden sm:flex items-center">
            <Users className="w-4 h-4 mr-1" />
            <span>{ACTIVE_USERS} people viewing</span>
          </div>
        </div>
      </div>

      <div className="flex-1 flex items-center justify-center px-4 py-16">
        <div className="w-full max-w-md">
        <div className="relative bg-white/90 dark:bg-slate-900/90 backdrop-blur-sm rounded-xl p-8 shadow-xl border border-gray-200/50 dark:border-gray-800/50 transform hover:scale-[1.01] transition-all duration-300 mx-auto cyber-button web3-glow">
          <div className="text-center mb-8">
            <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
              <Shield className="w-6 h-6 text-blue-600 dark:text-blue-400" />
            </div>
            <h2 className="text-3xl font-bold web3-luxury mb-2">
              Start Your 7-Day Free Trial
            </h2>
            <p className="text-gray-600 dark:text-gray-400">
              Get instant access to 500+ premium AI tools worth $50,000+
            </p>
            {selectedPlan && (
              <div className="mt-2 text-sm text-blue-600 dark:text-blue-400 font-medium">
                Selected Plan: {selectedPlan}
              </div>
            )}
            <div className="mt-4 flex items-center justify-center space-x-6">
              <div className="flex items-center">
                <Shield className="w-4 h-4 text-green-500 mr-1" />
                <span className="text-sm font-medium text-gray-600 dark:text-gray-400">7-day free trial</span>
              </div>
              <div className="flex items-center">
                <CheckCircle2 className="w-4 h-4 text-green-500 mr-1" />
                <span className="text-sm font-medium text-gray-600 dark:text-gray-400">Cancel anytime</span>
              </div>
            </div>
          </div>

          {error && (
            <div className="mb-6 p-4 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 rounded-lg text-sm">
              {error}
            </div>
          )}

          <div className="mb-8">
            <button
              onClick={handleGoogleSignup}
              className="w-full flex items-center justify-center px-4 py-3 bg-white dark:bg-slate-800 border-2 border-gray-200 dark:border-gray-700 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 transition-all duration-300 group hover:scale-[1.02] active:scale-[0.98] dark:cyber-button"
            >
              <Chrome className="w-5 h-5 text-[#4285F4] mr-2" />
              <span className="font-medium">Continue with Google</span>
            </button>
          </div>

          <div className="relative text-center mb-6">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-200 dark:border-gray-700"></div>
            </div>
            <div className="relative">
              <span className="px-4 text-sm text-gray-500 bg-white dark:bg-slate-900">
                Or continue with email
              </span>
            </div>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Work Email
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="name@company.com"
                className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800 focus:ring-2 focus:ring-blue-500 transition-all duration-300 dark:focus:ring-violet-500/50 dark:focus:border-violet-500/50"
                required
              />
            </div>

            <Button
              type="submit"
              variant="primary"
              size="lg"
              icon={ArrowRight}
              className="w-full group hover:scale-[1.02] active:scale-[0.98] dark:cyber-button"
              loading={loading}
            >
              <span className="relative">
                Start Free Trial
                <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/[0.1] to-transparent translate-x-[-200%] group-hover:translate-x-[200%] transition-transform duration-[400ms]" />
              </span>
            </Button>

            <div className="text-center">
              <p className="text-xs text-gray-500 dark:text-gray-400">
                By continuing, you agree to our Terms of Service and Privacy Policy
              </p>
            </div>
          </form>

          {/* Social Proof */}
          <div className="mt-8 space-y-4">
            <div className="flex items-center justify-center space-x-2">
              <div className="flex -space-x-2">
                {[...Array(3)].map((_, i) => (
                  <div key={i} className="relative">
                    <div className="absolute inset-0 rounded-full animate-pulse-slow" style={{ animationDelay: `${i * 0.2}s` }}>
                      <img
                        src={`https://i.pravatar.cc/32?img=${i + 10}`}
                        alt="User avatar"
                        className="w-8 h-8 rounded-full border-2 border-white dark:border-slate-900 transform hover:scale-110 transition-transform duration-300"
                      />
                    </div>
                  </div>
                ))}
              </div>
              <span className="text-sm text-gray-600 dark:text-gray-400">
                50+ people signed up today
              </span>
            </div>
            <div className="flex items-center justify-center">
              <Star className="w-4 h-4 text-yellow-400 fill-yellow-400 mr-1" />
              <span className="text-sm font-medium text-gray-600 dark:text-gray-400">
                4.9/5 from 500+ reviews
              </span>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
}