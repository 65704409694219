import React, { useState } from 'react';
import { Brain, Rocket, Target, ArrowRight, Shield, CheckCircle2, DollarSign, Users, Star, Zap, Building2, Lock, Globe } from 'lucide-react';
import Button from '../components/ui/Button';
import ConsultationForm from '../components/venture/ConsultationForm';
import { useRouter } from '../hooks/useRouter';
import { trackConversion } from '../utils/analytics';
import { ROUTES } from '../utils/routes';
import { startFreeTrial } from '../utils/auth';

const technicalServices = [
  {
    name: 'Custom AI Development',
    icon: Brain, 
    description: 'Enterprise-grade AI infrastructure and model development',
    features: [
      'Custom GPT-4/Claude 3 development',
      'Proprietary model training & fine-tuning',
      'Enterprise MLOps infrastructure',
      'Advanced AI agent development',
      'Real-time model monitoring'
    ]
  },
  {
    name: 'Web Development',
    icon: Rocket,
    description: 'Production-ready web application development',
    features: [
      'Modern React/Next.js/TypeScript stack',
      'Scalable serverless architecture',
      'Real-time data synchronization',
      'Enterprise security & compliance',
      'Automated CI/CD pipelines'
    ]
  },
  {
    name: 'AI Agents & Automation',
    icon: Target,
    description: 'Intelligent AI agents and business automation',
    features: [
      'Custom AI agent development',
      'Multi-agent orchestration',
      'Natural language interfaces',
      'Process automation & optimization',
      'Enterprise tool integration'
    ]
  }
];

const venturePackages = [
  {
    name: 'Basic Build',
    subtitle: 'Unvalidated Ventures',
    price: '$15,000',
    maintenance: '$499/month',
    equity: '30-35%',
    description: 'Complete technical foundation for unvalidated ventures',
    features: [
      'Fully functional website',
      'AI bot tailored to your niche',
      'Technical documentation',
      'Growth roadmap',
      'Basic infrastructure',
      'Standard support',
      'No validation required',
      'Launch assistance'
    ]
  },
  {
    name: 'Validated Build',
    subtitle: 'Market-Tested Ventures',
    price: '$25,000',
    maintenance: '$999/month',
    equity: '20-25%',
    description: 'Full technical solution with proven market demand',
    popular: true,
    features: [
      'Everything in Basic Build',
      'Market validation testing',
      'Lead capture system',
      'Beta program setup',
      'Revenue validation',
      'Partnership development',
      'Investor pitch materials',
      'Traction metrics'
    ]
  },
  {
    name: 'Custom Enterprise',
    subtitle: 'Established Businesses',
    price: '$50,000+',
    maintenance: '$1,500/month',
    equity: '10-20%',
    description: 'Bespoke AI ventures for established organizations',
    features: [
      'Everything in Validated Build',
      'Custom AI infrastructure',
      'Industry-specific features',
      'White-label capabilities',
      'Enterprise integrations',
      '6 months consulting',
      'Board advisory services',
      'Advanced security'
    ]
  }
];

const addOnServices = [
  {
    name: 'Tech Maintenance & Updates',
    price: '$500-$1,500/month',
    features: [
      'Bug fixes & security updates',
      'Feature enhancements',
      'AI model retraining',
      'Performance optimization',
      'Continuous improvements'
    ]
  },
  {
    name: 'AI Bot Licensing',
    price: '$100-$500/month per bot',
    features: [
      'Access to GALAX AI tools',
      'Regular model updates',
      'Usage analytics',
      'Custom training data',
      'Model fine-tuning'
    ]
  },
  {
    name: 'Growth Consulting',
    price: '5-10% of revenue growth',
    features: [
      'Marketing strategy',
      'Scaling guidance',
      'Network introductions',
      'Growth optimization',
      'Revenue acceleration'
    ]
  }
];
export default function VentureBuilder() {
  const [showConsultationForm, setShowConsultationForm] = useState(false);
  const { navigate } = useRouter();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleApplyNow = async (packageName: string) => {
    setLoading(true);
    try {
      // Store selected package
      localStorage.setItem('selectedPackage', packageName);
      
      // Track conversion
      trackConversion({
        type: 'venture_application',
        page: ROUTES.VENTURE_BUILDER,
        data: { package: packageName }
      });

      // Open consultation form
      setShowConsultationForm(true);
    } catch (err) {
      console.error('Error handling application:', err);
      setError(err instanceof Error ? err.message : 'Failed to process application');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-24">
      {/* Hero Section */}
      <div className="relative mb-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <Rocket className="w-6 h-6 text-blue-600 dark:text-blue-400 animate-pulse" />
          </div>
          
          <h1 className="text-4xl sm:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-violet-600 to-purple-600 dark:from-blue-400 dark:via-violet-400 dark:to-purple-400">
            Launch Your AI Venture with Elite Technical Co-Founders
          </h1>
          
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto mb-8">
            Get world-class technical co-founding with custom AI development, enterprise-grade web applications, and intelligent AI agents. We build the complete technical foundation for your AI venture.
          </p>

          {/* Stats Grid */}
          <div className="grid grid-cols-4 gap-6 max-w-4xl mx-auto mb-8">
            {[
              { icon: DollarSign, value: '10x', label: 'Faster Launch' },
              { icon: Users, value: '45+', label: 'Ventures Launched' },
              { icon: Star, value: '98%', label: 'Success Rate' },
              { icon: Shield, value: '24/7', label: 'Support' }
            ].map(({ icon: Icon, value, label }) => (
              <div key={label} className="bg-white/80 dark:bg-slate-900/80 backdrop-blur-sm rounded-xl p-4 border border-gray-200/50 dark:border-gray-800/50">
                <Icon className="w-6 h-6 text-blue-600 dark:text-blue-400 mx-auto mb-2" />
                <div className="text-2xl font-bold text-gray-900 dark:text-white mb-1">{value}</div>
                <div className="text-sm text-gray-600 dark:text-gray-400">{label}</div>
              </div>
            ))}
          </div>

          <Button 
            variant="primary" 
            size="lg" 
            icon={ArrowRight}
            onClick={() => setShowConsultationForm(true)}
          >
            Schedule Consultation
          </Button>
          <Button 
            variant="outline" 
            size="lg" 
            icon={ArrowRight}
            onClick={() => navigate(ROUTES.CASE_STUDIES)}
          >
            View Case Studies
          </Button>
        </div>
      </div>

      {/* Services Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-16">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            Technical Co-Founding Services
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Complete technical development and AI infrastructure for your venture
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {technicalServices.map((service) => {
            const Icon = service.icon;
            return (
              <div
                key={service.name}
                className="group relative bg-white dark:bg-slate-900 rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-[1.02] border border-gray-200/50 dark:border-gray-800/50"
              >
                <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-violet-500/5 dark:from-blue-400/5 dark:to-violet-400/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
                
                <div className="relative">
                  <div className="w-12 h-12 rounded-xl bg-gradient-to-br from-blue-100 to-violet-100 dark:from-blue-900/20 dark:to-violet-900/20 p-3 mb-6">
                    <Icon className="w-full h-full text-blue-600 dark:text-blue-400" />
                  </div>

                  <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-4">
                    {service.name}
                  </h3>
                  
                  <p className="text-gray-600 dark:text-gray-400 mb-6">
                    {service.description}
                  </p>

                  <ul className="space-y-3">
                    {service.features.map((feature) => (
                      <li key={feature} className="flex items-center text-gray-600 dark:text-gray-300">
                        <CheckCircle2 className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Equity Packages */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-16">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            Equity Partnership Models
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Fair and transparent equity partnerships based on your stage
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {venturePackages.map((pkg) => (
            <div
              key={pkg.name}
              className="group relative bg-white dark:bg-slate-900 rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-[1.02] border border-gray-200/50 dark:border-gray-800/50"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-violet-500/5 dark:from-blue-400/5 dark:to-violet-400/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
              
              <div className="relative">
                <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">
                  {pkg.name}
                </h3>
                
                <div className="flex items-baseline mb-4">
                  <span className="text-3xl font-bold bg-gradient-to-br from-blue-600 to-violet-600 dark:from-blue-400 dark:to-violet-400 bg-clip-text text-transparent">
                    {pkg.price}
                  </span>
                  <span className="ml-2 text-gray-600 dark:text-gray-400">one-time</span>
                </div>
                {pkg.maintenance && (
                  <div className="text-sm text-violet-600 dark:text-violet-400 font-medium mb-4">
                    + {pkg.maintenance} maintenance
                  </div>
                )}

                <div className="text-sm text-blue-600 dark:text-blue-400 font-medium mb-4">
                  Investment: {pkg.price}
                </div>

                <p className="text-gray-600 dark:text-gray-400 mb-6">
                  {pkg.description}
                </p>

                <ul className="space-y-3 mb-6">
                  {pkg.features.map((feature) => (
                    <li key={feature} className="flex items-center text-gray-600 dark:text-gray-300">
                      <CheckCircle2 className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                      {feature}
                    </li>
                  ))}
                </ul>

                <Button
                  variant="primary"
                  size="lg"
                  icon={ArrowRight}
                  className="w-full"
                  onClick={() => handleApplyNow(pkg.name)}
                >
                  Apply Now
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* CTA Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Add-On Services */}
        <div className="mb-16">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
              Optional Add-On Services
            </h2>
            <p className="text-lg text-gray-600 dark:text-gray-300">
              Enhance your venture with additional support and services
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {addOnServices.map((service) => (
              <div
                key={service.name}
                className="group relative bg-white dark:bg-slate-900 rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-[1.02] border border-gray-200/50 dark:border-gray-800/50"
              >
                <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-violet-500/5 dark:from-blue-400/5 dark:to-violet-400/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
                
                <div className="relative">
                  <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">
                    {service.name}
                  </h3>
                  <div className="text-lg font-bold text-blue-600 dark:text-blue-400 mb-4">
                    {service.price}
                  </div>
                  <ul className="space-y-3">
                    {service.features.map((feature) => (
                      <li key={feature} className="flex items-center text-gray-600 dark:text-gray-300">
                        <CheckCircle2 className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-12 text-center">
          <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Ready to Build Something Amazing?
          </h3>
          <p className="text-gray-600 dark:text-gray-400 mb-8 max-w-2xl mx-auto">
            Let's discuss how we can help bring your AI venture to life. Schedule a consultation with our technical co-founding team.
          </p>
          <Button 
            variant="primary" 
            size="lg" 
            icon={ArrowRight}
            onClick={() => setShowConsultationForm(true)}
          >
            Schedule Consultation
          </Button>
        </div>
      </div>

      {/* Consultation Form Modal */}
      <ConsultationForm
        isOpen={showConsultationForm}
        onClose={() => setShowConsultationForm(false)}
      />
    </div>
  );
}