import React, { useState } from 'react';
import { Brain, Zap, Shield, ArrowRight, Check, Gift, Clock } from 'lucide-react';
import Button from '../ui/Button';

const features = [
  {
    name: 'AI Content Writer',
    description: 'Generate blog posts, social media, and marketing copy',
    included: '250 credits/month'
  },
  {
    name: 'Image Generator',
    description: 'Create custom images and graphics with AI',
    included: '50 images/month'
  },
  {
    name: 'Quick-Start Templates',
    description: 'Pre-built workflows for common tasks',
    included: '25+ templates'
  }
];

const bonuses = [
  {
    name: 'ROI Calculator',
    value: '$99',
    description: 'Calculate your potential AI savings'
  },
  {
    name: 'Implementation Guide',
    value: '$199',
    description: 'Step-by-step setup instructions'
  },
  {
    name: 'Priority Support',
    value: '$299',
    description: '24/7 chat and email support'
  }
];

export default function AIStarterPack() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    // Integrate with payment processor here
    await new Promise(resolve => setTimeout(resolve, 1000));
    setLoading(false);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <Brain className="w-6 h-6 text-blue-600 dark:text-blue-400" />
          </div>
          <h1 className="text-4xl sm:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-violet-600 to-purple-600 dark:from-blue-400 dark:via-violet-400 dark:to-purple-400">
            AI Starter Pack
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            Everything you need to start with AI in one simple package
          </p>
        </div>

        <div className="grid lg:grid-cols-2 gap-12 items-start">
          {/* Features List */}
          <div className="space-y-8">
            <div className="bg-white dark:bg-slate-900 rounded-xl p-6 border border-gray-200 dark:border-gray-800">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
                What's Included
              </h2>
              <div className="space-y-6">
                {features.map((feature) => (
                  <div key={feature.name} className="flex">
                    <div className="flex-shrink-0">
                      <Check className="w-6 h-6 text-green-500" />
                    </div>
                    <div className="ml-4">
                      <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                        {feature.name}
                      </h3>
                      <p className="text-gray-600 dark:text-gray-400 mt-1">
                        {feature.description}
                      </p>
                      <p className="text-sm text-blue-600 dark:text-blue-400 mt-1">
                        Included: {feature.included}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Bonuses */}
            <div className="bg-gradient-to-br from-violet-50 to-purple-50 dark:from-violet-900/20 dark:to-purple-900/20 rounded-xl p-6">
              <div className="flex items-center mb-6">
                <Gift className="w-6 h-6 text-violet-600 dark:text-violet-400 mr-2" />
                <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                  Special Launch Bonuses
                </h2>
              </div>
              <div className="space-y-4">
                {bonuses.map((bonus) => (
                  <div key={bonus.name} className="flex items-center justify-between p-4 bg-white/80 dark:bg-slate-900/80 rounded-lg">
                    <div>
                      <h3 className="font-medium text-gray-900 dark:text-white">
                        {bonus.name}
                      </h3>
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        {bonus.description}
                      </p>
                    </div>
                    <div className="text-lg font-bold text-violet-600 dark:text-violet-400">
                      {bonus.value}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Pricing Card */}
          <div className="sticky top-24">
            <div className="bg-white dark:bg-slate-900 rounded-xl border-2 border-blue-500 dark:border-blue-400 p-8">
              <div className="flex items-center justify-between mb-6">
                <div>
                  <div className="text-sm text-gray-600 dark:text-gray-400 mb-1">
                    Regular Price
                  </div>
                  <div className="text-2xl font-bold text-gray-900 dark:text-white line-through">
                    $997
                  </div>
                </div>
                <div className="text-right">
                  <div className="text-sm text-blue-600 dark:text-blue-400 mb-1">
                    Launch Price
                  </div>
                  <div className="text-4xl font-bold text-blue-600 dark:text-blue-400">
                    $297
                  </div>
                </div>
              </div>

              <div className="space-y-4 mb-8">
                <div className="flex items-center text-green-600 dark:text-green-400">
                  <Zap className="w-5 h-5 mr-2" />
                  <span className="font-medium">Instant Access</span>
                </div>
                <div className="flex items-center text-blue-600 dark:text-blue-400">
                  <Shield className="w-5 h-5 mr-2" />
                  <span className="font-medium">30-Day Money Back Guarantee</span>
                </div>
                <div className="flex items-center text-violet-600 dark:text-violet-400">
                  <Clock className="w-5 h-5 mr-2" />
                  <span className="font-medium">Launch Price Ends Soon</span>
                </div>
              </div>

              <form onSubmit={handleSubmit} className="space-y-4">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-slate-800 focus:ring-2 focus:ring-blue-500"
                  required
                />
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  icon={ArrowRight}
                  className="w-full group"
                  loading={loading}
                >
                  Get Started Now
                </Button>
              </form>

              <p className="text-center text-sm text-gray-500 dark:text-gray-400 mt-4">
                No credit card required • Instant access • Cancel anytime
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}