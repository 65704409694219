import React from 'react';
import { Shield, Star, Users, ArrowRight, Brain, Lock, Zap, Network, Database, Building2, Rocket } from 'lucide-react';
import Button from './ui/Button';
import SupernovaNetwork from './hero/SupernovaNetwork';
import SpaceBackground from './hero/SpaceBackground';

const pillars = [
  {
    title: 'Decentralized AI',
    icon: Network,
    description: 'Distributed computing networks for unmatched scalability',
    metrics: {
      nodes: '10K+',
      uptime: '99.99%',
      latency: '<10ms'
    },
    color: 'blue'
  },
  {
    title: 'Zero-Trust Security',
    icon: Lock,
    description: 'Enterprise-grade protection with quantum-resistant encryption',
    metrics: {
      encryption: '256-bit',
      compliance: 'SOC2',
      protection: '24/7'
    },
    color: 'violet'
  },
  {
    title: 'Edge Computing',
    icon: Zap,
    description: 'Process AI workloads at the edge for maximum performance',
    metrics: {
      speed: '10x faster',
      savings: '65% cost',
      scale: 'Global'
    },
    color: 'purple'
  },
  {
    title: 'Neural Networks',
    icon: Brain,
    description: 'Advanced AI models with multi-modal capabilities',
    metrics: {
      accuracy: '99.9%',
      models: '500+',
      training: '24/7'
    },
    color: 'indigo'
  },
  {
    title: 'Data Infrastructure',
    icon: Database,
    description: 'Scalable data processing and storage solutions',
    metrics: {
      storage: 'Petabyte',
      processing: '1M TPS',
      availability: '99.999%'
    },
    color: 'cyan'
  }
];
import { ROUTES } from '../utils/routes';
import { useRouter } from '../hooks/useRouter';

export default function Hero() {
  const { navigate } = useRouter();

  return (
    <div className="relative overflow-hidden bg-gradient-to-b from-white via-white to-gray-50/50 dark:from-[rgb(3,3,5)] dark:via-[rgb(8,8,15)] dark:to-[rgb(8,12,23)] text-gray-900 dark:text-white min-h-[90vh] lg:min-h-screen flex flex-col justify-center pb-16 md:pb-20">
      {/* Dark mode background effects only */}
      <div className="hidden dark:block">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(120,119,198,0.03),rgba(0,0,0,0))]" />
        <div className="absolute inset-y-0 right-0 w-1/2 bg-[radial-gradient(circle_at_70%_50%,rgba(120,119,198,0.03),rgba(0,0,0,0))]" />
        <div className="absolute inset-0 bg-[conic-gradient(from_0deg_at_50%_50%,rgba(139,92,246,0.03)_0deg,rgba(147,51,234,0.03)_120deg,rgba(168,85,247,0.03)_240deg)]" />
        <SpaceBackground />
      </div>

      {/* Glowing Orbs */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="orb w-[40rem] h-[40rem] text-blue-400/10 dark:text-blue-500/5 blur-3xl" 
          style={{ top: '10%', left: '5%', animationDelay: '0s' }} />
        <div className="orb w-[50rem] h-[50rem] text-violet-400/10 dark:text-violet-500/5 blur-3xl" 
          style={{ top: '30%', right: '10%', animationDelay: '-4s' }} />
        <div className="orb w-[35rem] h-[35rem] text-indigo-400/10 dark:text-indigo-500/5 blur-3xl" 
          style={{ bottom: '15%', left: '20%', animationDelay: '-8s' }} />
        <div className="orb w-[45rem] h-[45rem] text-purple-400/10 dark:text-purple-900/5 blur-3xl" 
          style={{ top: '50%', right: '20%', animationDelay: '-12s' }} />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-16 sm:pt-40 sm:pb-24">
        <div className="text-center">
          <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-extrabold tracking-tight mb-8 leading-[1.1] animate-fade-in">
            <span className="relative block">
              <span className="absolute inset-0 hidden dark:block bg-gradient-to-r from-blue-400/10 via-violet-400/10 to-purple-400/10 blur-xl animate-pulse-slow"></span>
              <span className="web3-luxury">
                <span className="block text-3xl sm:text-4xl md:text-5xl lg:text-6xl mb-2 sm:mb-4">
                  Revolutionizing the Future
                </span>
                <span className="block text-3xl sm:text-4xl md:text-5xl lg:text-6xl mb-2 sm:mb-4">
                  with AI Ventures That Shape
                </span>
                <span className="block text-3xl sm:text-4xl md:text-5xl lg:text-6xl">
                  Industries and Uplift Humanity
                </span>
              </span>
            </span>
          </h1>
          <p className="max-w-2xl mx-auto text-lg sm:text-xl text-gray-600 dark:text-slate-300 mb-12 animate-fade-in [animation-delay:200ms] px-4 sm:px-0 leading-relaxed">
            We own the venture-building journey—from predictive AI blueprints to engineering excellence. Whether it's internal ventures, enterprise solutions, or government-scale projects, we deliver scalable, impactful success.
          </p>

          {/* CTA buttons */}
          <div className="flex flex-col sm:flex-row justify-center gap-4 mb-12 animate-fade-in [animation-delay:400ms]">
            <Button
              variant="primary"
              size="lg"
              icon={ArrowRight} 
              onClick={() => navigate(ROUTES.ASSESSMENT)}
              className="group relative px-8 sm:px-12 py-6 text-lg font-semibold bg-gradient-to-r from-blue-600 via-violet-600 to-purple-600 hover:from-blue-500 hover:via-violet-500 hover:to-purple-500 transition-all duration-300 hover:scale-[1.02] active:scale-[0.98] shadow-xl hover:shadow-2xl border border-white/10 text-white dark:cyber-button min-w-[200px] sm:min-w-[250px]"
            >
              <span className="relative">
                Get Started Now
              </span>
            </Button>
            <Button
              variant="outline"
              size="lg"
              icon={ArrowRight}
              onClick={() => navigate(ROUTES.GOVERNMENT)}
              className="group relative px-8 sm:px-12 py-6 text-lg font-semibold hover:scale-[1.02] active:scale-[0.98] shadow-lg hover:shadow-xl border-2 dark:web3-glow min-w-[200px] sm:min-w-[250px] hover:bg-white/5 dark:hover:bg-white/5"
            >
              <span className="relative">
                Enterprise & Gov
              </span>
            </Button>
          </div>

          {/* Microtext */}
          <p className="text-sm text-gray-600 dark:text-gray-400 mb-6">
            Join 50,000+ companies already using our AI tools to save 40+ hours per month
          </p>

          {/* Trust Badges */}
          <div className="flex flex-wrap items-center justify-center gap-12 mx-auto max-w-2xl">
            <div className="flex items-center space-x-2">
              <div className="flex -space-x-2">
                {[...Array(6)].map((_, i) => (
                  <img
                    key={i}
                    src={`https://i.pravatar.cc/40?img=${i + 10}`}
                    alt="User avatar"
                    className="w-10 h-10 rounded-full border-2 border-white dark:border-slate-900 transform hover:scale-110 transition-transform duration-300"
                  />
                ))}
              </div>
              <span className="text-sm font-medium text-gray-600 dark:text-gray-400">
                <span className="font-semibold">50K+</span> active users
              </span>
            </div>
            <div className="flex items-center space-x-1">
              {[...Array(5)].map((_, i) => (
                <Star
                  key={i}
                  className="w-5 h-5 text-yellow-400 fill-yellow-400 transform hover:scale-110 transition-transform duration-300"
                />
              ))}
              <span className="ml-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                <span className="font-semibold">4.9/5</span> from 2,500+ reviews
              </span>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
}