import React from 'react';
import { Shield, CheckCircle2, AlertTriangle, Clock, FileCheck, Lock, Users } from 'lucide-react';
import { COMPLIANCE_REQUIREMENTS } from '../../constants/government';

const complianceMetrics = [
  { name: 'Controls Implemented', value: '325/325', status: 'complete' },
  { name: 'Last Assessment', value: '15 days ago', status: 'current' },
  { name: 'ConMon Status', value: 'Active', status: 'active' },
  { name: 'POA&M Items', value: '0 Open', status: 'optimal' },
  { name: 'STIG Compliance', value: '100%', status: 'optimal' },
  { name: 'Data Residency', value: 'CONUS', status: 'optimal' }
];

const complianceStatuses = {
  FedRAMP: { status: 'authorized', level: 'High Impact', lastAudit: '2024-02-15' },
  NIST: { status: 'compliant', framework: '800-53 Rev 5', lastAssessment: '2024-01-20' },
  CMMC: { status: 'certified', level: 'Level 3', expiration: '2025-03-01' },
  FISMA: { status: 'compliant', rating: 'Excellent', lastReport: '2024-02-01' },
  DISA: { status: 'authorized', level: 'IL5/IL6', lastAudit: '2024-03-01' },
  GSA: { status: 'active', contract: 'Schedule 70', expiration: '2025-12-31' }
};

export default function ComplianceStatus() {
  return (
    <div className="bg-[#F8FAFC]/95 dark:bg-[#1a365d]/95 rounded-xl p-8 shadow-xl border-2 border-[#003875]/20 dark:border-[#4A90E2]/20">
      <div className="flex items-center mb-6">
        <Shield className="w-8 h-8 text-[#003875] dark:text-[#4A90E2] mr-3" />
        <h2 className="text-2xl font-bold text-[#003875] dark:text-[#4A90E2] tracking-tight">
          Compliance & Authorization Status
        </h2>
      </div>
      <div className="relative mb-8 pl-8 border-l-2 border-[#003875]/20 dark:border-[#4A90E2]/20">
        {[
          { date: '2024-03', event: 'DoD IL6 Authorization Achieved', status: 'complete' },
          { date: '2024-02', event: 'FedRAMP High Authorization Renewed', status: 'complete' },
          { date: '2024-01', event: 'DISA STIG Compliance Verified', status: 'complete' },
          { date: '2024-01', event: 'Annual Security Assessment', status: 'complete' },
          { date: '2023-12', event: 'Zero Trust Architecture Implementation', status: 'complete' }
        ].map((item, index) => (
          <div key={index} className="mb-6 relative">
            <div className="absolute -left-[41px] p-2 bg-white dark:bg-slate-900 rounded-full border-2 border-blue-200 dark:border-blue-800">
              <CheckCircle2 className="w-4 h-4 text-green-500" />
            </div>
            <div className="text-sm text-[#2C3E50] dark:text-[#B8C2CC] mb-1">{item.date}</div>
            <div className="font-medium text-[#003875] dark:text-[#4A90E2]">{item.event}</div>
          </div>
        ))}
      </div>

      {/* Compliance Metrics */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">
        {complianceMetrics.map((metric) => (
          <div key={metric.name} className="bg-gray-50 dark:bg-slate-800 rounded-lg p-4">
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm text-black dark:text-gray-400">{metric.name}</span>
              <CheckCircle2 className="w-4 h-4 text-green-500" />
            </div>
            <div className="text-lg font-semibold text-black dark:text-white">
              {metric.value}
            </div>
          </div>
        ))}
      </div>
      <div className="grid md:grid-cols-2 gap-6">
        {Object.entries(complianceStatuses).map(([framework, details]) => (
          <div key={framework} className="bg-gray-50 dark:bg-slate-800 rounded-lg p-4">
            <div className="flex items-center justify-between mb-4">
              <span className="font-medium text-gray-900 dark:text-white">{framework}</span>
              <div className="flex items-center">
                <CheckCircle2 className="w-5 h-5 text-green-500 mr-1" />
                <span className="text-sm text-green-600 dark:text-green-400">Active</span>
              </div>
            </div>
            {Object.entries(details).map(([key, value]) => (
              <div key={key} className="flex items-center text-sm mt-2">
                <Clock className="w-4 h-4 text-gray-400 mr-2" />
                <span className="text-gray-600 dark:text-gray-400 capitalize">
                  {key.replace(/([A-Z])/g, ' $1').trim()}: {value}
                </span>
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="mt-8">
        <h3 className="font-medium text-gray-900 dark:text-white mb-4">
          Active Controls & Requirements
        </h3>
        <div className="grid md:grid-cols-3 gap-4">
          {Object.entries(COMPLIANCE_REQUIREMENTS).map(([category, requirements]) => (
            <div key={category} className="space-y-2">
              <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 capitalize mb-2">
                {category}
              </h4>
              {requirements.map((req) => (
                <div key={req} className="flex items-center text-sm">
                  <CheckCircle2 className="w-4 h-4 text-green-500 mr-2 flex-shrink-0" />
                  <span className="text-gray-600 dark:text-gray-400">{req}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}