import React, { useState } from 'react';
import { Mail, Building2, Users, ArrowRight, Shield, Clock, DollarSign } from 'lucide-react';
import Button from '../ui/Button';
import { trackConversion } from '../../utils/analytics';
import { ROUTES } from '../../utils/routes';

interface ConsultationFormProps {
  isOpen: boolean;
  onClose: () => void;
  type?: 'enterprise' | 'venture' | 'government';
}

const companyScales = [
  { value: '50-200', label: '50-200 employees' },
  { value: '201-500', label: '201-500 employees' },
  { value: '501-1000', label: '501-1000 employees' },
  { value: '1001-5000', label: '1001-5000 employees' },
  { value: '5000+', label: '5000+ employees' }
];

const budgetRanges = [
  { value: '10k-50k', label: '$10K - $50K monthly' },
  { value: '51k-100k', label: '$51K - $100K monthly' },
  { value: '101k-500k', label: '$101K - $500K monthly' },
  { value: '500k+', label: '$500K+ monthly' }
];

export default function ConsultationForm({ isOpen, onClose, type = 'enterprise' }: ConsultationFormProps) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    role: '',
    companySize: '',
    budget: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Format email content
      const emailContent = `
New ${type.charAt(0).toUpperCase() + type.slice(1)} Consultation Request

Name: ${formData.name}
Email: ${formData.email}
Company: ${formData.company}
Role: ${formData.role}
Company Size: ${formData.companySize}
Budget Range: ${formData.budget}

Additional Details:
${formData.message}
      `.trim();

      // Send to landon@galax.ai
      await fetch('/.netlify/functions/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          to: 'landon@galax.ai',
          subject: `${type.charAt(0).toUpperCase() + type.slice(1)} Consultation Request - ${formData.company}`,
          text: emailContent
        })
      });

      // Track form submission
      trackConversion({
        type: 'form_submit',
        page: window.location.pathname as any,
        data: { 
          form: 'consultation',
          type,
          companySize: formData.companySize,
          budget: formData.budget
        }
      });

      // Show success message
      setSuccess(true);
      setFormData({
        name: '',
        email: '',
        company: '',
        role: '',
        companySize: '',
        budget: '',
        message: ''
      });

      // Close modal after delay
      setTimeout(() => {
        onClose();
        setSuccess(false);
      }, 2000);

    } catch (err) {
      setError('Failed to submit consultation request. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[100] flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm overflow-y-auto pt-24">
      <div className="relative w-full max-w-2xl bg-white dark:bg-slate-900 rounded-xl shadow-xl">
        <div className="p-8 max-h-[85vh] overflow-y-auto">
          {success ? (
            <div className="text-center py-8">
              <div className="inline-flex items-center justify-center p-2 rounded-lg bg-green-100 dark:bg-green-900/30 mb-4">
                <Shield className="w-8 h-8 text-green-600 dark:text-green-400" />
              </div>
              <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">
                Request Submitted!
              </h3>
              <p className="text-gray-600 dark:text-gray-400">
                We'll be in touch within 24 hours to schedule your consultation.
              </p>
            </div>
          ) : (
            <>
              <div className="text-center mb-8">
                <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
                  <Building2 className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                </div>
                <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
                  Schedule {type.charAt(0).toUpperCase() + type.slice(1)} Consultation
                </h2>
                <p className="text-gray-600 dark:text-gray-400">
                  Let's discuss how we can help transform your business with AI
                </p>
              </div>

              {error && (
                <div className="mb-6 p-4 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 rounded-lg text-sm">
                  {error}
                </div>
              )}

              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Full Name
                    </label>
                    <input
                      type="text"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Work Email
                    </label>
                    <input
                      type="email"
                      value={formData.email}
                      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                      required
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Company Name
                    </label>
                    <input
                      type="text"
                      value={formData.company}
                      onChange={(e) => setFormData({ ...formData, company: e.target.value })}
                      className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Role/Title
                    </label>
                    <input
                      type="text"
                      value={formData.role}
                      onChange={(e) => setFormData({ ...formData, role: e.target.value })}
                      className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                      required
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Company Size
                    </label>
                    <select
                      value={formData.companySize}
                      onChange={(e) => setFormData({ ...formData, companySize: e.target.value })}
                      className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                      required
                    >
                      <option value="">Select company size</option>
                      {companyScales.map(scale => (
                        <option key={scale.value} value={scale.value}>
                          {scale.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Budget Range
                    </label>
                    <select
                      value={formData.budget}
                      onChange={(e) => setFormData({ ...formData, budget: e.target.value })}
                      className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                      required
                    >
                      <option value="">Select budget range</option>
                      {budgetRanges.map(range => (
                        <option key={range.value} value={range.value}>
                          {range.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Additional Details
                  </label>
                  <textarea
                    value={formData.message}
                    onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                    rows={4}
                    className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
                    placeholder="Tell us about your AI implementation goals..."
                  />
                </div>

                <div className="flex items-center justify-between mt-6">
                  <button
                    type="button"
                    onClick={onClose}
                    className="text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
                  >
                    Cancel
                  </button>
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    icon={ArrowRight}
                    loading={loading}
                  >
                    Schedule Consultation
                  </Button>
                </div>
              </form>

              {/* Trust Indicators */}
              <div className="mt-6 flex items-center justify-center space-x-6 text-sm text-gray-500">
                <div className="flex items-center">
                  <Shield className="w-4 h-4 text-green-500 mr-1" />
                  Enterprise-Grade Security
                </div>
                <div className="flex items-center">
                  <Users className="w-4 h-4 text-blue-500 mr-1" />
                  500+ Enterprise Clients
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}