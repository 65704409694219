import React, { useState } from 'react';
import { FileText, Download, Mail, Building2, Phone, ArrowRight, Shield, Mail as MailIcon, Phone as PhoneIcon } from 'lucide-react';
import Button from '../ui/Button';

interface CapabilityStatementModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function CapabilityStatementModal({ isOpen, onClose }: CapabilityStatementModalProps) {
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    agency: '',
    email: '',
    phone: ''
  });

  const handleDirectDownload = () => {
    // In production, this would be a real PDF URL
    const pdfUrl = '/GALAX-AI-Capability-Statement-2024.pdf';
    window.open(pdfUrl, '_blank');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      // Here you would integrate with your email service
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Show success message
      alert('Thank you! The capability statement has been sent to your email.');
      onClose();
    } catch (error) {
      console.error('Error sending capability statement:', error);
      alert('Failed to send capability statement. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm overflow-y-auto">
      <div className="relative w-full max-w-2xl bg-white dark:bg-slate-900 rounded-xl shadow-xl">
        <div className="p-8 max-h-[85vh] overflow-y-auto">
          {/* Header */}
          <div className="text-center mb-8">
            <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
              <FileText className="w-6 h-6 text-blue-600 dark:text-blue-400" />
            </div>
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
              GALAX AI Capability Statement
            </h2>
            <p className="text-gray-600 dark:text-gray-400">
              Learn how we can support your agency's mission
            </p>
          </div>

          {/* Quick Stats */}
          <div className="grid grid-cols-3 gap-4 mb-8">
            {[
              { label: 'FedRAMP Status', value: 'High Impact' },
              { label: 'Contract Value', value: '$250M+' },
              { label: 'Agency Partners', value: '45+' }
            ].map((stat) => (
              <div key={stat.label} className="text-center p-4 bg-gray-50 dark:bg-slate-800 rounded-lg">
                <div className="font-bold text-gray-900 dark:text-white">
                  {stat.value}
                </div>
                <div className="text-sm text-gray-600 dark:text-gray-400">
                  {stat.label}
                </div>
              </div>
            ))}
          </div>

          {/* Past Performance */}
          <div className="mb-8 bg-gradient-to-br from-green-50 to-emerald-50 dark:from-green-900/20 dark:to-emerald-900/20 rounded-xl p-6">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
              Past Performance
            </h3>
            <div className="grid gap-4">
              {[
                {
                  agency: 'FEMA',
                  project: 'Disaster Response AI Platform',
                  impact: 'Reduced resource deployment time by 30%',
                  metrics: { efficiency: '+30%', coverage: '24/7', response: '-45min' }
                },
                {
                  agency: 'Department of Transportation',
                  project: 'Traffic Analytics System',
                  impact: 'Decreased congestion by 25%',
                  metrics: { congestion: '-25%', accuracy: '98%', savings: '$2.1M' }
                },
                {
                  agency: 'State Health Department',
                  project: 'Vaccine Distribution Platform',
                  impact: 'Improved distribution efficiency by 40%',
                  metrics: { efficiency: '+40%', coverage: '95%', time: '-60%' }
                }
              ].map((project) => (
                <div key={project.agency} className="bg-white/50 dark:bg-slate-900/50 rounded-lg p-4">
                  <div className="flex justify-between mb-2">
                    <h4 className="font-medium text-gray-900 dark:text-white">
                      {project.agency}
                    </h4>
                    <div className="text-sm text-green-600 dark:text-green-400 font-medium">
                      Success Story
                    </div>
                  </div>
                  <p className="text-sm text-gray-600 dark:text-gray-400 mb-3">
                    {project.impact}
                  </p>
                  <div className="grid grid-cols-3 gap-2">
                    {Object.entries(project.metrics).map(([key, value]) => (
                      <div key={key} className="text-center bg-green-100/50 dark:bg-green-900/20 rounded-lg p-2">
                        <div className="text-sm font-medium text-green-600 dark:text-green-400">
                          {value}
                        </div>
                        <div className="text-xs text-gray-600 dark:text-gray-400 capitalize">
                          {key}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Differentiators */}
          <div className="mb-8 bg-gradient-to-br from-blue-50 to-indigo-50 dark:from-blue-900/20 dark:to-indigo-900/20 rounded-xl p-6">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
              Key Differentiators
            </h3>
            <div className="grid gap-4">
              {[
                {
                  title: 'Agile Talent Network',
                  description: 'Scalable solutions leveraging top AI talent worldwide.',
                  highlight: '200+ AI experts'
                },
                {
                  title: 'Regulatory Compliance',
                  description: 'Fully compliant with Section 508, NIST 800-171, and FedRAMP standards.',
                  highlight: 'IL5/IL6 Certified'
                },
                {
                  title: 'Mission-Focused Innovation',
                  description: 'Custom AI systems designed specifically for government and military operations.',
                  highlight: '45+ agencies served'
                }
              ].map((diff) => (
                <div key={diff.title} className="flex items-start">
                  <div className="w-1.5 h-1.5 rounded-full bg-blue-500 dark:bg-blue-400 mt-2 mr-3 flex-shrink-0" />
                  <div>
                    <div className="flex items-center gap-3">
                      <h4 className="font-medium text-gray-900 dark:text-white">
                        {diff.title}
                      </h4>
                      <span className="text-xs font-medium text-blue-600 dark:text-blue-400 bg-blue-100 dark:bg-blue-900/30 px-2 py-1 rounded-full">
                        {diff.highlight}
                      </span>
                    </div>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      {diff.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Core Competencies */}
          <div className="mb-8 bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-6">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
              Core Competencies
            </h3>
            <div className="grid gap-4">
              {[
                {
                  title: 'AI-Driven Security',
                  description: 'Advanced threat detection, predictive analytics, and cyber defense tools compliant with DoD frameworks.'
                },
                {
                  title: 'Operational Efficiency',
                  description: 'Intelligent automation and predictive models to streamline decision-making and resource allocation.'
                },
                {
                  title: 'Custom Software Development',
                  description: 'Agile development of scalable, mission-focused applications integrated with legacy systems.'
                },
                {
                  title: 'Data Management',
                  description: 'Secure data aggregation, visualization, and actionable insights for real-time operations.'
                }
              ].map((competency) => (
                <div key={competency.title} className="flex items-start">
                  <div className="w-1.5 h-1.5 rounded-full bg-blue-500 dark:bg-blue-400 mt-2 mr-3 flex-shrink-0" />
                  <div>
                    <h4 className="font-medium text-gray-900 dark:text-white">
                      {competency.title}
                    </h4>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      {competency.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Certifications Section */}
          <div className="mb-8 bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-6">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
              Certifications and Compliance
            </h3>
            <div className="grid md:grid-cols-2 gap-4">
              {[
                { name: 'ISO 9001', description: 'Quality Management System' },
                { name: 'ISO 27001', description: 'Information Security Management' },
                { name: 'FedRAMP Moderate', description: 'Cloud Services Authorization' },
                { name: 'Section 508', description: 'Accessibility Compliance' },
                { name: 'NIST 800-171', description: 'Cybersecurity Compliance' },
                { name: 'CMMC Level 3', description: 'Cybersecurity Maturity' }
              ].map((cert) => (
                <div key={cert.name} className="flex items-start">
                  <div className="w-1.5 h-1.5 rounded-full bg-blue-500 dark:bg-blue-400 mt-2 mr-3 flex-shrink-0" />
                  <div>
                    <h4 className="font-medium text-gray-900 dark:text-white">
                      {cert.name}
                    </h4>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      {cert.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Corporate Data Section */}
          <div className="mb-8 bg-gradient-to-br from-violet-50 to-purple-50 dark:from-violet-900/20 dark:to-purple-900/20 rounded-xl p-6">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
              Corporate Data
            </h3>
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <div className="mb-4">
                  <div className="text-sm text-gray-600 dark:text-gray-400">DUNS Number</div>
                  <div className="font-mono text-lg font-medium text-gray-900 dark:text-white">123456789</div>
                </div>
                <div>
                  <div className="text-sm text-gray-600 dark:text-gray-400">CAGE Code</div>
                  <div className="font-mono text-lg font-medium text-gray-900 dark:text-white">ABCDE</div>
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-600 dark:text-gray-400 mb-2">NAICS Codes</div>
                <div className="space-y-2">
                  {[
                    { code: '541511', desc: 'Custom Computer Programming Services' },
                    { code: '541512', desc: 'Computer Systems Design Services' },
                    { code: '541715', desc: 'R&D in Physical, Engineering, and Life Sciences' }
                  ].map((naics) => (
                    <div key={naics.code} className="flex items-start">
                      <code className="font-mono text-sm text-blue-600 dark:text-blue-400 min-w-[80px]">
                        {naics.code}
                      </code>
                      <span className="text-sm text-gray-600 dark:text-gray-400 ml-4">
                        {naics.desc}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Call to Action Section */}
          <div className="mb-8 bg-gradient-to-br from-green-50 to-emerald-50 dark:from-green-900/20 dark:to-emerald-900/20 rounded-xl p-8 text-center">
            <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-4">
              Ready to Transform Your Mission?
            </h3>
            <p className="text-gray-600 dark:text-gray-400 mb-6 max-w-xl mx-auto">
              Contact GALAX AI Studios today to learn how our AI solutions can help your agency achieve its goals more efficiently and securely.
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <Button
                variant="primary"
                size="lg"
                icon={Mail}
                onClick={() => setShowForm(true)}
              >
                Request Information
              </Button>
              <Button
                variant="outline"
                size="lg"
                icon={Phone}
                onClick={() => window.location.href = 'tel:+1234567890'}
              >
                Schedule Call
              </Button>
            </div>
          </div>

          {showForm ? (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Full Name
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700"
                  required
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Agency/Department
                </label>
                <input
                  type="text"
                  value={formData.agency}
                  onChange={(e) => setFormData({ ...formData, agency: e.target.value })}
                  className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700"
                  required
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Government Email
                  </label>
                  <input
                    type="email"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    value={formData.phone}
                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                    className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700"
                    required
                  />
                </div>
              </div>

              <Button
                type="submit"
                variant="primary"
                size="lg"
                icon={ArrowRight}
                className="w-full"
                loading={loading}
              >
                Send Capability Statement
              </Button>
            </form>
          ) : (
            <div className="space-y-4">
              <Button
                variant="primary"
                size="lg"
                icon={Download}
                className="w-full"
                onClick={handleDirectDownload}
              >
                Download Now
              </Button>
              
              <div className="relative text-center">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-200 dark:border-gray-700" />
                </div>
                <div className="relative">
                  <span className="px-2 text-sm text-gray-500 bg-white dark:bg-slate-900">
                    or
                  </span>
                </div>
              </div>

              <Button
                variant="outline"
                size="lg"
                icon={Mail}
                className="w-full"
                onClick={() => setShowForm(true)}
              >
                Email Me a Copy
              </Button>
            </div>
          )}

          {/* Trust Indicators */}
          <div className="mt-8 flex items-center justify-center space-x-6 text-sm text-gray-500">
            <div className="flex items-center">
              <Shield className="w-4 h-4 text-green-500 mr-1" />
              Secure & Confidential
            </div>
            <div className="flex items-center">
              <Phone className="w-4 h-4 text-blue-500 mr-1" />
              24/7 Support
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}