import React from 'react';
import { Shield, Scale, FileText, Clock, Users, Lock } from 'lucide-react';

const sections = [
  {
    title: 'Terms of Use',
    icon: Scale,
    content: [
      'By accessing and using GALAX AI, you agree to these terms',
      'You must be at least 18 years old to use our services',
      'You are responsible for maintaining account security',
      'Usage must comply with all applicable laws and regulations',
      'Prohibited activities will result in account termination'
    ]
  },
  {
    title: 'Subscription Terms',
    icon: FileText,
    content: [
      'Subscription fees are billed monthly or annually',
      '14-day free trial available for new users',
      'Cancel anytime - no long-term commitments',
      'Refunds available within 30 days of purchase',
      'Usage limits apply based on subscription tier'
    ]
  },
  {
    title: 'Data & Privacy',
    icon: Shield,
    content: [
      'We protect your data with enterprise-grade security',
      'Data processing complies with GDPR and CCPA',
      'You retain ownership of your content',
      'We do not sell your personal information',
      'Data backups are encrypted and secure'
    ]
  },
  {
    title: 'Intellectual Property',
    icon: Lock,
    content: [
      'You retain rights to your generated content',
      'Our platform and tools are protected by copyright',
      'Proper attribution required for public usage',
      'API usage subject to additional terms',
      'Respect third-party intellectual property'
    ]
  },
  {
    title: 'User Guidelines',
    icon: Users,
    content: [
      'Respect other users and their content',
      'No unauthorized automated access',
      'Report violations and abusive behavior',
      'Keep account credentials confidential',
      'Follow community guidelines'
    ]
  }
];

export default function Terms() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-24">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <Scale className="w-6 h-6 text-blue-600 dark:text-blue-400" />
          </div>
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Terms of Service
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Please read these terms carefully before using GALAX AI
          </p>
          <div className="mt-6 inline-flex items-center text-sm text-gray-500 dark:text-gray-400">
            <Clock className="w-4 h-4 mr-1" />
            Last updated: {new Date().toLocaleDateString()}
          </div>
        </div>

        {/* Main Content */}
        <div className="space-y-12 mb-16">
          {sections.map((section) => {
            const Icon = section.icon;
            return (
              <div
                key={section.title}
                className="bg-white dark:bg-slate-900 rounded-xl p-6 shadow-lg border border-gray-200/50 dark:border-gray-800/50"
              >
                <div className="flex items-center mb-6">
                  <div className="p-2 rounded-lg bg-blue-50 dark:bg-blue-900/20">
                    <Icon className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                  </div>
                  <h2 className="ml-4 text-xl font-semibold text-gray-900 dark:text-white">
                    {section.title}
                  </h2>
                </div>
                <ul className="space-y-3">
                  {section.content.map((item, index) => (
                    <li
                      key={index}
                      className="flex items-start"
                    >
                      <div className="w-1.5 h-1.5 rounded-full bg-blue-500 dark:bg-blue-400 mt-2 mr-3 flex-shrink-0" />
                      <span className="text-gray-600 dark:text-gray-300">
                        {item}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>

        {/* Contact Section */}
        <div className="bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-8 text-center mb-16">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
            Questions About Our Terms?
          </h3>
          <p className="text-gray-600 dark:text-gray-300 mb-6">
            Our legal team is here to help you understand our terms and conditions.
          </p>
          <div className="text-gray-600 dark:text-gray-300 mb-6 space-y-4">
            <div>
              <strong>Utah Headquarters:</strong><br />
              GALAX Studios<br />
              10718 South Beckstead, Suite 200<br />
              South Jordan, Utah 84095
            </div>
            <div>
              <strong>Puerto Rico Office:</strong><br />
              GALAX Studios<br />
              1225 Avenida Juan Ponce de León<br />
              San Juan, 00907, Puerto Rico
            </div>
          </div>
          <a
            href="mailto:legal@galaxstudios.com"
            className="inline-flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Contact Legal Team
          </a>
        </div>

        {/* Footer Note */}
        <div className="text-center text-sm text-gray-500 dark:text-gray-400 mb-8">
          <p>
            These terms of service are effective as of {new Date().toLocaleDateString()} and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.
          </p>
          <p className="mt-2">
            We reserve the right to update or change our Terms of Service at any time and you should check these Terms of Service periodically.
          </p>
        </div>
      </div>
    </div>
  );
}