import React from 'react';
import { MapPin, Mountain, Sun, Building2 } from 'lucide-react';

const features = [
  {
    icon: Mountain,
    title: 'Silicon Slopes',
    description: 'Part of Utah\'s thriving tech ecosystem'
  },
  {
    icon: Building2,
    title: 'Innovation Hub',
    description: 'Located in the heart of Utah\'s tech corridor'
  },
  {
    icon: Sun,
    title: 'Quality of Life',
    description: 'World-class outdoor recreation and urban amenities'
  }
];

export default function AboutLocation() {
  return (
    <div className="py-24 bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <MapPin className="w-6 h-6 text-blue-600 dark:text-blue-400" />
          </div>
          
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            Our Utah Home
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            Proudly headquartered in Utah's Silicon Slopes, where innovation meets the mountains
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {features.map((feature) => (
            <div
              key={feature.title}
              className="bg-white dark:bg-slate-900 rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-100 dark:border-gray-800"
            >
              <feature.icon className="w-8 h-8 text-blue-600 dark:text-blue-400 mb-4" />
              <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">
                {feature.title}
              </h3>
              <p className="text-gray-600 dark:text-gray-400">
                {feature.description}
              </p>
            </div>
          ))}
        </div>

        <div className="aspect-video rounded-2xl overflow-hidden">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193365.00339202563!2d-111.9875267776019!3d40.76494938862429!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87523d9488d131ed%3A0x5b53b7a0484d31ca!2sSilicon%20Slopes!5e0!3m2!1sen!2sus!4v1709669755343!5m2!1sen!2sus"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </div>
    </div>
  );
}