import React from 'react';
import { Shield, Brain, Lock, FileCheck, Users, Network, Cpu, Target, Heart, FileText, Activity, ArrowRight, Star } from 'lucide-react';
import Button from '../ui/Button';
import type { GovernmentSolution, GovernmentApplication } from '../../types/government';
import { GovernmentErrorBoundary } from './ErrorBoundary';
import LoadingState from './LoadingState';
import { solutions as defaultSolutions, applications as defaultApplications } from '../../data/governmentData';

export default function GovernmentSolutionsContent() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [solutions, setSolutions] = React.useState(defaultSolutions);
  const [applications, setApplications] = React.useState(defaultApplications);
  const [error, setError] = React.useState<Error | null>(null);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      try {
        if (!defaultSolutions?.length || !defaultApplications?.length) {
          throw new Error('Government solutions data not available');
        }
        setSolutions(defaultSolutions);
        setApplications(defaultApplications);
        setIsLoading(false);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Unknown error'));
        setIsLoading(false);
      }
    }, 500);
    
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingState message="Loading government solutions..." />;
  }

  if (error) {
    throw error;
  }

  return (
    <div className="py-24 bg-white dark:bg-slate-900 relative overflow-hidden">
      {/* Background decorations */}
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />
      <div className="absolute inset-y-0 right-0 w-1/2 bg-[radial-gradient(circle_at_70%_50%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section header */}
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-violet-600 to-purple-600 dark:from-blue-400 dark:via-violet-400 dark:to-purple-400 mb-4">
            Mission-Critical AI Solutions
          </h2>
          <p className="text-lg text-black dark:text-gray-300">
            Proven track record of transforming government operations through secure AI implementation
          </p>
        </div>

        {/* Case Studies Section */}
        <div className="mb-16">
          <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-8">Success Stories</h3>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-white dark:bg-slate-900 rounded-xl p-6 border border-gray-200/50 dark:border-gray-800/50">
              <div className="flex items-center justify-between mb-4">
                <h4 className="font-semibold text-gray-900 dark:text-white">Department of Energy</h4>
                <span className="px-3 py-1 text-sm bg-green-100 dark:bg-green-900/30 text-green-600 dark:text-green-400 rounded-full">
                  Completed
                </span>
              </div>
              <p className="text-gray-600 dark:text-gray-400 mb-4">
                AI-powered infrastructure monitoring system reducing maintenance costs by 45%
              </p>
              <div className="grid grid-cols-2 gap-4">
                <div className="bg-gray-50 dark:bg-slate-800 rounded-lg p-3">
                  <div className="text-sm text-gray-500 dark:text-gray-400">Cost Savings</div>
                  <div className="font-semibold text-green-600 dark:text-green-400">45%</div>
                </div>
                <div className="bg-gray-50 dark:bg-slate-800 rounded-lg p-3">
                  <div className="text-sm text-gray-500 dark:text-gray-400">Timeline</div>
                  <div className="font-semibold text-blue-600 dark:text-blue-400">6 months</div>
                </div>
              </div>
            </div>
            <div className="bg-white dark:bg-slate-900 rounded-xl p-6 border border-gray-200/50 dark:border-gray-800/50">
              <div className="flex items-center justify-between mb-4">
                <h4 className="font-semibold text-gray-900 dark:text-white">State Healthcare Agency</h4>
                <span className="px-3 py-1 text-sm bg-green-100 dark:bg-green-900/30 text-green-600 dark:text-green-400 rounded-full">
                  Completed
                </span>
              </div>
              <p className="text-gray-600 dark:text-gray-400 mb-4">
                Predictive analytics platform improving patient outcomes by 32%
              </p>
              <div className="grid grid-cols-2 gap-4">
                <div className="bg-gray-50 dark:bg-slate-800 rounded-lg p-3">
                  <div className="text-sm text-gray-500 dark:text-gray-400">Efficiency Gain</div>
                  <div className="font-semibold text-green-600 dark:text-green-400">32%</div>
                </div>
                <div className="bg-gray-50 dark:bg-slate-800 rounded-lg p-3">
                  <div className="text-sm text-gray-500 dark:text-gray-400">Timeline</div>
                  <div className="font-semibold text-blue-600 dark:text-blue-400">4 months</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Main solutions grid */}
        <div className="grid lg:grid-cols-2 gap-4 sm:gap-8 mb-8 sm:mb-16 px-4">
          {solutions.map((solution: GovernmentSolution) => (
            <div
              key={solution.title}
              className="relative group bg-gradient-to-br from-gray-50 to-white dark:from-slate-800 dark:to-slate-900 rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-200/50 dark:border-gray-700/50"
            >
              <div className="flex items-start mb-6">
                <div className="p-3 rounded-xl bg-blue-50 dark:bg-blue-900/20">
                  <solution.icon className="w-8 h-8 text-blue-600 dark:text-blue-400" />
                </div>
                <div className="ml-4">
                  <h3 className="text-lg font-medium text-black dark:text-white">
                    {solution.title}
                  </h3>
                  <p className="text-black dark:text-gray-400">
                    {solution.description}
                  </p>
                </div>
              </div>

              <div className="space-y-4">
                {solution.features.map((feature, index) => (
                  <div
                    key={index}
                    className="flex items-center p-3 bg-white/50 dark:bg-slate-800/50 rounded-lg group-hover:bg-blue-50/50 dark:group-hover:bg-blue-900/20 transition-colors"
                  >
                    <feature.icon className="w-5 h-5 text-blue-600 dark:text-blue-400 mr-3" />
                    <span className="text-gray-700 dark:text-gray-300">{feature.text}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* Applications section */}
        <div className="bg-gray-50 dark:bg-slate-800/50 rounded-2xl p-8 backdrop-blur-sm">
          <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-8">
            Core Capabilities
          </h3>
          <div className="grid md:grid-cols-3 gap-6">
            {applications.map((app: GovernmentApplication) => (
              <div
                key={app.title}
                className="group bg-white dark:bg-slate-900 rounded-xl p-6 shadow-sm hover:shadow-md transition-all duration-300 hover:scale-[1.02] border border-gray-200/50 dark:border-gray-800/50"
              >
                <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-violet-500/5 dark:from-blue-400/5 dark:to-violet-400/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
                <h4 className="font-semibold text-gray-900 dark:text-white mb-2">
                  {app.title}
                </h4>
                <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">
                  {app.description}
                </p>
                <div className="space-y-2">
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600 dark:text-gray-400">Efficiency</span>
                    <span className="font-medium text-blue-600 dark:text-blue-400">
                      {app.progress}%
                    </span>
                  </div>
                  <div className="w-full h-2 bg-gray-100 dark:bg-gray-700 rounded-full overflow-hidden">
                    <div
                      className="h-full bg-gradient-to-r from-blue-600 to-violet-600 dark:from-blue-500 dark:to-violet-500 rounded-full transition-all duration-500"
                      style={{ width: `${app.progress}%` }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      
      {/* Urgency-Driven CTA */}
      <div className="mt-16 max-w-4xl mx-auto text-center">
        <div className="bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-2xl p-8 shadow-lg border border-blue-200/50 dark:border-blue-800/50">
          <div className="inline-block px-4 py-2 bg-amber-100 dark:bg-amber-900/30 text-amber-700 dark:text-amber-300 rounded-full text-sm font-medium mb-6 animate-pulse">
            Available for FY2024 Projects
          </div>
          
          <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Ready to Transform Your Agency?
          </h3>
          
          <p className="text-gray-600 dark:text-gray-400 mb-8 max-w-2xl mx-auto">
            Let's discuss how our proven AI solutions can help achieve your agency's mission objectives.
          </p>
          
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <Button 
              variant="primary" 
              size="lg" 
              icon={ArrowRight}
            >
              Schedule Priority Consultation
            </Button>
            <Button variant="outline" size="lg" icon={FileCheck}>
              Download FY2024 AI Playbook
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}