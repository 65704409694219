import React from 'react';
import { Brain, Book, Video, ArrowRight, Star, Users, Clock, Sparkles, Zap, Target, FileText } from 'lucide-react';
import Button from './ui/Button';

const insights = [
  {
    title: 'How Predictive AI is Redefining Venture Success',
    category: 'AI Strategy',
    readTime: '5 min read',
    views: '2.5K',
    image: 'https://images.unsplash.com/photo-1633356122544-f134324a6cee?w=800&auto=format&fit=crop',
    preview: 'Discover how our predictive AI models are transforming the venture building landscape with unprecedented accuracy.'
  },
  {
    title: 'The Role of Operators in Scaling AI Ventures',
    category: 'Leadership',
    readTime: '7 min read',
    views: '1.8K',
    image: 'https://images.unsplash.com/photo-1552664730-d307ca884978?w=800&auto=format&fit=crop',
    preview: 'Learn how elite operators drive AI venture growth and ensure sustainable success.'
  },
  {
    title: 'Enterprise AI Implementation Guide',
    category: 'Technical',
    readTime: '10 min read',
    views: '3.2K',
    image: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?w=800&auto=format&fit=crop',
    preview: 'A comprehensive guide to implementing AI solutions at enterprise scale.'
  }
];

const webinars = [
  {
    title: 'Supernova Framework Deep Dive',
    date: 'Next Session: March 25',
    duration: '60 min',
    instructor: 'Dr. Sarah Chen',
    attendees: '500+',
    topics: [
      'Predictive AI blueprints',
      'Market validation techniques',
      'Scaling strategies',
      'Success metrics'
    ]
  },
  {
    title: 'Building AI-First Ventures',
    date: 'On Demand',
    duration: '45 min',
    instructor: 'Michael Rodriguez',
    attendees: '1,200+',
    topics: [
      'AI architecture design',
      'Technical co-founding',
      'MLOps best practices',
      'Growth automation'
    ]
  }
];

export default function EducationalContent() {
  return (
    <div className="py-24 bg-gradient-to-br from-gray-50 via-white to-gray-50 dark:from-[rgb(3,3,5)] dark:via-[rgb(8,8,15)] dark:to-[rgb(3,3,5)] relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(59,130,246,0.03),rgba(0,0,0,0))]" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_80%,rgba(139,92,246,0.03),rgba(0,0,0,0))]" />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <Book className="w-6 h-6 text-blue-600 dark:text-blue-400" />
          </div>
          <h2 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            AI Venture Insights
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            Learn from our experience building successful AI ventures and stay ahead of industry trends
          </p>
        </div>

        {/* Featured Insights */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {insights.map((insight) => (
            <div
              key={insight.title}
              className="group relative bg-white dark:bg-slate-900 rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-[1.02] border border-gray-200/50 dark:border-gray-800/50"
            >
              <div className="aspect-video relative overflow-hidden">
                <img
                  src={insight.image}
                  alt={insight.title}
                  className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />
                <div className="absolute bottom-4 left-4 right-4">
                  <div className="text-sm text-white/80 mb-1">{insight.category}</div>
                  <h3 className="text-lg font-bold text-white">{insight.title}</h3>
                </div>
              </div>
              
              <div className="p-6">
                <p className="text-gray-600 dark:text-gray-400 mb-4">
                  {insight.preview}
                </p>
                
                <div className="flex items-center justify-between text-sm">
                  <div className="flex items-center text-gray-500 dark:text-gray-400">
                    <Clock className="w-4 h-4 mr-1" />
                    {insight.readTime}
                  </div>
                  <div className="flex items-center text-gray-500 dark:text-gray-400">
                    <Users className="w-4 h-4 mr-1" />
                    {insight.views} views
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Webinars Section */}
        <div className="grid md:grid-cols-2 gap-8">
          {webinars.map((webinar) => (
            <div
              key={webinar.title}
              className="group relative bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-8"
            >
              <div className="flex items-center justify-between mb-6">
                <div>
                  <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">
                    {webinar.title}
                  </h3>
                  <div className="flex items-center text-blue-600 dark:text-blue-400">
                    <Clock className="w-4 h-4 mr-1" />
                    {webinar.duration}
                  </div>
                </div>
                <div className="text-right">
                  <div className="text-sm font-medium text-gray-900 dark:text-white">
                    {webinar.date}
                  </div>
                  <div className="text-sm text-gray-600 dark:text-gray-400">
                    with {webinar.instructor}
                  </div>
                </div>
              </div>

              <div className="space-y-3 mb-6">
                {webinar.topics.map((topic) => (
                  <div key={topic} className="flex items-center text-gray-600 dark:text-gray-300">
                    <Sparkles className="w-4 h-4 text-blue-600 dark:text-blue-400 mr-2" />
                    {topic}
                  </div>
                ))}
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center text-gray-600 dark:text-gray-400">
                  <Users className="w-4 h-4 mr-1" />
                  {webinar.attendees} registered
                </div>
                <Button variant="outline" size="sm" icon={ArrowRight}>
                  Register Now
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}