import React from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import AdminStats from '../../components/admin/AdminStats';
import UserManagement from '../../components/admin/UserManagement';
import RevenueMetrics from '../../components/admin/RevenueMetrics';
import SystemHealth from '../../components/admin/SystemHealth';
import AffiliateTracking from '../../components/admin/AffiliateTracking';
import APIManagement from '../../components/admin/APIManagement';

export default function AdminDashboard() {
  return (
    <AdminLayout>
      {/* Quick Stats */}
      <div className="mb-8">
        <AdminStats />
      </div>

      {/* Main Content Grid */}
      <div className="grid lg:grid-cols-2 gap-8 mb-8">
        <div className="space-y-8">
          <RevenueMetrics />
          <UserManagement />
        </div>
        <div className="space-y-8">
          <SystemHealth />
          <AffiliateTracking />
        </div>
      </div>

      {/* API Management */}
      <div className="mb-8">
        <APIManagement />
      </div>
    </AdminLayout>
  );
}