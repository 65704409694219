import React, { useState, useEffect } from 'react';
import { Shield, Clock, Gift, ArrowRight, CheckCircle2 } from 'lucide-react';
import Button from '../components/ui/Button';
import { useRouter } from '../hooks/useRouter';
import { ROUTES } from '../utils/routes';
import { trackConversion } from '../utils/analytics';

export default function AutomationCheckout() {
  const { navigate } = useRouter();
  const [selectedPackage, setSelectedPackage] = useState(() => localStorage.getItem('selectedPackage'));
  const [selectedPrice] = useState(() => localStorage.getItem('selectedPrice') || '7.99');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [name, setName] = useState('');
  const [timeLeft, setTimeLeft] = useState(900); // 15 minutes
  const [error, setError] = useState<string | null>(null);
  const [showSuccess, setShowSuccess] = useState(false);

  const formatCardNumber = (value: string) => {
    return value
      .replace(/\s/g, '')
      .replace(/(\d{4})/g, '$1 ')
      .trim()
      .slice(0, 19);
  };

  const formatExpiry = (value: string) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d{0,2})/, '$1/$2')
      .slice(0, 5);
  };
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(t => t > 0 ? t - 1 : 0);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setShowSuccess(false);

    try {
      // Create payment intent
      const intent = await createPaymentIntent(
        parseFloat(selectedPrice),
        'card'
      );

      // Process payment
      const confirmation = await handlePayment(intent.id, 'card');

      // Activate subscription
      activateSubscription();

      // Track conversion
      trackConversion({
        type: 'purchase',
        page: ROUTES.AUTOMATION,
        data: { 
          package: selectedPackage,
          amount: selectedPrice,
          orderId: confirmation.orderId
        }
      });

      // Store user email
      localStorage.setItem('userEmail', email);
      localStorage.setItem('subscriptionActive', 'true');

      // Show success message
      setShowSuccess(true);

      // Redirect after delay
      setTimeout(() => {
        navigate(ROUTES.WORKSPACE);
      }, 2000);

    } catch (error) {
      console.error('Checkout error:', error);
      setError(error instanceof Error ? error.message : 'Payment failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-24">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Timer banner */}
        <div className="bg-red-50 dark:bg-red-900/20 p-4 rounded-xl mb-8 text-center">
          <div className="flex items-center justify-center text-red-600 dark:text-red-400">
            <Clock className="w-5 h-5 mr-2" />
            <span className="font-semibold">
              Special Launch Pricing Ends In: {formatTime(timeLeft)}
            </span>
          </div>
        </div>

        <div className="bg-white dark:bg-slate-900 rounded-xl p-8 shadow-lg">
          <div className="text-center mb-8">
            <Gift className="w-12 h-12 text-blue-600 dark:text-blue-400 mx-auto mb-4" />
            <h1 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
              Complete Your Order
            </h1>
            <p className="text-gray-600 dark:text-gray-400">
              Get instant access to your AI automation suite
            </p>
          </div>

          {/* Show error message */}
          {error && (
            <div className="mb-6 p-4 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 rounded-lg text-sm">
              {error}
            </div>
          )}

          {/* Show success message */}
          {showSuccess && (
            <div className="mb-6 p-4 bg-green-50 dark:bg-green-900/20 text-green-600 dark:text-green-400 rounded-lg text-sm">
              Payment successful! Redirecting to your workspace...
            </div>
          )}

          {/* Order summary */}
          <div className="bg-gray-50 dark:bg-slate-800 rounded-lg p-6 mb-8">
            <h3 className="font-semibold text-gray-900 dark:text-white mb-4">
              Order Summary
            </h3>
            <div className="space-y-4">
              <div className="flex justify-between">
                <span className="text-gray-600 dark:text-gray-400">
                  {selectedPackage || 'Growth'} Package
                </span>
                <span className="font-semibold text-gray-900 dark:text-white">
                  ${selectedPrice}/month
                </span>
              </div>
              <div className="flex justify-between text-green-600 dark:text-green-400">
                <span>Launch Discount</span>
                <span>-60%</span>
              </div>
              <div className="pt-4 border-t border-gray-200 dark:border-gray-700">
                <div className="flex justify-between">
                  <span className="font-semibold text-gray-900 dark:text-white">
                    Total Today
                  </span>
                  <span className="font-bold text-xl text-gray-900 dark:text-white">
                    ${selectedPrice}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Checkout form */}
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Email Address
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Card Number
              </label>
              <input
                type="text"
                value={cardNumber}
                onChange={(e) => setCardNumber(formatCardNumber(e.target.value))}
                placeholder="4242 4242 4242 4242"
                maxLength={19}
                className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800 focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Expiry Date
                </label>
                <input
                  type="text"
                  value={expiry}
                  onChange={(e) => setExpiry(formatExpiry(e.target.value))}
                  placeholder="MM/YY"
                  maxLength={5}
                  className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800 focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  CVC
                </label>
                <input
                  type="text"
                  value={cvc}
                  onChange={(e) => setCvc(e.target.value.replace(/\D/g, '').slice(0, 3))}
                  placeholder="123"
                  maxLength={3}
                  className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800 focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Cardholder Name
              </label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="John Smith"
                className="w-full px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800 focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>

            <Button
              type="submit"
              variant="primary"
              size="lg"
              icon={ArrowRight}
              className="w-full"
              loading={loading}
              disabled={!cardNumber || !expiry || !cvc || !name || loading}
            >
              Complete Purchase
            </Button>
          </form>

          {/* Trust badges */}
          <div className="mt-8 flex justify-center space-x-8 text-sm text-gray-500 dark:text-gray-400">
            <div className="flex items-center">
              <Shield className="w-4 h-4 mr-1" />
              Secure checkout
            </div>
            <div className="flex items-center">
              <CheckCircle2 className="w-4 h-4 mr-1" />
              14-day guarantee
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}