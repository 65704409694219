import React, { useState, useEffect } from 'react';
import { Play, Download, ArrowRight, CheckCircle2 } from 'lucide-react';
import Button from '../components/ui/Button';
import { useRouter } from '../hooks/useRouter';
import { ROUTES } from '../utils/routes';

// Demo workflows based on user selections
const demoWorkflows = {
  'ai-saas': {
    seed: [
      { name: 'AI Model Development', conversion: '$2.5M seed round secured' },
      { name: 'Technical Infrastructure', conversion: '8-month development saved' }
    ],
    enterprise: [
      { name: 'Enterprise AI Pipeline', conversion: '$500K ARR first client' },
      { name: 'MLOps Infrastructure', conversion: '99.9% model uptime' }
    ]
  },
  'ai-platform': {
    scale: [
      { name: 'Distributed Training', conversion: '100x processing speed' },
      { name: 'Auto-Scaling System', conversion: '$1M cloud costs saved' }
    ],
    exit: [
      { name: 'AI IP Portfolio', conversion: '$50M valuation achieved' },
      { name: 'Enterprise Contracts', conversion: '5 Fortune 500 clients' }
    ]
  }
  // Add more combinations
};

export default function AutomationDemo() {
  const [preferences, setPreferences] = useState<Record<string, string>>({});
  const [activeDemo, setActiveDemo] = useState<string | null>(null);
  const { navigate } = useRouter();

  useEffect(() => {
    const stored = localStorage.getItem('automationPreferences');
    if (stored) {
      setPreferences(JSON.parse(stored));
    }
  }, []);

  const relevantWorkflows = demoWorkflows[preferences.business]?.[preferences.goals] || [];

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-24">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            Your Personalized Automation Demo
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-400">
            See how these automations can transform your business
          </p>
        </div>

        <div className="grid lg:grid-cols-2 gap-8">
          {/* Demo viewer */}
          <div className="bg-white dark:bg-slate-900 rounded-xl p-6 shadow-lg">
            <div className="aspect-video bg-gray-100 dark:bg-slate-800 rounded-lg mb-6 flex items-center justify-center">
              {activeDemo ? (
                <video
                  className="w-full h-full rounded-lg"
                  controls
                  src={`/demos/${activeDemo}.mp4`}
                />
              ) : (
                <Play className="w-12 h-12 text-gray-400" />
              )}
            </div>
            
            <div className="space-y-4">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                {activeDemo || 'Select a workflow to preview'}
              </h3>
              <Button
                variant="primary"
                size="lg"
                icon={Download}
                className="w-full"
                disabled={!activeDemo}
              >
                Download Demo Package
              </Button>
            </div>
          </div>

          {/* Workflow list */}
          <div className="space-y-4">
            {relevantWorkflows.map((workflow) => (
              <button
                key={workflow.name}
                onClick={() => setActiveDemo(workflow.name)}
                className={`w-full p-6 rounded-xl text-left transition-all ${
                  activeDemo === workflow.name
                    ? 'bg-blue-50 dark:bg-blue-900/20 border-2 border-blue-500'
                    : 'bg-white dark:bg-slate-900 border-2 border-transparent hover:border-blue-300'
                }`}
              >
                <h3 className="font-semibold text-gray-900 dark:text-white mb-2">
                  {workflow.name}
                </h3>
                <div className="flex items-center text-green-600 dark:text-green-400">
                  <CheckCircle2 className="w-4 h-4 mr-2" />
                  {workflow.conversion}
                </div>
              </button>
            ))}

            <div className="bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-6">
              <h3 className="font-semibold text-gray-900 dark:text-white mb-2">
                Ready to Transform Your Business?
              </h3>
              <p className="text-gray-600 dark:text-gray-400 mb-4">
                Get instant access to all automation templates and start saving 40+ hours per month.
              </p>
              <Button
                variant="primary"
                size="lg"
                icon={ArrowRight}
                className="w-full"
                onClick={() => navigate(ROUTES.AUTOMATION_CHECKOUT)}
              >
                Get Started Now
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}