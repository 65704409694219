import React, { lazy, Suspense, useEffect } from 'react';
import Hero from '../components/Hero';
import HowWeWork from '../components/HowWeWork';
import WhatWeDo from '../components/WhatWeDo';
import WhyGalax from '../components/WhyGalax';
import EducationalContent from '../components/EducationalContent';
import { trackConversion } from '../utils/analytics';
import { ROUTES } from '../utils/routes';

// Lazy load non-critical components
const CustomerLogos = lazy(() => import('../components/CustomerLogos'));
const Web3Section = lazy(() => import('../components/Web3Section'));
const ImpactMetrics = lazy(() => import('../components/ImpactMetrics'));
const NovaChat = lazy(() => import('../components/nova/NovaChat'));

// Enhanced loading fallback with skeleton UI
const LoadingFallback = () => (
  <div className="w-full h-32 flex items-center justify-center bg-gradient-to-br from-gray-50 via-blue-50/10 to-gray-50 dark:from-slate-950 dark:via-blue-950/10 dark:to-slate-950">
    <div className="flex flex-col items-center space-y-4">
      <div className="w-8 h-8 border-4 border-blue-600 border-t-transparent rounded-full animate-spin"></div>
      <div className="h-2 w-24 bg-gray-200 dark:bg-gray-800 rounded animate-pulse"></div>
    </div>
  </div>
);

export default function Home() {
  useEffect(() => {
    // Track page view with enhanced data
    trackConversion({
      type: 'page_view',
      page: ROUTES.HOME,
      data: {
        referrer: document.referrer,
        viewport: {
          width: window.innerWidth,
          height: window.innerHeight
        }
      }
    });

    // Preload critical images
    const preloadImages = [
      'https://images.unsplash.com/photo-1551434678-e076c223a692',
      'https://images.unsplash.com/photo-1460925895917-afdab827c52f'
    ];
    preloadImages.forEach(url => {
      const img = new Image();
      img.src = url;
    });
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-[rgb(8,12,23)] overflow-x-hidden">
      {/* Main content */}
      <Hero />
      
      {/* Optimized section loading with IntersectionObserver */}
      <LazySection>
        <HowWeWork />
      </LazySection>
      
      <LazySection>
        <WhatWeDo />
      </LazySection>
      
      <LazySection>
        <WhyGalax />
      </LazySection>
      
      <LazySection>
        <EducationalContent />
      </LazySection>
      
      <LazySection>
        <Suspense fallback={<LoadingFallback />}>
          <CustomerLogos />
        </Suspense>
      </LazySection>
      
      <LazySection>
        <Suspense fallback={<LoadingFallback />}>
          <Web3Section />
        </Suspense>
      </LazySection>
      
      <LazySection>
        <Suspense fallback={<LoadingFallback />}>
          <ImpactMetrics />
        </Suspense>
      </LazySection>
      
      {/* Chat widget loaded last */}
      <Suspense fallback={null}>
        <NovaChat />
      </Suspense>
    </div>
  );
}

// Lazy loading section component with IntersectionObserver
const LazySection: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const sectionRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        rootMargin: '50px',
        threshold: 0.1
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div ref={sectionRef} className="transition-opacity duration-500" style={{ opacity: isVisible ? 1 : 0 }}>
      {isVisible && children}
    </div>
  );
}