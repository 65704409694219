import React from 'react';
import { Bot, Zap, ArrowRight, CheckCircle2, Clock, DollarSign } from 'lucide-react';
import Button from '../components/ui/Button';
import { useRouter } from '../hooks/useRouter';
import { ROUTES } from '../utils/routes';

const automationPackages = [
  {
    name: 'Venture Builder',
    price: '$997',
    period: '/month',
    description: 'Complete AI suite for building your startup',
    features: [
      'GPT-4 & Claude 3 API Access',
      'Custom AI Model Training',
      'Startup Tech Stack Setup',
      'AI Development Templates',
      'Weekly Strategy Calls'
    ]
  },
  {
    name: 'Scale-Up Suite',
    price: '$2,497',
    period: '/month',
    popular: true,
    description: 'Enterprise AI solutions for rapid growth',
    features: [
      'Everything in Venture Builder',
      'Custom AI Model Development',
      'Technical Co-Founder Support',
      'AI Infrastructure Setup',
      'Series A Pitch Support'
    ]
  },
  {
    name: 'Enterprise',
    price: 'Custom',
    description: 'Full-service AI venture studio partnership',
    features: [
      'Equity Partnership Available',
      'Full Technical Team',
      'AI Research Division Access',
      'Enterprise Sales Support',
      'Board Advisory Services'
    ]
  }
];

const workflowCategories = [
  {
    name: 'AI Development',
    description: 'Enterprise-grade AI model development',
    workflows: [
      'Custom GPT Model Training',
      'Computer Vision Systems',
      'NLP Pipeline Development',
      'MLOps Infrastructure'
    ]
  },
  {
    name: 'Startup Tech',
    description: 'Complete technical infrastructure setup',
    workflows: [
      'Cloud Architecture Design',
      'API Development Suite',
      'Database Schema Design',
      'Security Implementation'
    ]
  },
  {
    name: 'Growth Systems',
    description: 'AI-powered growth and scaling tools',
    workflows: [
      'AI Sales Automation',
      'Predictive Analytics',
      'Customer Journey AI',
      'Market Intelligence'
    ]
  }
];

export default function AutomationService() {
  const { navigate } = useRouter();

  const handleSubscribe = (packageName: string) => {
    // Store selected package in localStorage for checkout
    localStorage.setItem('selectedPackage', packageName);
    navigate(ROUTES.AUTOMATION_ONBOARDING);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <Bot className="w-6 h-6 text-blue-600 dark:text-blue-400" />
          </div>
          <h1 className="text-4xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-violet-600 to-purple-600 dark:from-blue-400 dark:via-violet-400 dark:to-purple-400">
            Build Your AI-First Startup
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            Launch your venture with enterprise-grade AI infrastructure and technical co-founding support.
          </p>
        </div>

        {/* ROI Stats */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {[
            { icon: Clock, value: '6 months', label: 'To Market Launch' },
            { icon: DollarSign, value: '$2.1M', label: 'Avg Seed Round' },
            { icon: Zap, value: '10x', label: 'Faster Development' }
          ].map(({ icon: Icon, value, label }) => (
            <div key={label} className="bg-white dark:bg-slate-900 rounded-xl p-6 text-center">
              <Icon className="w-8 h-8 text-blue-600 dark:text-blue-400 mx-auto mb-4" />
              <div className="text-2xl font-bold text-gray-900 dark:text-white mb-1">{value}</div>
              <div className="text-gray-600 dark:text-gray-400">{label}</div>
            </div>
          ))}
        </div>

        {/* Workflow Categories */}
        <div className="mb-16">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-8 text-center">
            Ready-to-Use Automation Templates
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {workflowCategories.map((category) => (
              <div key={category.name} className="bg-white dark:bg-slate-900 rounded-xl p-6">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                  {category.name}
                </h3>
                <p className="text-gray-600 dark:text-gray-400 mb-4">
                  {category.description}
                </p>
                <ul className="space-y-2">
                  {category.workflows.map((workflow) => (
                    <li key={workflow} className="flex items-center text-gray-700 dark:text-gray-300">
                      <CheckCircle2 className="w-4 h-4 text-green-500 mr-2" />
                      {workflow}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Pricing */}
        <div className="mb-16">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-8 text-center">
            Simple, Transparent Pricing
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {automationPackages.map((pkg) => (
              <div key={pkg.name} className={`relative bg-white dark:bg-slate-900 rounded-xl p-8 ${
                pkg.popular ? 'ring-2 ring-blue-500 dark:ring-blue-400' : ''
              }`}>
                {pkg.popular && (
                  <div className="absolute -top-4 right-4 bg-blue-500 text-white px-4 py-1 rounded-full text-sm">
                    Most Popular
                  </div>
                )}
                <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">{pkg.name}</h3>
                <div className="flex items-baseline mb-4">
                  <span className="text-3xl font-bold text-gray-900 dark:text-white">{pkg.price}</span>
                  {pkg.period && (
                    <span className="text-gray-500 dark:text-gray-400 ml-1">{pkg.period}</span>
                  )}
                </div>
                <p className="text-gray-600 dark:text-gray-400 mb-6">{pkg.description}</p>
                <ul className="space-y-3 mb-8">
                  {pkg.features.map((feature) => (
                    <li key={feature} className="flex items-center text-gray-700 dark:text-gray-300">
                      <CheckCircle2 className="w-4 h-4 text-green-500 mr-2 flex-shrink-0" />
                      {feature}
                    </li>
                  ))}
                </ul>
                <Button
                  variant={pkg.popular ? 'primary' : 'outline'}
                  size="lg"
                  icon={ArrowRight}
                  className="w-full"
                  onClick={() => handleSubscribe(pkg.name)}
                >
                  {pkg.name === 'Enterprise' ? 'Contact Sales' : 'Get Started'}
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}