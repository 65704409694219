import React, { useState, useEffect } from 'react';
import { Building2, ArrowRight, Shield, Star, Globe, Lock, Users, FileCheck, Brain, Target, DollarSign } from 'lucide-react';
import { useRouter } from '../../hooks/useRouter';
import { ROUTES } from '../../utils/routes';
import Button from '../ui/Button';
import ConsultationForm from './ConsultationForm';
import { ACHIEVEMENTS, CERTIFICATIONS, URGENCY_INDICATORS, AGENCIES } from '../../constants/government';
import CapabilityStatement from './CapabilityStatement';

export default function GovernmentHero() {
  const [showCapabilityStatement, setShowCapabilityStatement] = useState(false);
  const [showConsultationForm, setShowConsultationForm] = useState(false);
  const { navigate } = useRouter();

  return (
    <div className="relative overflow-hidden">
      {/* Professional gradient background */}
      <div className="absolute inset-0 overflow-hidden bg-gradient-to-b from-gray-50/95 to-white dark:from-slate-950/95 dark:to-slate-900">
        {/* Video Background */}
        <div className="absolute inset-0 z-0 overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-gray-50/95 to-gray-50 dark:via-slate-950/95 dark:to-slate-900 z-10">
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(59,130,246,0.05),rgba(0,0,0,0))]" />
          </div>
          <video
            autoPlay
            loop
            muted
            playsInline
            className="w-full h-full object-cover scale-105 opacity-[0.08] dark:opacity-[0.04] filter grayscale"
          >
            <source src="https://cdn.pixabay.com/vimeo/505603739/abstract-66101.mp4?width=1280&hash=f2ed9daa64ec75d2f24ec4e58f71a3cd7a786e2d" type="video/mp4" />
          </video>
        </div>
      </div>
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 z-20 pt-16 pb-32">
        <div className="text-center max-w-6xl mx-auto">
          {/* Official Agency Badge */}
          <div className="inline-flex items-center justify-center mb-12 px-6 py-3 bg-gradient-to-r from-blue-600 to-violet-600 dark:from-blue-500 dark:to-violet-500 text-white rounded-xl shadow-lg border border-white/10 backdrop-blur-sm">
            <Shield className="w-5 h-5 mr-2" />
            <span className="text-sm font-bold tracking-[0.2em] uppercase">Federal AI Solutions Provider</span>
          </div>
          
          {/* Main Heading */}
          <h1 className="text-4xl sm:text-5xl md:text-6xl font-extrabold tracking-tight mb-8 text-gray-900 dark:text-white max-w-5xl mx-auto leading-[1.1]">
            Secure AI Solutions for Federal, State & Local Agencies
          </h1>

          {/* Subtitle */}
          <p className="text-xl sm:text-2xl text-gray-600 dark:text-gray-300 mb-16 max-w-3xl mx-auto leading-relaxed">
            Mission-critical AI solutions with FedRAMP High authorization and proven deployments across defense and civilian agencies.
          </p>

          {/* Authority Indicators */}
          <div className="grid sm:grid-cols-3 gap-8 max-w-5xl mx-auto mb-16">
            {[
              { 
                label: 'FedRAMP High', 
                icon: Shield, 
                description: 'Highest Level Authorization',
                badge: 'JAB P-ATO',
                status: 'Active'
              },
              { 
                label: 'IL5/IL6', 
                icon: Lock, 
                description: 'Defense Information Systems',
                badge: 'DISA Approved',
                status: 'Authorized'
              },
              { 
                label: 'CMMC Level 3', 
                icon: FileCheck, 
                description: 'Advanced Cybersecurity',
                badge: 'Certified',
                status: 'Compliant'
              }
            ].map((badge) => (
              <div
                key={badge.label}
                className="group relative bg-white/90 dark:bg-slate-900/90 backdrop-blur-sm rounded-xl p-6
                  border border-gray-200/50 dark:border-gray-800/50
                  shadow-lg hover:shadow-xl
                  transform hover:scale-[1.02] transition-all duration-300"
              >
                {/* Top Banner */}
                <div className="absolute top-0 left-0 right-0 h-1 bg-gradient-to-r from-blue-600 to-violet-600 dark:from-blue-500 dark:to-violet-500" />
                
                <div className="relative">
                  {/* Badge & Status */}
                  <div className="flex items-center justify-between mb-6">
                    <div className="px-3 py-1 text-xs font-bold tracking-wider uppercase bg-blue-600 dark:bg-blue-500 text-white rounded">
                      {badge.badge}
                    </div>
                    <div className="flex items-center">
                      <div className="w-2 h-2 bg-green-500 rounded-full mr-2">
                        <div className="absolute w-2 h-2 bg-green-500 rounded-full animate-ping" />
                      </div>
                      <span className="text-xs font-medium text-green-600 dark:text-green-400">
                        {badge.status}
                      </span>
                    </div>
                  </div>
                  
                  {/* Icon & Title */}
                  <div className="flex items-center mb-4">
                    <div className="p-3 rounded-lg bg-blue-50 dark:bg-blue-900/20 mr-4">
                      <badge.icon className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                    </div>
                    <div>
                      <h3 className="text-lg font-bold text-gray-900 dark:text-white">
                        {badge.label}
                      </h3>
                    </div>
                  </div>
                  
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    {badge.description}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* Official Agency Badge */}


          {/* Rest of the component remains the same */}
          {/* ... */}
          {/* CTA Buttons */}
          <div className="flex flex-col sm:flex-row justify-center gap-4 mt-12">
            <Button
              variant="primary"
              size="lg"
              icon={ArrowRight}
              onClick={() => setShowConsultationForm(true)}
              className="px-8 py-4 text-lg font-semibold bg-blue-600 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600"
            >
              Schedule Consultation
            </Button>
            <Button
              variant="outline"
              size="lg"
              icon={FileCheck}
              onClick={() => navigate(ROUTES.GOVERNMENT_CAPABILITY)}
              className="px-8 py-4 text-lg font-semibold"
            >
              View Capability Statement
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}