import React from 'react';
import GalaxLogo from './GalaxLogo';
import { useNavigateToHome } from '../../hooks/useNavigateToHome';

export default function Logo() {
  const navigateToHome = useNavigateToHome();

  return (
    <div className="flex items-center space-x-1.5">
      <button
        onClick={navigateToHome}
        className="hover:opacity-90 transition-all duration-300 transform hover:scale-105 cursor-pointer"
        aria-label="Go to home page"
      >
        <GalaxLogo />
      </button>
    </div>
  );
}