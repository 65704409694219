import React from 'react';
import { Target, Brain, Shield, Globe, Heart, Star, Users, Rocket, CheckCircle2 } from 'lucide-react';

const values = [
  {
    name: 'Our Mission',
    icon: Target,
    description: 'To revolutionize how businesses harness AI by providing accessible, ethical, and powerful solutions that drive meaningful impact.',
    benefits: [
      'Democratize AI access',
      'Drive innovation',
      'Enable transformation'
    ],
    gradient: 'from-blue-600 to-violet-600 dark:from-blue-400 dark:to-violet-400'
  },
  {
    name: 'Our Vision',
    icon: Brain,
    description: 'To be the world\'s most trusted partner in AI venture development, known for excellence, innovation, and ethical leadership.',
    benefits: [
      'Global AI leadership',
      'Ethical innovation',
      'Sustainable growth'
    ],
    gradient: 'from-violet-600 to-purple-600 dark:from-violet-400 dark:to-purple-400'
  },
  {
    name: 'Core Values',
    icon: Heart,
    description: 'Our values shape how we innovate and create meaningful solutions that transform industries.',
    benefits: [
      'Integrity First',
      'Customer Success',
      'Continuous Innovation'
    ],
    gradient: 'from-purple-600 to-pink-600 dark:from-purple-400 dark:to-pink-400'
  }
];

const achievements = [
  {
    icon: Rocket,
    value: '45+',
    label: 'Ventures Launched',
    description: 'Successful AI ventures built and scaled'
  },
  {
    icon: Users,
    value: '500+',
    label: 'Enterprise Clients',
    description: 'Global organizations transformed'
  },
  {
    icon: Star,
    value: '98%',
    label: 'Success Rate',
    description: 'Project success rate across ventures'
  },
  {
    icon: Globe,
    value: '24/7',
    label: 'Global Support',
    description: 'Round-the-clock expert assistance'
  }
];

const timeline = [
  {
    year: '2009',
    title: 'Founded as Ufluid Media',
    description: 'Started in South Jordan, UT as a pioneering force in digital transformation',
    icon: Brain,
    gradient: 'from-blue-400 to-violet-400'
  },
  {
    year: '2015',
    title: 'Enterprise Solutions Launch',
    description: 'Expanded into enterprise AI consulting and custom development',
    icon: Rocket,
    gradient: 'from-violet-400 to-purple-400'
  },
  {
    year: '2020',
    title: 'AI Innovation Hub',
    description: 'Launched AI research division and venture studio',
    icon: Star,
    gradient: 'from-purple-400 to-pink-400'
  },
  {
    year: '2024',
    title: 'GALAX AI Evolution',
    description: 'Rebranded to reflect our expanded vision of building the future\'s most powerful AI venture tools',
    icon: Globe,
    gradient: 'from-pink-400 to-rose-400'
  }
];

export default function AboutMission() {
  return (
    <div className="py-32 bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 relative overflow-hidden">
      {/* Background decorations */}
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />
      <div className="absolute inset-y-0 right-0 w-1/2 bg-[radial-gradient(circle_at_70%_50%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Mission & Vision Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-24">
          {values.map((value) => (
            <div
              key={value.name}
              className="group relative bg-white/90 dark:bg-slate-900/90 backdrop-blur-sm rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-[1.02] border border-gray-200/50 dark:border-gray-800/50"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-violet-500/5 dark:from-blue-400/5 dark:to-violet-400/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
              
              <div className="relative">
                <div className={`w-16 h-16 rounded-xl bg-gradient-to-br ${value.gradient} bg-clip-text p-4 mb-6 group-hover:scale-110 transition-transform`}>
                  <value.icon className="w-full h-full text-transparent" />
                </div>

                <h3 className={`text-2xl font-bold bg-gradient-to-br ${value.gradient} bg-clip-text text-transparent mb-4`}>
                  {value.name}
                </h3>
                
                <p className="text-gray-600 dark:text-gray-400 mb-6">
                  {value.description}
                </p>

                <ul className="space-y-3">
                  {value.benefits.map((benefit) => (
                    <li key={benefit} className="flex items-center text-gray-700 dark:text-gray-300">
                      <CheckCircle2 className={`w-5 h-5 bg-gradient-to-br ${value.gradient} bg-clip-text text-transparent mr-2 flex-shrink-0`} />
                      {benefit}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        {/* Timeline Section */}
        <div className="mb-24">
          <div className="text-center mb-16">
            <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
              <Globe className="w-6 h-6 text-blue-600 dark:text-blue-400" />
            </div>
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
              Our Journey
            </h2>
            <p className="text-lg text-gray-600 dark:text-gray-300">
              Building the future of AI, one milestone at a time
            </p>
          </div>

          <div className="relative">
            {/* Timeline line */}
            <div className="absolute top-1/2 left-0 right-0 h-0.5 bg-gradient-to-r from-blue-200 via-violet-200 to-purple-200 dark:from-blue-800/30 dark:via-violet-800/30 dark:to-purple-800/30 transform -translate-y-1/2" />
            
            <div className="grid md:grid-cols-4 gap-8">
              {timeline.map((milestone, index) => (
                <div key={milestone.year} className="relative">
                  {/* Timeline dot */}
                  <div className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-4 h-4 rounded-full bg-gradient-to-br ${milestone.gradient}`}>
                    <div className="absolute inset-0 rounded-full animate-ping-slow opacity-75 bg-gradient-to-br from-blue-400 to-violet-400" />
                  </div>
                  
                  {/* Content card */}
                  <div className={`relative pt-8 text-center transform transition-transform duration-300 hover:scale-105 ${
                    index % 2 === 0 ? 'mt-8' : '-mt-32'
                  }`}>
                    <div className={`w-12 h-12 mx-auto rounded-xl bg-gradient-to-br ${milestone.gradient} p-3 mb-4`}>
                      <milestone.icon className="w-full h-full text-white" />
                    </div>
                    
                    <div className={`text-2xl font-bold bg-gradient-to-br ${milestone.gradient} bg-clip-text text-transparent mb-2`}>
                      {milestone.year}
                    </div>
                    
                    <h3 className="font-semibold text-gray-900 dark:text-white mb-2">
                      {milestone.title}
                    </h3>
                    
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      {milestone.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Achievements Grid */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          {achievements.map((achievement) => {
            const Icon = achievement.icon;
            return (
              <div key={achievement.label} className="group relative bg-white/80 dark:bg-slate-900/80 backdrop-blur-sm rounded-xl p-6 border border-gray-200/50 dark:border-gray-800/50 hover:border-blue-500/30 dark:hover:border-blue-400/30 transition-all duration-300 hover:scale-105">
                <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-violet-500/5 dark:from-blue-400/5 dark:to-violet-400/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
                
                <div className="relative">
                  <Icon className="w-8 h-8 text-blue-600 dark:text-blue-400 mb-4 group-hover:scale-110 transition-transform" />
                  
                  <div className="text-3xl font-bold bg-gradient-to-br from-blue-600 to-violet-600 dark:from-blue-400 dark:to-violet-400 bg-clip-text text-transparent mb-2">
                    {achievement.value}
                  </div>
                  
                  <div className="font-medium text-gray-900 dark:text-white mb-1">
                    {achievement.label}
                  </div>
                  
                  <div className="text-sm text-gray-600 dark:text-gray-400">
                    {achievement.description}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}