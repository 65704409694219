import React, { useEffect, useState } from 'react';
import { useRouter } from './hooks/useRouter';
import { ErrorBoundary } from './components/ErrorBoundary';
import { NovaProvider } from './components/nova/NovaContext';
import { ChatbotWidget } from './components/chatbot';
import { ROUTES } from './utils/routes';
import AssessmentFunnel from './pages/AssessmentFunnel';
import CheckoutSuccess from './pages/CheckoutSuccess';
import { checkAuth } from './utils/auth';
import ToolsDirectory from './pages/ToolsDirectory';
import Home from './pages/Home';
import Impact from './pages/Impact';
import CaseStudies from './pages/CaseStudies';
import InvestorNetwork from './pages/InvestorNetwork';
import DealFlow from './pages/DealFlow';
import VentureMarketplace from './pages/VentureMarketplace';
import APIReference from './pages/APIReference';
import Contact from './pages/Contact';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Header from './components/Header';
import Tools from './pages/Tools';
import Dashboard from './pages/Dashboard';
import AIStarterPack from './components/products/AIStarterPack';
import Pricing from './pages/Pricing';
import AutomationService from './pages/AutomationService';
import AutomationOnboarding from './pages/AutomationOnboarding';
import AutomationDemo from './pages/AutomationDemo';
import AutomationCheckout from './pages/AutomationCheckout';
import VentureBuilder from './pages/VentureBuilder';
import AISoftwareDev from './pages/AISoftwareDev';
import StartAIVenture from './pages/StartAIVenture';
import CapabilityStatement from './pages/government/CapabilityStatement';
import Enterprise from './pages/Enterprise';
import ToolPage from './pages/ToolPage';
import Government from './pages/Government';
import GovernmentSolutions from './pages/GovernmentSolutions';
import GovernmentAssessment from './pages/GovernmentAssessment';
import GovernmentConsultation from './pages/GovernmentConsultation';
import GrantsDirectory from './pages/government/GrantsDirectory';
import Signup from './pages/Signup';
import Terms from './pages/Terms';
import Security from './pages/Security';
import Status from './pages/Status';
import Privacy from './pages/Privacy';
import About from './pages/About';
import ChoosePath from './pages/ChoosePath';
import Footer from './components/Footer';
import SocialProof from './components/conversion/SocialProof';
import NovaChat from './components/nova/NovaChat';
import { trackConversion } from './utils/analytics';
import AdminLogin from './pages/admin/Login';
import AdminDashboard from './pages/admin/Dashboard';
import AdminUsers from './pages/admin/Users';
import CareerCalculator from './pages/CareerCalculator';

function App() {
  const { currentPath, navigate } = useRouter();
  const [key, setKey] = useState(0);

  useEffect(() => {
    // Track page views
    trackConversion({
      type: 'page_view' as const,
      page: currentPath,
      data: {
        url: window.location.href,
        title: document.title
      }
    });
  }, [currentPath]);

  useEffect(() => {
    // Force re-render when route changes
    setKey(prev => prev + 1);
  }, [currentPath]);

  return (
    <ErrorBoundary>
      <NovaProvider>
        <div key={key} className="min-h-screen bg-gray-50 dark:bg-slate-950">
          <Header />
          {currentPath === ROUTES.HOME ? (
            <Home />
          ) : currentPath === ROUTES.TOOLS ? (
            <Tools />
          ) : currentPath === ROUTES.TOOLS_DIRECTORY ? (
            <ToolsDirectory />
          ) : currentPath === ROUTES.ASSESSMENT ? (
            <AssessmentFunnel />
          ) : currentPath === ROUTES.LOGIN ? (
            <Login />
          ) : currentPath === ROUTES.STARTER_PACK ? (
            <AIStarterPack />
          ) : currentPath === ROUTES.WORKSPACE || currentPath.startsWith('/workspace/') ? (
            <Workspace />
          ) : currentPath === ROUTES.ADMIN.LOGIN ? (
            <AdminLogin />
          ) : currentPath === ROUTES.ADMIN.USERS ? (
            <AdminUsers />
          ) : currentPath === ROUTES.ADMIN.DASHBOARD ? (
            <AdminDashboard />
          ) : currentPath === ROUTES.PRICING ? (
            <Pricing />
          ) : currentPath === ROUTES.AUTOMATION ? (
            <AutomationService />
          ) : currentPath === ROUTES.AUTOMATION_ONBOARDING ? (
            <AutomationOnboarding />
          ) : currentPath === ROUTES.AUTOMATION_DEMO ? (
            <AutomationDemo />
          ) : currentPath === ROUTES.AUTOMATION_CHECKOUT ? (
            <AutomationCheckout />
          ) : currentPath === '/checkout/success' ? (
            <CheckoutSuccess />
          ) : currentPath === ROUTES.GOVERNMENT ? (
            <Government />
          ) : currentPath === ROUTES.GOVERNMENT_SOLUTIONS ? (
            <Government />
          ) : currentPath === ROUTES.GOVERNMENT_ASSESSMENT ? (
            <GovernmentAssessment />
          ) : currentPath === ROUTES.GOVERNMENT_CONSULTATION ? (
            <GovernmentConsultation />
          ) : currentPath === ROUTES.GOVERNMENT_GRANTS ? (
            <GrantsDirectory />
          ) : currentPath === ROUTES.GOVERNMENT_CAPABILITY ? (
            <CapabilityStatement />
          ) : currentPath === ROUTES.VENTURE_BUILDER ? (
            <VentureBuilder />
          ) : currentPath === ROUTES.CAREER_CALCULATOR ? (
            <CareerCalculator />
          ) : currentPath === ROUTES.AI_SOFTWARE_DEV ? (
            <AISoftwareDev />
          ) : currentPath === ROUTES.START_AI_VENTURE ? (
            <StartAIVenture />
          ) : currentPath === ROUTES.ENTERPRISE ? (
            <Enterprise />
          ) : currentPath.startsWith(ROUTES.TOOL) ? (
            <ToolPage tool={currentPath.split('/')[2]} />
          ) : currentPath === ROUTES.SIGNUP ? (
            <Signup />
          ) : currentPath === ROUTES.ABOUT ? (
            <About />
          ) : currentPath === ROUTES.CONTACT ? (
            <Contact />
          ) : currentPath === ROUTES.PRIVACY ? (
            <Privacy />
          ) : currentPath === ROUTES.TERMS ? (
            <Terms />
          ) : currentPath === ROUTES.SECURITY ? (
            <Security />
          ) : currentPath === ROUTES.STATUS ? (
            <Status />
          ) : currentPath === ROUTES.IMPACT ? (
            <Impact />
          ) : currentPath === ROUTES.CASE_STUDIES ? (
            <CaseStudies />
          ) : currentPath === ROUTES.INVESTOR_NETWORK ? (
            <InvestorNetwork />
          ) : currentPath === ROUTES.DEAL_FLOW ? (
            <DealFlow />
          ) : currentPath === ROUTES.VENTURE_MARKETPLACE ? (
            <VentureMarketplace />
          ) : currentPath === ROUTES.API ? (
            <APIReference />
          ) : currentPath === ROUTES.CONTACT ? (
            <Contact />
          ) : currentPath === ROUTES.CHOOSE_PLAN ? (
            <ChoosePath />
          ) : (
            <NotFound />
          )}
          <Footer />
          <SocialProof />
          <NovaChat />
        </div>
      </NovaProvider>
    </ErrorBoundary>
  );
}

export default App;