import React, { useState } from 'react';
import { Lightbulb, ChevronDown } from 'lucide-react';

interface PromptHelperProps {
  onPromptSelect: (prompt: string) => void;
}

const promptTemplates = [
  {
    category: 'Product',
    prompts: [
      'Professional product photo of [product] on white background',
      'Lifestyle photo of [product] in use',
      '[Product] flatlay with complementary items'
    ]
  },
  {
    category: 'Marketing',
    prompts: [
      'Social media banner featuring [subject]',
      'Instagram-style photo of [subject]',
      'Facebook ad creative for [product/service]'
    ]
  },
  {
    category: 'Art',
    prompts: [
      'Digital illustration of [subject] in [style]',
      'Fantasy artwork of [subject]',
      'Abstract interpretation of [concept]'
    ]
  }
];

export default function PromptHelper({ onPromptSelect }: PromptHelperProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center text-gray-600 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400"
      >
        <Lightbulb className="w-4 h-4 mr-1" />
        <span className="text-sm">Prompt Helper</span>
        <ChevronDown className="w-4 h-4 ml-1" />
      </button>

      {isOpen && (
        <div className="absolute left-0 mt-2 w-80 bg-white dark:bg-slate-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 z-10">
          <div className="p-4">
            <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-4">
              Prompt Templates
            </h3>
            <div className="space-y-4">
              {promptTemplates.map((category) => (
                <div key={category.category}>
                  <h4 className="text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider mb-2">
                    {category.category}
                  </h4>
                  <div className="space-y-2">
                    {category.prompts.map((prompt) => (
                      <button
                        key={prompt}
                        onClick={() => {
                          onPromptSelect(prompt);
                          setIsOpen(false);
                        }}
                        className="w-full text-left px-3 py-2 text-sm rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-600 dark:text-gray-300"
                      >
                        {prompt}
                      </button>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}