import React, { useState, useEffect } from 'react';
import { Clock } from 'lucide-react';

export default function UrgencyTimer() {
  const [timeLeft, setTimeLeft] = useState(3600); // 1 hour

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) return 3600;
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className="bg-red-50 dark:bg-red-900/20 p-4 rounded-lg">
      <div className="flex items-center text-red-600 dark:text-red-400">
        <Clock className="w-5 h-5 mr-2" />
        <span className="font-semibold">
          Special Launch Pricing Ends In: {formatTime(timeLeft)}
        </span>
      </div>
    </div>
  );
}