import React, { useState } from 'react';
import PricingLayout from '../components/pricing/PricingLayout';
import PricingContent from '../components/pricing/PricingContent';
import ConsultationForm from '../components/shared/ConsultationForm';
import UpgradeModal from '../components/pricing/UpgradeModal';
import EquityTiers from '../components/pricing/EquityTiers';
import ComparisonTable from '../components/pricing/ComparisonTable';

export default function Pricing() {
  const [showConsultationForm, setShowConsultationForm] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  return (
    <PricingLayout>
      {/* Main Pricing Content */}
      <PricingContent 
        onConsultation={() => setShowConsultationForm(true)}
        onUpgrade={() => setShowUpgradeModal(true)}
      />
      
      {/* Modals */}
      <ConsultationForm
        isOpen={showConsultationForm}
        onClose={() => setShowConsultationForm(false)}
      />
      <UpgradeModal
        isOpen={showUpgradeModal}
        onClose={() => setShowUpgradeModal(false)}
      />
    </PricingLayout>
  );
}