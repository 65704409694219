import React, { useState } from 'react';
import { CheckCircle2, Clock, DollarSign, TrendingUp, Users, Zap, ArrowRight } from 'lucide-react';
import Button from '../ui/Button';
import StarRating from './StarRating';
import VideoThumbnail from './VideoThumbnail';
import VideoModal from '../ui/VideoModal';
import { checkToolAccess } from '../../utils/auth';
import { useRouter } from '../../hooks/useRouter';
import { ROUTES } from '../../utils/routes';
import type { Tool } from '../../types/tools';

interface ToolCardProps {
  tool: Tool;
  videoId?: string;
}
import { trackConversion } from '../../utils/analytics';

export default function ToolCard({ tool, videoId: propVideoId }: ToolCardProps) {
  const Icon = tool.icon;
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const { navigate } = useRouter();
  const hasAccess = checkToolAccess();
  
  // Use prop videoId if provided, otherwise fall back to tool.videoId
  const videoId = propVideoId || tool.videoId;

  const handleToolAccess = () => {
    if (!hasAccess) {
      try {
        // Track conversion
        trackConversion({
          type: 'checkout_start',
          page: ROUTES.TOOLS,
          data: { tool: tool.name }
        });

        // Redirect to Stripe checkout
        window.location.href = 'https://buy.stripe.com/7sI9C76iF8LWfNS28p';
      } catch (error) {
        console.error('Error handling tool access:', error);
        // Fallback to tools directory
        window.location.href = ROUTES.TOOLS_DIRECTORY;
      }
      return;
    }
    
    try {
      // Handle tool access
      console.log('Accessing tool:', tool.name);
      
      // Track tool access
      trackConversion({
        type: 'tool_access',
        page: window.location.pathname as any,
        data: { tool: tool.name }
      });
      
      // Navigate to tool page
      navigate(`${ROUTES.TOOL}${tool.name.toLowerCase().replace(/\s+/g, '-')}`);
    } catch (error) {
      console.error('Error accessing tool:', error);
    }
  };

  return (
    <>
    <div className="group relative bg-white/80 dark:bg-slate-900/90 backdrop-blur-sm rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-[1.01] border border-gray-200/50 dark:border-gray-800/60 hover:border-blue-500/30 dark:hover:border-blue-400/30 font-[system-ui]">
      <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-violet-500/5 dark:from-blue-400/5 dark:to-violet-400/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
      
      <div className="flex items-start justify-between gap-8">
        {/* Left Column - Tool Info */}
        <div className="flex-1">
          <div className="flex items-start space-x-4 mb-6">
            <div className="p-3 rounded-xl bg-gradient-to-br from-blue-100 to-violet-100 dark:from-blue-900/30 dark:to-violet-900/30 group-hover:scale-110 transition-transform">
              <Icon className="w-6 h-6 text-blue-600 dark:text-blue-400" />
            </div>
            
            <div>
              <div className="flex items-center space-x-2">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-slate-50">
                  {tool.name}
                </h3>
                {tool.new && (
                  <span className="px-2 py-1 text-xs font-medium bg-green-100 dark:bg-green-900/30 text-green-600 dark:text-green-400 rounded-full">
                    New
                  </span>
                )}
                {tool.popular && (
                  <span className="px-2 py-1 text-xs font-medium bg-blue-100 dark:bg-blue-900/40 text-blue-600 dark:text-blue-300 rounded-full">
                    Popular
                  </span>
                )}
              </div>

              <p className="text-gray-600 dark:text-slate-300 mt-1 leading-relaxed">
                {tool.description}
              </p>
            </div>
          </div>

          {/* Features Grid */}
          <div className="grid grid-cols-2 gap-4 mb-6">
            {tool.features.map((feature, index) => (
              <div key={index} className="flex items-center text-sm text-gray-600 dark:text-slate-300 font-medium">
                <Zap className="w-4 h-4 text-blue-600 dark:text-blue-400 mr-2 flex-shrink-0" />
                {feature}
              </div>
            ))}
          </div>

          {/* ROI and Metrics */}
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div className="bg-blue-50 dark:bg-blue-900/30 rounded-lg p-3 border border-transparent dark:border-blue-800/30">
              <div className="flex items-center text-sm font-medium text-blue-700 dark:text-blue-200 mb-2">
                <DollarSign className="w-4 h-4 mr-1" />
                ROI & Savings
              </div>
              <div className="space-y-2 text-sm">
                <div className="flex items-center">
                  <Clock className="w-4 h-4 text-green-500 mr-1" />
                  40+ hours saved/month
                </div>
                <div className="flex items-center">
                  <TrendingUp className="w-4 h-4 text-green-500 mr-1" />
                  $2,000+ value/month
                </div>
              </div>
            </div>

            {tool.popular && (
              <div className="bg-violet-50 dark:bg-violet-900/30 rounded-lg p-3 border border-transparent dark:border-violet-800/30">
                <div className="flex items-center text-sm font-medium text-violet-700 dark:text-violet-200 mb-2">
                  <Users className="w-4 h-4 mr-1" />
                  Social Proof
                </div>
                <div className="space-y-2 text-sm">
                  <div className="flex items-center">
                    <CheckCircle2 className="w-4 h-4 text-green-500 mr-1" />
                    50+ companies joined today
                  </div>
                  <div className="flex items-center">
                    <CheckCircle2 className="w-4 h-4 text-green-500 mr-1" />
                    95% satisfaction rate
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Right Column - Video & CTA */}
        <div className="flex flex-col items-end space-y-6">
          {videoId && (
            <VideoThumbnail 
              videoId={videoId} 
              title={tool.name} 
              onClick={() => setIsVideoOpen(true)}
            />
          )}

          <div className="text-right">
            <div className="text-sm font-medium text-blue-600 dark:text-blue-400 mb-2">
              {tool.price}
            </div>
            <StarRating rating={tool.rating} reviewCount={tool.reviewCount} />
          </div>

          <div className="text-right">
            <Button
              variant={tool.popular ? 'primary' : 'outline'}
              size="lg"
              icon={ArrowRight}
              onClick={handleToolAccess}
              className={tool.popular ? 'animate-pulse' : ''}
            >
              {tool.price.toLowerCase().includes('learn more')
                ? 'Learn More'
                : !hasAccess 
                ? (tool.popular ? 'Get Access Now' : 'Start Free Trial')
                : 'Launch Tool'
              }
            </Button>
            <p className="text-xs text-gray-500 dark:text-gray-400 mt-2">
              {tool.popular ? 'Limited time offer' : 'No credit card required • 7-day free trial'}
            </p>
          </div>
        </div>
      </div>
    </div>

    {/* Video Modal */}
    {videoId && (
      <VideoModal
        isOpen={isVideoOpen}
        onClose={() => setIsVideoOpen(false)}
        videoId={videoId}
        title={tool.name}
      />
    )}
    </>
  );
}