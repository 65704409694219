import React, { useState } from 'react';
import { Brain, Rocket, Target, ArrowRight, Shield, CheckCircle2, DollarSign, Users, Star, Zap, Building2, Lock } from 'lucide-react';
import Button from '../components/ui/Button';
import ConsultationForm from '../components/shared/ConsultationForm';

const ventureSteps = [
  {
    title: 'Technical Co-Founding',
    icon: Brain,
    description: 'Get elite technical co-founders who build your complete AI infrastructure',
    features: [
      'Custom AI model development',
      'Full-stack web application',
      'Enterprise MLOps setup',
      'Technical architecture',
      'Launch support'
    ],
    timeline: '2-3 months'
  },
  {
    title: 'Venture Development',
    icon: Rocket,
    description: 'Transform your idea into a production-ready AI venture',
    features: [
      'MVP development',
      'AI infrastructure setup',
      'Security & compliance',
      'Technical documentation',
      'Deployment & scaling'
    ],
    timeline: '3-4 months'
  },
  {
    title: 'Growth & Scale',
    icon: Target,
    description: 'Scale your AI venture with ongoing technical partnership',
    features: [
      'Technical team building',
      'Infrastructure scaling',
      'AI model optimization',
      'Enterprise sales support',
      'Investor preparation'
    ],
    timeline: '4-6 months'
  }
];

const investmentPackage = {
  price: '$19,995',
  maintenance: '$499/month',
  equity: 'Up to 25%',
  features: [
    'Complete technical development',
    'Custom AI infrastructure',
    'Full-stack web application',
    'Technical co-founding support',
    'Launch strategy & support',
    'Ongoing maintenance & updates',
    'Investor network access'
  ]
};

export default function StartAIVenture() {
  const [showConsultationForm, setShowConsultationForm] = useState(false);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-24">
      {/* Hero Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-16">
        <div className="text-center">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <Rocket className="w-6 h-6 text-blue-600 dark:text-blue-400 animate-pulse" />
          </div>
          <h1 className="text-4xl sm:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-violet-600 to-purple-600 dark:from-blue-400 dark:via-violet-400 dark:to-purple-400">
            Start Your AI Venture Today
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto mb-8">
            Launch your AI startup with technical co-founding support, custom development, and proven playbooks. Get everything you need to build a successful AI venture.
          </p>

          {/* Stats Grid */}
          <div className="grid grid-cols-4 gap-6 max-w-4xl mx-auto mb-8">
            {[
              { icon: DollarSign, value: '10x', label: 'Faster Launch' },
              { icon: Users, value: '45+', label: 'Ventures Launched' },
              { icon: Star, value: '98%', label: 'Success Rate' },
              { icon: Shield, value: '24/7', label: 'Support' }
            ].map(({ icon: Icon, value, label }) => (
              <div key={label} className="bg-white/80 dark:bg-slate-900/80 backdrop-blur-sm rounded-xl p-4 border border-gray-200/50 dark:border-gray-800/50">
                <Icon className="w-6 h-6 text-blue-600 dark:text-blue-400 mx-auto mb-2" />
                <div className="text-2xl font-bold text-gray-900 dark:text-white mb-1">{value}</div>
                <div className="text-sm text-gray-600 dark:text-gray-400">{label}</div>
              </div>
            ))}
          </div>

          <Button
            variant="primary"
            size="lg"
            icon={ArrowRight}
            onClick={() => setShowConsultationForm(true)}
            className="group"
          >
            Start Your AI Venture
          </Button>
        </div>
      </div>

      {/* Venture Steps */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-16">
        <div className="grid md:grid-cols-3 gap-8">
          {ventureSteps.map((step, index) => {
            const Icon = step.icon;
            return (
              <div
                key={step.title}
                className="group relative bg-white dark:bg-slate-900 rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-[1.02] border border-gray-200/50 dark:border-gray-800/50"
              >
                <div className="absolute -top-4 left-4 px-4 py-1 bg-blue-100 dark:bg-blue-900/30 rounded-full text-sm font-medium text-blue-600 dark:text-blue-400">
                  Phase {index + 1}
                </div>
                
                <div className="relative">
                  <div className="w-12 h-12 rounded-xl bg-gradient-to-br from-blue-100 to-violet-100 dark:from-blue-900/20 dark:to-violet-900/20 p-3 mb-6">
                    <Icon className="w-full h-full text-blue-600 dark:text-blue-400" />
                  </div>

                  <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-4">
                    {step.title}
                  </h3>
                  
                  <p className="text-gray-600 dark:text-gray-400 mb-6">
                    {step.description}
                  </p>

                  <ul className="space-y-3 mb-6">
                    {step.features.map((feature) => (
                      <li key={feature} className="flex items-center text-gray-600 dark:text-gray-300">
                        <CheckCircle2 className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                        {feature}
                      </li>
                    ))}
                  </ul>

                  <div className="text-sm text-blue-600 dark:text-blue-400 font-medium">
                    Timeline: {step.timeline}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Investment Package */}
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 mb-16">
        <div className="bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-8">
          <div className="text-center mb-8">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              Technical Co-Founding Package
            </h2>
            <div className="flex items-center justify-center">
              <span className="text-4xl font-bold text-gray-900 dark:text-white">
                {investmentPackage.price}
              </span>
              <span className="text-gray-600 dark:text-gray-400 ml-2">one-time</span>
            </div>
            <div className="text-violet-600 dark:text-violet-400 font-medium mt-2">
              + {investmentPackage.maintenance} maintenance
            </div>
            <div className="text-blue-600 dark:text-blue-400 font-medium">
              {investmentPackage.equity} equity
            </div>
          </div>

          <div className="grid md:grid-cols-2 gap-8 mb-8">
            {investmentPackage.features.map((feature) => (
              <div key={feature} className="flex items-center text-gray-700 dark:text-gray-300">
                <CheckCircle2 className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
                {feature}
              </div>
            ))}
          </div>

          <div className="text-center">
            <Button
              variant="primary"
              size="lg"
              icon={ArrowRight}
              onClick={() => setShowConsultationForm(true)}
              className="group"
            >
              Schedule Consultation
            </Button>
          </div>
        </div>
      </div>

      {/* Consultation Form Modal */}
      <ConsultationForm
        isOpen={showConsultationForm}
        onClose={() => setShowConsultationForm(false)}
        type="venture"
      />
    </div>
  );
}