import React from 'react';
import { Activity, CheckCircle2, AlertTriangle, Clock, Server, Database, Globe, Zap, Brain } from 'lucide-react';

const services = [
  {
    name: 'API Services',
    icon: Server,
    status: 'operational',
    uptime: '99.99%',
    latency: '145ms',
    lastIncident: 'None'
  },
  {
    name: 'Database Clusters',
    icon: Database,
    status: 'operational',
    uptime: '99.99%',
    latency: '45ms',
    lastIncident: '7 days ago'
  },
  {
    name: 'AI Models',
    icon: Brain,
    status: 'operational',
    uptime: '99.95%',
    latency: '235ms',
    lastIncident: 'None'
  },
  {
    name: 'CDN',
    icon: Globe,
    status: 'operational',
    uptime: '100%',
    latency: '35ms',
    lastIncident: 'None'
  }
];

const incidents = [
  {
    date: '2024-03-10',
    title: 'Increased API Latency',
    status: 'resolved',
    duration: '23 minutes',
    description: 'Increased latency in API responses due to database optimization.'
  },
  {
    date: '2024-03-05',
    title: 'Scheduled Maintenance',
    status: 'completed',
    duration: '45 minutes',
    description: 'Scheduled database maintenance and system updates.'
  }
];

const metrics = [
  { name: 'Uptime (30 days)', value: '99.99%', trend: 'up' },
  { name: 'Response Time', value: '145ms', trend: 'stable' },
  { name: 'Error Rate', value: '0.01%', trend: 'down' },
  { name: 'Success Rate', value: '99.99%', trend: 'up' }
];

export default function Status() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-green-100 dark:bg-green-900/30 mb-4">
            <Activity className="w-6 h-6 text-green-600 dark:text-green-400" />
          </div>
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            System Status
          </h1>
          <div className="flex items-center justify-center space-x-2">
            <div className="w-3 h-3 bg-green-500 rounded-full animate-pulse" />
            <span className="text-lg text-gray-600 dark:text-gray-300">
              All Systems Operational
            </span>
          </div>
        </div>

        {/* Metrics Overview */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mb-12">
          {metrics.map((metric) => (
            <div key={metric.name} className="bg-white dark:bg-slate-900 rounded-xl p-6 border border-gray-200/50 dark:border-gray-800/50">
              <h3 className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                {metric.name}
              </h3>
              <div className="flex items-center justify-between">
                <span className="text-2xl font-bold text-gray-900 dark:text-white">
                  {metric.value}
                </span>
                <div className={`flex items-center ${
                  metric.trend === 'up' 
                    ? 'text-green-500' 
                    : metric.trend === 'down' 
                    ? 'text-red-500'
                    : 'text-gray-500'
                }`}>
                  {metric.trend === 'up' && <Zap className="w-4 h-4" />}
                  {metric.trend === 'down' && <AlertTriangle className="w-4 h-4" />}
                  {metric.trend === 'stable' && <Activity className="w-4 h-4" />}
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Services Status */}
        <div className="bg-white dark:bg-slate-900 rounded-xl border border-gray-200/50 dark:border-gray-800/50 mb-12">
          <div className="p-6 border-b border-gray-200 dark:border-gray-800">
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
              Service Status
            </h2>
          </div>
          <div className="divide-y divide-gray-200 dark:divide-gray-800">
            {services.map((service) => (
              <div key={service.name} className="p-6 hover:bg-gray-50 dark:hover:bg-slate-800/50">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center">
                    <div className="p-2 rounded-lg bg-blue-50 dark:bg-blue-900/20">
                      <service.icon className="w-5 h-5 text-blue-600 dark:text-blue-400" />
                    </div>
                    <div className="ml-4">
                      <h3 className="font-medium text-gray-900 dark:text-white">
                        {service.name}
                      </h3>
                      <div className="flex items-center mt-1">
                        <div className={`w-2 h-2 rounded-full ${
                          service.status === 'operational' 
                            ? 'bg-green-500' 
                            : 'bg-yellow-500'
                        } mr-2`} />
                        <span className="text-sm text-gray-500 dark:text-gray-400">
                          {service.status === 'operational' ? 'Operational' : 'Degraded'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <div className="text-sm font-medium text-gray-900 dark:text-white">
                      {service.uptime}
                    </div>
                    <div className="text-sm text-gray-500 dark:text-gray-400">
                      Uptime
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-gray-50 dark:bg-slate-800 rounded-lg p-3">
                    <div className="text-sm text-gray-500 dark:text-gray-400">
                      Latency
                    </div>
                    <div className="font-medium text-gray-900 dark:text-white">
                      {service.latency}
                    </div>
                  </div>
                  <div className="bg-gray-50 dark:bg-slate-800 rounded-lg p-3">
                    <div className="text-sm text-gray-500 dark:text-gray-400">
                      Last Incident
                    </div>
                    <div className="font-medium text-gray-900 dark:text-white">
                      {service.lastIncident}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Incident History */}
        <div className="bg-white dark:bg-slate-900 rounded-xl border border-gray-200/50 dark:border-gray-800/50 mb-12">
          <div className="p-6 border-b border-gray-200 dark:border-gray-800">
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
              Recent Incidents
            </h2>
          </div>
          <div className="divide-y divide-gray-200 dark:divide-gray-800">
            {incidents.map((incident) => (
              <div key={incident.date} className="p-6">
                <div className="flex items-center justify-between mb-4">
                  <div>
                    <h3 className="font-medium text-gray-900 dark:text-white">
                      {incident.title}
                    </h3>
                    <div className="flex items-center mt-1">
                      {incident.status === 'resolved' ? (
                        <CheckCircle2 className="w-4 h-4 text-green-500 mr-2" />
                      ) : (
                        <Clock className="w-4 h-4 text-blue-500 mr-2" />
                      )}
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        {incident.status.charAt(0).toUpperCase() + incident.status.slice(1)}
                      </span>
                    </div>
                  </div>
                  <div className="text-right">
                    <div className="text-sm font-medium text-gray-900 dark:text-white">
                      {incident.date}
                    </div>
                    <div className="text-sm text-gray-500 dark:text-gray-400">
                      Duration: {incident.duration}
                    </div>
                  </div>
                </div>
                <p className="text-gray-600 dark:text-gray-400 text-sm">
                  {incident.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Subscribe to Updates */}
        <div className="bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-8 text-center">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
            Subscribe to Status Updates
          </h3>
          <p className="text-gray-600 dark:text-gray-300 mb-6">
            Get notified about system status changes and maintenance windows.
          </p>
          <form className="max-w-md mx-auto">
            <div className="flex gap-4">
              <input
                type="email"
                placeholder="Enter your email"
                className="flex-1 px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
              />
              <button
                type="submit"
                className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}