import React from 'react';
import { FileCheck, Clock, DollarSign, Target, CheckCircle2 } from 'lucide-react';

const pastProjects = [
  {
    id: 'ai-infrastructure',
    agency: {
      name: 'Federal Agency',
      logo: 'https://images.unsplash.com/photo-1569098644584-210bcd375b59?w=400',
      type: 'Civilian'
    },
    project: {
      name: 'AI Infrastructure Modernization',
      status: 'Operational',
      classification: 'FedRAMP High'
    },
    outcomes: [
      'Reduced processing time by 45%',
      'Improved system efficiency by 32%',
      'Automated 78% of routine tasks'
    ],
    metrics: {
      efficiency: '+45%',
      accuracy: '95%',
      timeframe: '12 months'
    },
    duration: '12 months',
    value: '$2.5M',
    impact: 'High Value'
  },
  {
    id: 'data-analytics',
    agency: {
      name: 'State Agency',
      logo: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?w=400',
      type: 'Government'
    },
    project: {
      name: 'Advanced Analytics Platform',
      status: 'Active',
      classification: 'IL4'
    },
    outcomes: [
      'Improved data processing by 40%',
      'Enhanced reporting accuracy by 35%',
      'Automated 65% of workflows'
    ],
    metrics: {
      speed: '40% faster',
      accuracy: '+35%',
      automation: '65%'
    },
    duration: '9 months',
    value: '$1.8M',
    impact: 'Operational'
  },
  {
    id: 'process-automation',
    agency: 'Local Government',
    project: 'Process Automation Initiative',
    outcomes: [
      'Reduced processing time by 35%',
      'Improved service delivery by 28%',
      'Automated 50% of manual processes'
    ],
    duration: '6 months',
    value: '$850K',
    classification: 'IL2',
    impact: 'Service Improvement'
  },
  {
    id: 'hhs-analytics',
    agency: 'State Healthcare Agency',
    project: 'Predictive Analytics Platform',
    outcomes: [
      'Improved patient outcomes by 32%',
      'Reduced wait times by 45%',
      'Streamlined reporting by 67%'
    ],
    duration: '12 months',
    value: '$1.8M'
  },
  {
    id: 'dot-traffic',
    agency: 'County Transportation',
    project: 'Smart Traffic Management',
    outcomes: [
      'Reduced congestion by 28%',
      'Decreased incident response time by 40%',
      'Optimized route planning efficiency by 35%'
    ],
    duration: '15 months',
    value: '$2.1M'
  }
];

export default function PastPerformance() {
  return (
    <div className="py-24 bg-gradient-to-br from-gray-50 via-white to-gray-50 dark:from-slate-950 dark:via-slate-900 dark:to-slate-950 relative overflow-hidden">
      {/* Background decorations */}
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />
      <div className="absolute inset-y-0 right-0 w-1/2 bg-[radial-gradient(circle_at_70%_50%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <FileCheck className="w-6 h-6 text-blue-600 dark:text-blue-400" />
          </div>
          <h2 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-violet-600 to-purple-600 dark:from-blue-400 dark:via-violet-400 dark:to-purple-400 mb-4">
            Recent Success Stories
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            Proven results from our government AI solutions
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 relative">
          {/* Connecting lines between cards */}
          <div className="absolute top-1/2 left-0 right-0 h-px bg-gradient-to-r from-blue-200 via-violet-200 to-blue-200 dark:from-blue-800 dark:via-violet-800 dark:to-blue-800 hidden lg:block -translate-y-1/2 opacity-50" />

          {pastProjects.map((project) => (
            <div
              key={project.id}
              className="group relative bg-white/90 dark:bg-slate-900/90 backdrop-blur-sm rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-[1.02] border border-gray-200/50 dark:border-gray-800/50"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-violet-500/5 dark:from-blue-400/5 dark:to-violet-400/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
              
              {project.project?.classification && (
                <div className="absolute -top-3 right-4 px-4 py-1 bg-gradient-to-r from-blue-600 to-violet-600 dark:from-blue-500 dark:to-violet-500 text-white text-sm font-medium rounded-full shadow-lg">
                  {project.project.classification}
                </div>
              )}

              {project.impact && (
                <div className="absolute -top-3 left-4 px-4 py-1 bg-amber-100 dark:bg-amber-900/30 text-amber-700 dark:text-amber-300 text-sm font-medium rounded-full">
                  {project.impact}
                </div>
              )}

              <div className="relative">
                <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
                  {project.agency.name || project.agency}
                </h3>
                <p className="text-lg text-gray-600 dark:text-gray-400 mb-6">
                  {project.project.name || project.project}
                </p>

                <div className="space-y-4 mb-8">
                  {project.outcomes.map((outcome, index) => (
                    <div 
                      key={index} 
                      className="flex items-start p-3 bg-gray-50 dark:bg-slate-800 rounded-lg transform group-hover:translate-x-1 transition-all duration-300"
                    >
                      <div className="relative">
                        <CheckCircle2 className="w-5 h-5 text-green-500 mr-3 flex-shrink-0" />
                        <div className="absolute inset-0 bg-green-500/20 rounded-full blur group-hover:animate-ping" />
                      </div>
                      <span className="text-gray-600 dark:text-gray-400">{outcome}</span>
                    </div>
                  ))}
                </div>

                <div className="grid grid-cols-2 gap-4 pt-6 border-t border-gray-200 dark:border-gray-700">
                  <div className="flex items-center">
                    <Clock className="w-5 h-5 text-blue-600 dark:text-blue-400 mr-2" />
                    <span className="text-sm font-medium text-gray-600 dark:text-gray-400">
                      {project.duration}
                    </span>
                  </div>
                  <div className="flex items-center">
                    <DollarSign className="w-5 h-5 text-blue-600 dark:text-blue-400 mr-2" />
                    <span className="text-sm font-medium text-gray-600 dark:text-gray-400">
                      {project.value}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}