import React, { useState } from 'react';
import { DollarSign, Calendar, Users, ArrowRight, Shield, Search, Filter, Building2, Target, Globe } from 'lucide-react';
import Button from '../../components/ui/Button';

const grants = [
  {
    title: 'Defense AI Modernization',
    agency: 'Department of Defense',
    amount: '$50M',
    deadline: '2024-06-30',
    eligibility: ['Prime Contractors', 'Cleared Facilities'],
    description: 'Modernizing defense systems with advanced AI capabilities.',
    requirements: [
      'IL5/IL6 Certification',
      'CMMC Level 3',
      'US-based development',
      'TS/SCI Clearance'
    ],
    focus: [
      'Predictive maintenance',
      'Autonomous systems',
      'Intelligence analysis',
      'Mission planning'
    ],
    status: 'active'
  },
  {
    title: 'Healthcare AI Initiative',
    agency: 'Veterans Affairs',
    amount: '$25M',
    deadline: '2024-07-15',
    eligibility: ['Healthcare Providers', 'Research Institutions'],
    description: 'Improving veteran care through AI-powered diagnostics and treatment.',
    requirements: [
      'HIPAA Compliance',
      'FedRAMP High',
      'Healthcare Experience',
      'US-based'
    ],
    focus: [
      'Patient outcomes',
      'Treatment optimization',
      'Resource allocation',
      'Preventive care'
    ],
    status: 'active'
  },
  {
    title: 'Cybersecurity AI Program',
    agency: 'CISA',
    amount: '$35M',
    deadline: '2024-08-01',
    eligibility: ['Cybersecurity Firms', 'Critical Infrastructure'],
    description: 'Protecting national infrastructure with AI-powered security.',
    requirements: [
      'FedRAMP High',
      'NIST 800-53',
      'US-based',
      'Security Clearance'
    ],
    focus: [
      'Threat detection',
      'Network security',
      'Zero trust',
      'Incident response'
    ],
    status: 'active'
  }
];

const filters = [
  { name: 'Agency', options: ['DoD', 'VA', 'CISA', 'DHS', 'All'] },
  { name: 'Amount', options: ['< $1M', '$1M-10M', '$10M-50M', '$50M+'] },
  { name: 'Deadline', options: ['Next 30 days', '1-3 months', '3-6 months', '6+ months'] }
];

export default function GrantsDirectory() {
  const [activeFilters, setActiveFilters] = useState<Record<string, string>>({});
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <DollarSign className="w-6 h-6 text-blue-600 dark:text-blue-400" />
          </div>
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Government AI Grants
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Access federal funding opportunities for AI innovation and modernization
          </p>

          {/* Quick Stats */}
          <div className="grid grid-cols-4 gap-6 max-w-4xl mx-auto mt-8">
            {[
              { icon: Building2, value: '45+', label: 'Active Grants' },
              { icon: DollarSign, value: '$250M+', label: 'Total Funding' },
              { icon: Target, value: '92%', label: 'Success Rate' },
              { icon: Globe, value: '24/7', label: 'Support' }
            ].map(stat => (
              <div key={stat.label} className="bg-white/80 dark:bg-slate-900/80 backdrop-blur-sm rounded-xl p-6 border border-gray-200/50 dark:border-gray-800/50">
                <stat.icon className="w-6 h-6 text-blue-600 dark:text-blue-400 mx-auto mb-2" />
                <div className="text-2xl font-bold text-gray-900 dark:text-white mb-1">{stat.value}</div>
                <div className="text-sm text-gray-600 dark:text-gray-400">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>

        {/* Filters & Search */}
        <div className="mb-8 flex gap-4">
          <div className="flex gap-2">
            {filters.map(filter => (
              <div key={filter.name} className="relative">
                <button className="flex items-center gap-2 px-4 py-2 bg-white dark:bg-slate-900 border border-gray-200 dark:border-gray-800 rounded-lg text-sm">
                  <Filter className="w-4 h-4 text-gray-600 dark:text-gray-400" />
                  {filter.name}
                </button>
              </div>
            ))}
          </div>
          <div className="flex-grow">
            <div className="relative">
              <input
                type="text"
                placeholder="Search grants by name, agency, or focus area"
                className="w-full px-4 py-2 bg-white dark:bg-slate-900 border border-gray-200 dark:border-gray-800 rounded-lg text-sm"
              />
              <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-600 dark:text-gray-400" />
            </div>
          </div>
        </div>

        {/* Grants Grid */}
        <div className="space-y-6 mb-16">
          {grants.map((grant) => (
            <div
              key={grant.title}
              className="group relative bg-white dark:bg-slate-900 rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-200/50 dark:border-gray-800/50"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-violet-500/5 dark:from-blue-400/5 dark:to-violet-400/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
              
              <div className="relative">
                <div className="flex items-center justify-between mb-6">
                  <div>
                    <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">
                      {grant.title}
                    </h3>
                    <div className="flex items-center gap-4">
                      <span className="text-blue-600 dark:text-blue-400">{grant.agency}</span>
                      <span className="text-gray-600 dark:text-gray-400">
                        Deadline: {new Date(grant.deadline).toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                  <div className="text-right">
                    <div className="text-2xl font-bold text-gray-900 dark:text-white mb-1">
                      {grant.amount}
                    </div>
                    <div className="text-sm text-green-600 dark:text-green-400">
                      Active Grant
                    </div>
                  </div>
                </div>

                <div className="grid md:grid-cols-2 gap-8 mb-6">
                  {/* Requirements */}
                  <div>
                    <h4 className="font-medium text-gray-900 dark:text-white mb-3">
                      Requirements
                    </h4>
                    <div className="space-y-2">
                      {grant.requirements.map((req, index) => (
                        <div key={index} className="flex items-center text-gray-600 dark:text-gray-300">
                          <Shield className="w-4 h-4 text-blue-600 dark:text-blue-400 mr-2 flex-shrink-0" />
                          {req}
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Focus Areas */}
                  <div>
                    <h4 className="font-medium text-gray-900 dark:text-white mb-3">
                      Focus Areas
                    </h4>
                    <div className="space-y-2">
                      {grant.focus.map((focus, index) => (
                        <div key={index} className="flex items-center text-gray-600 dark:text-gray-300">
                          <Target className="w-4 h-4 text-blue-600 dark:text-blue-400 mr-2 flex-shrink-0" />
                          {focus}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex gap-2">
                    {grant.eligibility.map((item) => (
                      <span
                        key={item}
                        className="px-3 py-1 text-sm bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400 rounded-full"
                      >
                        {item}
                      </span>
                    ))}
                  </div>
                  <Button
                    variant="primary"
                    size="lg"
                    icon={ArrowRight}
                    className="group"
                  >
                    View Grant Details
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* CTA Section */}
        <div className="text-center">
          <div className="bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-12 max-w-4xl mx-auto">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              Need Help with Your Grant Application?
            </h2>
            <p className="text-lg text-gray-600 dark:text-gray-400 mb-8">
              Our team of experts can help you navigate the application process and maximize your chances of success.
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <Button
                variant="primary"
                size="lg"
                icon={ArrowRight}
                className="group"
              >
                Schedule Consultation
              </Button>
              <Button
                variant="outline"
                size="lg"
                icon={ArrowRight}
              >
                Download Grant Guide
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}