import React from 'react';
import AdminLayout from '../../components/admin/AdminLayout';
import UserManagement from '../../components/admin/UserManagement';

export default function AdminUsers() {
  return (
    <AdminLayout>
      <div className="space-y-8">
        <div>
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
            User Management
          </h1>
          <p className="text-gray-600 dark:text-gray-400">
            Manage user accounts, roles, and permissions
          </p>
        </div>

        <UserManagement />
      </div>
    </AdminLayout>
  );
}