import { Shield, Brain, Lock, FileCheck, Users, Network, Cpu, Target, Heart, FileText, Activity } from 'lucide-react';

import type { GovernmentSolution, GovernmentApplication } from '../types/government';

export const solutions: GovernmentSolution[] = [
  {
    title: 'AI Research & Development',
    icon: Brain,
    description: 'Advanced R&D initiatives aligned with national priorities',
    features: [
      { icon: Target, text: 'National AI Research Resource collaboration' },
      { icon: Brain, text: 'Innovative AI applications development' },
      { icon: Network, text: 'Cross-agency research partnerships' }
    ]
  },
  {
    title: 'AI Talent Development',
    icon: Users,
    description: 'Comprehensive AI training and workforce development programs',
    features: [
      { icon: FileText, text: 'Custom AI training curriculum development' },
      { icon: Brain, text: 'Hands-on AI implementation workshops' },
      { icon: Activity, text: 'Skills assessment and certification programs' }
    ]
  },
  {
    title: 'Autonomous Systems',
    icon: Cpu,
    description: 'Advanced autonomous capabilities for defense and civilian missions',
    features: [
      { icon: Target, text: 'Multi-domain autonomous operations' },
      { icon: Network, text: 'Swarm intelligence and coordination' },
      { icon: Brain, text: 'AI-powered mission planning and execution' }
    ]
  },
  {
    title: 'Healthcare AI',
    icon: Heart,
    description: 'Advanced AI solutions for veterans healthcare and public health',
    features: [
      { icon: Brain, text: 'Multimodal medical diagnostics AI' },
      { icon: Heart, text: 'Predictive patient care analytics' },
      { icon: Shield, text: 'HIPAA-compliant health data processing' }
    ]
  },
  {
    title: 'Cybersecurity AI',
    icon: Shield,
    description: 'AI-powered zero trust security for critical infrastructure',
    features: [
      { icon: Brain, text: 'Advanced persistent threat detection' },
      { icon: Shield, text: 'AI-enabled zero trust architecture' },
      { icon: Lock, text: 'Quantum-resistant encryption' }
    ]
  },
  {
    title: 'Procurement & Supply Chain AI',
    icon: Network,
    description: 'AI-powered procurement and supply chain optimization',
    features: [
      { icon: Brain, text: 'Intelligent procurement automation' },
      { icon: Shield, text: 'Risk management & compliance' },
      { icon: Target, text: 'Strategic supplier engagement' }
    ]
  }
];

export const applications = [
  {
    title: 'R&D Innovation',
    description: 'Cutting-edge AI research for national security and societal needs',
    progress: 95
  },
  {
    title: 'Workforce Training',
    description: 'AI competency development and certification programs',
    progress: 85
  },
  {
    title: 'Healthcare Analytics',
    description: 'Advanced analytics for veterans healthcare and medical research',
    progress: 78
  },
  {
    title: 'Procurement Efficiency',
    description: 'AI-driven procurement and supply chain optimization',
    progress: 92
  }
];