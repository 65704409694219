import React from 'react';
import { FileText, Video, Book, Download, Shield, ArrowRight } from 'lucide-react';
import Button from '../ui/Button';

const resources = [
  {
    title: 'AI Implementation Guide',
    type: 'Official Documentation',
    icon: FileText,
    description: 'Comprehensive implementation playbook aligned with NIST AI Risk Management Framework.',
    downloadUrl: '#',
    tags: ['FedRAMP', 'NIST AI RMF', 'Implementation', 'IL5'],
    format: 'PDF & Interactive',
    pages: '250+',
    lastUpdated: 'March 2024'
  },
  {
    title: 'Security Compliance',
    type: 'Technical Package',
    icon: Shield,
    description: 'Complete security documentation package including ATO templates and compliance matrices.',
    downloadUrl: '#',
    tags: ['IL5/IL6', 'CMMC', 'NIST 800-53', 'Zero Trust'],
    format: 'PDF & Excel',
    pages: '500+',
    lastUpdated: 'March 2024'
  },
  {
    title: 'Agency Success Stories',
    type: 'Case Studies',
    icon: Video,
    description: 'Detailed case studies from DoD, Intelligence Community, and Federal Civilian implementations.',
    downloadUrl: '#',
    tags: ['DoD', 'IC', 'Federal Civilian', 'ROI Analysis'],
    format: 'PDF & Video',
    pages: '150+',
    lastUpdated: 'March 2024'
  }
];

export default function ResourcesSection() {
  return (
    <div className="py-24 bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            Government Resources
          </h2>
          <p className="text-lg text-black dark:text-gray-300">
            Access comprehensive guides and documentation for your AI initiatives
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {resources.map((resource) => {
            const Icon = resource.icon;
            return (
              <div
                key={resource.title}
                className="group relative bg-white dark:bg-slate-900 rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-[1.02] border border-gray-200/50 dark:border-gray-800/50"
              >
                <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-violet-500/5 dark:from-blue-400/5 dark:to-violet-400/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
                
                <div className="relative">
                  {/* Icon */}
                  <div className="w-12 h-12 rounded-xl bg-gradient-to-br from-blue-100 to-violet-100 dark:from-blue-900/20 dark:to-violet-900/20 p-3 mb-6">
                    <Icon className="w-full h-full text-blue-600 dark:text-blue-400" />
                  </div>

                  {/* Content */}
                  <div className="mb-6">
                    <div className="text-sm text-blue-600 dark:text-blue-400 font-medium mb-2">
                      {resource.type}
                    </div>
                    <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-2">
                      {resource.title}
                    </h3>
                    <p className="text-black dark:text-gray-400">
                      {resource.description}
                    </p>
                  </div>

                  {/* Tags */}
                  <div className="flex flex-wrap gap-2 mb-6">
                    {resource.tags.map((tag) => (
                      <span
                        key={tag}
                        className="px-2 py-1 text-xs font-medium bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400 rounded-full"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>

                  {/* Download Button */}
                  <Button
                    variant="outline"
                    size="sm"
                    icon={Download}
                    className="w-full"
                    onClick={() => window.open(resource.downloadUrl, '_blank')}
                  >
                    Download Resource
                  </Button>
                </div>
              </div>
            );
          })}
        </div>

        {/* CTA Section */}
        <div className="mt-16 bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-8 text-center">
          <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Need Custom Documentation?
          </h3>
          <p className="text-gray-600 dark:text-gray-400 mb-6 max-w-2xl mx-auto">
            Our team can provide customized documentation and implementation guides specific to your agency's needs.
          </p>
          <Button variant="primary" size="lg" icon={ArrowRight}>
            Request Custom Resources
          </Button>
        </div>
      </div>
    </div>
  );
}