import React, { useState } from 'react';
import { LogIn, User, Lock } from 'lucide-react';
import Button from '../ui/Button';
import LoginModal from '../auth/LoginModal';
import { useRouter } from '../../hooks/useRouter';
import { checkAuth, logout } from '../../utils/auth';
import { ROUTES } from '../../utils/routes';
import { trackConversion } from '../../utils/analytics';

export default function LoginButton() {
  const { navigate } = useRouter();
  const [isLoggedIn, setIsLoggedIn] = useState(() => checkAuth());
  const [showLoginModal, setShowLoginModal] = useState(false);
  const userEmail = localStorage.getItem('userContact');

  const handleLogin = () => {
    trackConversion({
      type: 'cta_click',
      page: window.location.pathname as any,
      data: { button: 'login' }
    });
    setShowLoginModal(true);
  };

  const handleLogout = () => {
    logout();
    setIsLoggedIn(false);
  };

  if (isLoggedIn && userEmail) {
    return (
      <div className="flex items-center space-x-3">
        <div className="hidden md:block text-right">
          <div className="text-sm font-medium text-gray-900 dark:text-white">
            {userEmail}
          </div>
          <button
            onClick={handleLogout}
            className="text-xs text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
          >
            Sign Out
          </button>
        </div>
        <button 
          onClick={() => navigate(ROUTES.WORKSPACE)}
          className="relative p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-slate-800 group"
        >
          <User className="w-5 h-5 text-gray-600 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-400" />
          <div className="absolute -bottom-1 -right-1 w-2 h-2 bg-green-500 rounded-full" />
        </button>
      </div>
    );
  }

  return (
    <div className="flex items-center space-x-3">
      <Button
        variant="ghost"
        size="sm"
        icon={LogIn}
        onClick={handleLogin}
        className="hidden md:flex group relative overflow-hidden"
      >
        <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 via-violet-500/10 to-purple-500/10 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg" />
        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/[0.05] to-transparent translate-x-[-200%] group-hover:translate-x-[200%] transition-transform duration-[400ms]" />
        Sign In
      </Button>
      <LoginModal isOpen={showLoginModal} onClose={() => setShowLoginModal(false)} />
    </div>
  );
}