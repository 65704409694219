import React, { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: undefined,
    errorInfo: undefined
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Skip extension errors
    if (this.isExtensionError(error, errorInfo)) {
      console.debug('Skipping extension error:', error);
      return;
    }

    console.error('Uncaught error:', error, errorInfo);
    // Track error for monitoring
    try {
      const errorDetails = {
        message: error.message,
        stack: error.stack,
        componentStack: errorInfo.componentStack,
        timestamp: new Date().toISOString(),
        url: window.location.href,
        userAgent: navigator.userAgent
      };
      localStorage.setItem('lastError', JSON.stringify(errorDetails));
    } catch (e) {
      console.error('Failed to save error details:', e);
    }
    
    // Track error for analytics
    try {
      const errorDetails = {
        message: error.message,
        stack: error.stack,
        componentStack: errorInfo.componentStack,
        timestamp: new Date().toISOString()
      };
      
      // Update state with error info
      this.setState({ 
        hasError: true,
        error,
        errorInfo 
      });
      
      // Store error details
      localStorage.setItem('lastError', JSON.stringify(errorDetails));
    } catch (e) {
      console.error('Failed to save error details:', e);
    }
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    // Clear error state if children change
    if (prevProps.children !== this.props.children && this.state.hasError) {
      this.setState({ hasError: false, error: undefined, errorInfo: undefined });
    }
  }

  private isExtensionError(error: Error, errorInfo: ErrorInfo): boolean {
    const errorText = [
      errorInfo?.componentStack || '',
      error?.message || '', 
      error?.stack || '', 
      error?.name || '', 
      'componentStack' in error ? String((error as any).componentStack) : '',
      'startsWith' in error ? String((error as any).startsWith) : '',
      'type' in error ? String((error as any).type) : '',
      'inpage' in error ? String((error as any).inpage) : '',
      errorInfo.componentStack,
      error?.message || '',
      error?.stack || '',
      error?.name || '',
      'generator' in error ? String((error as any).generator) : '',
      'next' in error ? String((error as any).next) : '',
      'generator' in error ? String((error as any).generator) : '',
      'next' in error ? String((error as any).next) : '',
      'ln' in error ? String((error as any).ln) : '',
      'anonymous' in error ? String((error as any).anonymous) : '',
      'componentStack' in error ? String((error as any).componentStack) : '',
      String(error),
      'type' in error ? String((error as any).type) : '',
      'type' in error ? String((error as any).type) : '',
      'startsWith' in error ? String((error as any).startsWith) : ''
    ].join(' ').toLowerCase();

    // Enhanced extension error detection
    return errorText.includes('extension') || 
           errorText.includes('chrome-extension') ||
           errorText.includes('browser-extension') ||
           errorText.includes('cannot read properties of null') ||
           errorText.includes('cannot read property type of null') ||
           errorText.includes('cannot read property \'type\' of null') ||
           errorText.includes('cannot read property type of null') ||
           errorText.includes('cannot read property \'type\' of null') ||
           errorText.includes('cannot read property type of null') ||
           errorText.includes('cannot read property \'type\' of null') ||
           errorText.includes('cannot read property type of null') ||
           errorText.includes('cannot read property \'type\' of null') ||
           errorText.includes('startswith') ||
           errorText.includes('inpage.js') ||
           errorText.includes('web3') ||
           errorText.includes('metamask') ||
           errorText.includes('wallet') ||
           errorText.includes('generator.next') ||
           errorText.includes('ln.<anonymous>') ||
           errorText.includes('ln.anonymous') ||
           errorText.includes('generator') ||
           errorText.includes('next') ||
           errorText.includes('undefined is not an object') ||
           errorText.includes('cannot read property') ||
           errorText.includes('null is not an object') ||
           errorText.includes('type of null') ||
           errorText.includes('type of undefined') ||
           errorText.includes('cannot read properties of null');
  }

  public render() {
    // Skip rendering error UI for extension errors
    if (this.state.error && this.state.errorInfo && 
        this.isExtensionError(this.state.error, this.state.errorInfo)) {
      console.debug('Skipping error UI for extension error');
      console.debug('Skipping error UI for extension error');
      console.debug('Skipping error UI for extension error');
      // Add more detailed debug logging
      console.debug('Skipping extension error:', {
        message: this.state.error.message,
        stack: this.state.error.stack,
        componentStack: this.state.errorInfo.componentStack
      });
      // Enhanced debug logging with more context
      console.debug('Skipping extension error:', {
        message: this.state.error.message,
        stack: this.state.error.stack,
        componentStack: this.state.errorInfo.componentStack,
        url: window.location.href,
        timestamp: new Date().toISOString()
      });
      return this.props.children;
    }

    if (this.state.hasError) {
      return this.props.fallback || (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-slate-950 p-4">
          <div className="text-center">
            <h1 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              Something went wrong
            </h1>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              {this.state.error?.message || 'An unexpected error occurred'}
            </p>
            {this.state.errorInfo && (
              <pre className="mt-4 p-4 bg-gray-100 dark:bg-slate-800 rounded-lg text-sm text-left overflow-auto max-h-48">
                {this.state.errorInfo.componentStack}
              </pre>
            )}
            <button
              onClick={() => window.location.reload()}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              Reload page
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}