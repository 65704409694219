import { ROUTES } from './routes';
import { handleError } from './errorHandler';

interface AdminSession {
  id: string;
  username: string;
  expires: number;
}

export function checkAdminAuth(): boolean {
  try {
    const sessionData = localStorage.getItem('adminSession');
    if (!sessionData) {
      redirectToLogin();
      return false;
    }
    
    const session = JSON.parse(sessionData);
    if (Date.now() > session.expires) {
      localStorage.removeItem('adminSession');
      redirectToLogin();
      return false;
    }
    
    return true;
  } catch (error) {
    handleError(error);
    redirectToLogin();
    return false;
  }
}

export function redirectToLogin(): void {
  try {
    window.location.replace(ROUTES.ADMIN.LOGIN);
  } catch (error) {
    handleError(error);
    // Fallback to direct string path if ROUTES fails
    window.location.replace('/admin/login');
  }
}

export function logoutAdmin(): void {
  localStorage.removeItem('adminSession');
  redirectToLogin();
}