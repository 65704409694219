import React, { useState } from 'react';
import { Code2, Search, Copy, CheckCircle2, Lock, Shield, Zap, ArrowRight } from 'lucide-react';
import Button from '../components/ui/Button';

const endpoints = [
  {
    name: 'Authentication',
    description: 'Secure API access with JWT tokens',
    endpoints: [
      {
        method: 'POST',
        path: '/api/auth/token',
        description: 'Generate API access token',
        parameters: [
          { name: 'api_key', type: 'string', required: true, description: 'Your API key' },
          { name: 'scope', type: 'string', required: false, description: 'Requested access scope' }
        ],
        response: `{
  "access_token": "eyJhbGciOiJIUzI1...",
  "expires_in": 3600,
  "token_type": "Bearer"
}`
      }
    ]
  },
  {
    name: 'AI Models',
    description: 'Access our suite of AI models',
    endpoints: [
      {
        method: 'POST',
        path: '/api/v1/models/generate',
        description: 'Generate content using AI models',
        parameters: [
          { name: 'model', type: 'string', required: true, description: 'Model identifier' },
          { name: 'prompt', type: 'string', required: true, description: 'Input prompt' },
          { name: 'max_tokens', type: 'number', required: false, description: 'Maximum tokens to generate' }
        ],
        response: `{
  "id": "gen_123abc",
  "model": "gpt-4",
  "generated_text": "...",
  "usage": {
    "prompt_tokens": 10,
    "completion_tokens": 50,
    "total_tokens": 60
  }
}`
      }
    ]
  }
];

export default function APIReference() {
  const [searchQuery, setSearchQuery] = useState('');
  const [copiedEndpoint, setCopiedEndpoint] = useState<string | null>(null);

  const copyCode = async (code: string, endpoint: string) => {
    await navigator.clipboard.writeText(code);
    setCopiedEndpoint(endpoint);
    setTimeout(() => setCopiedEndpoint(null), 2000);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <Code2 className="w-6 h-6 text-blue-600 dark:text-blue-400" />
          </div>
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            API Reference
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
            Integrate GALAX AI's powerful features directly into your applications
          </p>
        </div>

        {/* Quick Stats */}
        <div className="grid grid-cols-3 gap-6 mb-12">
          <div className="bg-white/80 dark:bg-slate-900/80 backdrop-blur-sm rounded-xl p-6 border border-gray-200/50 dark:border-gray-800/50">
            <Lock className="w-6 h-6 text-blue-600 dark:text-blue-400 mb-2" />
            <div className="text-2xl font-bold text-gray-900 dark:text-white mb-1">256-bit</div>
            <div className="text-sm text-gray-600 dark:text-gray-400">SSL Encryption</div>
          </div>
          <div className="bg-white/80 dark:bg-slate-900/80 backdrop-blur-sm rounded-xl p-6 border border-gray-200/50 dark:border-gray-800/50">
            <Shield className="w-6 h-6 text-blue-600 dark:text-blue-400 mb-2" />
            <div className="text-2xl font-bold text-gray-900 dark:text-white mb-1">99.9%</div>
            <div className="text-sm text-gray-600 dark:text-gray-400">Uptime SLA</div>
          </div>
          <div className="bg-white/80 dark:bg-slate-900/80 backdrop-blur-sm rounded-xl p-6 border border-gray-200/50 dark:border-gray-800/50">
            <Zap className="w-6 h-6 text-blue-600 dark:text-blue-400 mb-2" />
            <div className="text-2xl font-bold text-gray-900 dark:text-white mb-1">50ms</div>
            <div className="text-sm text-gray-600 dark:text-gray-400">Avg Response Time</div>
          </div>
        </div>

        {/* Search */}
        <div className="relative mb-8">
          <Search className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
          <input
            type="search"
            placeholder="Search endpoints..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-12 pr-4 py-3 rounded-xl border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
          />
        </div>

        {/* API Documentation */}
        <div className="space-y-12">
          {endpoints.map((section) => (
            <div key={section.name} className="bg-white dark:bg-slate-900 rounded-xl border border-gray-200/50 dark:border-gray-800/50 overflow-hidden">
              <div className="p-6 border-b border-gray-200 dark:border-gray-800">
                <h2 className="text-xl font-bold text-gray-900 dark:text-white mb-2">
                  {section.name}
                </h2>
                <p className="text-gray-600 dark:text-gray-400">
                  {section.description}
                </p>
              </div>

              <div className="divide-y divide-gray-200 dark:divide-gray-800">
                {section.endpoints.map((endpoint) => (
                  <div key={endpoint.path} className="p-6">
                    <div className="flex items-start justify-between mb-4">
                      <div>
                        <div className="flex items-center space-x-2 mb-2">
                          <span className="px-2 py-1 text-sm font-medium bg-blue-100 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400 rounded">
                            {endpoint.method}
                          </span>
                          <code className="text-sm font-mono text-gray-900 dark:text-white">
                            {endpoint.path}
                          </code>
                        </div>
                        <p className="text-gray-600 dark:text-gray-400">
                          {endpoint.description}
                        </p>
                      </div>
                      <button
                        onClick={() => copyCode(endpoint.response, endpoint.path)}
                        className="p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors"
                      >
                        {copiedEndpoint === endpoint.path ? (
                          <CheckCircle2 className="w-5 h-5 text-green-500" />
                        ) : (
                          <Copy className="w-5 h-5 text-gray-400" />
                        )}
                      </button>
                    </div>

                    <div className="mt-4">
                      <h4 className="text-sm font-medium text-gray-900 dark:text-white mb-2">
                        Parameters
                      </h4>
                      <div className="bg-gray-50 dark:bg-slate-800 rounded-lg p-4">
                        <div className="space-y-2">
                          {endpoint.parameters.map((param) => (
                            <div key={param.name} className="flex items-start">
                              <code className="text-sm font-mono text-blue-600 dark:text-blue-400 min-w-[100px]">
                                {param.name}
                              </code>
                              <span className="text-sm text-gray-600 dark:text-gray-400 ml-4">
                                {param.type}
                                {param.required && (
                                  <span className="text-red-500 ml-1">*</span>
                                )}
                                {' - '}
                                {param.description}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="mt-4">
                      <h4 className="text-sm font-medium text-gray-900 dark:text-white mb-2">
                        Response
                      </h4>
                      <pre className="bg-gray-50 dark:bg-slate-800 rounded-lg p-4 overflow-x-auto">
                        <code className="text-sm font-mono text-gray-900 dark:text-white">
                          {endpoint.response}
                        </code>
                      </pre>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* CTA Section */}
        <div className="mt-16 text-center">
          <div className="bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-8">
            <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              Ready to Get Started?
            </h3>
            <p className="text-gray-600 dark:text-gray-400 mb-6">
              Sign up now to get your API key and start building with GALAX AI
            </p>
            <Button variant="primary" size="lg" icon={ArrowRight}>
              Get API Access
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}