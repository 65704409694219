import React, { useState } from 'react';
import { Image, Wand2, Settings2, Download, Copy, Share2, Loader2 } from 'lucide-react';
import Button from '../../ui/Button';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../../ui/Tabs';
import StyleControls from './StyleControls';
import PromptHelper from './PromptHelper';
import ImagePreview from './ImagePreview';
import BatchSettings from './BatchSettings';

export default function ImageGenerator() {
  const [prompt, setPrompt] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedImages, setGeneratedImages] = useState<string[]>([]);

  const handleGenerate = async () => {
    setIsGenerating(true);
    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 2000));
    setGeneratedImages([
      'https://images.unsplash.com/photo-1637858868799-7f26a0640eb6',
      'https://images.unsplash.com/photo-1637858868799-7f26a0640eb7',
      'https://images.unsplash.com/photo-1637858868799-7f26a0640eb8'
    ]);
    setIsGenerating(false);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid lg:grid-cols-[2fr,1fr] gap-8">
          {/* Main Content */}
          <div className="space-y-6">
            {/* Prompt Input */}
            <div className="bg-white dark:bg-slate-900 rounded-xl p-6 border border-gray-200 dark:border-gray-800">
              <div className="flex items-center mb-4">
                <Image className="w-5 h-5 text-blue-600 dark:text-blue-400 mr-2" />
                <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Create Image
                </h2>
              </div>
              
              <textarea
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="Describe the image you want to create..."
                className="w-full h-32 px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-slate-800 focus:ring-2 focus:ring-blue-500 resize-none"
              />
              
              <div className="flex items-center justify-between mt-4">
                <PromptHelper onPromptSelect={(p) => setPrompt(p)} />
                <Button
                  variant="primary"
                  size="lg"
                  icon={isGenerating ? Loader2 : Wand2}
                  onClick={handleGenerate}
                  loading={isGenerating}
                >
                  {isGenerating ? 'Generating...' : 'Generate Image'}
                </Button>
              </div>
            </div>

            {/* Generated Images */}
            {generatedImages.length > 0 && (
              <div className="bg-white dark:bg-slate-900 rounded-xl p-6 border border-gray-200 dark:border-gray-800">
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                  {generatedImages.map((image, index) => (
                    <ImagePreview
                      key={index}
                      src={image}
                      onDownload={() => {}}
                      onCopy={() => {}}
                      onShare={() => {}}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* Controls Sidebar */}
          <div className="space-y-6">
            <div className="bg-white dark:bg-slate-900 rounded-xl border border-gray-200 dark:border-gray-800">
              <Tabs defaultValue="style">
                <TabsList className="p-2 border-b border-gray-200 dark:border-gray-800">
                  <TabsTrigger value="style">Style Controls</TabsTrigger>
                  <TabsTrigger value="batch">Batch Settings</TabsTrigger>
                </TabsList>
                <div className="p-4">
                  <TabsContent value="style">
                    <StyleControls />
                  </TabsContent>
                  <TabsContent value="batch">
                    <BatchSettings />
                  </TabsContent>
                </div>
              </Tabs>
            </div>

            {/* Usage Stats */}
            <div className="bg-white dark:bg-slate-900 rounded-xl p-6 border border-gray-200 dark:border-gray-800">
              <h3 className="text-sm font-medium text-gray-900 dark:text-white mb-4">
                Usage This Month
              </h3>
              <div className="space-y-4">
                <div>
                  <div className="flex justify-between text-sm mb-1">
                    <span className="text-gray-600 dark:text-gray-400">Images Generated</span>
                    <span className="font-medium text-gray-900 dark:text-white">45/100</span>
                  </div>
                  <div className="h-2 bg-gray-100 dark:bg-gray-800 rounded-full">
                    <div className="h-full w-[45%] bg-blue-500 dark:bg-blue-400 rounded-full" />
                  </div>
                </div>
                <div>
                  <div className="flex justify-between text-sm mb-1">
                    <span className="text-gray-600 dark:text-gray-400">Storage Used</span>
                    <span className="font-medium text-gray-900 dark:text-white">2.1/5 GB</span>
                  </div>
                  <div className="h-2 bg-gray-100 dark:bg-gray-800 rounded-full">
                    <div className="h-full w-[42%] bg-violet-500 dark:bg-violet-400 rounded-full" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}