import React from 'react';

interface PricingLayoutProps {
  children: React.ReactNode;
}

export default function PricingLayout({ children }: PricingLayoutProps) {
  return (
    <div className="min-h-screen pt-16 bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />
      <div className="absolute inset-y-0 right-0 w-1/2 bg-[radial-gradient(circle_at_70%_50%,rgba(120,119,198,0.05),rgba(0,0,0,0))]" />
      
      {/* Content */}
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {children}
      </div>
    </div>
  );
}