import React, { useState } from 'react';
import { Github, Twitter, Linkedin, Mail, Heart, MapPin, Phone, Globe, ArrowRight } from 'lucide-react';
import GalaxLogo from './header/GalaxLogo';
import { useRouter } from '../hooks/useRouter';
import { ROUTES } from '../utils/routes';
import Button from '../components/ui/Button';
import { trackConversion } from '../utils/analytics';

const footerLinks = {
  product: [
    { label: 'AI Tools', href: ROUTES.SIGNUP },
    { label: 'Workspace', href: ROUTES.WORKSPACE.ROOT },
    { label: 'Venture Marketplace', href: ROUTES.VENTURE_MARKETPLACE },
    { label: 'Career Calculator', href: '#career-calculator' },
    { label: 'AI Development', href: ROUTES.AI_SOFTWARE_DEV },
    { label: 'Tools Directory', href: ROUTES.TOOLS_DIRECTORY },
    { label: 'Pricing', href: ROUTES.PRICING },
    { label: 'Enterprise', href: ROUTES.ENTERPRISE },
    { label: 'API Reference', href: ROUTES.API },
    { label: 'Documentation', href: ROUTES.DOCUMENTATION }
  ],
  solutions: [
    { label: 'Enterprise', href: ROUTES.ENTERPRISE },
    { label: 'Venture Builder', href: ROUTES.VENTURE },
    { label: 'Government', href: ROUTES.GOVERNMENT_SOLUTIONS },
    { label: 'Case Studies', href: ROUTES.CASE_STUDIES }
  ],
  company: [
    { label: 'About Us', href: ROUTES.ABOUT },
    { label: 'Impact', href: ROUTES.IMPACT },
    { label: 'Careers', href: ROUTES.CAREERS },
    { label: 'Press Kit', href: ROUTES.PRESS_KIT },
    { label: 'Admin', href: ROUTES.ADMIN.LOGIN },
    { label: 'Contact', href: ROUTES.CONTACT }
  ],
  legal: [
    { label: 'Privacy Policy', href: ROUTES.PRIVACY },
    { label: 'Terms of Service', href: ROUTES.TERMS },
    { label: 'Security', href: ROUTES.SECURITY },
    { label: 'Status', href: ROUTES.STATUS },
    { label: 'Compliance', href: ROUTES.COMPLIANCE }
  ]
};

const socialLinks = [
  { icon: Github, href: '#', label: 'GitHub', color: 'hover:text-[#333]' },
  { icon: Twitter, href: '#', label: 'Twitter', color: 'hover:text-[#1DA1F2]' },
  { icon: Linkedin, href: 'https://www.linkedin.com/company/galax-ai-studios', label: 'LinkedIn', color: 'hover:text-[#0A66C2]' },
  { icon: Mail, href: '#', label: 'Email', color: 'hover:text-blue-600' }
];

const locations = [
  {
    name: 'Utah Headquarters',
    address: '10718 South Beckstead, Suite 200',
    city: 'South Jordan',
    state: 'Utah',
    zip: '84095',
    icon: MapPin
  },
  {
    name: 'Dallas Office',
    address: 'Trammell Crow Building, 7th Floor',
    city: 'Dallas',
    state: 'Texas',
    zip: '75201',
    icon: MapPin
  },
  {
    name: 'Puerto Rico Office',
    address: '1225 Avenida Juan Ponce de León',
    city: 'San Juan',
    state: 'Puerto Rico',
    zip: '00907',
    icon: Globe
  }
];

const stats = [
  { value: '500+', label: 'AI Tools' },
  { value: '5K+', label: 'Active Users' },
  { value: '99.9%', label: 'Uptime' },
  { value: '24/7', label: 'Support' }
];

export default function Footer() {
  const { navigate } = useRouter();
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubscribe = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (!email) return;
    
    trackConversion({
      type: 'form_submit',
      page: window.location.pathname as any,
      data: { form: 'newsletter', email }
    });
    
    // Clear form
    setEmail('');
  };

  return (
    <footer className="relative bg-gradient-to-b from-white to-gray-50 dark:from-slate-900 dark:to-slate-950 border-t border-gray-200 dark:border-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 pb-safe-bottom">
        {/* Newsletter Section */}
        <div className="max-w-2xl mx-auto text-center mb-8 sm:mb-12 px-4">
          <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-4">
            Get AI Industry Updates
          </h3>
          <p className="text-gray-600 dark:text-gray-400 mb-6">
            Stay ahead with weekly insights on AI tools and industry trends
          </p>
          <form onSubmit={handleSubscribe} className="flex flex-col sm:flex-row gap-4 max-w-md mx-auto">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="w-full sm:flex-1 px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
              required
            />
            <Button 
              variant="primary" 
              type="submit" 
              icon={ArrowRight}
              loading={isSubmitting}
              className="w-full sm:w-auto"
            >
              Subscribe
            </Button>
          </form>
        </div>

        {/* Stats Section */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 sm:gap-8 mb-8 sm:mb-12 text-center px-4">
          {stats.map((stat) => (
            <div key={stat.label}>
              <div className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-violet-600 dark:from-blue-400 dark:to-violet-400">
                {stat.value}
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400">
                {stat.label}
              </div>
            </div>
          ))}
        </div>

        {/* Main footer content */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-8 pb-8 sm:pb-12 px-4">
          {/* Logo and description */}
          <div className="col-span-1 sm:col-span-2">
            <button
              onClick={() => navigate(ROUTES.HOME)}
              className="hover:opacity-80 transition-all duration-300 transform hover:scale-105"
              aria-label="Go to home page"
            >
              <GalaxLogo />
            </button>
            <p className="mt-4 text-gray-600 dark:text-gray-400 text-sm max-w-md">
              Empowering creators and enterprises with Web3-ready AI solutions—multimodal, scalable, and secure.
            </p>
            
            {/* Locations */}
            <div className="mt-6 space-y-4 mb-8 sm:mb-0">
              {locations.map((location) => (
                <div key={location.name} className="flex items-start space-x-3 text-sm">
                  <location.icon className="w-4 h-4 text-blue-600 dark:text-blue-400 mt-1 flex-shrink-0" />
                  <div className="text-gray-600 dark:text-gray-400">
                    <strong className="text-gray-900 dark:text-white">{location.name}</strong><br />
                    {location.address}<br />
                    {location.city}, {location.state} {location.zip}
                  </div>
                </div>
              ))}
            </div>
            
            {/* Social links */}
            <div className="mt-6 flex space-x-6">
              {socialLinks.map(({ icon: Icon, href, label, color }) => (
                <a
                  key={label}
                  href={href}
                  className={`p-2 text-gray-600 dark:text-gray-400 ${color} transform hover:scale-110 transition-all duration-300`}
                  aria-label={label}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon className="w-5 h-5" />
                </a>
              ))}
            </div>
          </div>

          {/* Footer links */}
          {Object.entries(footerLinks).map(([category, links]) => (
            <div key={category} className="col-span-1 space-y-6">
              <h3 className="text-sm font-semibold text-gray-900 dark:text-white uppercase tracking-wider mb-4">
                {category}
              </h3>
              <ul className="space-y-4">
                {links.map(({ label, href }) => (
                  <li key={label}>
                    <a
                      href={label === 'Admin' ? ROUTES.ADMIN.LOGIN : undefined}
                      onClick={(e) => {
                        if (label !== 'Admin') {
                          e.preventDefault();
                          navigate(href);
                        }
                      }} 
                      className="cursor-pointer text-gray-600 hover:text-blue-600 dark:text-gray-400 dark:hover:text-blue-400 transition-all duration-300 text-sm hover:translate-x-1 inline-block py-1"
                    >
                      {label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Bottom bar */}
        <div className="pt-6 sm:pt-8 border-t border-gray-200 dark:border-gray-800 bg-gradient-to-r from-transparent via-gray-200/20 dark:via-gray-700/20 to-transparent px-4">
          <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0 text-center sm:text-left">
            <div className="flex items-center space-x-1 text-sm text-gray-600 dark:text-gray-400">
              <span>Made with</span>
              <Heart className="w-4 h-4 text-red-500 animate-pulse hover:scale-125 transition-transform duration-300 cursor-pointer" />
              <span>by</span>
              <span className="font-semibold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-violet-600 dark:from-blue-400 dark:to-violet-400">GALAX Studios</span>
            </div>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              © {new Date().getFullYear()} GALAX Studios. All rights reserved.
            </p>
          </div>
          <div className="text-center mt-4 sm:mt-6 text-sm text-gray-500 dark:text-gray-400 pb-4">
            <span className="inline-flex items-center">
              <span className="mr-1">🇺🇸</span> Proudly Made in America
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}