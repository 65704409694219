import React, { useState } from 'react';
import { Building2, DollarSign, Target, ArrowRight, Users, Star, ChartBar, Filter, Search, Briefcase, Globe, Shield } from 'lucide-react';
import { ROUTES } from '../utils/routes';
import Button from '../components/ui/Button';

const investorStats = [
  { value: '25K+', label: 'Active Investors' },
  { value: '$2.3B+', label: 'Capital Deployed' },
  { value: '45+', label: 'Countries' },
  { value: '92%', label: 'Success Rate' }
];

const investorTypes = [
  {
    name: 'Angel Investors',
    icon: Users,
    firms: [
      { name: 'AI Angels Network', focus: 'Early-stage AI/ML', stage: 'Pre-seed/Seed', checkSize: '$50K-500K' },
      { name: 'Tech Founders Club', focus: 'B2B SaaS, AI', stage: 'Seed', checkSize: '$100K-1M' },
      { name: 'ML Investors Group', focus: 'Machine Learning', stage: 'Pre-seed', checkSize: '$25K-250K' }
    ]
  },
  {
    name: 'Micro VCs',
    icon: Building2,
    firms: [
      { name: 'AI Seed Fund', focus: 'AI Infrastructure', stage: 'Seed', checkSize: '$250K-2M' },
      { name: 'Deep Tech Ventures', focus: 'AI/ML, Robotics', stage: 'Seed-Series A', checkSize: '$500K-3M' },
      { name: 'Future Stack Capital', focus: 'Enterprise AI', stage: 'Seed', checkSize: '$1M-5M' }
    ]
  },
  {
    name: 'Strategic Investors',
    icon: Target,
    firms: [
      { name: 'AI First Capital', focus: 'Applied AI', stage: 'Seed-Series A', checkSize: '$1M-10M' },
      { name: 'Deep Learning Fund', focus: 'AI Research', stage: 'Seed+', checkSize: '$2M-15M' },
      { name: 'Neural Ventures', focus: 'AI Platforms', stage: 'Series A', checkSize: '$5M-20M' }
    ]
  }
];

const filters = [
  { name: 'Stage', options: ['Pre-seed', 'Seed', 'Series A', 'Series B', 'Growth'] },
  { name: 'Industry', options: ['Enterprise AI', 'Data Infrastructure', 'Cybersecurity', 'FinTech'] },
  { name: 'Deal Size', options: ['$1M-5M', '$5M-10M', '$10M-25M', '$25M+'] }
];

export default function InvestorNetwork() {
  const [activeFilters, setActiveFilters] = useState<Record<string, string>>({});
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <div className="min-h-screen bg-white dark:from-[#0d0b1f] dark:via-[#141034] dark:to-[#0d0b1f] pt-24 relative overflow-hidden">
      {/* Web3 Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(120,119,198,0.03),rgba(0,0,0,0))] dark:bg-[radial-gradient(circle_at_30%_20%,rgba(120,119,198,0.1),rgba(0,0,0,0))]" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_80%,rgba(147,51,234,0.03),rgba(0,0,0,0))] dark:bg-[radial-gradient(circle_at_70%_80%,rgba(147,51,234,0.1),rgba(0,0,0,0))]" />
        <div className="absolute inset-0 bg-[conic-gradient(from_0deg_at_50%_50%,rgba(139,92,246,0.02)_0deg,rgba(147,51,234,0.02)_120deg,rgba(168,85,247,0.02)_240deg)] dark:bg-[conic-gradient(from_0deg_at_50%_50%,rgba(139,92,246,0.05)_0deg,rgba(147,51,234,0.05)_120deg,rgba(168,85,247,0.05)_240deg)]" />
      </div>
      
      {/* Animated Particles */}
      <div className="absolute inset-0 overflow-hidden">
        {[...Array(20)].map((_, i) => (
          <div
            key={i}
            className="absolute animate-float"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 5}s`,
              animationDuration: `${5 + Math.random() * 5}s`
            }}
          >
            <div className="w-1 h-1 bg-blue-400/20 dark:bg-blue-400/30 rounded-full" />
          </div>
        ))}
      </div>
      
      {/* Glowing Orbs */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="orb w-64 h-64 text-blue-400/10 dark:text-blue-500/10" 
          style={{ top: '15%', left: '10%', animationDelay: '0s' }} />
        <div className="orb w-96 h-96 text-violet-400/10 dark:text-violet-500/10" 
          style={{ top: '40%', right: '15%', animationDelay: '-4s' }} />
        <div className="orb w-48 h-48 text-indigo-400/10 dark:text-indigo-500/10" 
          style={{ bottom: '20%', left: '25%', animationDelay: '-8s' }} />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <Briefcase className="w-6 h-6 text-blue-600 dark:text-blue-400" />
          </div>
          <h1 className="text-4xl sm:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-violet-600 to-purple-600 dark:from-blue-400 dark:via-violet-400 dark:to-purple-400 [text-shadow:0_4px_8px_rgba(0,0,0,0.1)] dark:[text-shadow:0_4px_8px_rgba(0,0,0,0.2)]">
            Connect with 25,000+ Elite AI Investors
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto mb-8 leading-relaxed">
            Join our exclusive network of angel investors, VCs, and strategic partners actively investing in AI ventures. Get direct access to capital, strategic guidance, and industry connections.
          </p>

          {/* Quick Stats */}
          <div className="grid grid-cols-4 gap-6 max-w-4xl mx-auto">
            {investorStats.map(stat => (
              <div key={stat.label} className="group relative bg-gray-50/80 dark:bg-slate-900/80 backdrop-blur-sm rounded-xl p-6 border border-gray-200/50 dark:border-gray-800/50 hover:border-blue-500/30 dark:hover:border-blue-400/30 transition-all duration-300 hover:scale-105">
                <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-violet-500/5 dark:from-blue-400/5 dark:to-violet-400/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
                <div className="relative">
                <div className="text-2xl font-bold text-gray-900 dark:text-white mb-1">{stat.value}</div>
                <div className="text-sm text-gray-600 dark:text-gray-400">{stat.label}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Investor Categories */}
        <div className="mb-16">
          <div className="grid md:grid-cols-3 gap-8">
            {investorTypes.map((category) => (
              <div key={category.name} className="bg-gray-50 dark:bg-slate-900 rounded-xl p-8 shadow-lg border border-gray-200/50 dark:border-gray-800/50">
                <div className="flex items-center mb-6">
                  <div className="p-2 rounded-lg bg-blue-50 dark:bg-blue-900/20">
                    <category.icon className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                  </div>
                  <h2 className="text-xl font-bold text-gray-900 dark:text-white ml-3">
                    {category.name}
                  </h2>
                </div>

                <div className="space-y-6">
                  {category.firms.map(firm => (
                    <div key={firm.name} className="border-t border-gray-200 dark:border-gray-800 pt-4">
                      <h3 className="font-semibold text-gray-900 dark:text-white mb-1">
                        {firm.name}
                      </h3>
                      <p className="text-sm text-blue-600 dark:text-blue-400 mb-1">
                        {firm.focus}
                      </p>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-600 dark:text-gray-400">
                          {firm.stage}
                        </span>
                        <span className="font-medium text-gray-900 dark:text-white">
                          {firm.checkSize}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Filters & Search */}
        <div className="mb-8 flex gap-4">
          <div className="flex gap-2">
            {filters.map(filter => (
              <div key={filter.name} className="relative">
                <button className="flex items-center gap-2 px-4 py-2 bg-white dark:bg-slate-900 border border-gray-200 dark:border-gray-800 rounded-lg text-sm">
                  <Filter className="w-4 h-4 text-gray-600 dark:text-gray-400" />
                  {filter.name}
                </button>
              </div>
            ))}
          </div>
          <div className="flex-grow">
            <div className="relative">
              <input
                type="text"
                placeholder="Search investors, firms, or industries"
                className="w-full px-4 py-2 bg-white dark:bg-slate-900 border border-gray-200 dark:border-gray-800 rounded-lg text-sm"
              />
              <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-600 dark:text-gray-400" />
            </div>
          </div>
        </div>

      {/* Portfolio Performance Section */}
      <div className="mb-16">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
            Portfolio Performance Highlights
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Track record of successful AI venture investments
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {[
            {
              company: 'AI Vision Pro',
              logo: 'https://images.unsplash.com/photo-1560179707-f14e90ef3623?w=300',
              return: '428%',
              invested: '$2.5M',
              stage: 'Series A',
              exit: '$12.7M',
              timeframe: '18 months'
            },
            {
              company: 'Neural Systems',
              logo: 'https://images.unsplash.com/photo-1551434678-e076c223a692?w=300',
              return: '312%',
              invested: '$1.8M',
              stage: 'Seed',
              exit: '$7.2M',
              timeframe: '24 months'
            },
            {
              company: 'DataFlow AI',
              logo: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?w=300',
              return: '275%',
              invested: '$3.2M',
              stage: 'Series A',
              exit: '$15.1M',
              timeframe: '22 months'
            }
          ].map((investment) => (
            <div key={investment.company} 
              className="group relative bg-gray-50/90 dark:bg-slate-900/90 backdrop-blur-sm rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 hover:scale-[1.02] border border-gray-200/50 dark:border-gray-800/50 overflow-hidden"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-violet-500/5 dark:from-blue-400/5 dark:to-violet-400/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
              
              <div className="relative">
                <div className="flex items-center mb-6">
                  <img
                    src={investment.logo}
                    alt={investment.company}
                    className="w-16 h-16 rounded-xl object-cover mr-4"
                  />
                  <div>
                    <h3 className="text-xl font-bold text-gray-900 dark:text-white">
                      {investment.company}
                    </h3>
                    <span className="text-blue-600 dark:text-blue-400 text-sm">
                      {investment.stage}
                    </span>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4 mb-6">
                  <div className="bg-green-50 dark:bg-green-900/20 rounded-lg p-4">
                    <div className="text-2xl font-bold text-green-600 dark:text-green-400">
                      {investment.return}
                    </div>
                    <div className="text-sm text-gray-600 dark:text-gray-400">
                      ROI
                    </div>
                  </div>
                  <div className="bg-blue-50 dark:bg-blue-900/20 rounded-lg p-4">
                    <div className="text-2xl font-bold text-blue-600 dark:text-blue-400">
                      {investment.exit}
                    </div>
                    <div className="text-sm text-gray-600 dark:text-gray-400">
                      Exit Value
                    </div>
                  </div>
                </div>

                <div className="flex justify-between text-sm text-gray-600 dark:text-gray-400">
                  <span>Initial: {investment.invested}</span>
                  <span>Timeline: {investment.timeframe}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

        {/* CTA Section */}
        <div className="text-center">
          <div className="bg-gradient-to-br from-gray-50 to-gray-100 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-12 max-w-4xl mx-auto">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              Launch Your AI Venture with Technical Co-Founding Support
            </h2>
            <p className="text-lg text-gray-600 dark:text-gray-400 mb-8 max-w-2xl mx-auto">
              Get elite technical co-founders, custom AI development, and direct introductions to our network of 25,000+ investors
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <Button
                variant="primary"
                size="lg"
                icon={ArrowRight}
                onClick={() => window.location.replace(ROUTES.VENTURE_BUILDER)}
                className="group"
              >
                <span className="relative">
                  Start Your AI Venture
                  <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/[0.1] to-transparent translate-x-[-200%] group-hover:translate-x-[200%] transition-transform duration-[400ms]" />
                </span>
              </Button>
              <Button
                variant="outline"
                size="lg"
                icon={ArrowRight}
              >
                Schedule Investor Call
              </Button>
            </div>
            <div className="mt-6 flex items-center justify-center space-x-6 text-sm text-gray-500">
              <div className="flex items-center">
                <Shield className="w-4 h-4 text-green-500 mr-1" />
                7 Day Free Trial
              </div>
              <div className="flex items-center">
                <Users className="w-4 h-4 text-blue-500 mr-1" />
                25K+ Investors
              </div>
              <div className="flex items-center">
                <Star className="w-4 h-4 text-yellow-400 mr-1" />
                98% Success Rate
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}