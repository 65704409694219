import React, { useState, useEffect } from 'react';
import { Calculator, GraduationCap, Rocket, DollarSign, Clock, Brain, Target, CheckCircle2, ArrowRight, LineChart, TrendingUp, Shield, Star, Users } from 'lucide-react';
import Button from '../ui/Button';
import { useRouter } from '../../hooks/useRouter';
import { ROUTES } from '../../utils/routes';

const ACTIVE_USERS = Math.floor(Math.random() * 50) + 350;

export default function PricingHero() {
  const [timeLeft, setTimeLeft] = useState(900); // 15 minutes

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(time => time > 0 ? time - 1 : 0);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className="relative min-h-[90vh] flex items-center justify-center overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(59,130,246,0.03),rgba(0,0,0,0))]" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_80%,rgba(139,92,246,0.03),rgba(0,0,0,0))]" />
        
        {/* Animated Particles */}
        {[...Array(20)].map((_, i) => (
          <div
            key={i}
            className="absolute w-1 h-1 bg-blue-400/20 dark:bg-blue-400/30 rounded-full animate-float"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              animationDelay: `${Math.random() * 5}s`,
              animationDuration: `${5 + Math.random() * 5}s`
            }}
          />
        ))}
      </div>

      {/* Glowing Orbs */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="orb w-96 h-96 text-blue-400/10 dark:text-blue-900/10" 
          style={{ top: '10%', left: '5%', animationDelay: '0s' }} />
        <div className="orb w-[40rem] h-[40rem] text-violet-400/10 dark:text-violet-900/10" 
          style={{ top: '30%', right: '10%', animationDelay: '-4s' }} />
        <div className="orb w-80 h-80 text-indigo-400/10 dark:text-indigo-900/10" 
          style={{ bottom: '15%', left: '20%', animationDelay: '-8s' }} />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-center max-w-4xl mx-auto">
          {/* Animated Gradient Badge */}
          <div className="inline-flex items-center justify-center p-3 mb-8 rounded-2xl bg-gradient-to-r from-blue-600/10 to-violet-600/10 dark:from-blue-400/10 dark:to-violet-400/10 backdrop-blur-sm border border-blue-200/50 dark:border-blue-800/50 animate-pulse-slow">
            <div className="px-6 py-2 rounded-xl bg-gradient-to-r from-blue-600 to-violet-600 text-white font-medium">
              Enterprise-Grade AI Solutions
            </div>
          </div>

          <h1 className="text-4xl sm:text-5xl font-extrabold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-violet-600 to-purple-600 dark:from-blue-400 dark:via-violet-400 dark:to-purple-400">
            Scalable Solutions, Tailored Pricing: Build, Innovate, and Lead with AI
          </h1>
          
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-2xl mx-auto leading-relaxed">
            Whether you're launching a startup, optimizing enterprise workflows, or tackling complex government initiatives, our flexible pricing adapts to your goals. Unlock the future of AI—your way.
          </p>

          {/* Milestone Steps */}
          <div className="grid grid-cols-4 gap-8 max-w-5xl mx-auto mt-20 relative">
            {/* Connecting Line */}
            <div className="absolute top-1/2 left-0 right-0 h-1 bg-gradient-to-r from-blue-500/10 via-violet-500/20 to-blue-500/10 dark:from-blue-400/10 dark:via-violet-400/20 dark:to-blue-400/10 transform -translate-y-1/2">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/50 via-violet-500/50 to-blue-500/50 dark:from-blue-400/30 dark:via-violet-400/30 dark:to-blue-400/30 animate-pulse-slow" />
            </div>
            
            {[
              {
                label: 'Idea',
                icon: Brain,
                description: 'Transform your vision into a production-ready AI solution',
                metrics: { timeline: '2-4 weeks', success: '98%' },
                features: ['Custom AI Development', 'Technical Co-Founding', 'MVP Launch']
              },
              {
                label: 'Validation',
                icon: Target,
                description: 'Validate product-market fit with real users & data',
                metrics: { timeline: '4-8 weeks', traction: '10x' },
                features: ['Market Testing', 'User Analytics', 'Rapid Iteration']
              },
              {
                label: 'Funding',
                icon: DollarSign,
                description: 'Secure investment with proven AI metrics',
                metrics: { avg: '$2.1M', success: '92%' },
                features: ['Investor Network', 'Due Diligence', 'Growth Strategy']
              },
              {
                label: 'Scalability',
                icon: TrendingUp,
                description: 'Scale your AI venture with enterprise infrastructure',
                metrics: { growth: '10x', global: '45+ countries' },
                features: ['Global Infrastructure', 'Enterprise Sales', 'Technical Team']
              }
            ].map((step, index) => (
              <div key={step.label} className="relative">
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-4 h-4 rounded-full bg-gradient-to-r from-blue-500 to-violet-500 dark:from-blue-400 dark:to-violet-400">
                  <div className="absolute inset-0 rounded-full animate-ping-slow opacity-75 bg-gradient-to-r from-blue-400 to-violet-400 blur-lg" />
                  <div className="absolute inset-0 rounded-full bg-gradient-to-r from-blue-400/50 to-violet-400/50 animate-pulse" />
                </div>
                <div className={`relative pt-8 text-center transform transition-transform duration-300 hover:scale-105 ${
                  index % 2 === 0 ? 'mt-8' : '-mt-32'
                }`}>
                  <div className="w-14 h-14 mx-auto rounded-xl bg-gradient-to-br from-blue-100 to-violet-100 dark:from-blue-900/30 dark:to-violet-900/30 p-3.5 mb-4 transform hover:scale-110 transition-transform duration-300 shadow-lg hover:shadow-xl group">
                    <step.icon className="w-full h-full text-blue-600 dark:text-blue-400" />
                  </div>
                  <div className="space-y-2">
                    <div className="font-semibold text-gray-900 dark:text-white bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-violet-600 dark:from-blue-400 dark:to-violet-400">
                      {step.label}
                    </div>
                    <p className="text-sm text-gray-600 dark:text-gray-400 max-w-[200px] mx-auto">
                      {step.description}
                    </p>
                    {/* Features List */}
                    <div className="text-xs text-gray-500 dark:text-gray-400 space-y-1 mt-2">
                      {step.features.map((feature, i) => (
                        <div key={i} className="flex items-center justify-center">
                          <div className="w-1 h-1 rounded-full bg-blue-500 dark:bg-blue-400 mr-1.5" />
                          {feature}
                        </div>
                      ))}
                    </div>
                    <div className="flex justify-center gap-3 text-xs">
                      {Object.entries(step.metrics).map(([key, value]) => (
                        <div key={key} className="px-2.5 py-1.5 rounded-full bg-gradient-to-r from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 border border-blue-100/50 dark:border-blue-800/50 shadow-sm">
                          <span className="font-medium text-blue-600 dark:text-blue-400">
                            {value}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}