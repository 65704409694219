import React from 'react';
import { GovernmentErrorBoundary } from '../components/government/ErrorBoundary';
import EligibilityChecker from '../components/government/EligibilityChecker';

export default function GovernmentAssessment() {
  return (
    <GovernmentErrorBoundary section="assessment">
      <div className="min-h-screen bg-gray-50 dark:bg-slate-950 pt-24">
        <EligibilityChecker />
      </div>
    </GovernmentErrorBoundary>
  );
}