import React from 'react';
import { GovernmentErrorBoundary } from '../components/government/ErrorBoundary';
import GovernmentHero from '../components/government/GovernmentHero';
import GovernmentSolutions from '../components/government/GovernmentSolutions';
import ExpertiseSection from '../components/government/ExpertiseSection';
import PastPerformance from '../components/government/PastPerformance';
import GrantsGrid from '../components/government/GrantsGrid';
import ProjectsShowcase from '../components/government/ProjectsShowcase';
import ResourcesSection from '../components/government/ResourcesSection';
import ConsultationScheduler from '../components/government/ConsultationScheduler';
import SecurityMonitor from '../components/government/SecurityMonitor';
import ComplianceStatus from '../components/government/ComplianceStatus';

export default function Government() {
  return (
    <GovernmentErrorBoundary section="main">
      <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-32">
        <GovernmentHero />
        
        {/* Past Performance Section */}
        <PastPerformance />
        
        {/* Security & Compliance Section */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 space-y-12">
          <SecurityMonitor />
          <ComplianceStatus />
        </div>
        
        <GovernmentSolutions />
        <ExpertiseSection />
        <GrantsGrid />
        <ProjectsShowcase />
        <ResourcesSection />
        <ConsultationScheduler />
      </div>
    </GovernmentErrorBoundary>
  );
}