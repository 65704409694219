import React, { useState, useEffect } from 'react';
import { Brain, Shield, ArrowRight, Users, DollarSign, CheckCircle2, Clock, Star, Zap, Target, FileText, ShoppingBag, Code2 } from 'lucide-react';
import { formatTime } from '../utils/formatters';
import Button from '../components/ui/Button';
import { updateFunnelStep } from '../utils/funnelTracking';
import { useRouter } from '../hooks/useRouter';
import { ROUTES } from '../utils/routes';
import { trackConversion } from '../utils/analytics';

// Constants
const ACTIVE_USERS = Math.floor(Math.random() * 50) + 350;

const steps = [
  { 
    id: 'business', 
    label: 'Your Profile',
    description: 'Find Your Perfect AI Stack',
    icon: Brain
  },
  { 
    id: 'goals', 
    label: 'Growth Goals',
    description: 'Customize Your Solution',
    icon: Target
  }
];

const businessTypes = [
  {
    id: 'content',
    label: 'Content Creator',
    icon: FileText,
    benefits: [
      'Create 30 days of content in 1 hour',
      'AI research & writing (save 40h/mo)',
      'Multi-platform content optimizer',
      'Viral content predictor'
    ],
    savings: '$3,800/month average savings',
    testimonial: '10X content output in first week',
    tools: ['AI Writer', 'Image Generator', 'Video Creator'],
    stats: {
      content: '10x',
      engagement: '+312%',
      timeframe: '7 days'
    }
  },
  {
    id: 'ecommerce',
    label: 'E-commerce',
    icon: ShoppingBag,
    benefits: [
      'Generate 100+ product descriptions/hour',
      'AI-powered email campaigns (32% CTR)',
      'SEO product listings that rank',
      'Visual AI for product photos'
    ],
    savings: '$4,500/month average savings',
    testimonial: 'Generated $12K in first month',
    tools: ['Content Writer', 'Image Generator', 'Email Assistant'],
    stats: {
      customers: '2,500+',
      revenue: '+127%',
      timeframe: '30 days'
    }
  },
  {
    id: 'developer',
    label: 'Developer',
    icon: Code2,
    benefits: [
      'AI-assisted development (2X speed)',
      'Automated technical documentation',
      '24/7 AI customer support bot',
      'API & integration tools'
    ],
    savings: '$6,100/month average savings',
    testimonial: 'Cut development time by 65%',
    tools: ['Code Assistant', 'Doc Generator', 'Support Bot'],
    stats: {
      development: '2x',
      support: '-75%',
      timeframe: '45 days'
    }
  }
];

const BUSINESS_GOALS = [
  {
    id: 'automation',
    label: 'Task Automation',
    value: 'Save 40+ hours/month',
    icon: Zap,
    benefits: [
      { text: 'Automated workflows', metric: '40h saved/mo' },
      { text: 'Process optimization', metric: '75% faster' },
      { text: 'Error reduction', metric: '95% accuracy' }
    ]
  },
  {
    id: 'growth',
    label: 'Business Growth',
    value: '5x output & revenue',
    icon: Target,
    benefits: [
      { text: 'Lead generation', metric: '3x leads' },
      { text: 'Sales automation', metric: '45% more sales' },
      { text: 'Customer engagement', metric: '89% response' }
    ]
  },
  {
    id: 'quality',
    label: 'Quality & Consistency',
    value: '95% quality score',
    icon: Shield,
    benefits: [
      { text: 'AI quality control', metric: '95% accuracy' },
      { text: 'Brand consistency', metric: '100% on-brand' },
      { text: 'Error prevention', metric: '-85% errors' }
    ]
  }
];

export default function ChoosePath() {
  const { navigate } = useRouter();
  const [step, setStep] = useState(1);
  const [selections, setSelections] = useState<Record<string, string[]>>({
    businessType: [],
    goals: []
  });
  const [timeLeft, setTimeLeft] = useState(900); // 15 minutes
  const [loading, setLoading] = useState(false);
  const [confidence, setConfidence] = useState(0);

  useEffect(() => {
    // Initialize funnel state if needed
    const funnelState = localStorage.getItem('funnelState');
    if (!funnelState) {
      initializeFunnel();
    }

    const timer = setInterval(() => {
      setTimeLeft(time => time > 0 ? time - 1 : 0);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    // Track page view
    trackConversion({
      type: 'page_view',
      page: ROUTES.CHOOSE_PLAN,
      data: { step: 'assessment' }
    });
  }, []);

  // Add micro-interactions for selection
  const handleSelection = (type: 'businessType' | 'goals', value: string) => {
    setSelections(prev => {
      const current = prev[type];
      const updated = current.includes(value) 
        ? current.filter(v => v !== value)
        : [...current, value];
      
      // Update confidence score
      setConfidence(Math.min((updated.length / 2) * 100, 100));
      
      // Update funnel with selection
      updateFunnelStep('assessment', {
        [type]: updated,
        step: type === 'businessType' ? 1 : 2
      });
      
      return { ...prev, [type]: updated };
    });
  };

  // Add confidence indicator
  const ConfidenceIndicator = () => (
    <div className="mt-8 max-w-md mx-auto">
      <div className="flex items-center justify-between mb-2">
        <span className="text-sm text-gray-600 dark:text-gray-400">Solution Confidence</span>
        <span className="text-sm font-medium text-blue-600 dark:text-blue-400">{confidence}%</span>
      </div>
      <div className="h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
        <div 
          className="h-full bg-gradient-to-r from-blue-500 to-violet-500 transition-all duration-500"
          style={{ width: `${confidence}%` }}
        />
      </div>
    </div>
  );

  const handleContinue = () => {
    if (step === 1 && selections.businessType.length === 0) {
      return; // Require at least one selection
    }

    if (step === 2) {
      if (selections.goals.length === 0) {
        return; // Require at least one goal
      }
      
      setLoading(true);

      // Store selections and track completion
      localStorage.setItem('assessmentResults', JSON.stringify(selections));
      
      trackConversion({
        type: 'assessment_complete',
        page: ROUTES.ASSESSMENT,
        data: { selections }
      });

      // Redirect to personalized signup
      window.location.href = ROUTES.CHOOSE_PLAN;
      return;
    }

    // Move to next step
    setStep(prev => prev + 1);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-24">
      {/* Urgency Banner */}
      <div className="fixed top-0 left-0 right-0 bg-gradient-to-r from-blue-600 to-violet-600 text-white py-2 text-center z-50">
        <div className="flex items-center justify-center space-x-4">
          <div className="flex items-center">
            <Clock className="w-4 h-4 mr-1" />
            <span>🔥 60% Launch Discount Ends In: {formatTime(timeLeft)}</span>
          </div>
          <div className="hidden sm:flex items-center">
            <Users className="w-4 h-4 mr-1" />
            <span>{ACTIVE_USERS} people viewing</span>
          </div>
        </div>
      </div>

      {/* Value Proposition */}
      <div className="text-center mb-16">
        <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
          <Brain className="w-6 h-6 text-blue-600 dark:text-blue-400 animate-pulse" />
        </div>
        <h1 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 via-violet-600 to-purple-600 dark:from-blue-400 dark:via-violet-400 dark:to-purple-400 mb-4">
          Let's Build Your Perfect AI Stack
        </h1>
        <p className="text-lg text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
          Answer 2 quick questions to get your personalized AI solution and save 40+ hours per month
        </p>
        
        {/* Trust Badges */}
        <div className="flex flex-wrap justify-center gap-8 mt-8">
          <div className="flex items-center">
            <Shield className="w-5 h-5 text-green-500 mr-2" />
            <span className="text-gray-700 dark:text-gray-300">
              <span className="font-medium">7-day</span> free trial
            </span>
          </div>
          <div className="flex items-center">
            <Star className="w-5 h-5 text-yellow-400 fill-yellow-400 mr-2" />
            <span className="text-gray-700 dark:text-gray-300">
              <span className="font-medium">4.9/5</span> from 500+ reviews
            </span>
          </div>
          <div className="flex items-center">
            <CheckCircle2 className="w-5 h-5 text-blue-500 mr-2" />
            <span className="text-gray-700 dark:text-gray-300">
              <span className="font-medium">2-minute</span> setup
            </span>
          </div>
        </div>
      </div>

      <div className="max-w-4xl mx-auto px-4">
        {/* Progress Steps */}
        <div className="max-w-3xl mx-auto mb-12">
          <div className="flex items-center justify-between">
            {steps.map((step, index) => (
              <div key={step.label} className="flex items-center">
                <div className={`w-8 h-8 rounded-full flex items-center justify-center ${
                  index + 1 <= step
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-200 text-gray-400'
                }`}>
                  {index + 1}
                </div>
                {index < steps.length - 1 && (
                  <div className={`w-24 h-1 mx-2 ${
                    index + 1 < step
                      ? 'bg-blue-600'
                      : 'bg-gray-200'
                  }`} />
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Step Content */}
        <div className="max-w-4xl mx-auto">
          {/* Add confidence indicator */}
          <ConfidenceIndicator />
          
          {step === 1 ? (
            <div className="grid md:grid-cols-3 gap-8">
              {businessTypes.map((type) => (
                <button
                  key={type.id}
                  onClick={() => handleSelection('businessType', type.id)}
                  className={`group relative p-6 rounded-xl text-left transition-all duration-300 ${
                    selections.businessType.includes(type.id)
                      ? 'bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 border-2 border-blue-500 shadow-xl scale-[1.02]'
                      : 'bg-white/90 dark:bg-slate-900/90 backdrop-blur-sm border-2 border-gray-200/50 dark:border-gray-700/50 hover:border-blue-300 hover:scale-[1.01] hover:shadow-lg'
                  }`}
                >
                  {/* Success Badge */}
                  {type.testimonial && (
                    <div className="absolute top-3 right-3 text-xs bg-green-100 dark:bg-green-900/30 text-green-700 dark:text-green-300 px-2 py-1 rounded-full animate-pulse">
                      "{type.testimonial}"
                    </div>
                  )}

                  <div className="flex items-start">
                    <div className="p-3 rounded-lg bg-gradient-to-br from-blue-100 to-violet-100 dark:from-blue-900/20 dark:to-violet-900/20">
                      <type.icon className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                    </div>
                    <div className="ml-4 flex-1">
                      <h4 className="text-lg font-semibold text-gray-900 dark:text-white mb-3">
                        {type.label}
                      </h4>

                      {/* Benefits List */}
                      <ul className="space-y-3">
                        {type.benefits.map((benefit, index) => (
                          <li
                            key={index}
                            className="flex items-center justify-between text-sm"
                          >
                            <div className="flex items-center text-gray-700 dark:text-gray-300">
                              <CheckCircle2 className="w-4 h-4 text-green-500 mr-2 flex-shrink-0" />
                              {benefit.text}
                            </div>
                            <span className="text-blue-600 dark:text-blue-400 font-medium ml-2">
                              {benefit.metric}
                            </span>
                          </li>
                        ))}
                      </ul>

                      {/* ROI Preview */}
                      <div className="mt-4 text-sm font-bold bg-gradient-to-r from-green-600 to-emerald-600 dark:from-green-400 dark:to-emerald-400 bg-clip-text text-transparent">
                        {type.savings}
                      </div>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          ) : (
            <div className="grid md:grid-cols-3 gap-8">
              {BUSINESS_GOALS.map((goal) => (
                <button
                  key={goal.id}
                  onClick={() => handleSelection('goals', goal.id)}
                  className={`group relative p-6 rounded-xl text-left transition-all duration-300 ${
                    selections.goals.includes(goal.id)
                      ? 'bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 border-2 border-blue-500 shadow-xl scale-[1.02]'
                      : 'bg-white/90 dark:bg-slate-900/90 backdrop-blur-sm border-2 border-gray-200/50 dark:border-gray-700/50 hover:border-blue-300 hover:scale-[1.01] hover:shadow-lg'
                  }`}
                >
                  <div className="flex items-start">
                    <div className="p-3 rounded-lg bg-gradient-to-br from-blue-100 to-violet-100 dark:from-blue-900/20 dark:to-violet-900/20">
                      <goal.icon className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                    </div>
                    <div className="ml-4 flex-1">
                      <h4 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                        {goal.label}
                      </h4>
                      <p className="text-sm text-blue-600 dark:text-blue-400 mb-4">
                        {goal.value}
                      </p>

                      {/* Benefits List */}
                      <ul className="space-y-3">
                        {goal.benefits.map((benefit, index) => (
                          <li
                            key={index}
                            className="flex items-center justify-between text-sm"
                          >
                            <div className="flex items-center text-gray-700 dark:text-gray-300">
                              <CheckCircle2 className="w-4 h-4 text-green-500 mr-2 flex-shrink-0" />
                              {benefit.text}
                            </div>
                            <span className="text-blue-600 dark:text-blue-400 font-medium">
                              {benefit.metric}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          )}

          {/* Continue Button */}
          <Button
            variant="primary"
            size="lg"
            icon={ArrowRight}
            onClick={handleContinue}
            disabled={confidence < 50}
            className="mt-12 w-full sm:w-auto mx-auto block group"
          >
            <span className="relative">
              {step === 2 ? 'See Your Personalized Solution' : 'Continue'}
              <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/[0.1] to-transparent translate-x-[-200%] group-hover:translate-x-[200%] transition-transform duration-[400ms]" />
            </span>
          </Button>

          {/* Trust Indicators */}
          <div className="mt-6 flex items-center justify-center space-x-6 text-sm text-gray-500 dark:text-gray-400">
            <div className="flex items-center">
              <Shield className="w-4 h-4 text-green-500 mr-1" />
              No credit card required
            </div>
            <div className="flex items-center">
              <Clock className="w-4 h-4 text-blue-500 mr-1" />
              2-minute setup
            </div>
            <div className="flex items-center">
              <Users className="w-4 h-4 text-violet-500 mr-1" />
              50,000+ users
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}