import React from 'react';
import { Moon, Circle, Star, Brain, Heart, DollarSign, Shield, Database } from 'lucide-react';


export default function SpaceBackground() {
  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      {/* Enhanced constellation lines with gradients */}
      <svg className="absolute inset-0 w-full h-full opacity-[0.15]">
        <defs>
          <linearGradient id="line-gradient-1" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="rgb(59, 130, 246)" stopOpacity="0.2" />
            <stop offset="100%" stopColor="rgb(139, 92, 246)" stopOpacity="0.2" />
          </linearGradient>
          <linearGradient id="line-gradient-2" x1="0%" y1="100%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="rgb(139, 92, 246)" stopOpacity="0.2" />
            <stop offset="100%" stopColor="rgb(167, 139, 250)" stopOpacity="0.2" />
          </linearGradient>
          
          {/* Data stream gradients */}
          <linearGradient id="data-stream" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="rgb(59, 130, 246)" stopOpacity="0" />
            <stop offset="50%" stopColor="rgb(59, 130, 246)" stopOpacity="0.1" />
            <stop offset="100%" stopColor="rgb(59, 130, 246)" stopOpacity="0" />
          </linearGradient>
        </defs>

        {/* Industry connection lines */}
        <path
          d="M200,100 C300,150 400,120 500,200"
          className="stroke-[url(#line-gradient-1)]"
          strokeWidth="0.5"
          fill="none"
        />
        <path
          d="M600,300 C700,250 800,300 750,400"
          className="stroke-[url(#line-gradient-2)]"
          strokeWidth="0.5"
          fill="none"
        />
        <path
          d="M100,400 C200,350 300,400 250,500"
          className="stroke-[url(#line-gradient-1)]"
          strokeWidth="0.5"
          fill="none"
        />
        
        {/* Animated data streams */}
        {[...Array(8)].map((_, i) => (
          <rect
            key={i}
            x={100 + i * 100}
            y="0"
            width="2"
            height="100%"
            fill="url(#data-stream)"
            opacity="0.5"
            className="animate-pulse"
            style={{ animationDelay: `${i * 0.2}s` }}
          />
        ))}
      </svg>

      {/* Data Streams */}
      <div className="absolute inset-0">
        {[...Array(12)].map((_, i) => (
          <div
            key={i}
            className="absolute h-full w-0.5 bg-gradient-to-b from-transparent via-blue-400/5 to-transparent animate-pulse"
            style={{
              left: `${(100 / 12) * i}%`,
              animationDelay: `${i * 0.2}s`,
              animationDuration: '3s'
            }}
          />
        ))}
      </div>

      {/* Add nebula effects */}
      <div className="absolute inset-0 opacity-10">
        <div className="absolute top-1/4 -left-1/4 w-1/2 h-1/2 bg-gradient-radial from-violet-500/20 via-violet-500/5 to-transparent blur-3xl animate-pulse-slow" />
        <div className="absolute bottom-1/4 -right-1/4 w-1/2 h-1/2 bg-gradient-radial from-blue-500/20 via-blue-500/5 to-transparent blur-3xl animate-pulse-slow" style={{ animationDelay: '-2s' }} />
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-3/4 h-3/4 bg-gradient-conic from-blue-500/10 via-violet-500/10 to-blue-500/10 blur-3xl animate-spin-slow" />
      </div>

      {/* Enhanced star field */}
      {[...Array(50)].map((_, i) => (
        <div
          key={i}
          className="absolute animate-twinkle"
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            width: `${2 + Math.random() * 2}px`,
            height: `${2 + Math.random() * 2}px`,
            animationDelay: `${Math.random() * 5}s`,
            animationDuration: `${3 + Math.random() * 4}s`,
            backgroundColor: `rgba(${147 + Math.random() * 108}, ${92 + Math.random() * 138}, ${246}, ${0.1 + Math.random() * 0.2})`
          }}
        >
          <div className="absolute inset-0 rounded-full animate-pulse" style={{ animationDelay: `${Math.random() * 2}s` }} />
        </div>
      ))}


      {/* Celestial bodies */}
      <div className="absolute right-[20%] top-[25%] animate-float" style={{ animationDuration: '6s', animationDelay: '-2s' }}>
        <div className="relative">
          <div className="absolute inset-0 blur-xl bg-gradient-to-br from-blue-200/30 to-violet-200/30 dark:from-blue-400/30 dark:to-violet-400/30 rounded-full scale-[1.75] animate-pulse" />
          <Moon className="w-16 h-16 text-blue-100/30 dark:text-blue-400/30 rotate-180 relative" />
        </div>
      </div>

      {/* Stars with enhanced glow */}
      {[...Array(75)].map((_, i) => (
        <div
          key={i}
          className="absolute animate-pulse"
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            animationDuration: `${3 + Math.random() * 4}s`,
            animationDelay: `${Math.random() * 2}s`
          }}
        >
          <div className="relative">
            <div className="absolute inset-0 blur-lg bg-blue-400/40 dark:bg-blue-400/60 rounded-full scale-[2]" />
            <Star
              className="relative text-blue-100/30 dark:text-blue-400/30"
              style={{
                width: `${8 + Math.random() * 8}px`,
                height: `${8 + Math.random() * 8}px`
              }}
              fill="currentColor"
            />
          </div>
        </div>
      ))}
    </div>
  );
}