import React from 'react';
import { DollarSign, Calendar, Users, ArrowRight } from 'lucide-react';
import Button from '../ui/Button';

const grants = [
  {
    title: 'Defense AI Modernization',
    agency: 'Department of Defense',
    amount: '$50M',
    deadline: '2024-06-30',
    eligibility: ['Prime Contractors', 'Cleared Facilities'],
    description: 'Modernizing defense systems with advanced AI capabilities.'
  },
  {
    title: 'Healthcare AI Initiative',
    agency: 'Veterans Affairs',
    amount: '$25M',
    deadline: '2024-07-15',
    eligibility: ['Healthcare Providers', 'Research Institutions'],
    description: 'Improving veteran care through AI-powered diagnostics and treatment.'
  },
  {
    title: 'Cybersecurity AI Program',
    agency: 'CISA',
    amount: '$35M',
    deadline: '2024-08-01',
    eligibility: ['Cybersecurity Firms', 'Critical Infrastructure'],
    description: 'Protecting national infrastructure with AI-powered security.'
  }
];

export default function GrantsGrid() {
  return (
    <div className="py-12 sm:py-16 bg-gradient-to-br from-white via-gray-50 to-white dark:from-slate-900 dark:via-slate-950 dark:to-slate-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-white mb-6 sm:mb-8">
          Available Grants
        </h2>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-8 relative">
          {/* Decorative grid background */}
          <div className="absolute inset-0 bg-gradient-to-b from-blue-50/50 to-transparent dark:from-blue-950/20 dark:to-transparent" />
          
          {grants.map((grant) => (
            <div
              key={grant.title}
              className="relative bg-white/80 dark:bg-slate-800/80 backdrop-blur-sm rounded-xl p-6 hover:shadow-lg transition-all duration-300 hover:scale-[1.02] border border-gray-200/50 dark:border-gray-700/50"
            >
              <div className="flex items-start justify-between mb-4">
                <div>
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-1">
                    {grant.title}
                  </h3>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    {grant.agency}
                  </p>
                </div>
                <div className="bg-gradient-to-br from-blue-100 to-violet-100 dark:from-blue-900/30 dark:to-violet-900/30 p-2 rounded-lg group-hover:scale-110 transition-transform">
                  <DollarSign className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                </div>
              </div>

              <div className="space-y-4 mb-6">
                <div className="flex items-center text-gray-700 dark:text-gray-300">
                  <DollarSign className="w-5 h-5 mr-2 text-green-600 dark:text-green-400" />
                  <span>Amount: {grant.amount}</span>
                </div>
                <div className="flex items-center text-gray-700 dark:text-gray-300">
                  <Calendar className="w-5 h-5 mr-2 text-blue-600 dark:text-blue-400" />
                  <span>Deadline: {new Date(grant.deadline).toLocaleDateString()}</span>
                </div>
                <div className="flex items-center text-gray-700 dark:text-gray-300">
                  <Users className="w-5 h-5 mr-2 text-violet-600 dark:text-violet-400" />
                  <span>Eligibility: {grant.eligibility.join(', ')}</span>
                </div>
              </div>

              <p className="text-gray-600 dark:text-gray-400 mb-6">
                {grant.description}
              </p>

              <Button variant="primary" size="sm" icon={ArrowRight}>
                Apply Now
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}