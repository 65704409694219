import React from 'react';
import { Shield, Lock, Eye, FileText, Database, Users, Bell, Clock } from 'lucide-react';

const sections = [
  {
    title: 'Information We Collect',
    icon: FileText,
    content: [
      'Personal information (name, email, phone number)',
      'Usage data and analytics',
      'Device and browser information',
      'AI model interaction data',
      'Payment information (processed securely through our payment providers)'
    ]
  },
  {
    title: 'How We Use Your Data',
    icon: Database,
    content: [
      'Providing and improving our AI services',
      'Personalizing your experience',
      'Processing payments and transactions',
      'Sending important updates and notifications',
      'Analyzing usage patterns to enhance our platform'
    ]
  },
  {
    title: 'Data Protection',
    icon: Shield,
    content: [
      'Enterprise-grade encryption (AES-256)',
      'Regular security audits and penetration testing',
      'Secure data centers with redundancy',
      'Strict access controls and authentication',
      'Regular backups and disaster recovery'
    ]
  },
  {
    title: 'Your Privacy Rights',
    icon: Lock,
    content: [
      'Access your personal data',
      'Request data deletion',
      'Opt-out of data collection',
      'Export your data',
      'Update your preferences'
    ]
  },
  {
    title: 'Data Sharing',
    icon: Users,
    content: [
      'Never sold to third parties',
      'Shared only with essential service providers',
      'Compliance with legal requirements',
      'Anonymous aggregated data for research',
      'With your explicit consent only'
    ]
  },
  {
    title: 'Communication Preferences',
    icon: Bell,
    content: [
      'Email notifications settings',
      'Marketing communications opt-out',
      'Service updates and announcements',
      'Security alerts',
      'Newsletter preferences'
    ]
  }
];

export default function Privacy() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-24">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <Eye className="w-6 h-6 text-blue-600 dark:text-blue-400" />
          </div>
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Privacy Policy
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300">
            Your privacy is our top priority. Learn how we protect and handle your data.
          </p>
          <div className="mt-6 inline-flex items-center text-sm text-gray-500 dark:text-gray-400">
            <Clock className="w-4 h-4 mr-1" />
            Last updated: {new Date().toLocaleDateString()}
          </div>
        </div>

        {/* Main Content */}
        <div className="space-y-12 mb-16">
          {sections.map((section) => {
            const Icon = section.icon;
            return (
              <div
                key={section.title}
                className="bg-white dark:bg-slate-900 rounded-xl p-6 shadow-lg border border-gray-200/50 dark:border-gray-800/50"
              >
                <div className="flex items-center mb-6">
                  <div className="p-2 rounded-lg bg-blue-50 dark:bg-blue-900/20">
                    <Icon className="w-6 h-6 text-blue-600 dark:text-blue-400" />
                  </div>
                  <h2 className="ml-4 text-xl font-semibold text-gray-900 dark:text-white">
                    {section.title}
                  </h2>
                </div>
                <ul className="space-y-3">
                  {section.content.map((item, index) => (
                    <li
                      key={index}
                      className="flex items-start"
                    >
                      <div className="w-1.5 h-1.5 rounded-full bg-blue-500 dark:bg-blue-400 mt-2 mr-3 flex-shrink-0" />
                      <span className="text-gray-600 dark:text-gray-300">
                        {item}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>

        {/* Contact Section */}
        <div className="bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-8 text-center mb-16">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
            Questions About Your Privacy?
          </h3>
          <p className="text-gray-600 dark:text-gray-300 mb-6">
            Our dedicated privacy team is here to help you with any questions or concerns.
          </p>
          <div className="text-gray-600 dark:text-gray-300 mb-6 space-y-4">
            <div>
              <strong>Utah Headquarters:</strong><br />
              GALAX Studios<br />
              10718 South Beckstead, Suite 200<br />
              South Jordan, Utah 84095
            </div>
            <div>
              <strong>Puerto Rico Office:</strong><br />
              GALAX Studios<br />
              1225 Avenida Juan Ponce de León<br />
              San Juan, 00907, Puerto Rico
            </div>
          </div>
          <a
            href="mailto:privacy@galaxstudios.com"
            className="inline-flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            Contact Privacy Team
          </a>
        </div>

        {/* Footer Note */}
        <div className="text-center text-sm text-gray-500 dark:text-gray-400 mb-8">
          <p>
            This privacy policy is effective as of {new Date().toLocaleDateString()} and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.
          </p>
          <p className="mt-2">
            We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically.
          </p>
        </div>
      </div>
    </div>
  );
}