import React, { useState } from 'react';
import { ArrowRight, Bot, Rocket, Target, CheckCircle2 } from 'lucide-react';
import Button from '../components/ui/Button';

const steps = [
  {
    id: 'business',
    title: 'Select Your Business Type',
    options: [
      { id: 'ai-saas', label: 'AI-First SaaS', description: 'Building AI-powered software products' },
      { id: 'ai-platform', label: 'AI Platform', description: 'Creating enterprise AI solutions' },
      { id: 'ml-startup', label: 'ML Startup', description: 'Developing machine learning products' },
      { id: 'ai-marketplace', label: 'AI Marketplace', description: 'Building AI model marketplace' }
    ]
  },
  {
    id: 'goals',
    title: 'What are your goals?',
    options: [
      { id: 'seed', label: 'Raise Seed Round', description: 'Build MVP and secure funding' },
      { id: 'enterprise', label: 'Enterprise Sales', description: 'Land enterprise clients' },
      { id: 'scale', label: 'Scale Operations', description: 'Build scalable AI infrastructure' },
      { id: 'exit', label: 'Position for Exit', description: 'Prepare for acquisition/IPO' }
    ]
  }
];

export default function AutomationOnboarding() {
  const [currentStep, setCurrentStep] = useState(0);
  const [selections, setSelections] = useState<Record<string, string>>({});
  
  const handleSelection = (stepId: string, optionId: string) => {
    setSelections(prev => ({ ...prev, [stepId]: optionId }));
  };

  const handleContinue = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(prev => prev + 1);
    } else {
      // Store selections and redirect to personalized demo
      localStorage.setItem('automationPreferences', JSON.stringify(selections));
      window.location.href = '/automation/demo';
    }
  };

  const currentStepData = steps[currentStep];

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-24">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Progress indicator */}
        <div className="mb-8">
          <div className="flex items-center justify-between">
            {steps.map((step, index) => (
              <div key={step.id} className="flex items-center">
                <div className={`w-8 h-8 rounded-full flex items-center justify-center ${
                  index <= currentStep
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-200 text-gray-400'
                }`}>
                  {index + 1}
                </div>
                {index < steps.length - 1 && (
                  <div className={`w-24 h-1 mx-2 ${
                    index < currentStep
                      ? 'bg-blue-600'
                      : 'bg-gray-200'
                  }`} />
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Step content */}
        <div className="bg-white dark:bg-slate-900 rounded-xl p-8 shadow-lg">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-8">
            {currentStepData.title}
          </h2>

          <div className="grid grid-cols-2 gap-4 mb-8">
            {currentStepData.options.map((option) => (
              <button
                key={option.id}
                onClick={() => handleSelection(currentStepData.id, option.id)}
                className={`p-6 rounded-xl text-left transition-all ${
                  selections[currentStepData.id] === option.id
                    ? 'bg-blue-50 dark:bg-blue-900/20 border-2 border-blue-500'
                    : 'bg-gray-50 dark:bg-slate-800 border-2 border-transparent hover:border-blue-300'
                }`}
              >
                <h3 className="font-semibold text-gray-900 dark:text-white mb-2">
                  {option.label}
                </h3>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  {option.description}
                </p>
              </button>
            ))}
          </div>

          <Button
            variant="primary"
            size="lg"
            icon={ArrowRight}
            className="w-full"
            disabled={!selections[currentStepData.id]}
            onClick={handleContinue}
          >
            {currentStep === steps.length - 1 ? 'See Your Custom Demo' : 'Continue'}
          </Button>
        </div>
      </div>
    </div>
  );
}