import React, { useState } from 'react';
import { Building2, DollarSign, Target, ArrowRight, Users, Star, ChartBar, Filter, Search, Briefcase, Globe } from 'lucide-react';
import Button from '../components/ui/Button';

const deals = [
  {
    company: 'AI Analytics Pro',
    stage: 'Series A',
    raising: '$5M',
    valuation: '$25M',
    traction: '125% MoM growth',
    industry: 'Enterprise AI',
    location: 'San Francisco',
    highlights: [
      '$2.1M ARR',
      '156 enterprise customers',
      'AI-powered analytics platform'
    ],
    status: 'active',
    progress: 65
  },
  {
    company: 'DataFlow Systems',
    stage: 'Seed',
    raising: '$2.5M',
    valuation: '$12M',
    traction: '45K monthly users',
    industry: 'Data Infrastructure',
    location: 'New York',
    highlights: [
      '$850K ARR',
      '32% MoM growth',
      'Data processing automation'
    ],
    status: 'active',
    progress: 82
  },
  {
    company: 'ML Security',
    stage: 'Series B',
    raising: '$15M',
    valuation: '$85M',
    traction: '$4.2M ARR',
    industry: 'Cybersecurity',
    location: 'Boston',
    highlights: [
      '98% customer retention',
      '200+ enterprise clients',
      'AI threat detection'
    ],
    status: 'closing soon',
    progress: 92
  }
];

const filters = [
  { name: 'Stage', options: ['Seed', 'Series A', 'Series B', 'Growth'] },
  { name: 'Industry', options: ['Enterprise AI', 'Data Infrastructure', 'Cybersecurity', 'FinTech'] },
  { name: 'Deal Size', options: ['$1M-5M', '$5M-10M', '$10M-25M', '$25M+'] }
];

export default function DealFlow() {
  const [activeFilters, setActiveFilters] = useState<Record<string, string>>({});
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50/20 to-gray-50 dark:from-slate-950 dark:via-blue-950/20 dark:to-slate-950 pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-16">
          <div className="inline-flex items-center justify-center p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30 mb-4">
            <Briefcase className="w-6 h-6 text-blue-600 dark:text-blue-400" />
          </div>
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Active Deal Flow
          </h1>
          <p className="text-lg text-gray-600 dark:text-gray-300 mb-8">
            Curated investment opportunities in high-growth AI ventures
          </p>

          {/* Quick Stats */}
          <div className="grid grid-cols-4 gap-6">
            {[
              { icon: Building2, value: '45+', label: 'Active Deals' },
              { icon: DollarSign, value: '$250M+', label: 'Total Deal Value' },
              { icon: Star, value: '92%', label: 'Success Rate' },
              { icon: Users, value: '150+', label: 'Investor Network' }
            ].map(stat => (
              <div key={stat.label} className="bg-white/80 dark:bg-slate-900/80 backdrop-blur-sm rounded-xl p-6 border border-gray-200/50 dark:border-gray-800/50">
                <stat.icon className="w-6 h-6 text-blue-600 dark:text-blue-400 mx-auto mb-2" />
                <div className="text-2xl font-bold text-gray-900 dark:text-white mb-1">{stat.value}</div>
                <div className="text-sm text-gray-600 dark:text-gray-400">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>

        {/* Filters & Search */}
        <div className="mb-8 flex gap-4">
          <div className="flex-1 relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
            <input
              type="search"
              placeholder="Search deals by company, industry, or location..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
            />
          </div>
          {filters.map(filter => (
            <select
              key={filter.name}
              onChange={(e) => setActiveFilters(prev => ({ ...prev, [filter.name]: e.target.value }))}
              className="px-4 py-3 rounded-lg border border-gray-200 dark:border-gray-700 bg-white dark:bg-slate-800"
            >
              <option value="">{filter.name}</option>
              {filter.options.map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          ))}
        </div>

        {/* Deal Cards */}
        <div className="space-y-6 mb-16">
          {deals.map(deal => (
            <div
              key={deal.company}
              className="group relative bg-white dark:bg-slate-900 rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-200/50 dark:border-gray-800/50"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-500/5 to-violet-500/5 dark:from-blue-400/5 dark:to-violet-400/5 opacity-0 group-hover:opacity-100 transition-opacity rounded-xl" />
              
              <div className="relative">
                <div className="flex items-center justify-between mb-6">
                  <div>
                    <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-1">
                      {deal.company}
                    </h3>
                    <div className="flex items-center gap-4">
                      <span className="text-blue-600 dark:text-blue-400">{deal.stage}</span>
                      <span className="text-gray-600 dark:text-gray-400">{deal.industry}</span>
                      <span className="text-gray-600 dark:text-gray-400">{deal.location}</span>
                    </div>
                  </div>
                  <div className="text-right">
                    <div className="text-2xl font-bold text-gray-900 dark:text-white mb-1">
                      {deal.raising}
                    </div>
                    <div className="text-sm text-gray-600 dark:text-gray-400">
                      {deal.valuation} valuation
                    </div>
                  </div>
                </div>

                <div className="grid md:grid-cols-2 gap-8 mb-6">
                  {/* Traction & Metrics */}
                  <div>
                    <h4 className="font-medium text-gray-900 dark:text-white mb-3">Key Metrics</h4>
                    <div className="space-y-2">
                      {deal.highlights.map((highlight, index) => (
                        <div key={index} className="flex items-center text-gray-600 dark:text-gray-300">
                          <div className="w-1.5 h-1.5 rounded-full bg-blue-500 dark:bg-blue-400 mr-2" />
                          {highlight}
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Deal Progress */}
                  <div>
                    <h4 className="font-medium text-gray-900 dark:text-white mb-3">Round Progress</h4>
                    <div className="space-y-2">
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-600 dark:text-gray-400">Committed</span>
                        <span className="font-medium text-blue-600 dark:text-blue-400">
                          {deal.progress}%
                        </span>
                      </div>
                      <div className="h-2 bg-gray-100 dark:bg-gray-800 rounded-full overflow-hidden">
                        <div
                          className="h-full bg-blue-600 dark:bg-blue-400 rounded-full transition-all duration-500"
                          style={{ width: `${deal.progress}%` }}
                        />
                      </div>
                      {deal.status === 'closing soon' && (
                        <div className="text-sm text-amber-600 dark:text-amber-400 animate-pulse">
                          Closing soon - Limited allocation remaining
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <Button
                  variant="primary"
                  size="lg"
                  icon={ArrowRight}
                  className="w-full sm:w-auto"
                >
                  View Deal Details
                </Button>
              </div>
            </div>
          ))}
        </div>

        {/* CTA Section */}
        <div className="text-center">
          <div className="bg-gradient-to-br from-blue-50 to-violet-50 dark:from-blue-900/20 dark:to-violet-900/20 rounded-xl p-8 max-w-3xl mx-auto">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              Looking for Investment Opportunities?
            </h2>
            <p className="text-gray-600 dark:text-gray-400 mb-6">
              Join our investor network to get early access to curated AI venture deals
            </p>
            <Button
              variant="primary"
              size="lg"
              icon={ArrowRight}
            >
              Apply for Investor Access
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}